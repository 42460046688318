const axios = require("axios")
const safeJsonStringify = require("safe-json-stringify")

const { CERTIFICATION_TYPE, NEW_API_URL, NEW_API_KEY } = require(".")
const { LOCALES } = require("./i18n/utils")
const { isContentActive } = require("../components/contentful/util")
const {
    getStoresByStatus,
    getContentfulStore,
    getCoupons,
    getDiscountsOrAmenities,
    getDMALocationOrder,
    getDMAPageList,
    getEntriesByType,
} = require("./contentful/api")
const { ContentType } = require("./contentful/graphql")
const { getEnvironmentVariable } = require("./env")
const { handleError } = require("./handlers")

const STORE_STATUS = {
    comingSoon: "New License-Not Open(Pipeline)",
    open: "Open-Generating Business",
}

const getStoreCoupons = async ({ stores, limit, preview }) => {
    let coupons = await getCoupons({ preview })

    const arrayFunction = limit ? "find" : "filter"

    return stores.map((store) => {
        store.coupon =
            coupons[arrayFunction]((coupon) => {
                return (
                    coupon.locationIDs?.find(
                        (locationID) =>
                            locationID === store.storeId.toString() ||
                            locationID === "all"
                    ) && isContentActive({ ...coupon, preview })
                )
            }) || null

        return store
    })
}

const getStoreDiscountsOrAmenities = async ({ list, preview, type }) => {
    let returnValue
    const name =
        type === "discount" ? "Store Discount Icons" : "Store Amenities Icons"

    try {
        const discountsAndAmenitiesContent = await getDiscountsOrAmenities({
            name,
            preview,
        })
        returnValue =
            list
                ?.map(
                    (discountOrAmenity) =>
                        discountsAndAmenitiesContent.find(
                            ({ name }) => name === discountOrAmenity.name.trim()
                        ) || null
                )
                .filter((discountOrAmenity) => discountOrAmenity) ?? list
    } catch (err) {
        // TODO: Unsure if this should throw an error and
        // break the build or just return the default value
        return handleError(err, false, list)
    }

    return returnValue
}

const reformatStoreHours = (hours) =>
    Object.keys(hours).forEach((day) => {
        hours[day] = {
            open: "Closed",
            close: "Closed",
            is_open: 0,
        }
    })

const getClosedLocations = async (entity = []) => {
    const closedLocations = await getStoresByStatus("temporarily closed")
    // Pulling in coming soon stores from Contentful as safety net
    // in case data from FranConnect is not accurate
    const comingSoonLocations = await getStoresByStatus("coming soon")

    return entity.map((store) => {
        if (closedLocations?.includes(store.storeId.toString())) {
            store.isLocationClosed = true
            reformatStoreHours(store.hours)
        }
        if (
            store.isComingSoon ||
            comingSoonLocations?.includes(store.storeId.toString())
        ) {
            store.isComingSoon = true //ensure coming soon is set to true if it isn't already
            reformatStoreHours(store.hours)
        }

        return store
    })
}

const getCertificate = (content, name) =>
    content.find((certificationsContent) => {
        return certificationsContent.fields.apiName === name
    })

const API = {
    getStates: async ({
        country_code = getEnvironmentVariable({
            key: "COUNTRY_CODE",
        }),
        isServerCall = true,
    } = {}) => {
        try {
            const res = await axios.post(
                `${NEW_API_URL}/motor/states`,
                {
                    country_code,
                },
                {
                    headers: {
                        apikey: NEW_API_KEY,
                    },
                }
            )

            return res.data || []
        } catch (ex) {
            return handleError(ex, isServerCall)
        }
    },
    getCities: async (stateName) => {
        try {
            const cityRes = await axios.post(
                `${NEW_API_URL}/cities/`,
                { state: stateName },
                {
                    headers: {
                        apikey: NEW_API_KEY,
                    },
                }
            )

            return cityRes.data.cities.sort()
        } catch (ex) {
            return handleError(ex)
        }
    },
    getStores: async ({ country = "", lat, lng, isServerCall = true }) => {
        try {
            const locationsResponse = await axios.post(
                `${NEW_API_URL}/stores`,
                {
                    lat,
                    lng,
                    country,
                },
                {
                    headers: { apikey: NEW_API_KEY },
                }
            )

            const allStores = locationsResponse.data.store || []

            let stores = await getClosedLocations(allStores)

            return stores
        } catch (ex) {
            return handleError(ex, isServerCall)
        }
    },
    getMsoList: async () => {
        try {
            const country = getEnvironmentVariable({
                key: "COUNTRY_CODE",
            })
            const { data } = await axios.get(
                `${NEW_API_URL}/mso-list/${country}`,
                {
                    headers: { apikey: NEW_API_KEY },
                }
            )

            return data?.msos.map(({ mso }) => mso)
        } catch (ex) {
            return handleError(ex)
        }
    },
    getMsoStores: async ({ msoUri, preview }) => {
        try {
            const { data } = await axios.post(
                `${NEW_API_URL}/mso-stores`,
                { msoUri },
                {
                    headers: { apikey: NEW_API_KEY },
                }
            )
            // filter out any stores that are flagged as coming soon
            const openStores = data?.stores?.filter(
                ({ status }) => status === STORE_STATUS.open
            )
            const stores = await getStoreCoupons({
                preview,
                stores: openStores,
                limit: true,
            })

            return stores
        } catch (ex) {
            return handleError(ex)
        }
    },
    getDmaList: async (withName) => {
        try {
            const country = getEnvironmentVariable({
                key: "COUNTRY_CODE",
            })
            const locale = country ? LOCALES[country] : LOCALES.us
            const dmaList = await getDMAPageList({ locale, withName })
            return dmaList
        } catch (ex) {
            return handleError(ex)
        }
    },
    getDmaStores: async ({ dmaUri, preview }) => {
        try {
            const { data } = await axios.post(
                `${NEW_API_URL}/dma-coupons`,
                {
                    dmaUri: dmaUri.toLowerCase(),
                },
                {
                    headers: {
                        apikey: NEW_API_KEY,
                    },
                }
            )
            // filter out any stores that are flagged as coming soon or fleet
            const openStores = data.stores?.filter(
                ({ isFleet, status }) =>
                    status === STORE_STATUS.open && !isFleet
            )

            const storeOrder =
                (await getDMALocationOrder(preview)).find(({ area }) => {
                    return (
                        `${area.toLowerCase()}-area` ===
                        data?.dma?.toLowerCase()
                    )
                })?.locations ?? []

            const stores = (
                await getStoreCoupons({
                    preview,
                    stores: openStores,
                    limit: true,
                })
            )
                .sort(
                    (a, b) =>
                        storeOrder.indexOf(a.storeId.toString()) -
                        storeOrder.indexOf(b.storeId.toString())
                )
                .map((store) => ({
                    ...store,
                    dma: data?.dma,
                }))

            // Passing name as null for passing as a static prop
            // A null name should trigger a 404 page
            return {
                name: data?.dma ?? null,
                stores,
                sortedStoreList: storeOrder.length > 0,
            }
        } catch (ex) {
            return handleError(ex)
        }
    },
    getLocations: async ({
        city,
        state,
        country = getEnvironmentVariable({
            key: "COUNTRY_CODE",
        }),
    }) => {
        try {
            const locationsResponse = await axios.post(
                `${NEW_API_URL}/location`,
                {
                    city,
                    state,
                    country,
                },
                {
                    headers: {
                        apikey: NEW_API_KEY,
                    },
                }
            )

            let allStores = locationsResponse.data?.length
                ? locationsResponse.data[0].stores
                : []

            let stores = await getClosedLocations(allStores)

            return stores
        } catch (ex) {
            // Do not throw error - return empty array
            return handleError(ex, false, [])
        }
    },
    getStore: async ({ selectedStoreId, preview }) => {
        try {
            const storeResponse = await axios.get(
                `${NEW_API_URL}/store/` + selectedStoreId,
                {
                    headers: {
                        apikey: NEW_API_KEY,
                    },
                }
            )

            if (!Object.keys(storeResponse.data).length) return

            const storeWithCoupons = (
                await getStoreCoupons({
                    stores: [storeResponse?.data],
                    preview,
                })
            )[0]

            const storeStatus = await getClosedLocations([storeWithCoupons])
            const store = storeStatus[0]

            // add the services content from Contentful
            let servicesContent = await getEntriesByType(
                ContentType.Card.name,
                {
                    preview,
                    "metadata.tags.sys.id[in]": "service",
                    "fields.apiName[exists]": true,
                }
            )
            servicesContent = servicesContent.items
            store.services = store?.services
                ?.map((service) => {
                    let content = servicesContent.find((serviceContent) => {
                        return (
                            serviceContent.fields.apiName ===
                            service.name.trim()
                        )
                    })

                    if (content?.fields?.actions?.length) {
                        // Filter out booking appointment buttons if the store does not have an active schedule
                        content.fields.actions = content.fields.actions
                            .map((action) => {
                                if (
                                    action.fields.name ===
                                        "Store - Book an Appointment Button" &&
                                    (!store.scheduleActive ||
                                        store.isComingSoon)
                                ) {
                                    return null
                                }
                                return action
                            })
                            .filter((action) => action)
                    }

                    content = content || null

                    return content
                })
                .filter((service) => service) // filter out unsupported services

            store.services = safeJsonStringify(store.services) // make JSON safe for parsing without circular refs
            store.services = JSON.parse(store.services)

            // add the discount icon content from Contentful
            store.discounts = await getStoreDiscountsOrAmenities({
                list: store?.discounts,
                preview,
                type: "discount",
            })
            store.discounts = safeJsonStringify(store.discounts) // make JSON safe for parsing without circular refs
            store.discounts = JSON.parse(store.discounts)

            // add the amenities icon content from Contentful
            store.amenities = await getStoreDiscountsOrAmenities({
                list: store?.amenities,
                preview,
                type: "amenities",
            })
            store.amenities = safeJsonStringify(store.amenities) // make JSON safe for parsing without circular refs
            store.amenities = JSON.parse(store.amenities)

            const contentfulStore = await getContentfulStore({
                preview,
                storeId: store.storeId,
            })

            store.certificationOrder =
                contentfulStore?.certificationOrder || "default"

            //add the certification content from Contentful
            let certificationsContent = await getEntriesByType(
                ContentType.Card.name,
                {
                    preview,
                    "metadata.tags.sys.id[in]": "certification",
                    "fields.apiName[exists]": true,
                }
            )

            certificationsContent = certificationsContent.items

            store.oem =
                store?.oem
                    ?.map((certification) => {
                        if (certification?.oemProgramStatus !== "Active") return

                        let content = getCertificate(
                            certificationsContent,
                            certification.oem?.trim()
                        )
                        return content || null
                    })
                    .filter((certification) => certification) || [] // filter out unsupported services

            if (store.currentCertificationLevel) {
                let content = getCertificate(
                    certificationsContent,
                    store.currentCertificationLevel
                )

                if (content) {
                    const platinum = getCertificate(
                        certificationsContent,
                        CERTIFICATION_TYPE.platinum
                    )
                    const gold = getCertificate(
                        certificationsContent,
                        CERTIFICATION_TYPE.gold
                    )
                    switch (content?.fields?.apiName) {
                        case CERTIFICATION_TYPE.diamond:
                            store.oem = [...store.oem, content, platinum, gold]
                            break
                        case CERTIFICATION_TYPE.platinum:
                            store.oem = [...store.oem, content, gold]
                            break
                        case CERTIFICATION_TYPE.gold:
                            store.oem.push(content)
                            break
                        default:
                            break
                    }
                }
            }

            if (store.certificateICBC) {
                let content = getCertificate(certificationsContent, "ICBC")

                if (content) store.oem.push(content)
            }

            if (
                store.certifications.find(
                    (certification) =>
                        certification.name ===
                        "Better Business Bureau Accreditation"
                )
            ) {
                let content = getCertificate(
                    certificationsContent,
                    "Better Business Bureau Accreditation"
                )

                if (content) store.oem.push(content)
            }

            if (
                store.iCARGoldCertified &&
                isContentActive({
                    expirationDate: new Date(
                        store.iCARGoldCertifiedExpDate
                    ).toISOString(),
                })
            ) {
                let content = getCertificate(
                    certificationsContent,
                    "I-CAR Gold"
                )

                if (content) store.oem.push(content)
            }

            store.oem = safeJsonStringify(store.oem) // make JSON safe for parsing without circular refs
            store.oem = JSON.parse(store.oem)

            return store
        } catch (ex) {
            // Do not throw error - return null
            return handleError(ex, false, null)
        }
    },
    getPromos: async (promo) => {
        try {
            const res = await axios.post(
                `${NEW_API_URL}/promo-coupons`,
                {
                    promoUri: promo,
                },
                {
                    headers: {
                        apikey: NEW_API_KEY,
                    },
                }
            )

            if (!res?.data.length) return null

            if (
                res.data[0]["New URL"].includes(promo) &&
                !res.data[0].Disclaimer?.startsWith("Disclaimer:")
            ) {
                res.data[0].Disclaimer = `Disclaimer: ${res.data[0].Disclaimer}`
            }

            return res.data[0]
        } catch (err) {
            console.log("error at promo api", err, err.response)
            return null
        }
    },
}

module.exports = {
    API,
}
