/** @jsxImportSource @emotion/react */
/** @format */

import { css } from "@emotion/react"
import { Grid, Typography } from "@mui/material"
import MD from "react-markdown"

import Link from "../Link"
import { Heading } from "."

export const Testimonial = ({
    heading: { fields: heading } = {},
    body,
    quote,
}) => {
    return (
        <Grid item container direction={"column"} alignContent={"center"}>
            <Typography
                component={"blockquote"}
                css={css`
                    color: #000;
                    width: 75%;
                `}
            >
                {heading && <Heading {...heading} />}
                {body && (
                    <MD
                        components={{
                            a: ({ node, ...props }) => {
                                return <Link {...props} underline="hover" />
                            },
                        }}
                        css={css`
                            font-size: 1.4em;
                            font-style: italic;
                        `}
                    >
                        {body}
                    </MD>
                )}
                {quote && (
                    <Grid
                        item
                        paddingLeft={{
                            xs: 0,
                            sm: "50%",
                        }}
                        css={css`
                            font-size: 1.2em;
                        `}
                    >
                        {quote}
                    </Grid>
                )}
            </Typography>
        </Grid>
    )
}
