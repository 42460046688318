/** @format */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api"
import { useRef } from "react"
import { Button, Grid, InputAdornment, OutlinedInput } from "@mui/material"
import { Search } from "@mui/icons-material"
import { useTranslation } from "next-i18next"
import { GOOGLE_MAP_API_KEY } from "../../util"

const DmaSearch = ({ onInputFocus, onPlaceChanged: onPlaceChangedProp }) => {
    const { t } = useTranslation()

    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: GOOGLE_MAP_API_KEY,
    })

    const autocompleteInput = useRef()

    const handleKeyUp = (event) => {
        //key code for enter
        if (event.keyCode === 13) {
            event.preventDefault()
            event.target.blur()
        }
    }

    const onPlaceChanged = () => {
        if (autocompleteInput.current)
            onPlaceChangedProp?.(autocompleteInput.current.value)
    }

    return (
        isLoaded && (
            <Grid
                container
                item
                xs={12}
                className={`search-location-container`}
                wrap="nowrap"
                justifyContent="center"
                css={css`
                    width: auto; // override full-width
                    flex-grow: 1;
                `}
            >
                <Autocomplete
                    className={`locationsSearchbox`}
                    onPlaceChanged={onPlaceChanged}
                    options={{
                        types: [
                            "postal_code",
                            "locality",
                            "administrative_area_level_1",
                        ],
                        componentRestrictions: {
                            country: t("current_locale"),
                        },
                    }}
                >
                    <OutlinedInput
                        inputRef={autocompleteInput}
                        style={{
                            borderRadius: 0,
                            margin: 0,
                            backgroundColor: "white",
                            textAlign: "center",
                        }}
                        fullWidth
                        placeholder={t("store_search")}
                        onKeyUp={handleKeyUp}
                        onFocus={onInputFocus}
                        endAdornment={
                            <InputAdornment position="end">
                                <Search
                                    style={{
                                        color: "rgba(0, 0, 0, 0.23)",
                                    }}
                                />
                            </InputAdornment>
                        }
                    />
                </Autocomplete>
                <Button
                    type="submit"
                    css={css`
                        background-color: #fff;
                        border-radius: 0;
                        text-transform: uppercase;
                        border: 3px solid #006bb7;
                        color: #214e70;
                        padding: 0 1.5em;
                        cursor: pointer;
                        font-weight: 800;
                        &:hover {
                            background-color: #006bb7;
                            color: #fff;
                        }
                        margin: 0 0.25rem;
                        height: auto;
                    `}
                    onClick={onPlaceChanged}
                >
                    Find
                </Button>
            </Grid>
        )
    )
}

export default DmaSearch
