/** @format */

import { createTheme } from "@mui/material/styles"
import { red } from "@mui/material/colors"

const theme = createTheme({
    palette: {
        primary: {
            main: "#004E83",
            dark: "#2c5878",
            light: "#006bb7",
        },
        secondary: {
            main: "#AF0E11",
        },
        textPrimary: {
            main: "#595959",
            dark: "#214e70",
        },
        gray: {
            main: "#333",
            light: "#595959",
            dark: "#333",
            contrastText: "#EBEBEB",
        },
        error: {
            main: red.A400,
        },
        info: {
            main: "#fffde7",
        },
        green: {
            main: "#b5c427",
        },
    },
    props: {
        MuiTypography: {
            variantMapping: {
                h1: "h1",
                h2: "h2",
                h3: "h3",
                h4: "h4",
                h5: "h5",
                h6: "h6",
                body1: "p",
            },
        },
    },
    typography: {
        fontFamily: [
            '"Gilroy"',
            '"Gotham"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
        htmlFontSize: 16,
        h1: {
            fontFamily: "Gilroy Medium",
            fontSize: "2.5rem",
            fontWeight: 800,
            lineHeight: "1.2",
            margin: "8px 0px",
        },
        h2: {
            fontFamily: "Gilroy Medium",
            fontSize: "2rem",
            fontWeight: 700,
            lineHeight: "1.2",
            margin: "8px 0px",
        },
        h3: {
            fontFamily: "Gilroy Medium",
            fontSize: "1.8rem",
            fontWeight: 700,
            lineHeight: "1.2",
            margin: "8px 0px",
        },
        h4: {
            color: "#000000",
            fontFamily: "Gilroy Medium",
            fontSize: "1.4rem",
            fontWeight: 600,
            lineHeight: "1.2",
            margin: "8px 0px",
        },
        h5: {
            fontFamily: "Gilroy Medium",
            fontSize: "1rem",
            fontWeight: 500,
            margin: "8px 0px",
        },
        h6: {
            fontFamily: "Gilroy Medium",
            fontSize: "1rem",
            fontWeight: 500,
            margin: "8px 0px",
        },
        body1: {
            fontFamily: "Gilroy Regular",
            fontSize: "1rem",
            margin: "2px 0px",
        },
        body2: {
            fontFamily: "Gilroy Regular",
            fontSize: "0.9rem",
        },
        body3: {
            fontFamily: "Gilroy Regular",
            fontSize: "0.8rem",
        },

        navigationLabel: {
            fontFamily: "Gilroy Medium",
            lineHeight: 1.1,
            textTransform: "uppercase",
            margin: "2px 6px",
            color: "#595959",
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    height: 40,
                    borderRadius: 0,
                    margin: "5px 12px 5px 0px",
                },
            },
        },
        // MuiTextField: {
        //     styleOverrides: {
        //         root: {
        //             border: "1px solid #979797",
        //             borderRadius: 0,
        //             padding: "8px 0px 0px 12px",
        //             margin: "10px 0px",
        //             lineHeight: "2em",
        //         },
        //     },
        // },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    backgroundColor: "#FFFFFF",
                    margin: 0,
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    marginTop: "12px",
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    // border: "5px solid #004E83",
                    borderRadius: 0,
                    lineHeight: "1.2",
                    height: "45px",
                    margin: "10px 0px",
                },
            },
        },
        MuiStepLabel: {
            styleOverrides: {
                label: {
                    fontSize: "0.8rem",
                    "@media only screen and (min-width:900px)": {
                        fontSize: "0.9rem",
                    },
                },
            },
        },
    },
})

export default theme
