/** @format */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { Grid, Typography, useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/styles"
import { useContext } from "react"
import { useSWRConfig } from "swr"

import { Context } from "../../context"
import {
    STATIC_IMAGE_PATH,
    cleanPhone,
    onPhoneClick,
    reformatPhone,
} from "../../util"
import BookAnAppointment from "../Buttons/BookAnAppointment"
import EstimateOnline from "../Buttons/EstimateOnline"
import LinkButton from "../Buttons/LinkButton"
import { Heading } from "../contentful"
import Link from "../Link"
import { hoursAccordion } from "../util"
import { TopStoreCertification } from "./TopStoreCertification"

const paymentsData = [
    {
        name: "Discover",
        asset: "discover",
    },
    {
        name: "Visa",
        asset: "visa",
    },
    {
        name: "MasterCard",
        asset: "mastercard",
    },
    {
        name: "American Express",
        asset: "americanexpress",
    },
    {
        name: "Car Care One",
        asset: "carcareone",
    },
]

export const findCertification = (store, certificationName) => {
    return store?.oem?.find((certification) => {
        const cert = certification.fields || certification

        return cert.apiName === certificationName
    })
}

const mapIcons = (iconList) =>
    iconList?.map(({ icon, name, text }) => (
        <Typography variant="body2" display="flex" fontWeight="500" key={name}>
            <i
                className={icon}
                css={css`
                    width: 20%;
                    text-align: center;
                `}
            />
            {text}
        </Typography>
    ))

export const StoreDetails = ({ ...props }) => {
    const { state } = useContext(Context)
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
    const { fallback } = useSWRConfig()

    const { currentStore: store = fallback?.store } = state

    let { heading = {} } = props
    heading = heading?.fields || heading

    if (!store) {
        return null
    }

    const hasCertificates =
        store.currentCertificationLevel ||
        store.certificateICBC ||
        store.oem?.length > 0

    const amenities = store.amenities ? mapIcons(store.amenities) : null
    const discounts = store.discounts ? mapIcons(store.discounts) : null

    const storeHours = () => {
        if (!store?.hours) return null

        return hoursAccordion(store.hours)
    }

    const renderSplDiscount = () => {
        const showAmenities = amenities && amenities.length > 0
        const showDiscounts = discounts && discounts.length > 0

        return showAmenities || showDiscounts ? (
            <Grid item container xs={12} md={isMobile ? undefined : 7}>
                {showAmenities ? (
                    <Grid
                        item
                        xs={5}
                        md={5}
                        css={css`
                            position: relative;
                        `}
                    >
                        <Typography variant="body1" fontWeight="bold">
                            Amenities
                        </Typography>
                        {amenities}
                    </Grid>
                ) : null}

                {showDiscounts ? (
                    <Grid
                        item
                        xs={7}
                        md={7}
                        css={css`
                            position: relative;
                            padding-left: 10px;
                            border-left: 1px dashed #595959;
                        `}
                    >
                        <>
                            <Typography variant="body1" fontWeight="bold">
                                Special Discounts
                            </Typography>
                            {discounts}
                        </>
                    </Grid>
                ) : null}
            </Grid>
        ) : null
    }

    return (
        <Grid
            item
            container
            spacing={2}
            css={css`
                padding: 3px 0 3px 0;
            `}
        >
            {store.storeId && hasCertificates ? (
                <Grid
                    item
                    container
                    xs={12}
                    sm={4}
                    direction="column"
                    alignItems="center"
                >
                    <TopStoreCertification store={store} />
                </Grid>
            ) : null}

            <Grid
                item
                container
                direction={"column"}
                flexWrap={"nowrap"}
                xs={12}
                sm={hasCertificates ? 8 : 12}
            >
                <Grid item xs={12}>
                    {heading && <Heading {...heading} />}

                    {store.organicPhone ? (
                        <Typography
                            variant="h4"
                            color="primary"
                            css={css`
                                display: block;
                                font-weight: normal;
                                text-decoration: none;
                                margin-left: 7px;
                                margin-top: 0px;
                                &:hover {
                                    text-decoration: underline;
                                }
                            `}
                            component="span"
                        >
                            <Link
                                href={`tel:${cleanPhone(store.organicPhone)}`}
                                onClick={() => onPhoneClick(store)}
                            >
                                {reformatPhone(store.organicPhone)}
                            </Link>
                        </Typography>
                    ) : (
                        <Typography variant={`h1`} color={`primary`}>
                            {`Location Coming Soon`}
                        </Typography>
                    )}
                </Grid>

                <Grid item xs={12} md={5}>
                    <Typography variant={`body1`}>
                        {store.storeAddress}
                    </Typography>
                    <Typography variant={`body1`}>
                        {`${store.storeCity}, ${store.storeState} ${store.storePostcode}`}
                    </Typography>
                </Grid>

                {!isMobile && renderSplDiscount()}

                {/* TODO What does this have to do with services? */}
                {store.services?.length ? (
                    <Grid item xs={12} md={6}>
                        <Typography
                            component="span"
                            variant="body1"
                            css={css`
                                font-weight: 600;
                                margin: 12px 0;
                            `}
                            className="hidden-xs"
                        >
                            <Link
                                target="_blank"
                                href={`https://www.google.com/maps/dir/?api=1&destination=${store.storeAddress},${store.storeCity},${store.storeState}`}
                            >
                                Get Directions
                            </Link>
                        </Typography>

                        {store && store.hours && (
                            <>
                                <Typography variant="body1" fontWeight="bold">
                                    {`Store Hours:`}
                                </Typography>

                                {storeHours()}
                            </>
                        )}
                    </Grid>
                ) : (
                    ""
                )}

                {store.paymentsAccepted?.length > 0 && (
                    <Grid
                        item
                        xs={12}
                        md={6}
                        css={css`
                            position: relative;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-end;
                        `}
                    >
                        <Typography
                            variant={`body1`}
                            css={css`
                                color: #006bb7;
                                font-weight: 600;
                            `}
                        >
                            {`CREDIT CARDS ACCEPTED`}
                        </Typography>

                        <div>
                            <div
                                className="centrinfo-creditcards-list"
                                css={css`
                                    & > div {
                                        display: inline-block;
                                        width: 45px;
                                        height: 27px;
                                        background-repeat: no-repeat;
                                    }
                                `}
                            >
                                {store.paymentsAccepted.map((payment) => {
                                    const payments = paymentsData.find(
                                        ({ name }) => name === payment
                                    )
                                    if (!payments) return

                                    return (
                                        <div
                                            key={`paymentsAccepted-${payment}`}
                                            className="centerinfo-creditcards-item"
                                            title={payments.name}
                                            css={css`
                                                background-image: url("${STATIC_IMAGE_PATH}icon/creditcard-${payments.asset}.png");
                                            `}
                                        ></div>
                                    )
                                })}
                                <Typography
                                    variant="body2"
                                    component="span"
                                    css={css`
                                        display: inline-block;
                                        margin-top: 12px;
                                        line-height: 1.2rem;
                                    `}
                                    className="xs-block"
                                    title="Financing options"
                                >
                                    <Link
                                        href="/about/maaco-credit-card"
                                        noLinkStyle
                                    >
                                        Learn more about financing options
                                    </Link>
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                )}

                <Grid
                    item
                    container
                    direction={"column"}
                    className={`mobile-t-center`}
                    xs={12}
                    css={css`
                        justify-content: space-around;
                    `}
                >
                    {store.scheduleActive && !store.isComingSoon && (
                        <BookAnAppointment
                            dmaName={store.dma}
                            storeID={store.storeId}
                            shouldOpenAppointments={true}
                        />
                    )}

                    {store.oetActive && !store.isComingSoon && (
                        <EstimateOnline
                            dmaName={store.dma}
                            storeID={store.storeId}
                        />
                    )}

                    <LinkButton
                        linkURL={`/careers/franchise-careers`}
                        label={`NOW HIRING`}
                        css={css`
                            width: 100%;
                        `}
                    />
                </Grid>

                {isMobile && renderSplDiscount()}
            </Grid>
        </Grid>
    )
}
