import { Search } from "@mui/icons-material"
import {
    Button,
    FormHelperText,
    Grid,
    InputAdornment,
    OutlinedInput,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api"
import { useTranslation } from "next-i18next"
import { useCallback, useEffect, useRef, useState } from "react"
import Geocode from "react-geocode"

import { useDataLayerEvent } from "../../../../hooks/useDataLayerEvent.jsx"
import { GOOGLE_MAP_API_KEY } from "../../../../util"
import { API } from "../../../../util/api.js"
import { OET_STEPS } from "../../../../util/globalOETVariables.js"
import { handleCaughtError } from "../../../../util/handlers"

const useStyles = makeStyles((theme) => ({
    distance: {
        color: "#808080",
    },
    directions: {
        color: "#D40E00",
        textDecoration: "underline",
    },
    error: {
        color: "#D40E00",
        fontSize: "1rem",
    },
    separator: {
        height: 1,
        backgroundColor: "#eee",
        width: "100%",
        marginTop: "1rem",
        marginBottom: "1rem",
    },
    select: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
    },
    title: {
        textTransform: "capitalize",
    },
}))

// NOTE: This component is only used within the OET select location step
const StoreSearch = ({
    selected,
    setLoading,
    setStores,
    inputProps = {},
    endAdornment = <Search style={{ color: "rgba(0, 0, 0, 0.23)" }} />,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: GOOGLE_MAP_API_KEY,
    })

    const [autoComplete, setAutoComplete] = useState(null)
    const [error, setError] = useState("")
    const [firstTouch, setFirstTouch] = useState(false)

    Geocode.setApiKey(GOOGLE_MAP_API_KEY)
    const autocompleteInput = useRef(null)

    const autocompleteOnLoad = useCallback(function callback(autocomplete) {
        setAutoComplete(autocomplete)
    }, [])

    const isEnterKey = (event) => {
        if (event.key === "Enter") {
            event.preventDefault()
            event.stopPropagation()
            event.target.blur()
            return
        }
    }

    const handleKeyUp = (event) => {
        if (!firstTouch) setFirstTouch(true)
        isEnterKey(event)
    }

    useDataLayerEvent({
        step: OET_STEPS.selectLocation,
        dataLayerEventTrigger: firstTouch,
    })

    // placeChange is set to true after a user search
    // If select is passed & fetches stores this remains false
    const getStores = useCallback(
        async (postData, placeChange = false) => {
            if (!firstTouch) setFirstTouch(true)
            return await API.getStores({
                ...postData,
                country: t("current_locale"),
            })
                .then((stores) => {
                    if (!stores.length) {
                        setError("No stores found at this location")
                    }
                    setStores(stores, placeChange)
                    setLoading(false)
                })
                .catch((e) =>
                    handleCaughtError("error getting stores from API", e)
                )
        },
        [firstTouch, setLoading, setStores, t]
    )

    const onPlaceChanged = () => {
        setLoading(true)
        setStores([])
        Geocode.fromAddress(autocompleteInput?.current?.value)
            .then((response) => {
                const { lat, lng } = response.results[0].geometry.location
                getStores({ lat, lng }, true)
            })
            .catch((e) => {
                setLoading(false)
                setStores([])
                setError("Address not found")
                handleCaughtError(
                    "Error getting Google geocode from address",
                    e
                )
            })
    }

    useEffect(() => {
        if (selected?.storeLat) {
            getStores({
                lat: selected.storeLat,
                lng: selected.storeLong,
            })
        }
    }, [getStores, selected])

    return (
        isLoaded && (
            <Grid container item justifyContent="center" spacing={4}>
                <Grid
                    container
                    item
                    alignItems="center"
                    justifyContent="center"
                    className={`search-location-container`}
                    columnSpacing={{ xs: 0, md: 2 }}
                    rowSpacing={{ xs: 2, md: 0 }}
                >
                    <Grid item xs={12} md={8}>
                        <Autocomplete
                            className={`locationsSearchbox`}
                            onLoad={autocompleteOnLoad}
                            onPlaceChanged={onPlaceChanged}
                            options={{
                                types: [
                                    "postal_code",
                                    "locality",
                                    "administrative_area_level_1",
                                ],
                                componentRestrictions: {
                                    country: t("current_locale"),
                                },
                            }}
                        >
                            <OutlinedInput
                                inputRef={autocompleteInput}
                                style={{ borderRadius: 0, margin: 0 }}
                                fullWidth
                                placeholder={t("store_search")}
                                onKeyUp={handleKeyUp}
                                onKeyDown={isEnterKey}
                                onFocus={() => setError("")}
                                error={
                                    !!(
                                        autocompleteInput?.current?.value &&
                                        error
                                    )
                                }
                                className={classes.mapInput}
                                endAdornment={
                                    <InputAdornment position="end">
                                        {endAdornment}
                                    </InputAdornment>
                                }
                                {...inputProps}
                            />
                        </Autocomplete>
                    </Grid>
                    <Grid item xs={12} md={1}>
                        <Button
                            variant="outline"
                            sx={{
                                borderRadius: 0,
                                textTransform: "uppercase",
                                border: "3px solid #006bb7",
                                color: "#214e70",
                                height: "auto",
                                margin: 0,
                                width: { xs: "100%", md: "auto" },

                                "&:hover": {
                                    backgroundColor: "#006bb7",
                                    color: "#fff",
                                },
                            }}
                            onClick={onPlaceChanged}
                        >
                            Find
                        </Button>
                    </Grid>
                </Grid>
                <Grid item>
                    <FormHelperText
                        id="component-error-text"
                        className={classes.error}
                    >
                        {error}
                    </FormHelperText>
                </Grid>
            </Grid>
        )
    )
}

export default StoreSearch
