/** @format */

/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"

import { SavedSearch } from "@mui/icons-material"
import ActionButton from "../ActionButton"
import { GTM_EVENTS } from "../../../util"

const EstimateOnline = ({
    storeID = "",
    href = "/online-estimator",
    inTopBar,
    inPopper,
    label = "Get Online Estimate",
    dmaName = "",
}) => {
    const options = inTopBar
        ? {
            fontColor: "#AF0E11",
            bgColorHover: "#c67b78",
            iconColor: "secondary",
            hideMobile: true,
            fontSize: "1.8rem",
            border: true,
            padding: "3px 2px 0 4px",
        }
        : {
            bgColorHover: "#214e70",
            hideMobile: false,
            fontSize: "1.5rem",
        }
    return (
        <ActionButton
            href={storeID ? `${href}?storeid=${storeID}` : href}
            inTopBar={inTopBar}
            inPopper={inPopper}
            bgColor="#006bb7"
            bgColorHover={options.bgColorHover}
            topBgColor="#fff"
            label={label}
            reverse={inTopBar && inPopper}
            fontColor={options.fontColor}
            border={options.border}
            gtmValues={{
                event: GTM_EVENTS.oetCtaClick,
                gtm_storeid: storeID,
                gtm_dma: dmaName,
            }}
        >
            <SavedSearch
                color={options.iconColor}
                className={options.hideMobile ? "hidden-sm" : ""}
                css={css`
                    @media (max-width: 768px) {
                        display: block !important;
                    }
                    font-size: ${options.fontSize};
                    font-weight: bold;
                    margin-top: -2px;
                    padding: ${options.padding};
                `}
            />
        </ActionButton>
    )
}

export default EstimateOnline
