const contentful = require("contentful")

const { isContentActive } = require("../../components/contentful/util")
const { API_PROMOS, LEAD_GEN } = require("../paths")
const {
    accessToken,
    environment,
    host,
    isPreview,
    getLocale,
    space,
} = require("./config")

const getAPIClient = (preview, options) => {
    const CONTENTFUL_PREVIEW = preview || isPreview

    const client = contentful.createClient({
        // adapter: fetchAdapter,
        space,
        environment, // defaults to 'master' if not set
        accessToken: accessToken(CONTENTFUL_PREVIEW),
        host: host(CONTENTFUL_PREVIEW),
        ...options,
    })

    return client
}

const entryDepth = 10 // this is the maximum
const getEntryById = async (id, { preview, ...options }) => {
    const CONTENTFUL_PREVIEW = preview || isPreview
    const detailClient = getAPIClient(CONTENTFUL_PREVIEW)
    const entry = detailClient.getEntry(id, {
        include: entryDepth,
        ...options,
    })

    return entry
}

const getEntriesByType = (
    type,
    { preview, resolveLinks = true, ...options } = {}
) => {
    const CONTENTFUL_PREVIEW = preview || isPreview
    const query = {
        content_type: type,
        include: entryDepth,
        select: "fields,sys.contentType",
        ...options,
    }

    const summaryClient = getAPIClient(CONTENTFUL_PREVIEW, { resolveLinks })
    return summaryClient.getEntries(query)
}

let pageContents = {}
const fetchPage = async (
    { filterField, filterValue },
    { preview, locale, ...options }
) => {
    const CONTENTFUL_PREVIEW = preview || isPreview

    // fetch the page without using locale in the filter
    // returns the base entry without any other data
    const entries = await getEntriesByType("page", {
        preview: CONTENTFUL_PREVIEW,
        resolveLinks: false,
        [filterField]: filterValue,
        ...options,
    })

    // Additional check since we are using [match] in the filter field.
    // It is case insensitive and can pull up multiple matches
    let page =
        entries.items.length && entries.items.length > 1
            ? entries.items.find(
                  ({ fields }) =>
                      fields.path.toLowerCase() === filterValue.toLowerCase()
              )
            : entries.items[0]

    if (!page) return null

    const query = {
        include: entryDepth,
        ...options,
    }

    // get the localized content with all data
    pageContents[page.sys.id] =
        pageContents[page.sys.id] ||
        getEntryById(page.sys.id, {
            locale,
            preview: CONTENTFUL_PREVIEW,
            ...query,
        })

    return pageContents[page.sys.id]
}

const getObjectTypename = (obj) => {
    let __typename = obj.__typename || obj.sys?.contentType?.sys?.id

    __typename =
        __typename && __typename.charAt(0).toUpperCase() + __typename.slice(1)

    return __typename
}

const isContentApplicableToStore = (item, storeId) => {
    if (!storeId) return true

    if (!item?.locationIDs) return false // this promo doesn't apply to any stores

    const locationIDs = item.locationIDs.replaceAll(" | ", "|").split("|")
    return locationIDs.indexOf(storeId) >= 0 // this promo applies to this store
}

const getRedirects = async () => {
    const CONTENTFUL_PREVIEW = isPreview

    return (
        await getEntriesByType("redirectList", {
            resolveLinks: true,
            preview: CONTENTFUL_PREVIEW,
            locale: getLocale(),
        })
    ).items
        ?.map(
            (item) =>
                item.fields.redirectUrls.map(({ key, value }) => ({
                    source: key,
                    destination: value,
                    permanent: true,
                    basePath: value.match("^(http|https)://")?.length
                        ? false
                        : undefined,
                })) ?? item
        )
        .pop()
}

const getContentfulStore = async ({
    resolveLinks = true,
    storeId,
    ...options
} = {}) => {
    return (
        await getEntriesByType("store", {
            resolveLinks,
            "fields.storeId": storeId,
            limit: 1,
            ...options,
        })
    ).items
        ?.map((item) => item.fields || item)
        .pop()
}

const getCoupons = async ({
    couponId = null,
    resolveLinks = true,
    ...options
} = {}) => {
    let couponFilter = null
    if (couponId) {
        couponFilter = { "fields.id": couponId, limit: 1 }
    }

    return (
        await getEntriesByType("coupon", {
            resolveLinks,
            order: "fields.priority",
            locale: getLocale(),
            ...couponFilter,
            ...options,
        })
    ).items?.map((coupon) => {
        coupon = coupon.fields || coupon
        coupon.locationIDs = coupon.locationIDs
            ?.replaceAll(" | ", "|")
            .split("|")

        return coupon
    })
}

const getDiscountsOrAmenities = async ({
    resolveLinks = true,
    name,
    preview,
    ...options
} = {}) => {
    return (
        await getEntriesByType("iconList", {
            resolveLinks,
            "fields.name": name,
            preview: preview ?? isPreview,
            ...options,
        })
    ).items
        ?.map((iconItem) => {
            iconItem = iconItem.fields?.icons || iconItem
            return iconItem.map((icon) => icon.fields || icon)
        })
        .pop()
}

const getDMAPageList = async ({
    resolveLinks = true,
    preview,
    locale,
    withName = false,
    ...options
} = {}) => {
    return (
        await getEntriesByType("dmaPageList", {
            resolveLinks,
            preview: preview ?? isPreview,
            locale,
            ...options,
        })
    ).items
        ?.map((dma) => {
            dma = dma.fields?.dmaPages || dma
            return dma
                .map((dmaItem) => {
                    dmaItem = dmaItem.fields || dmaItem
                    const { activeDate, isActive, name, urlBase } = dmaItem
                    const dmaWithName = withName
                        ? { name, url: `${urlBase}-area` }
                        : `${urlBase}-area`
                    return isActive &&
                        isContentActive({ startDate: activeDate })
                        ? dmaWithName
                        : null
                })
                .filter((dma) => dma)
        })
        .pop()
}

const getDMALocationOrder = async ({
    resolveLinks = true,
    preview,
    ...options
} = {}) => {
    return (
        await getEntriesByType("dmaLocationOrder", {
            resolveLinks,
            preview: preview ?? isPreview,
            ...options,
        })
    ).items
        ?.map((dma) => {
            dma = dma.fields?.locations || dma
            return dma.map(({ key, value }) => ({
                area: key,
                locations: value?.replaceAll(" | ", "|").split("|"),
            }))
        })
        .pop()
}

const getAllPromos = async ({ defaultLocale, preview } = {}) => {
    const apiPromos = [...API_PROMOS, ...LEAD_GEN].map(({ path }) => ({
        path: path,
        type: "api",
    }))
    const locale = defaultLocale ?? getLocale()

    const contentfulPromos = await getPromos({
        preview,
        resolveLinks: false,
        locale,
        "fields.hasLandingPage": true,
    })

    const allPromos = [...apiPromos, ...contentfulPromos]

    return allPromos
}

const getPromos = async ({
    storeId,
    resolveLinks = false,
    ...options
} = {}) => {
    return (
        await getEntriesByType("promotionoffer", {
            resolveLinks,
            order: "fields.priority",
            ...options,
        })
    ).items
        ?.map((item) => {
            item = item.fields || item
            item.type = "contentful"

            if (storeId && !isContentApplicableToStore(item, storeId))
                return null

            return item
        })
        .filter((item) => item)
}

const getStoresByStatus = async (status) => {
    return (
        await getEntriesByType("storeStatus", {
            "fields.name": status,
        })
    ).items
        ?.map((location) => {
            location = location.fields || location
            location.locationIDs = location.locationIDs
                ?.replaceAll(" | ", "|")
                .split("|")

            return location.locationIDs
        })
        .pop()
}

const getArticles = async ({
    resolveLinks = false,
    preview,
    ...options
} = {}) => {
    const previewContent = preview ?? isPreview
    return (
        await getEntriesByType("article", {
            resolveLinks,
            preview: previewContent,
            ...options,
        })
    ).items
        ?.map((item) => {
            item = item.fields || item
            return isContentActive({
                startDate: item.date,
                preview: previewContent,
            })
                ? item
                : null
        })
        .filter((article) => article)
}

const getOetStepContent = async ({
    resolveLinks = true,
    preview,
    step,
    ...options
} = {}) => {
    const previewContent = preview ?? isPreview
    return (
        await getEntriesByType("oetStep", {
            "fields.name": step,
            resolveLinks,
            preview: previewContent,
            locale: getLocale(),
            ...options,
        })
    ).items
        ?.map((item) => {
            item = item.fields || item
            return item
        })
        .pop()
}

const getFeatureFlagByLocation = async (
    locationID,
    { resolveLinks = true, preview, ...options } = {}
) => {
    return (
        await getEntriesByType("featureFlags", {
            resolveLinks,
            preview: preview ?? isPreview,
            ...options,
        })
    ).items
        ?.map((item) => {
            item = item.fields?.featureFlags || item
            return item.find(({ value }) => value.includes(locationID))
        })
        .pop()
}

module.exports = {
    fetchPage,
    getAllPromos,
    getContentfulStore,
    getDiscountsOrAmenities,
    getDMALocationOrder,
    getDMAPageList,
    getEntryById,
    getEntriesByType,
    getFeatureFlagByLocation,
    getObjectTypename,
    getOetStepContent,
    getPromos,
    getCoupons,
    getRedirects,
    getArticles,
    getStoresByStatus,
}
