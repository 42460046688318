export const PAGE_SCHEMA = JSON.stringify({
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Maaco",
    url: "https://www.maaco.com/",
    sameAs: [
        "https://www.facebook.com/MaacoUSA/",
        "https://twitter.com/maaco",
        "https://www.youtube.com/user/MaacoAutoBodyShop",
        "https://www.instagram.com/maaconorthamerica/",
    ],
    legalName: "Maaco Franchising, Inc.",
    logo: "https://static.maaco.com/resources/front/resources/images/logo-usa.png",
})

// export const OET_SCHEMA = `{
//     "@context": "https://schema.org",
//     "@type": "FAQPage",
//      "mainEntity": [{
//       "@type": "Question",
//       "name": "Will my car look like new after a Maaco paint job?",
//       "acceptedAnswer": {
//         "@type": "Answer",
//         "text":"It will look like your car with a new paint job.Although a new paint job goes a long way towards making your vehicle look great, nothing we can do will make your car look new again."
//       }
//     }, {
//       "@type": "Question",
//       "name": "Should I wash my car before I bring it in for painting?",
//       "acceptedAnswer": {
//         "@type": "Answer",
//         "text":"It is not necessary, but we welcome you too! The cleaner your vehicle is, the easier it is to prepare for paint."
//       }
//     }, {
//       "@type": "Question",
//       "name": "How long does it take to paint my car?",
//       "acceptedAnswer": {
//         "@type": "Answer",
//         "text":"80% of all vehicles we paint are ready in 2-3 business days. You can add a day or two if you have a significant amount of bodywork, need replacement parts or if your vehicle requires extra preparation to repair existing paint damage. It is not uncommon for customers to drop their car off early one day and have it back by the end of the next business day!"
//       }
//     }, {
//       "@type": "Question",
//       "name": "Will my car look like new after a Maaco paint job?",
//       "acceptedAnswer": {
//         "@type": "Answer",
//         "text":"It will look like your car with a new paint job. Although a new paint job goes a long way towards making your vehicle look great, nothing we can do will make your car look new again."
//       }
//     }, {
//       "@type": "Question",
//       "name": "Is body repair included in the promotion?",
//       "acceptedAnswer": {
//         "@type": "Answer",
//         "text":"Body repair is a labor-intensive process requiring specialized skills, tools and materials. For this reason, we employ extremely skilled technicians that have dedicated their careers to the art of repairing damage to vehicle bodies. The only way for you to know exactly what this will cost is to come by and let us take a look at it. We offer free estimates, any time during business hours, with or without an appointment."
//       }
//     }, {
//       "@type": "Question",
//       "name": "Do you offer rental car assistance?",
//       "acceptedAnswer": {
//         "@type": "Answer",
//         "text":"Contact your local Maaco center about our rental car assistance program. We will help you obtain a rental while your car is being worked on."
//       }
//     }, {
//       "@type": "Question",
//       "name": "What is rust?",
//       "acceptedAnswer": {
//         "@type": "Answer",
//         "text":"Minor damage from rust is easy to ignore,  but really should be fixed immediately. Why? The longer you wait, the more you’ll have to pay later. Rust damage weakens the overall integrity of your vehicle, possibly threatening  your safety in traffic. If untreated, rust eventually leads to irreparable damage. <a href='#oet-rust' title='Learn more about rust - Maaco'>Learn more about rust.</a>"
//       }
//     }, {
//       "@type": "Question",
//       "name": "What are the different possible types of vehicle damage?",
//       "acceptedAnswer": {
//         "@type": "Answer",
//         "text":"Some common causes of vehicle damage include: regular wear and tear, results of a collision, improper washing with damaging products, parking lot and shopping cart incidences, and vandalism. <br> Natural damage from the outdoor elements is also a common form of vehicle damage. The sun’s UV rays, falling branches, hail storms, ice and salt, or rocks and road debris can all be detrimental to your car’s  paint and body. <br> Ignoring damage could lead to worse problems down the road, so be sure to repair any damage as  soon as possible."
//       }
//     }, {
//       "@type": "Question",
//       "name": "Why won't the shop give me an estimate over the phone or from an email with a picture?",
//       "acceptedAnswer": {
//         "@type": "Answer",
//         "text":"Without seeing the car in person, it's nearly impossible to provide an accurate quote. It is very common for vehicles to require additional prep to ensure the smoothest finish possible. This could include peeling, delaminating or cracks in your old finish, which are difficult to spot in pictures."
//       }
//     }, {
//       "@type": "Question",
//       "name": "Do I have to make an appointment for an estimate?",
//       "acceptedAnswer": {
//         "@type": "Answer",
//         "text":"Appointments are not necessary, but we do schedule appointments for your convenience. Select your preferred center and complete our appointment scheduler online or call the center."
//       }
//      }, {
//       "@type": "Question",
//       "name": "Do I have to pay for an estimate?",
//       "acceptedAnswer": {
//         "@type": "Answer",
//         "text":"Estimates are 100% free of charge and obligation."
//       }
//     }, {
//       "@type": "Question",
//       "name": "Will the price I pay be the same as the estimate? No surprises?",
//       "acceptedAnswer": {
//         "@type": "Answer",
//         "text":"The price you pay could be higher than your initial estimate if hidden damage is discovered during the repair and/or refinishing process or if the cost of parts increases. This is extremely uncommon; however when it does occur, we will always call to discuss your options and offer you the opportunity to come in and see the repair area first hand and discuss costs."
//       }
//     }, {
//       "@type": "Question",
//       "name": "How do you paint cars so inexpensively?",
//       "acceptedAnswer": {
//         "@type": "Answer",
//         "text":"Maaco has developed the easiest, most affordable and reliable paint process in the world. Our more than 40 years of experience makes our approach to overall auto painting the best way to turn the car you drive back into the car you love. We also have the advantage of volume. Our more than 475 locations nationwide and long-term relationships with vendors allows us to buy materials at deeply discounted prices. In turn, we pass that savings on to you."
//       }
//     }, {
//       "@type": "Question",
//       "name": "Why does it cost so much to repair a small ding?",
//       "acceptedAnswer": {
//         "@type": "Answer",
//         "text":"We get asked this question more than almost any other question! Repairing a dent or ding is a very involved process. There is a minimum amount of time it takes to repair one dent. A body repair technician must first grind all of the paint off of the area to be repaired, then prepare the filler compound, apply it uniformly, then wait for it to dry. It's then sanded and either glazed or a second coat is applied, at which point you have a second round of drying. We sand it again, mix a 2k primer, apply the primer and paint. <br> If you are concerned about cost, be sure to ask your center about paintless dent repair."
//       }
//     }, {
//       "@type": "Question",
//       "name": "What if I don't want to pay for the extra prep to take out the scratches and dents, won't my car still look better with new paint?",
//       "acceptedAnswer": {
//         "@type": "Answer",
//         "text":"You may choose to simply have your vehicle painted without addressing issues like peeling, cracking and old bodywork. Keep in mind that in order to receive the smoothest possible finish and highest possible gloss, these issues will need to be addressed. If the conditions are bad enough, it can and will affect the gloss and overall appearance of the newly painted surfaces. While you will receive a warranty if you choose to paint over dings and dents, that is not the case if you decline to repair conditions that your estimator has advised you to repair."
//       }
//     }, {
//       "@type": "Question",
//       "name": "How much does it cost to change the color of my car?",
//       "acceptedAnswer": {
//         "@type": "Answer",
//         "text":"If you simply want to change the color of the exterior, there is a charge in addition of your paint package to do so. Although it is not necessary, you can have your door jambs, under the hood and trunk painted for an additional charge."
//       }
//     }, {
//       "@type": "Question",
//       "name": "What are my cost options for removing or replacing parts?",
//       "acceptedAnswer": {
//         "@type": "Answer",
//         "text":"At Maaco we know that you are conscious of the cost. Therefore we offer three price levels when it comes to replacement parts. A more cost effective option is Aftermarket. While new, these parts might not necessarily come from your vehicle's manufacturer. Recycled parts are the  most cost-efficient. They come from vehicles no longer considered 'road-ready' but still adhere to safety and function standards. So if parts replacement is necessary, these three options provide flexibility to help you proceed with confidence."
//       }
//     }, {
//       "@type": "Question",
//       "name": "What should I expect when I file an insurance claim?",
//       "acceptedAnswer": {
//         "@type": "Answer",
//         "text":"If you're making a claim through your insurance company, you'll be happy to know that Maaco works with every major insurance carrier. We'll coordinate with the insurance companies involved, schedule an appointment for your car to be inspected, and even find you a rental car while we make your repairs. Maaco delivers certified bodywork and painting. We use original manufacturer parts, factory specs, and precision assembly to expertly rebuild your car so it looks and drives like it did before the accident."
//       }
//     }, {
//       "@type": "Question",
//       "name": "Do I still need to pay for repairs?",
//       "acceptedAnswer": {
//         "@type": "Answer",
//         "text":"Most insurance policies have a deductible of some amount. You may have to pay this part of the cost. Make sure to speak with your insurance agent to understand what expenses you will have."
//       }
//     }, {
//       "@type": "Question",
//       "name": "Why would I pay out of pocket instead of filing an insurance claim for damage and repairs?",
//       "acceptedAnswer": {
//         "@type": "Answer",
//         "text":"Depending on the amount of damage your car has and the cost of your deductible, you may save by paying out-of-pocket for repairs. There can also be collateral costs, including an increase in the cost of your policy, when you make a claim. Make sure to ask your agent about this before starting work."
//       }
//     }, {
//       "@type": "Question",
//       "name": "What does the Shop Technician need to know for my insurance claim?",
//       "acceptedAnswer": {
//         "@type": "Answer",
//         "text":"If you are using your Insurance to pay for part or all of a repair, the shop will need to know this before an estimate is finalized. Your policy may include specifics about the repair materials or process. This can affect the time required to accomplish the work and the price. <br> You will also need to coordinate with your insurance agent and the shop before finalizing the pricing to confirm all the details."
//       }
//     }, {
//       "@type": "Question",
//       "name": "What is rust?",
//       "acceptedAnswer": {
//         "@type": "Answer",
//         "text":"Rust is that dirty orange-red-brown coating that corrodes metal exposed to air and water. In English, rust is also a verb. So, it’s relentless, continually consuming metal in stages. First, rust forms on the exposed surface. If untreated, that surface rust flakes and proceeds out and downwards, eventually eating right through the metal. Salt speeds the whole process up."
//       }
//     }, {
//       "@type": "Question",
//       "name": "How does rust affect a vehicle?",
//       "acceptedAnswer": {
//         "@type": "Answer",
//         "text":"Rust is a form of corrosion. When it comes to the metal on your car, think of rust as holes-in-training. They weaken the integrity of the body and less visible parts of your vehicle. If untreated, rust also detracts from your vehicle’s resale value and is a potential hazard. We strongly recommend contacting your local Maaco shop right away when you notice any rust.."
//       }
//     }, {
//       "@type": "Question",
//       "name": "How much does rust repair cost?",
//       "acceptedAnswer": {
//         "@type": "Answer",
//         "text":"Costs for minor rust repair can be as low as some sand paper, touch-up paint and elbow grease. However, more advanced rust repair can cost thousands of dollars. So, at some point, you need to consider the diminishing returns. Decide how long you want to keep your ride roadworthy and how much work and rust repair cost it will take to get it there."
//       }
//     }, {
//       "@type": "Question",
//       "name": "What are the steps to repair rust?",
//       "acceptedAnswer": {
//         "@type": "Answer",
//         "text":"Rust is to cars what aging is to people. Meaning? It’s unavoidable, but you can minimize the harm by addressing it early. In fact, the process to fix rust on a car or truck only takes four steps. First, sand the patch of rust down to the remaining metal beneath. Then, add body filler to bond that newly exposed space. Next, sand the filler down, so the bonded area is flush with the surrounding surface. Lastly, paint the entire panel to match the original color of the vehicle. Now it’s beautiful again! Keep in mind, these four steps make rust repair sound simple but even light rust is not easy to fix. It’s highly skilled work. The pros at your local Maaco do it right the first time."
//       }
//     }, {
//       "@type": "Question",
//       "name": "How to stop rust on a car or truck … or at least slow it for a long, long time.",
//       "acceptedAnswer": {
//         "@type": "Answer",
//         "text":"Maintaining a paint job doesn’t just keep your ride looking beautiful, it protects against the natural rusting process. Your local Maaco shop helps with repair and maintenance, including rust repairs. Before that point, here are some useful tips to prevent the spread of rust: <br> <span class='display-block padding-bottom-1'></span> Check your vehicle regularly for new scratches and dents, as this is where rust is likely to form. <br> <span class='display-block padding-bottom-1'></span> Wash and wax your vehicle at least once a month. Be sure to wash within the wheel wells, as well as beneath the vehicle. If you’re washing it by hand, use carwash detergent and not everyday soap from around the house. <br> <span class='display-block padding-bottom-1'></span> In addition to cleaning the exterior, clean your vehicle’s carpets and protective mats. Your footwear is in constant contact with these surfaces and can spread salt onto the rocker panel beneath the door. <br> <span class='display-block padding-bottom-1'></span> Remember, rust is a verb and is constantly looking for exposed metal. Your car is most vulnerable at the vehicle’s connection points. With this in mind, use a flashlight to closely inspect the following areas: <br><ul class='common-list'> <li class='common-list-item'>near and under the sunroof, trims and moulding</li><li class='common-list-item'>the wheel wells and the undercarriage</li><li class='common-list-item'>the door and rocker panel (that base piece you see when you open the door)</li><li class='common-list-item'>the area attaching the mirrors</li></ul> &nbsp; If you find new sources of rust the next time you inspect your vehicle, get an estimate and halt the spread early. Additionally, if you find small (or big) dents that need fixing or sections that need painting, Maaco is here and happy to help"
//       }
//     }, {
//       "@type": "Question",
//       "name": "What is heavy or severe damage to a vehicle?",
//       "acceptedAnswer": {
//         "@type": "Answer",
//         "text":"Severe damage is just like it sounds: very heavy damage. This includes broken axles and bent or twisted frames. In a severe damage situation, air bags have almost always deployed. Examples include if your car has rolled over, or a complete side of the car is damaged. <br> <span class='display-block padding-bottom-1'></span> If your vehicle has suffered major structural damage—such as a bent frame—that makes it unsafe or impossible to drive, it is defined as severe. In many cases, you might assume that a vehicle that has suffered severe damage is totaled and beyond repair. <br> <span class='display-block padding-bottom-1'></span> If your vehicle has a large area of damage (3 or more panels), a large dent (11” or more), or a dent deeper than 2”, it may have heavy or severe damage. In this circumstance, there may be underlying damage that is difficult to see or to assess the full extent of repairs needs. The technician at the Maaco shop can do a thorough inspection of the damaged vehicle and give accurate pricing for repairs."
//       }
//     }, {
//       "@type": "Question",
//       "name": "How do I safely tow my car to Maaco?",
//       "acceptedAnswer": {
//         "@type": "Answer",
//         "text":"If you can put your car in neutral and push it to the side of the road, do so. If you cannot, place cones, flares, flags, or other indicators around your vehicle to alert oncoming traffic of the danger. And if it’s too dangerous to even get out of the car, stay put. Either way, put the hazard blinkers on and put a sign visibly in the car window that says “Towing.” <br> <span class='display-block padding-bottom-1'></span> Contact your local Maaco franchise to find out if they offer towing services or can make recommendations. Find a Maaco near you. When the truck arrives, share the Maaco location and contact information with the tow truck driver."
//       }

//     }]
//   }`
