/** @format */

/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { ExpandMore } from "@mui/icons-material"
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
} from "@mui/material"
import { format } from "date-fns"

import NextLink from "./Link"

export const EMAIL_MSG = (
    <Typography
        css={css`
            font-size: 1rem;
            color: #595959;
        `}
    >
        {`By clicking "Email Me The Coupon" I verify this is my email address and consent to receive communication from Maaco regarding offers and services. Email communication is not required to purchase goods or services. For further details please refer to our `}
        <NextLink
            target="_blank"
            href={"/privacy-policy/"}
        >{`Privacy Policy`}</NextLink>
    </Typography>
)

export const TEXT_MSG = (
    <Typography
        css={css`
            font-size: 1rem;
            color: #595959;
        `}
    >
        {`By clicking "Text Me The Coupon" I verify this is my mobile number and consent to receive text messages from Maaco via automated technology or automated dialing/sequencing systems regarding offers and service communication per Maaco online `}
        <NextLink
            target="_blank"
            href={"/privacy-policy/"}
        >{`Privacy Policy`}</NextLink>
        {` and `}
        <NextLink
            target="_blank"
            href={"/mobileterms/"}
        >{`Text Program Terms of Use`}</NextLink>
        {`. `}
        {`Consent for text communication is not required to purchase goods or services. Message and data rates may apply.`}
    </Typography>
)

// Used in location results and store pages
export const hoursAccordion = (storeHours) => {
    const hours = storeHours
        ? typeof storeHours === "string"
            ? Object.entries(JSON.parse(storeHours))
            : Object.entries(storeHours)
        : null

    const todayDayName = format(new Date(), "EEE")
    const todayHours = hours.find(
        (day) => day[0].substring(0, 3) === todayDayName
    )[1]

    return (
        <Accordion
            sx={{
                mb: 0.5,
                p: 1,

                "&.Mui-expanded, &.Mui-expanded:first-of-type, &.Mui-expanded:last-of-type":
                    {
                        mb: 0.5,
                    },
            }}
        >
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="hours-header"
                id="hours-header"
                sx={{
                    m: 0,
                    minHeight: "auto",
                    p: 0,

                    "&.Mui-expanded, & > .MuiAccordionSummary-content, & > .MuiAccordionSummary-content.Mui-expanded":
                        {
                            m: 0,
                            minHeight: "auto",
                        },
                }}
            >
                <Typography
                    component="div"
                    variant="body2"
                    sx={{
                        display: "inline-flex",
                    }}
                >
                    <Typography
                        sx={{ mr: 0.5 }}
                    >{`${todayDayName}: `}</Typography>
                    <Typography>
                        {todayHours.open === "Closed"
                            ? todayHours.open
                            : `${todayHours.open} - ${todayHours.close}`}
                    </Typography>
                </Typography>
            </AccordionSummary>
            <AccordionDetails
                sx={{ display: "flex", flexFlow: "column", py: 0.5 }}
            >
                {hours.map((day, index) => {
                    const dayName = day[0].substring(0, 3)
                    const dayHours = day[1]
                    const fontFamily =
                        dayName === todayDayName
                            ? "Gilroy Bold"
                            : "Gilroy Regular"
                    return (
                        <Typography
                            key={`${dayName}-${index}`}
                            component="div"
                            variant="body2"
                            sx={{
                                display: "inline-flex",
                                py: 0.25,
                            }}
                        >
                            <Typography
                                sx={{ fontFamily, mr: 0.5 }}
                            >{`${dayName}: `}</Typography>
                            <Typography sx={{ fontFamily }}>
                                {dayHours.open === "Closed"
                                    ? dayHours.open
                                    : `${dayHours.open} - ${dayHours.close}`}
                            </Typography>
                        </Typography>
                    )
                })}
            </AccordionDetails>
        </Accordion>
    )
}
