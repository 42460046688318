import { ArrowDropDown, Visibility, VisibilityOff } from "@mui/icons-material"
import Autocomplete from "@mui/material/Autocomplete"
import CircularProgress from "@mui/material/CircularProgress"
import InputAdornment from "@mui/material/InputAdornment"
import IconButton from "@mui/material/IconButton"
import InputLabel from "@mui/material/InputLabel"
import ListItemText from "@mui/material/ListItemText"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import Stack from "@mui/material/Stack"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { useState } from "react"
import { Controller, useFormContext } from "react-hook-form"

import { getProperCasing, reformatPhone } from "../../../util"

export const CustomLabel = ({ text, name, isId = false, ...props }) => {
    const showId = isId ? { id: name } : { htmlFor: name || text }
    return (
        <InputLabel {...showId} {...props}>
            <Typography variant="body1" sx={{ width: "100%" }}>
                {text}
            </Typography>
        </InputLabel>
    )
}

export const CustomErrorMessage = ({ errorMessage, styles }) => {
    return (
        <Typography variant="body1" color="error" sx={{ ...styles }}>
            {errorMessage}
        </Typography>
    )
}

export const generateLabel = (text, name, isId = false) => {
    const showId = isId ? { id: name } : { htmlFor: name || text }
    return (
        <InputLabel size="small" {...showId} sx={{ mb: 1 }}>
            <Typography fontFamily="Gilroy Medium" variant="body1">
                {text}
            </Typography>
        </InputLabel>
    )
}

export const FormDropdown = ({
    dropdownOptions = [],
    dropdownLabel,
    dropdownLabelKey = "dropdown",
    isLoading = false,
    name,
    placeholder,
    showIfEmpty = false,
    ...props
}) => {
    const {
        control,
        formState: { errors },
    } = useFormContext()

    if (!name || (!dropdownOptions?.length && !showIfEmpty)) return null

    return (
        <>
            {dropdownLabel &&
                generateLabel(dropdownLabel, `${dropdownLabelKey}-label`, true)}
            <Controller
                name={name}
                control={control}
                render={({ field: { onChange, ...field } }) => (
                    <Select
                        {...field}
                        fullWidth
                        IconComponent={(props) =>
                            isLoading ? (
                                <CircularProgress
                                    size={18}
                                    sx={{ display: "inline-flex", mr: 1 }}
                                    {...props}
                                />
                            ) : (
                                <ArrowDropDown {...props} />
                            )
                        }
                        labelId={
                            dropdownLabel
                                ? `${dropdownLabelKey}-label`
                                : undefined
                        }
                        inputProps={
                            !dropdownLabel
                                ? {
                                      "aria-label": `${dropdownLabelKey}-select`,
                                  }
                                : undefined
                        }
                        onChange={(e) => {
                            onChange(e.target.value)
                        }}
                        sx={{
                            "& .MuiSelect-select .notranslate::after":
                                placeholder
                                    ? {
                                          content: `"${placeholder}"`,
                                          opacity: 0.42,
                                      }
                                    : {},
                            "&.MuiInputBase-root.MuiOutlinedInput-root": {
                                height: { xs: "auto", sm: "45px" },
                            },
                        }}
                        {...props}
                    >
                        {dropdownOptions.map(({ label, value }) => (
                            <MenuItem
                                value={value}
                                key={`${dropdownLabelKey}-select-option-${label}`}
                            >
                                <ListItemText
                                    primary={
                                        <Typography
                                            variant="body1"
                                            component="span"
                                            sx={{ textWrap: "wrap" }}
                                        >
                                            {label}
                                        </Typography>
                                    }
                                    sx={{ px: 2, py: 1 }}
                                />
                            </MenuItem>
                        ))}
                    </Select>
                )}
            />

            {errors[name] && (
                <Typography variant="body1" color="error">
                    {errors[name]?.message}
                </Typography>
            )}
        </>
    )
}

export const FormAutoComplete = ({ name, options = {} }) => {
    const { onKeyDown, autoCompleteOptions, autoCompleteOptionsMapFunction } =
        options
    const {
        control,
        defaultValues,
        register,
        formState: { errors },
    } = useFormContext()

    const label = getProperCasing(name)

    return (
        <Stack sx={{ width: "100%" }}>
            {generateLabel(`${label}*`, label)}
            <Controller
                id={name}
                name={name}
                {...register(name)}
                ref={null}
                defaultValue={defaultValues?.name || ""}
                control={control}
                render={({ field: { onChange, value } }) => (
                    <Autocomplete
                        autoHighlight
                        autoSelect
                        options={autoCompleteOptions.map(
                            autoCompleteOptionsMapFunction
                        )}
                        value={value || null}
                        onChange={(_, data) => onChange(data || "")}
                        onKeyDown={(e) => onKeyDown(e)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                sx={{
                                    "& .MuiAutocomplete-input": {
                                        padding: "0px !important",
                                    },
                                }}
                            />
                        )}
                    />
                )}
            />

            <Typography
                variant="body1"
                color="error"
                sx={{ margin: 0, minHeight: 24 }}
            >
                {errors[name]?.message ? errors[name]?.message : ""}
            </Typography>
        </Stack>
    )
}

export const FormInput = ({
    labelText,
    labelId,
    name,
    type = "text",
    inputProps,
    disabled = false,
    characterCount,
    ...props
}) => {
    const [showPassword, setShowPassword] = useState(false)
    const {
        control,
        formState: { errors },
        watch,
    } = useFormContext()

    const onChangeFormHandler = (name, value) => {
        if (name === "phone" && value.length > 1) {
            return reformatPhone(value)
        }
        return value
    }

    const handleClickShowPassword = () => setShowPassword((show) => !show)

    const handleMouseDownPassword = (event) => {
        event.preventDefault()
    }

    return (
        <>
            {labelText && labelId && (
                <CustomLabel
                    text={labelText}
                    name={labelId}
                    sx={{ display: "flex", transform: "none" }}
                />
            )}
            <Controller
                control={control}
                name={name}
                render={({ field: { onChange, value, ...field } }) => (
                    <TextField
                        fullWidth
                        id={labelId}
                        type={
                            type === "password" && showPassword ? "text" : type
                        }
                        disabled={disabled}
                        InputProps={{
                            endAdornment: type === "password" && (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                        sx={{ width: "100%" }}
                                    >
                                        {showPassword ? (
                                            <VisibilityOff />
                                        ) : (
                                            <Visibility />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                            ...inputProps,
                        }}
                        onChange={(e) =>
                            onChange(onChangeFormHandler(name, e.target.value))
                        }
                        value={value}
                        {...props}
                    />
                )}
            />

            {characterCount &&
                `${watch(name)?.length ?? 0} / ${characterCount}`}

            {errors[name] && (
                <CustomErrorMessage
                    errorMessage={errors[name].message}
                    styles={{ mt: 1 }}
                />
            )}
        </>
    )
}
