/** @format */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { yupResolver } from "@hookform/resolvers/yup"
import {
    AdapterDateFns,
    DesktopDatePicker,
    LocalizationProvider,
} from "@mui/lab"
import {
    Button,
    Grid,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material"
import axios from "axios"
import { format } from "date-fns"
import { useContext, useState } from "react"
import { useForm } from "react-hook-form"
import * as Yup from "yup"

import { Context } from "../../context"
import {
    NEW_API_KEY,
    NEW_API_URL,
    containerCSS,
    reformatPhone,
} from "../../util"
import { handleCaughtError } from "../../util/handlers"

const DealTypeArr = [
    { id: 1, title: `Lease Building` },
    { id: 2, title: `Sale Building` },
    { id: 3, title: `Sale Land` },
]

const ZonedAutoArr = [
    { id: 1, title: `Yes` },
    { id: 2, title: `No` },
]

export const RealEstateForm = () => {
    const initialState = {
        selectedDealType: ``,
        selectedState: ``,
        selectedZonedAuto: ``,
        contactPhone: ``,
        selectedDate: null,
    }

    const { state } = useContext(Context)
    const [formState, setFormState] = useState(initialState)
    const [showSuccessMsg, setShowSuccessMessage] = useState(false)
    const [showErrorsMsg, setShowErrorMessage] = useState(false)

    const emailRegExp =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    const phoneRegExp = /^\(\d{3}\)[ ]\d{3}[-]\d{4}$/

    const validationSchema = Yup.object().shape({
        address: Yup.string().required("Enter address"),
        dealType: Yup.string(),
        zonedAuto: Yup.string().required("Please select Zoned for auto"),
        city: Yup.string().required("Enter City"),
        buildingSize: Yup.string(),
        buildingLength: Yup.string(),
        state: Yup.string().required("Please select state"),
        buildingDepth: Yup.string(),
        landSize: Yup.string(),
        country: Yup.string(),
        salePrice: Yup.string(),
        leaseRate: Yup.string(),
        date: Yup.string(),
        landSizeSqFt: Yup.string(),
        propertyTaxes: Yup.string(),
        contactFirstName: Yup.string().required("Enter First name"),
        contactEmail: Yup.string()
            .matches(emailRegExp, "Email is not valid")
            .required("Enter Email"),
        contactLastName: Yup.string().required("Enter Last name"),
        contactPhone: Yup.string().matches(
            phoneRegExp,
            "Phone number is not valid"
        ),
        contactAddress: Yup.string(),
        comments: Yup.string(),
    })

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        getValues,
        watch,
        setValue,
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: "all",
    })

    const handleChange = () => {}

    const getError = (errName) => {
        return (
            errName && (
                <Typography
                    variant="body1"
                    color="error"
                    css={css`
                        margin-top: -15px;
                        padding-top: 7px;
                    `}
                >
                    {errName}
                </Typography>
            )
        )
    }

    const GetTextField = ({ title, name, ...textFieldParams }) => {
        return (
            <>
                <Typography
                    variant={`subtitle2`}
                    css={css`
                        color: #214e70;
                        margin: 10px 0px -10px;
                    `}
                >
                    {title}
                </Typography>
                <TextField
                    {...register(name)}
                    name={name}
                    {...textFieldParams}
                    onChange={handleChange}
                    variant={`outlined`}
                />
            </>
        )
    }

    const resetAllFormData = () => {
        setFormState({
            selectedDate: null,
            selectedDealType: ``,
            selectedState: ``,
            selectedZonedAuto: ``,
            contactPhone: ``,
        })
        let values = getValues()
        Object.keys(getValues()).forEach((fieldName) => {
            if (fieldName !== "rental_country") values[fieldName] = ``
        })
        reset(
            {
                ...values,
            },
            {
                keepValues: false,
                keepErrors: false,
                keepTouched: false,
                keepDefaultValues: true,
            }
        )
    }

    const onSubmitClick = () => {
        const values = { ...getValues() }
        setShowErrorMessage(false)
        setShowSuccessMessage(false)
        const form = new FormData()
        form.append("rentalAddress", values?.address)
        form.append("dealType", formState?.selectedDealType)
        form.append("zonedAuto", formState?.selectedZonedAuto)
        form.append("rentalCity", values?.city)
        form.append("buildingSize", values?.buildingSize)
        form.append("buildingLength", values?.buildingLength)
        form.append("rentalState", formState?.selectedState)
        form.append("buildingDepth", values?.buildingDepth)
        form.append("landSizeMeter", values?.landSize)
        form.append("rentalCountry", values?.country)
        form.append("salePrice", values?.salePrice)
        form.append("leaseRate", values?.leaseRate)
        form.append(
            "availableDate",
            !formState?.selectedDate ||
                formState?.selectedDate == "Invalid date"
                ? ``
                : formState?.selectedDate
        )
        form.append("landSizeFt", values?.landSizeSqFt)
        form.append("propertyTax", values?.propertyTaxes)
        form.append("contactFirstName", values?.contactFirstName)
        form.append("contactEmail", values?.contactEmail)
        form.append("contactLastName", values?.contactLastName)
        form.append("contactPhone", reformatPhone(formState?.contactPhone))
        form.append("contactAddress", values?.contactAddress)
        form.append("comment", values?.comments)

        if (Object.entries(errors).length !== 0) {
            return
        } else {
            axios
                .post(`${NEW_API_URL}/oet/rental`, Object.fromEntries(form), {
                    headers: {
                        apiKey: NEW_API_KEY,
                    },
                })
                .then((response) => {
                    if (response?.status === 200 || response?.status === 201) {
                        setShowSuccessMessage(response?.data)
                    } else if (
                        response?.status !== 200 &&
                        response?.status !== 201
                    ) {
                        setShowErrorMessage(true)
                    }

                    resetAllFormData()
                })
                .catch((e) => {
                    setShowSuccessMessage(false)
                    setShowErrorMessage(true)
                    handleCaughtError("Error submitting form ", e)
                })
        }
    }

    return (
        <div
            css={css`
                background-color: #ebebeb;
            `}
        >
            <Grid css={css(`color: #595959;${containerCSS}padding: 20px 0;`)}>
                <Grid container>
                    <Grid item xs={12}>
                        <form
                            id="realEstateInfo"
                            autoComplete="off"
                            onSubmit={handleSubmit(onSubmitClick)}
                        >
                            <div css={css(containerCSS)}>
                                <Grid container>
                                    <Grid
                                        item
                                        sm={12}
                                        css={css`
                                            & .MuiOutlinedInput-root {
                                                height: 45px;
                                                background-color: #ffffff;
                                            }
                                            & .MuiOutlinedInput-multiline {
                                                height: auto;
                                            }
                                            & .MuiFormControl-root {
                                                width: 90%;
                                                margin-bottom: 0.5rem;
                                            }
                                            .dropdownItem {
                                                width: 90%;
                                            }
                                            & .MuiGrid-item {
                                                width: 100%;
                                            }
                                        `}
                                    >
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant={`h6`}
                                                    component={`h6`}
                                                    color={`#214e70`}
                                                    fontWeight={"bold"}
                                                >{`LOCATION INFORMATION`}</Typography>
                                                <Typography
                                                    variant={`body2`}
                                                >{`* Required field`}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <Grid item>
                                                    <GetTextField
                                                        title={"Address *"}
                                                        name={"address"}
                                                    ></GetTextField>
                                                    {getError(
                                                        errors?.address?.message
                                                    )}
                                                </Grid>
                                                <Grid item>
                                                    <GetTextField
                                                        title={"City *"}
                                                        name={"city"}
                                                    ></GetTextField>
                                                    {getError(
                                                        errors?.city?.message
                                                    )}
                                                </Grid>
                                                <Grid item>
                                                    <div className="dropdownItem">
                                                        <Typography
                                                            variant={`subtitle2`}
                                                            css={css`
                                                                color: #214e70;
                                                                margin-bottom: -10px;
                                                                margin-top: 10px;
                                                            `}
                                                        >
                                                            {`State *`}
                                                        </Typography>
                                                        <Select
                                                            {...register(
                                                                `state`
                                                            )}
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                setFormState({
                                                                    ...formState,
                                                                    selectedState:
                                                                        event
                                                                            .target
                                                                            .value,
                                                                })
                                                            }}
                                                            css={css`
                                                                width: 100%;
                                                                background-color: #fff;
                                                            `}
                                                            defaultValue=""
                                                            value={
                                                                formState?.selectedState
                                                            }
                                                        >
                                                            {state?.states?.map(
                                                                ({
                                                                    stateName,
                                                                    stateAbbreviation,
                                                                }) => (
                                                                    <MenuItem
                                                                        key={
                                                                            stateAbbreviation
                                                                        }
                                                                        value={
                                                                            stateName
                                                                        }
                                                                        className="dropdownMenuItem"
                                                                    >
                                                                        {
                                                                            stateName
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Select>
                                                    </div>
                                                    {getError(
                                                        errors?.state?.message
                                                    )}
                                                </Grid>
                                                <Grid item>
                                                    <div className="dropdownItem">
                                                        <Typography
                                                            variant={`subtitle2`}
                                                            css={css`
                                                                color: #214e70;
                                                                margin-bottom: -10px;
                                                                margin-top: 10px;
                                                            `}
                                                        >
                                                            {`Country`}
                                                        </Typography>
                                                        <Select
                                                            {...register(
                                                                `country`
                                                            )}
                                                            css={css`
                                                                width: 100%;
                                                                background-color: #fff;
                                                            `}
                                                            defaultValue="US"
                                                        >
                                                            <MenuItem
                                                                key="US"
                                                                value="US"
                                                                className="dropdownMenuItem"
                                                            >
                                                                {"USA"}
                                                            </MenuItem>
                                                        </Select>
                                                    </div>
                                                </Grid>
                                                <Grid item>
                                                    <Typography
                                                        variant={`subtitle2`}
                                                        css={css`
                                                            color: #214e70;
                                                            margin-bottom: -10px;
                                                            margin-top: 10px;
                                                        `}
                                                    >
                                                        {`Date available:`}
                                                    </Typography>
                                                    <LocalizationProvider
                                                        dateAdapter={
                                                            AdapterDateFns
                                                        }
                                                    >
                                                        <DesktopDatePicker
                                                            {...register(
                                                                `date`
                                                            )}
                                                            value={
                                                                formState?.selectedDate
                                                            }
                                                            onChange={(
                                                                newValue
                                                            ) => {
                                                                const formattedDate =
                                                                    format(
                                                                        newValue,
                                                                        "yyyy-MM-dd"
                                                                    )

                                                                setFormState({
                                                                    ...formState,
                                                                    selectedDate:
                                                                        formattedDate,
                                                                })
                                                            }}
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <TextField
                                                                    css={css`
                                                                        width: 96%;
                                                                        @media (max-width: 768px) {
                                                                            width: 100%;
                                                                        }
                                                                        margin-right: 1rem;
                                                                        position: relative;
                                                                    `}
                                                                    {...params}
                                                                />
                                                            )}
                                                            minDate={new Date()}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <Grid item>
                                                    <div className="dropdownItem">
                                                        <Typography
                                                            variant={`subtitle2`}
                                                            css={css`
                                                                color: #214e70;
                                                                margin: 10px 0 -10px
                                                                    0px;
                                                            `}
                                                        >
                                                            {`Deal type`}
                                                        </Typography>
                                                        <Select
                                                            {...register(
                                                                `dealType`
                                                            )}
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                setFormState({
                                                                    ...formState,
                                                                    selectedDealType:
                                                                        event
                                                                            .target
                                                                            .value,
                                                                })
                                                            }}
                                                            css={css`
                                                                width: 100%;
                                                                background-color: #fff;
                                                            `}
                                                            defaultValue=""
                                                            value={
                                                                formState?.selectedDealType
                                                            }
                                                        >
                                                            {DealTypeArr?.map(
                                                                ({
                                                                    id,
                                                                    title,
                                                                }) => (
                                                                    <MenuItem
                                                                        key={id}
                                                                        value={
                                                                            title
                                                                        }
                                                                        className="dropdownMenuItem"
                                                                    >
                                                                        {title}
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Select>
                                                    </div>
                                                </Grid>
                                                <Grid item>
                                                    <GetTextField
                                                        title={
                                                            "Building size (sq. meters)"
                                                        }
                                                        name={"buildingSize"}
                                                    ></GetTextField>
                                                </Grid>
                                                <Grid item>
                                                    <GetTextField
                                                        title={
                                                            "Building depth (in meters)"
                                                        }
                                                        name={"buildingDepth"}
                                                    ></GetTextField>
                                                </Grid>
                                                <Grid item>
                                                    <GetTextField
                                                        title={"Sale price"}
                                                        name={"salePrice"}
                                                    ></GetTextField>
                                                </Grid>
                                                <Grid item>
                                                    <GetTextField
                                                        title={
                                                            "Land size (sq. meters)"
                                                        }
                                                        name={"landSizeSqFt"}
                                                    ></GetTextField>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <Grid item>
                                                    <div className="dropdownItem">
                                                        <Typography
                                                            variant={`subtitle2`}
                                                            css={css`
                                                                color: #214e70;
                                                                margin: 10px 0 -10px
                                                                    0px;
                                                            `}
                                                        >
                                                            {`Zoned for auto *`}
                                                        </Typography>
                                                        <Select
                                                            {...register(
                                                                `zonedAuto`
                                                            )}
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                setFormState({
                                                                    ...formState,
                                                                    selectedZonedAuto:
                                                                        event
                                                                            .target
                                                                            .value,
                                                                })
                                                            }}
                                                            css={css`
                                                                width: 100%;
                                                                background-color: #fff;
                                                            `}
                                                            defaultValue=""
                                                            value={
                                                                formState?.selectedZonedAuto
                                                            }
                                                        >
                                                            {ZonedAutoArr?.map(
                                                                ({
                                                                    id,
                                                                    title,
                                                                }) => (
                                                                    <MenuItem
                                                                        key={id}
                                                                        value={
                                                                            title
                                                                        }
                                                                        className="dropdownMenuItem"
                                                                    >
                                                                        {title}
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Select>
                                                        {getError(
                                                            errors?.zonedAuto
                                                                ?.message
                                                        )}
                                                    </div>
                                                </Grid>
                                                <Grid item>
                                                    <GetTextField
                                                        title={
                                                            "Building length(in meters)"
                                                        }
                                                        name={"buildingLength"}
                                                    ></GetTextField>
                                                </Grid>
                                                <Grid item>
                                                    <GetTextField
                                                        title={
                                                            "Land size (in meters)"
                                                        }
                                                        name={"landSize"}
                                                    ></GetTextField>
                                                </Grid>
                                                <Grid item>
                                                    <GetTextField
                                                        title={"Lease rate"}
                                                        name={"leaseRate"}
                                                    ></GetTextField>
                                                </Grid>
                                                <Grid item>
                                                    <GetTextField
                                                        title={"Property taxes"}
                                                        name={"propertyTaxes"}
                                                    ></GetTextField>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        item
                                        sm={12}
                                        css={css`
                                            & .MuiOutlinedInput-root {
                                                height: 45px;
                                                background-color: #ffffff;
                                            }
                                            & .MuiInputBase-multiline {
                                                height: auto;
                                            }
                                            & .MuiFormControl-root {
                                                width: 90%;
                                                margin-bottom: 0.5rem;
                                            }
                                            .dropdownItem {
                                                width: 90%;
                                            }
                                        `}
                                    >
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant={`h6`}
                                                    component={`h6`}
                                                    color={`#214e70`}
                                                    fontWeight={"bold"}
                                                >{`CONTACT INFORMATION`}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <GetTextField
                                                    title={"First name *"}
                                                    name={"contactFirstName"}
                                                ></GetTextField>
                                                {getError(
                                                    errors?.contactFirstName
                                                        ?.message
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <GetTextField
                                                    title={"Last name *"}
                                                    name={"contactLastName"}
                                                ></GetTextField>
                                                {getError(
                                                    errors?.contactLastName
                                                        ?.message
                                                )}
                                            </Grid>
                                            <Grid item sm={4}></Grid>
                                            <Grid item xs={12} sm={4}>
                                                <GetTextField
                                                    title={"Email *"}
                                                    name={"contactEmail"}
                                                ></GetTextField>
                                                {getError(
                                                    errors?.contactEmail
                                                        ?.message
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <Typography
                                                    variant={`subtitle2`}
                                                    css={css`
                                                        color: #214e70;
                                                        margin: 10px 0px -10px;
                                                    `}
                                                >
                                                    {`Phone`}
                                                </Typography>
                                                <TextField
                                                    value={
                                                        formState?.contactPhone
                                                    }
                                                    {...register(
                                                        `contactPhone`
                                                    )}
                                                    name={`contactPhone`}
                                                    onChange={(e) => {
                                                        setFormState({
                                                            ...formState,
                                                            contactPhone:
                                                                reformatPhone(
                                                                    e.target
                                                                        .value
                                                                ),
                                                        })
                                                    }}
                                                    variant={`outlined`}
                                                />
                                                {getError(
                                                    errors?.contactPhone
                                                        ?.message
                                                )}
                                            </Grid>
                                            <Grid item sm={4}></Grid>
                                            <Grid item xs={12} sm={4}>
                                                <GetTextField
                                                    title={"Address"}
                                                    name={"contactAddress"}
                                                    multiline
                                                    rows={5}
                                                ></GetTextField>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <GetTextField
                                                    title={"Comments"}
                                                    name={"comments"}
                                                    multiline
                                                    rows={5}
                                                ></GetTextField>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        item
                                        container
                                        justifyContent={`center`}
                                        alignItems={`center`}
                                        marginTop={`15px`}
                                    >
                                        <Button
                                            // onClick={onSubmitClick}
                                            form="realEstateInfo"
                                            type="submit"
                                            css={css`
                                                background-color: #595959 !important;
                                                border-color: #000;
                                                color: #fff !important;
                                                padding-top: 0px !important;
                                            `}
                                        >
                                            <Typography
                                                variant={`body1`}
                                                css={css`
                                                    color: #fff !important;
                                                `}
                                            >
                                                {`SUBMIT`}
                                            </Typography>
                                        </Button>
                                        <Grid
                                            item
                                            container
                                            justifyContent={`center`}
                                            alignItems={`center`}
                                        >
                                            {showSuccessMsg && (
                                                <Typography
                                                    css={css`
                                                        margin: 15px;
                                                        color: #7a841b;
                                                    `}
                                                >
                                                    {`Thank you. Your request was sent successfully.`}
                                                </Typography>
                                            )}
                                            {showErrorsMsg && (
                                                <Typography
                                                    css={css`
                                                        margin: 15px;
                                                        color: #a11816;
                                                    `}
                                                >
                                                    {`Something went wrong. Please try again`}
                                                </Typography>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        </form>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}
