/** @format */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { Grid, Typography, useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/styles"
import Link from "next/link"

import { chunkArray, containerCSS } from "../../util"
import OptimizedImage from "../OptimizedImage"
import FooterLinks from "./FooterLinks"
import Links from "./Links"

import { Trans, useTranslation } from "next-i18next"
import { Socials } from "../contentful"

// import { Facebook, Instagram, Twitter, YouTube } from "@material-ui/icons"
const Footer = () => {
    const { t } = useTranslation()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

    let sitemap = t(`content:footer${isMobile ? "Mobile" : ""}.links.items`, "")
    sitemap = sitemap || t(`footer${isMobile ? "Mobile" : ""}.links.items`, "")
    sitemap = sitemap || []

    let footerLinks = t("content:footer.footer_links.items", "")
    footerLinks = footerLinks || []

    const MaacoCard = () => {
        return t("on_us_site") ? (
            <>
                <div
                    css={css`
                        width: 100%;
                    `}
                >
                    <OptimizedImage
                        src={t(["content:footer.card_img.src"])}
                        alt={t(["content:footer.card_img.alt"])}
                        width={t(["content:footer.card_img.width"])}
                    />
                </div>

                <Link href={t(["content:footer.learnMore.link"])} passHref>
                    <Typography
                        color="primary"
                        component="a"
                        title={t(["content:footer.learnMore.title"])}
                        css={css`
                            margin-top: 12px;
                            text-decoration: none;
                            &:hover {
                                text-decoration: underline;
                            }
                        `}
                    >
                        {t(["content:footer.learnMore.title"])}
                    </Typography>
                </Link>
            </>
        ) : (
            ""
        )
    }

    const socialMedia = t("content:footer.socials")

    return (
        <Grid
            container
            css={css`
                background-color: ${isMobile ? "#ebebeb" : "initial"};
                margin: ${isMobile
                    ? "12px auto 0px auto"
                    : "40px auto 8px auto"};
            `}
        >
            <Grid
                item
                container
                css={css`
                    border-top: ${isMobile
                        ? "3px solid #006bb7"
                        : "4px solid #004e83"};
                `}
            >
                <Grid
                    item
                    container
                    wrap={isMobile ? "wrap" : "nowrap"}
                    spacing={2}
                    css={css`
                        ${containerCSS}
                    `}
                >
                    <Grid
                        item
                        display={{ xs: "none", sm: "flex" }}
                        sm={2}
                        css={css`
                            width: ${t(["content:footer.maaco_logo.width"])}px;
                            height: ${t([
                            "content:footer.maaco_logo.height",
                        ])}px;
                        `}
                    >
                        <OptimizedImage
                            src={t(["content:footer.maaco_logo.src"])}
                            width={t(["content:footer.maaco_logo.width"])}
                            containerStyle={{
                                justifyContent: "center",
                                width: "100%",
                            }}
                            alt={t(["content:footer.maaco_logo.alt"])}
                        />
                    </Grid>

                    <Grid item container xs={12} sm={7} spacing={1}>
                        {chunkArray(3, sitemap).map((sitemapItem, k) => (
                            <Grid
                                container
                                item
                                direction={"column"}
                                xs={12}
                                sm={12 / 3}
                                key={k}
                                css={css`
                                    font-weight: 700;
                                `}
                            >
                                <Links links={sitemapItem} />
                            </Grid>
                        ))}
                    </Grid>

                    <Grid
                        item
                        container
                        xs={12}
                        sm={3}
                        alignItems="left"
                        justifyContent="top"
                        direction="row"
                        css={css`
                            margin-left: 10px;
                            margin-top: ${isMobile ? "12px" : "0"};
                        `}
                    >
                        <Grid item xs={6} sm={12}>
                            <MaacoCard />
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            css={css`
                                margin-top: ${isMobile ? "12px" : "0"};
                            `}
                        >
                            <Socials
                                {...{
                                    ...socialMedia,
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid
                item
                container
                css={css`
                    padding: 30px 40px;
                    @media (max-width: 767px) {
                        background-color: #ebebeb;
                        padding: 0px 12px 30px 12px;
                    }
                `}
            >
                <Grid item md={3}>
                    <Typography
                        component="p"
                        variant="navigationLabel"
                        css={css`
                            font-weight: normal;
                            font-size: 0.9rem;
                            text-transform: none;
                            @media (max-width: 767px) {
                                margin: 0;
                            }
                        `}
                    >
                        <Trans
                            i18nKey={["content:footer.copyright"]}
                            values={{ year: new Date().getFullYear() }}
                        />
                    </Typography>
                </Grid>
                <Grid
                    item
                    md={9}
                    container
                    css={css`
                        @media (max-width: 767px) {
                            display: flex;
                            margin-top: 16px;
                            flex-wrap: wrap;
                        }
                    `}
                >
                    <FooterLinks
                        links={footerLinks}
                        color="textPrimary"
                        fontWeight="normal"
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Footer
