import { useState, useContext } from 'react'
import CustomerInquiryForm from '../../Forms/CustomerInquiryForm'
import { Context } from '../../../context'
import { StyledButton } from '../Button'

const ContactUs = ({ ...props }) => {
  const [openCustomerInquiryForm, setOpenCustomerInquiryForm] = useState(false)

  const { state } = useContext(Context)
  const { currentStore } = state

  const handleOnClick = () => {
    setOpenCustomerInquiryForm(true)
  }

  return (
    <>
      <StyledButton {...{ ...props, onClick: handleOnClick }} />

      {openCustomerInquiryForm && (
        <CustomerInquiryForm
          onClose={() => setOpenCustomerInquiryForm(false)}
          storeID={currentStore.storeId}
        />
      )}
    </>
  )
}

export default ContactUs