import * as Yup from "yup"

import {
    OET_OUTCOMES,
    OET_STEPS,
    OET_SUB_FORMS,
} from "../../../util/globalOETVariables"
import { getEnvironmentVariable } from "../../../util/env"
import { reformatPhone } from "../../../util"
import { VEHICLE_TYPES } from "../../OET/Steps/VehicleInfo"
import { emailRegExp, phoneRegExp } from "./utils"

const locale = getEnvironmentVariable({
    key: "COUNTRY_CODE",
})
const defaultErrorMessage = "Please choose how you'd like to proceed."

const appointmentRequired = {
    // APPOINTMENT SCHEMA
    appointmentDate: Yup.string().when(
        ["spotRepairAction", "insuranceAction", "undecidedAction"],
        {
            is: (spotRepairAction, insuranceAction, undecidedAction) =>
                (spotRepairAction || insuranceAction || undecidedAction) ===
                OET_OUTCOMES.appointment,
            then: Yup.string()
                .nullable()
                .required("You must choose a date to set an appointment."),
            otherwise: Yup.string().notRequired().nullable(),
        }
    ),
    appointmentTime: Yup.string().when(
        ["spotRepairAction", "insuranceAction", "undecidedAction"],
        {
            is: (spotRepairAction, insuranceAction, undecidedAction) =>
                (spotRepairAction || insuranceAction || undecidedAction) ===
                OET_OUTCOMES.appointment,
            then: Yup.string().required(
                "You must choose a time to set an appointment."
            ),
            otherwise: Yup.string().notRequired(),
        }
    ),
}

const appointmentNotRequired = {
    // APPOINTMENT SCHEMA
    appointmentDate: Yup.string().notRequired().nullable(),
    appointmentTime: Yup.string().notRequired(),
}

const UserInfoSchema = Yup.object().shape({
    firstName: Yup.string().required("Enter first name").trim(),
    lastName: Yup.string().required("Enter last name").trim(),
    email: Yup.string().matches(emailRegExp, "Email is not valid").required(),
    phone: Yup.string()
        .matches(phoneRegExp, "Phone number is not valid")
        .required(),
    preferredMethod: Yup.string().required("Please select an option"),
    preferredTime: Yup.array()
        .of(Yup.string())
        .when("preferredMethod", {
            is: (val) => val === "call" || val === "text",
            then: Yup.array().min(1, "Preferred time of day is required"),
            otherwise: Yup.array().notRequired(),
        }),
    contactByText: Yup.bool().when("preferredMethod", {
        is: (val) => val === "call" || val === "text",
        then: Yup.bool().oneOf(
            [true],
            "Opt-in is required if your preferred method of contact is call or text."
        ),
        otherwise: Yup.bool().notRequired().nullable(),
    }),
})

const VehicleInfoSchema = Yup.object().shape({
    hasVinNumber: Yup.bool()
        .nullable()
        .oneOf([true, false], "Please choose an option."),
    vehicleType: Yup.string().oneOf(
        [VEHICLE_TYPES.CAR, VEHICLE_TYPES.EV, VEHICLE_TYPES.OTHER],
        "Please choose an option."
    ),
    vin: Yup.string().when("hasVinNumber", {
        is: true,
        then: Yup.string().required("Enter VIN").max(17),
        otherwise: Yup.string().notRequired(),
    }),
    make: Yup.string().when("vehicleType", {
        is: (val) => val !== VEHICLE_TYPES.OTHER,
        then: Yup.string().when("hasVinNumber", {
            is: false,
            then: Yup.string().required("Enter Make."),
            otherwise: Yup.string().notRequired(),
        }),
    }),
    model: Yup.string().when("vehicleType", {
        is: (val) => val !== VEHICLE_TYPES.OTHER,
        then: Yup.string().when("hasVinNumber", {
            is: false,
            then: Yup.string().required("Enter Model."),
            otherwise: Yup.string().notRequired(),
        }),
    }),
    year: Yup.string().when("vehicleType", {
        is: (val) => val !== VEHICLE_TYPES.OTHER,
        then: Yup.string().when("hasVinNumber", {
            is: false,
            then: Yup.string().required("Enter Year."),
            otherwise: Yup.string().notRequired(),
        }),
    }),
    notes: Yup.string().when("vehicleType", {
        is: (val) => val === VEHICLE_TYPES.OTHER,
        then: Yup.string()
            .required("Please enter the item you would like to have painted.")
            .max(150, "Cannot exceed 150 characters"),
        otherwise: Yup.string().notRequired(),
    }),
})

const VehicleServiceSchema = (scheduleActive) => {
    const appointmentSchema = scheduleActive
        ? appointmentRequired
        : appointmentNotRequired

    return Yup.object().shape({
        vehicleService: Yup.string().required("Please select a service."),
        paintType: Yup.string().when("vehicleService", {
            is: OET_SUB_FORMS.wholeVehicle,
            then: Yup.string().required("Please select a paint type."),
            otherwise: Yup.string().notRequired(),
        }),
        spotRepairAction: Yup.string().when("vehicleService", {
            is: OET_SUB_FORMS.spotRepair,
            then: Yup.string().required(defaultErrorMessage),
            otherwise: Yup.string().notRequired(),
        }),
        insuranceAction: Yup.string().when("vehicleService", {
            is: OET_SUB_FORMS.insurance,
            then: Yup.string().required(defaultErrorMessage),
            otherwise: Yup.string().notRequired(),
        }),
        insuranceProvider: Yup.string().when("vehicleService", {
            is: OET_SUB_FORMS.insurance,
            then: Yup.string().required("Insurance provider is required!"),
            otherwise: Yup.string().notRequired(),
        }),
        claimNumber: Yup.string(),
        undecidedAction: Yup.string().when("vehicleService", {
            is: OET_SUB_FORMS.undecided,
            then: Yup.string().required(defaultErrorMessage),
            otherwise: Yup.string().notRequired(),
        }),
        ...appointmentSchema,
        // FOLLOW UP SCHEMA
        comment: Yup.string()
            .max(1000, "Comment cannot exceed 1000 characters.")
            .trim(),
    })
}

export const getFormSchema = ({ state, formName }) => {
    switch (formName) {
        case OET_STEPS.selectLocation:
            return {
                schema: Yup.object().shape({}),
                defaultValues: {},
            }
        case OET_STEPS.yourInfo:
            return {
                schema: UserInfoSchema,
                defaultValues: {
                    firstName: state.userInfo.firstName || "",
                    lastName: state.userInfo.lastName || "",
                    email: state.userInfo.email || "",
                    phone:
                        (state.userInfo.phone &&
                            reformatPhone(state.userInfo.phone)) ||
                        "",
                    preferredMethod: state.userInfo.preferredMethod || "",
                    preferredTime: state.userInfo.preferredTime || [],
                    // TODO: REMOVE TERNARY OPERATOR ONCE ZETA APPROVES CA TEXTING DISCLAIMERS
                    contactByText:
                        locale === "us" ? state.userInfo.contactByText : true,
                },
            }
        case OET_STEPS.vehicleInfo:
            return {
                schema: VehicleInfoSchema,
                defaultValues: {
                    make: state.vehicleInfo.make || "",
                    model: state.vehicleInfo.model || "",
                    year: state.vehicleInfo.year || "",
                    vin: state.vehicleInfo.vin || "",
                    hasVinNumber: state.vehicleInfo.hasVinNumber ?? false,
                    vehicleType:
                        state.vehicleInfo.vehicleType ?? VEHICLE_TYPES.CAR,
                    notes: state.vehicleInfo.notes ?? "",
                },
            }
        case OET_STEPS.vehicleServices:
            return {
                schema: VehicleServiceSchema(state.location.scheduleActive),
                defaultValues: {
                    vehicleService: state.service.type || "",
                    paintType: state.service.paintType || "",
                    spotRepairAction: state.service.spotRepairAction || "",
                    insuranceAction: state.service.insuranceAction || "",
                    claimNumber: state.service.claimNumber || "",
                    insuranceProvider: state.service.insuranceProvider || "",
                    undecidedAction: state.service.undecidedAction || "",
                    appointmentDate: state.service.appointmentDate
                        ? new Date(state.service.appointmentDate)
                        : null,
                    appointmentTime: state.service.appointmentTime || "",
                    comment: state.service.comment || "",
                },
            }
        default:
            return {
                schema: Yup.object(),
                defaultValues: {},
            }
    }
}
