/** @jsxImportSource @emotion/react */
/** @format */

import { css } from "@emotion/react"
import { Grid, Typography, useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/styles"
import { useTranslation } from "next-i18next"
import React, { useContext } from "react"
import MD from "react-markdown"
import { useSWRConfig } from "swr"

import OptimizedImage from "../../components/OptimizedImage"
import { Context } from "../../context"
import { containerCSS } from "../../util"
import Link from "../Link"
import { Actions, ContentGrid, Heading } from "."

export const Section = ({
    anchor,
    body,
    fullWidth,
    hideConditionally,
    hideOnMobile,
    icon,
    iconAlignment,
    inline,
    locale,
    paddingAmount,
    subtitle,
    variant,
    verticalAlignment,
    verticalMargin,
    verticalPadding,
    backgroundColor = "#ffffff",
    fontColor,
    blocksCollection = {},
    actionsCollection = {},
    ...props
}) => {
    const { t } = useTranslation()
    const { state } = useContext(Context)
    const { fallback } = useSWRConfig()
    const { currentStore = fallback?.store, content = fallback?.context } =
        state
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
    // Hide section if marked to hide conditionally
    // Current conditions: hide if store schedule is not active, coming soon, or temporarily closed
    const hideIfNoAvailability =
        hideConditionally &&
        (!currentStore?.scheduleActive || currentStore?.isComingSoon)

    if ((hideOnMobile && isMobile) || hideIfNoAvailability) return null

    let {
        backgroundImage,
        blocks,
        color,
        actions,
        heading,
        alignment = "start",
    } = props
    backgroundImage = backgroundImage?.fields || backgroundImage
    blocks = blocks || blocksCollection.items || []
    color = color || fontColor || "#595959"
    actions = actions || actionsCollection.items || []
    actions = actions.filter((action) => !action?.fields?.hidden)
    heading = heading?.fields || heading
    alignment = alignment?.toLowerCase()

    let headingElements = []
    let elements = []

    if (heading) {
        headingElements.push(
            <Grid item container xs={12}>
                <Heading
                    {...{
                        icon,
                        iconAlignment,
                        ...heading,
                        alignment: heading.alignment || alignment,
                    }}
                />
            </Grid>
        )
    }

    if (subtitle) {
        headingElements.push(
            <Grid item container xs={12}>
                <Heading
                    {...{
                        level: "h6",
                        text: subtitle,
                        alignment,
                        color,
                    }}
                />
            </Grid>
        )
    }

    if (body) {
        elements.push(
            <Grid item xs={12}>
                <Typography
                    variant="body1"
                    textAlign={alignment}
                    component="div"
                >
                    <MD
                        components={{
                            a: ({ node, ...props }) => {
                                return <Link underline="hover" {...props} />
                            },
                            p: ({ node, ...props }) => {
                                return <p {...props} />
                            },
                            img: ({ node, ...props }) => {
                                return (
                                    <div
                                        style={{
                                            display: "flex",
                                            width: "25em",
                                            marginLeft: "1.5em",
                                            marginRight: "1.5em",
                                        }}
                                    >
                                        <OptimizedImage
                                            {...props}
                                            containerCss={css`
                                                width: 100%;
                                            `}
                                        />
                                    </div>
                                )
                            },
                        }}
                    >
                        {t(body, { defaultValue: body, ...content })}
                    </MD>
                </Typography>
            </Grid>
        )
    }

    if (blocks.length) {
        elements.push(
            <ContentGrid
                {...{
                    locale,
                    variant,
                    items: blocks,
                }}
            />
        )
    }

    if (actions?.length) {
        elements.push(<Actions {...{ actions, alignment }} />)
    }

    const displayInline = inline && !isMobile

    const marginTop = ["top", "both"].indexOf(verticalMargin) >= 0 ? "2em" : ""
    const marginBottom =
        ["bottom", "both"].indexOf(verticalMargin) >= 0 ? "2em" : ""

    const paddingMap = {
        small: "0.5em",
        medium: "1em",
        large: "2em",
    }

    const paddingValue = paddingMap[paddingAmount] || paddingMap.large

    const paddingTop =
        ["top", "both"].indexOf(verticalPadding) >= 0 ? paddingValue : ""
    const paddingBottom =
        ["bottom", "both"].indexOf(verticalPadding) >= 0 ? paddingValue : ""

    backgroundImage =
        backgroundImage && (backgroundImage.file || backgroundImage)
    backgroundImage =
        backgroundImage &&
        `linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url(${backgroundImage.url}?fm=webp)`

    elements = [...headingElements, ...elements]

    return (
        <>
            <a id={anchor} name={anchor} />

            <Grid
                item
                xs={12}
                style={{
                    backgroundColor,
                    backgroundImage,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    color,
                    marginTop,
                    marginBottom,
                    paddingTop,
                    paddingBottom,
                }}
            >
                <Grid
                    container
                    direction={displayInline ? "row" : "column"}
                    alignItems={
                        (!displayInline && alignment) || verticalAlignment
                    }
                    justifyContent={
                        (displayInline && alignment) || verticalAlignment
                    }
                    flexWrap={displayInline ? "nowrap" : "wrap"}
                    css={css`
                        ${!fullWidth && containerCSS}
                        padding-top: 0 !important; //TODO override the containerCSS
                        padding-bottom: 0 !important; //TODO override the containerCSS
                    `}
                >
                    {displayInline ? (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            {elements.map((element, index) => (
                                <div
                                    key={`element-${index}`}
                                    style={{
                                        display: "inline-flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        marginRight: "1em",
                                    }}
                                >
                                    {element}
                                </div>
                            ))}
                        </div>
                    ) : (
                        <Grid
                            item
                            container
                            direction={displayInline ? "row" : "column"}
                            flexWrap={"nowrap"}
                        >
                            {elements.map((element, index) => (
                                <React.Fragment key={`element-${index}`}>
                                    {element}
                                </React.Fragment>
                            ))}
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </>
    )
}
