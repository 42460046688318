/** @format */
import { useContext, useEffect, useState } from "react"
import { useSWRConfig } from "swr"

import { Context } from "../../context"
import { CouponGroup } from "../contentful"

const Offers = ({ config = {} }) => {
    const { state } = useContext(Context)
    const { fallback } = useSWRConfig()
    const [coupons, setCoupons] = useState([])

    const { currentStore: store = fallback?.store } = state

    useEffect(() => {
        if (store?.coupon) {
            // In case store coupons are not an array
            const storeCoupons = store.coupon?.length ? store.coupon : [store.coupon]
            setCoupons(storeCoupons)
        } else {
            setCoupons([])
        }
    }, [store])

    if (!store) return null

    return (
        <CouponGroup config={config} coupons={coupons} store={store} />
    )
}

export default Offers
