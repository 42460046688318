/** @format */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { Grid } from "@mui/material"
import { useContext } from "react"
import { useSWRConfig } from "swr"

import { Context } from "../../context"
import { containerCSS } from "../../util"
import { ContentGrid, Heading } from "../contentful"
import { mainCerts } from "./TopStoreCertification"

export const StoreCertifications = ({
    config: { disableThirdPartyCertifications, ...config },
    ...props
}) => {
    const { state } = useContext(Context)
    const { fallback } = useSWRConfig()

    const { currentStore: store = fallback?.store } = state

    if (!store) return null

    const { oem } = store

    let {
        heading = {
            alignment: "center",
            level: "h4",
            text: "Certifications",
        },
    } = props
    heading = heading?.fields || heading

    const otherCertifications =
        oem?.filter((certification) => {
            const cert = certification.fields || certification
            cert.hideImage = false //override the value set from the about/certifications content

            return mainCerts.indexOf(cert.apiName) === -1
        }) || []

    const maacoCertifications =
        store.oem?.filter((certification) => {
            const cert = certification.fields || certification
            cert.hideImage = false //override the value set from the about/certifications content

            // Includes ICBC
            return mainCerts.indexOf(cert.apiName) !== -1
        }) || []

    if (!maacoCertifications?.length > 0 && !otherCertifications.length)
        return null

    return (
        <div css={css(containerCSS)} id={`certificate_section`}>
            <Grid container justifyContent={"center"}>
                <Grid item xs={12}>
                    <Heading {...heading} />
                </Grid>

                {/* This will include ICBC */}
                {maacoCertifications?.length > 0 && (
                    <ContentGrid items={maacoCertifications}></ContentGrid>
                )}

                {!disableThirdPartyCertifications &&
                    otherCertifications.length > 0 && (
                        <ContentGrid items={otherCertifications} {...config} />
                    )}
            </Grid>
        </div>
    )
}
