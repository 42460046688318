import React, { useContext, useEffect, useRef } from "react"
import { useSWRConfig } from "swr"

import { Context } from "../../context"

export const CustomerTestimonials = () => {
    const { fallback } = useSWRConfig()
    const { state: { currentStore = fallback?.store } } = useContext(Context)
    const containerRef = useRef(null)
    const showTestimonials = currentStore?.testimonials && currentStore?.testimonials?.length > 0

    // This is required to force the script and iframe to render inline.
    // Without the useEffect the script will load at the bottom of the page (under the footer)
    useEffect(() => {
        const scriptId = "625ed4310eeb935a6b963457"
        const scriptChild = document.getElementById(scriptId)
        // Condition needed to ensure the script is not created and loaded more than once
        if (showTestimonials && containerRef?.current && !scriptChild) {
            const script = document.createElement('script', {})
            script.src = `https://live.chatmeter.com/reviewBuilder/widget/script?widgetId=${scriptId}&accountId=61a8bac3d6b4073ebba55f0a&locationId=${currentStore.testimonials}&providers=GOOGLE&formCampaignId=64c43859f31ed316e28ab5b6`
            script.id = scriptId
            containerRef.current.appendChild(script)
        }
    }, [currentStore, showTestimonials])

    return showTestimonials && containerRef ? (
        <div id="store-testimonials" ref={containerRef}></div>
    ) : null
}
