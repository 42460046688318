import * as React from 'react';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import NextLink from 'next/link';
import MuiLink from '@mui/material/Link';
import { styled } from '@mui/material/styles';

export const isExternalLink = (url) => url && url.indexOf('http' || 'https' || 'www') !== -1

const renderLink = (path, other) => {
  if (isExternalLink(path)) {
    return (
      <a
        href={path}
        rel='noreferrer'
        target='_blank'
        title={`External link to ${path}`}
        {...other}
      />
    );
  }

  if (path && path.indexOf('tel:') !== -1) {
    return (
      <a
        href={path}
        title={`Phone number ${path}`}
        {...other}
      />
    );
  }

  if (path && path.indexOf('mailto:') !== -1) {
    return (
      <a
        href={path}
        title={`Email ${path}`}
        {...other}
      />
    );
  }

  if (path && path.indexOf("#") === 0) {
    return (
      <a
        href={path}
        title={`Hash link ${path}`}
        {...other}
      />
    );
  }

  return false
}

// Add support for the sx prop for consistency with the other branches.
const Anchor = styled('a')({});

export const NextLinkComposed = React.forwardRef(
  function NextLinkComposed(props, ref) {
    const {
      to,
      as,
      prefetch = false,
      replace,
      scroll,
      shallow,
      legacyBehavior = true,
      locale,
      ...other
    } = props;

    const pathname = typeof to === 'string' ? to : to.pathname;
    const otherLink = renderLink(pathname, other)

    if (otherLink) {
      return otherLink
    }

    return (
      <NextLink
        href={to}
        as={as}
        prefetch={prefetch}
        replace={replace}
        scroll={scroll}
        shallow={shallow}
        passHref
        locale={locale}
        legacyBehavior={legacyBehavior}
      >
        <Anchor ref={ref} {...other} />
      </NextLink>
    )
  },
);

// A styled version of the Next.js Link component:
// https://nextjs.org/docs/api-reference/next/link
const Link = React.forwardRef(function Link(props, ref) {
  const {
    activeClassName = 'active',
    as,
    className: classNameProps,
    href,
    legacyBehavior = true,
    linkAs: linkAsProp,
    locale,
    noLinkStyle,
    prefetch = false,
    replace,
    // eslint-disable-next-line no-unused-vars
    role, // Link doesn't have roles.
    scroll,
    shallow,
    ...other
  } = props;

  const router = useRouter();
  const pathname = typeof href === 'string' ? href : href.pathname;
  const className = clsx(classNameProps, {
    [activeClassName]: router.pathname === pathname && activeClassName,
  });

  const otherLink = renderLink(pathname, other)

  if (otherLink) {
    return otherLink
  }

  const linkAs = linkAsProp || as;
  const nextjsProps = {
    as: linkAs,
    to: href,
    prefetch,
    replace,
    scroll,
    shallow,
    legacyBehavior,
    locale,
  };

  if (noLinkStyle) {
    return <NextLinkComposed className={className} ref={ref} {...nextjsProps} {...other} />;
  }

  return (
    <MuiLink
      component={NextLinkComposed}
      className={className}
      ref={ref}
      {...nextjsProps}
      {...other}
    />
  );
});

export default Link;