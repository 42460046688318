import { Box, Grid, Stack, TextField, Typography } from "@mui/material"
import { useFormContext } from "react-hook-form"
import { useCallback, useContext, useEffect, useMemo } from "react"

import {
    insuranceOptions,
    OET_FORMS,
    OET_OUTCOMES,
    paintTypes,
    spotRepairOptions,
    undecidedOptions,
} from "../../util/globalOETVariables"
import { OetContext } from "../../context"
import { OETCoupon, RichText } from "../contentful"
import InputComponent from "../InputComponent"
import { OETAppointment } from "../OET/Components/OETAppointment"
import { FormDropdown, generateLabel } from "./utils/form-elements"

// THIS COMPONENT IS USED TO RENDER THE FOLLOWING SUB-FORMS
// WHOLE VEHICLE, SPOT REPAIR, INSURANCE, AND UNDECIDED
export const DynamicFormContent = ({
    coupon = [],
    formHelperText = "",
    legalDisclaimer,
    name,
}) => {
    const { state, dispatch } = useContext(OetContext)
    const {
        formState: { errors },
        register,
        watch,
    } = useFormContext()
    const disabled = state?.formSubmitting

    const paintOptionDropDown = state?.location?.oetWholeVehiclePricing
        ? Object.entries(state?.location?.oetWholeVehiclePricing)
              .sort()
              .filter(([key, value]) => !!value && !key.match("Plus"))
              .map(([key, objValue]) => {
                  const priceMatch = paintTypes.find(({ value }) =>
                      key.match(value)
                  )
                  return { label: priceMatch.label, value: priceMatch.value }
              })
        : paintTypes

    const getFormProps = useCallback(() => {
        const defaultPlaceholder = "Please choose how you'd like to proceed"
        let formProps = {
            name: "paintType",
            dropdownLabelKey: "paint-type",
            dropdownOptions: paintOptionDropDown,
            placeholder:
                "Please choose from the available packages below based on your selected location",
        }
        switch (name) {
            case OET_FORMS.wholeVehicle:
                return formProps
            case OET_FORMS.spotRepair:
                return (formProps = {
                    ...formProps,
                    name: "spotRepairAction",
                    dropdownLabelKey: "spot-repair",
                    dropdownOptions: spotRepairOptions,
                    placeholder: defaultPlaceholder,
                })
            case OET_FORMS.insurance:
                return (formProps = {
                    name: "insuranceAction",
                    dropdownLabel:
                        "Do you want to schedule your car inspection now?*",
                    dropdownLabelKey: "insurance",
                    dropdownOptions: insuranceOptions,
                })
            case OET_FORMS.undecided:
                return (formProps = {
                    ...formProps,
                    name: "undecidedAction",
                    dropdownLabelKey: "undecided",
                    dropdownOptions: undecidedOptions,
                    placeholder: defaultPlaceholder,
                })
            default:
                return formProps
        }
    }, [name])

    const formProps = useMemo(() => getFormProps(), [getFormProps])

    const formAction = watch(formProps?.name)
    const insuranceProvider = watch("insuranceProvider")
    const claimNumber = watch("claimNumber")

    const formCoupon = coupon?.length
        ? coupon
              .filter(({ fields }) => fields.name === formAction?.toLowerCase())
              .pop()
        : null

    const showFollowup =
        formAction === OET_OUTCOMES.followUpSpot ||
        formAction === OET_OUTCOMES.followUpUndecided

    useEffect(() => {
        dispatch({
            type: "SERVICE_DETAIL",
            payload: {
                ...state.service,
                claimNumber,
                insuranceProvider,
                [formProps?.name]: formAction,
                coupon: formCoupon?.fields,
            },
        })
    }, [
        claimNumber,
        dispatch,
        formAction,
        formCoupon,
        formProps,
        insuranceProvider,
    ])

    if (!name || !formProps) return null

    return (
        <Grid container item spacing={4}>
            <Grid item width="100%">
                <Stack
                    spacing={2}
                    sx={{
                        backgroundColor: "#E2F1FF",
                        boxSizing: "border-box",
                        justifyContent: "center",
                        p: 2.5,
                    }}
                >
                    <Stack>
                        <RichText alignment="start" {...formHelperText} />
                    </Stack>
                    <Stack>
                        {name === OET_FORMS.insurance && (
                            <Grid
                                container
                                spacing={0}
                                sx={{ width: { xs: "100%", sm: "100%" } }}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    sx={{
                                        boxSizing: "border-box",
                                        pr: { xs: 0, sm: 2, md: 2 },
                                    }}
                                >
                                    {generateLabel(
                                        "Insurance Company*",
                                        "insurance-company"
                                    )}

                                    <TextField
                                        {...register("insuranceProvider")}
                                        disabled={disabled}
                                        fullWidth
                                        size="small"
                                        id="insurance-company"
                                        name="insuranceProvider"
                                        type="text"
                                        inputProps={{
                                            "aria-describedby":
                                                "insurance-provider-error-helper-text",
                                            "aria-invalid":
                                                errors.insuranceProvider,
                                        }}
                                    />

                                    {errors.insuranceProvider && (
                                        <Typography
                                            id="insurance-provider-error-helper-text"
                                            variant="body1"
                                            color="error"
                                        >
                                            {errors.insuranceProvider?.message}
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    sx={{ boxSizing: "border-box" }}
                                >
                                    {generateLabel(
                                        "Claim Number",
                                        "claim-number"
                                    )}

                                    <TextField
                                        {...register("claimNumber")}
                                        disabled={disabled}
                                        fullWidth
                                        size="small"
                                        id="claim-number"
                                        name="claimNumber"
                                        type="text"
                                    />
                                </Grid>
                            </Grid>
                        )}
                        <FormDropdown disabled={disabled} {...formProps} />
                    </Stack>
                </Stack>
            </Grid>
            <Grid item justifyContent="center" sx={{ width: "100%" }}>
                <Box sx={{ p: 2 }}>
                    {formAction === OET_OUTCOMES.appointment && (
                        <OETAppointment />
                    )}

                    {showFollowup && (
                        <>
                            <InputComponent
                                name="comment"
                                disabled={disabled}
                                fullInputName="Please explain what we can help you with"
                                multiline
                                lastInput
                                fullWidth
                                formProps={{
                                    inputProps: {
                                        maxLength: 1000,
                                    },
                                }}
                                rows={4}
                                cssStyle={`
                            & .MuiOutlinedInput-root {
                                height: auto;
                            }
                        `}
                            />
                            {`${
                                watch("comment") ? watch("comment").length : "0"
                            } / 1000`}
                        </>
                    )}

                    {formCoupon && <OETCoupon {...formCoupon.fields} />}
                </Box>
                {legalDisclaimer && (
                    <Box sx={{ p: 3 }}>
                        <RichText {...legalDisclaimer} />
                    </Box>
                )}
            </Grid>
        </Grid>
    )
}
