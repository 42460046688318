import { CalendarToday } from "@mui/icons-material"
import { Card, CardContent, Grid, Stack, Typography } from "@mui/material"
import { useContext } from "react"

import { OetContext } from "../../../../context"

// TODO: FINISH WHEN API SUPPORT IS READY
export const StartingAtPrice = () => {
    const { state } = useContext(OetContext)

    if (
        !state?.location ||
        !state?.service?.paintType ||
        !state?.estimateAmount
    )
        return null

    const filteredPaintPrices = Object.entries(
        state?.location?.oetWholeVehiclePricing
    )
        .sort()
        .filter(([key, value]) => !!value && !key.match("Plus"))

    const AppointmentCopy = () => (
        <Typography component="span">
            Click
            <Typography component="span" fontFamily="Gilroy Bold">
                {` "Make an Appointment"`}
            </Typography>
        </Typography>
    )

    const NoAppointmentCopy = () => (
        <Typography component="span">
            Call your Maaco at
            <Typography component="span" fontFamily="Gilroy Bold">
                {` ${state?.location?.organicPhone} `}
            </Typography>
            to make an appointment
        </Typography>
    )

    return (
        <Grid container item sx={{ width: "100%" }}>
            <Grid item>
                <Stack direction="column" spacing={2} width="100%">
                    <Typography variant="h4">
                        {`Based on your selections, your starting-at price is $${state?.estimateAmount}`}
                    </Typography>
                    <Stack
                        alignItems="center"
                        justifyContent="center"
                        sx={{ flexDirection: { sm: "column", md: "row" } }}
                    >
                        {filteredPaintPrices.map(([key, value]) => {
                            const priceMatch = key.match(
                                state?.service?.paintType
                            )
                            return (
                                <Card
                                    key={`${key}-price-item`}
                                    raised
                                    sx={{
                                        backgroundColor: priceMatch
                                            ? "primary.light"
                                            : "none",
                                        borderRadius: "5px",
                                        mt: { xs: 2, md: 0 },
                                        mr: { xs: 0, md: 2 },
                                        height: "auto",
                                        width: {
                                            xs: "100%",
                                            md: priceMatch ? "175px" : "200px",
                                        },
                                    }}
                                >
                                    <CardContent
                                        sx={{
                                            p: priceMatch
                                                ? "24px"
                                                : "16px !important",
                                        }}
                                    >
                                        <Stack alignItems="center" spacing={1}>
                                            <Typography
                                                variant="h4"
                                                sx={{
                                                    color: priceMatch
                                                        ? "common.white"
                                                        : "primary.light",
                                                    m: 0,
                                                }}
                                            >
                                                {`$${Math.floor(
                                                    Number(value)
                                                ).toLocaleString()}`}
                                            </Typography>
                                            <Typography
                                                variant="caption"
                                                sx={{
                                                    textAlign: "center",
                                                    color: priceMatch
                                                        ? "common.white"
                                                        : "inherit",
                                                    m: 0,
                                                    textTransform: "capitalize",
                                                }}
                                            >
                                                {`${key
                                                    .split(/(?=[A-Z])/)
                                                    .join(" ")}`}
                                            </Typography>
                                        </Stack>
                                    </CardContent>
                                </Card>
                            )
                        })}
                    </Stack>
                    <Typography>
                        Prices above represent the starting-at price for each
                        package.
                    </Typography>
                    <Typography>
                        Some additional costs may apply to trucks, vans, SUVs,
                        and commercial vehicles. Parts, body work, and surface
                        repairs are not included in the starting at value noted
                        above. Please schedule an appointment with your nearby
                        Maaco to get an in-shop written estimate on your
                        vehicle.
                    </Typography>
                </Stack>
            </Grid>
            <Grid item sx={{ mt: 4 }}>
                <Stack direction="column">
                    <Typography variant="h4" color="primary">
                        Next Step:
                    </Typography>
                    <Stack direction="row" spacing={1}>
                        <CalendarToday fontSize="small" />
                        {state?.location?.scheduleActive ? (
                            <AppointmentCopy />
                        ) : (
                            <NoAppointmentCopy />
                        )}
                    </Stack>
                    <Typography sx={{ mt: 1 }}>
                        {
                            "Or bring your vehicle in the shop. We'll discuss your options and provide a written estimate."
                        }
                    </Typography>
                </Stack>
            </Grid>
        </Grid>
    )
}
