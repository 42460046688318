import OnlineEstimatorForm from "../OET"
import { OETAppointmentConfirmation } from "../OET/Components/AppointmentConfirmation"
import { StartingAtPrice } from "../OET/Components/StartingAtPrice"
import DrivenBrandsOffers from "../DrivenBrandsOffers"
import { StoreCertifications } from "../StoreDetails/certifications"
import { CustomerTestimonials } from "../StoreDetails/CustomerTestimonials"
import { StoreDetails } from "../StoreDetails/details"
import Offers from "../StoreDetails/offers"
import ScheduleAppointment from "../StoreDetails/ScheduleAppointment"
import { StoreServices } from "../StoreDetails/services"
import { convertConfig } from "./util"

export const DynamicComponent = ({ name, ...props }) => {
    const DynamicTypeMap = {
        "Schedule Appointment": {
            Component: ScheduleAppointment,
        },
        "Store Certifications": {
            Component: StoreCertifications,
        },
        "Store Coupons": {
            Component: Offers,
        },
        "Store Details": {
            Component: StoreDetails,
        },
        "Store Services": {
            Component: StoreServices,
        },
        "Store Testimonials": {
            Component: CustomerTestimonials,
        },
        "OET Form": {
            Component: OnlineEstimatorForm,
        },
        "Starting At Price": {
            Component: StartingAtPrice,
        },
        "Appointment Confirmation": {
            Component: OETAppointmentConfirmation,
        },
        "DrivenBrands Offers": {
            Component: DrivenBrandsOffers,
        },
    }

    const DynamicType = DynamicTypeMap[name]
    const { config } = props

    if (DynamicType)
        return (
            <DynamicType.Component {...props} config={convertConfig(config)} />
        )

    console.log("Dynamic Not Found", { name, ...props })
    return null
}
