import { Typography } from "@mui/material"
import Link from "../Link"

export const ContentLink = ({ url, text = "", style, onClick }) => {
    return (
        <Typography variant={style} onClick={onClick}>
            <Link href={url || "#"} noLinkStyle>
                {text}
            </Link>
        </Typography>
    )
}
