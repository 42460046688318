/** @jsxImportSource @emotion/react */
/** @format */
import { css } from "@emotion/react"
import { Grid, Typography } from "@mui/material"
import { useTranslation } from "next-i18next"
import React, { useContext } from "react"
import MD from "react-markdown"
import { useSWRConfig } from "swr"

import OptimizedImage from "../OptimizedImage"
import { Context } from "../../context"
import Link from "../Link"

// TODO: IMPLEMENT IN ALL COMPONENTS THAT USE THE REACT-MARKDOWN COMPONENT
export const Markdown = ({
    alignment = "center",
    content,
    context = {},
    ...props
}) => {
    const { t } = useTranslation()
    const { state } = useContext(Context)
    const { fallback } = useSWRConfig()
    const { content: stateContent = fallback?.context } = state

    let elements = []

    if (content) {
        elements.push(
            <Grid item xs={12}>
                <Typography
                    variant="body1"
                    textAlign={alignment}
                    component="div"
                >
                    <MD
                        {...props}
                        components={{
                            a: ({ node, ...props }) => {
                                return <Link underline="hover" {...props} />
                            },
                            h1: ({ node, ...props }) => {
                                return (
                                    <Typography
                                        variant="h1"
                                        sx={{ color: "primary.main" }}
                                        {...props}
                                    />
                                )
                            },
                            h2: ({ node, ...props }) => {
                                return (
                                    <Typography
                                        variant="h2"
                                        sx={{ color: "primary.main" }}
                                        {...props}
                                    />
                                )
                            },
                            h3: ({ node, ...props }) => {
                                return (
                                    <Typography
                                        variant="h3"
                                        sx={{ color: "primary.main" }}
                                        {...props}
                                    />
                                )
                            },
                            h4: ({ node, ...props }) => {
                                return (
                                    <Typography
                                        variant="h4"
                                        sx={{ color: "primary.main" }}
                                        {...props}
                                    />
                                )
                            },
                            h5: ({ node, ...props }) => {
                                return (
                                    <Typography
                                        variant="h5"
                                        sx={{ color: "primary.main" }}
                                        {...props}
                                    />
                                )
                            },
                            p: ({ node, ...props }) => {
                                return <p {...props} />
                            },
                            img: ({ node, ...props }) => {
                                return (
                                    <div
                                        style={{
                                            display: "flex",
                                            width: "25em",
                                            marginLeft: "1.5em",
                                            marginRight: "1.5em",
                                        }}
                                    >
                                        <OptimizedImage
                                            {...props}
                                            containerCss={css`
                                                width: 100%;
                                            `}
                                        />
                                    </div>
                                )
                            },
                        }}
                    >
                        {t(content, {
                            defaultValue: content,
                            ...stateContent,
                            ...context,
                        })}
                    </MD>
                </Typography>
            </Grid>
        )
    }

    return (
        <Grid container item>
            {elements.length > 0 &&
                elements.map((element, index) => (
                    <React.Fragment key={`element-${index}`}>
                        {element}
                    </React.Fragment>
                ))}
        </Grid>
    )
}
