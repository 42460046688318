/** @format */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { Menu, Room } from "@mui/icons-material"
import {
    AppBar,
    ClickAwayListener,
    Grid,
    IconButton,
    MenuList,
    Paper,
    Popper,
    useMediaQuery,
} from "@mui/material"
import { useTheme } from "@mui/styles"
import {
    bindToggle,
    bindPopper,
    usePopupState,
} from "material-ui-popup-state/hooks"
import { useTranslation } from "next-i18next"
import Link from "next/link"
import { useRef, useState } from "react"

import { containerCSS } from "../../util"
import OptimizedImage from "../OptimizedImage"
import FindLocation from "./FindLocation"
import Links from "./Links"
import LocationFindModal from "./LocationFindModal"
import { PhoneNumber } from "./phoneNumber"

const googleSuggestionClass = "pac-item"
export const menuClass = "menu-button"
export const menuText = "menu-button--text"

const hasClass = (className) =>
    typeof className === "string" &&
    (className?.match(googleSuggestionClass)?.length > 0 ||
        className?.match(menuClass)?.length > 0 ||
        className?.match(menuText)?.length > 0)

export const handleOnClickAway = (e, popupState) => {
    const className = e.target.className
    const isChildOfElement = e.target?.parentElement?.className
    const isGoogleorMenuClass =
        (className && hasClass(className)) ||
        (isChildOfElement && hasClass(isChildOfElement))
    // If location search suggestion or menu button is clicked ignore click away event
    if (isGoogleorMenuClass && popupState.isOpen) {
        return
    } else if (popupState.isOpen) {
        // If element aside from menu button or Google suggestion is clicked, close the popup.
        popupState.close()
    }
}

const TopBar = ({ hideSearchBar = false }) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
    // We want menu to align under the menu bar/ container,
    // NOT under the button triggering the popper to open
    const containerRef = useRef()
    const popupState = usePopupState({
        variant: "popper",
        popupId: "maaco-nav-popper",
    })

    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    return (
        <AppBar
            position="relative"
            css={css`
                box-shadow: none;
                background-color: #fff;
                padding: ${isMobile && 0};

                & > div {
                    width: ${isMobile && "100%"};
                    padding: ${isMobile && 0};
                }
            `}
        >
            <div
                css={css(
                    `${containerCSS}
                    @media (min-width: 767px) and (max-width: 995px) {
                        width: 97%;
                    }
                    padding-top: 3px;
                    padding-bottom: 3px;`
                )}
            >
                <Grid
                    alignItems="center"
                    container
                    css={css`
                        display: ${isMobile
                            ? "flex !important"
                            : "none !important"};
                        background-color: ${isMobile ? "#ebebeb" : "initial"};
                        padding: ${isMobile && "8px 0 10px 0"};
                    `}
                    ref={containerRef}
                >
                    <Grid item xs={4}>
                        <IconButton
                            size="large"
                            edge="start"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                            className="mvMenuButton"
                            css={css`
                                margin: 0;
                                padding: 0;
                            `}
                            {...bindToggle(popupState)}
                        >
                            <Menu
                                css={css`
                                    font-size: 2.5rem !important;
                                    color: #006bb7;
                                `}
                            />
                        </IconButton>
                    </Grid>

                    <Grid
                        item
                        xs={4}
                        container
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Link href="/" title="Home page">
                            <div
                                css={css`
                                    width: 200px;
                                    height: 100%;
                                    @media (min-width: 768px) and (max-width: 991px) {
                                        width: 100%;
                                    }
                                    cursor: "pointer";
                                `}
                            >
                                <OptimizedImage
                                    src={t(["content:header.maacoLogo.src"])}
                                    alt={t(["content:header.maacoLogo.title"])}
                                />
                            </div>
                        </Link>
                    </Grid>

                    <Grid
                        item
                        xs={4}
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <PhoneNumber {...{ isMobile }} />

                        <Room
                            onClick={handleOpen}
                            css={css`
                                color: #006bb7;
                                font-size: 2rem;
                                margin: 0 12px;
                            `}
                        />

                        {isMobile && (
                            <LocationFindModal
                                open={open}
                                handleClose={handleClose}
                            />
                        )}
                    </Grid>

                    <ClickAwayListener
                        onClickAway={(e) => handleOnClickAway(e, popupState)}
                    >
                        <Popper
                            {...bindPopper(popupState)}
                            anchorEl={containerRef.current}
                            placement="bottom-start"
                            css={css`
                                width: 100%;
                                z-index: 999;

                                @media (min-width: 600px) {
                                    display: none;
                                }
                            `}
                        >
                            <Paper
                                sx={{
                                    width: "97%",
                                    margin: "0px auto",
                                    maxWidth: "100%",
                                }}
                            >
                                <MenuList>
                                    <Links
                                        inPopper
                                        toggleDrawer={() => popupState.close()}
                                    />
                                </MenuList>
                            </Paper>
                        </Popper>
                    </ClickAwayListener>
                </Grid>

                <Grid
                    container
                    spacing={1}
                    display={{ xs: "none", sm: "flex" }}
                >
                    <Grid
                        item
                        container
                        alignItems="center"
                        justifyContent={"center"}
                    >
                        <Grid item xs={2} sx={{ cursor: "pointer" }}>
                            <Link href="/" title="Home page">
                                <OptimizedImage
                                    src={t(["content:header.maacoLogo.src"])}
                                    alt={t(["content:header.maacoLogo.title"])}
                                />
                            </Link>
                        </Grid>

                        <Grid item xs={10} container>
                            <FindLocation hideSearchBar={hideSearchBar} />
                        </Grid>
                    </Grid>

                    <Grid
                        item
                        container
                        css={css`
                            border-top: 1px solid #ebebeb;
                        `}
                    >
                        <Links />
                    </Grid>
                </Grid>
            </div>
        </AppBar>
    )
}

export default TopBar
