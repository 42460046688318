const PreviewBanner = () => {
  return (
    <div
      style={{ width: "100%", background: "black", position: "sticky" }}
    >
      <div style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center"
      }}>
        <p style={{
          color: "white"
        }}>YOU ARE IN PREVIEW MODE</p>
        <button onClick={() => location.assign("/api/exit-preview")}>Exit Preview</button>
      </div>
    </div>
  )
}

export default PreviewBanner