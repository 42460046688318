import React from "react"
import { Grid } from "@mui/material"

import {
    renderComponent,
    Heading,
    RichText,
    ContentGrid,
    OETSection,
} from "../../../contentful"
import EstimateSummary from "../../Components/EstimateSummary"

const OETStep = ({
    content,
    heading,
    description,
    name,
    oetFooterContent,
    showEstimateSummaryPanel,
    ...props
}) => {
    let footerElements = []

    if (oetFooterContent?.length) {
        footerElements.push(
            <ContentGrid
                {...{
                    items: oetFooterContent,
                    columns: 1,
                    spacing: 8,
                }}
            />
        )
    }

    return (
        <Grid container>
            <Grid
                container
                display="flex"
                alignItems={showEstimateSummaryPanel ? "flex-start" : undefined}
                rowSpacing={{ xs: showEstimateSummaryPanel ? 4 : 0, md: 0 }}
                columnSpacing={{ xs: 0, md: showEstimateSummaryPanel ? 10 : 0 }}
                sx={{ pt: !(heading || description) ? 8 : 0 }}
            >
                <Grid
                    container
                    item
                    spacing={4}
                    md={showEstimateSummaryPanel ? 8 : 12}
                    sx={{ border: "none", order: { xs: 2, md: 1 } }}
                >
                    {(heading || description) && (
                        <Grid
                            item
                            xs={12}
                            sx={{
                                my: showEstimateSummaryPanel ? 4 : 8,
                                p: 0,
                            }}
                        >
                            {heading && (
                                <Heading
                                    {...{
                                        ...heading.fields,
                                        textTransform: "none",
                                    }}
                                />
                            )}

                            {description && (
                                <RichText
                                    {...{ ...description, alignment: "center" }}
                                />
                            )}
                        </Grid>
                    )}

                    <Grid
                        container
                        item
                        direction="column"
                        flexWrap="nowrap"
                        sx={{
                            pb: showEstimateSummaryPanel ? 8 : 0,
                            px: showEstimateSummaryPanel ? 1 : 0,
                        }}
                    >
                        {content &&
                            content.length > 0 &&
                            content.map((component, index) =>
                                renderComponent({
                                    component,
                                    key: `oet-step-content-${index}`,
                                })
                            )}
                        {props.children}
                    </Grid>
                </Grid>

                {showEstimateSummaryPanel && (
                    <Grid item xs={12} md={4} sx={{ order: { xs: 1, md: 2 } }}>
                        <EstimateSummary />
                    </Grid>
                )}
            </Grid>

            {oetFooterContent?.length > 0 && (
                <Grid container item sx={{ mt: 6 }} spacing={6}>
                    {oetFooterContent.map(({ fields }, index) => {
                        return (
                            <OETSection key={`element-${index}`} {...fields} />
                        )
                    })}
                </Grid>
            )}
        </Grid>
    )
}

export default OETStep
