/** @format */
/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react"
import { Autocomplete, Grid, TextField, Typography } from "@mui/material"
import axios from "axios"
import React, { useEffect, useRef, useState } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { NEW_API_KEY, NEW_API_URL, searchForNextInput } from "../../util"
import { handleCaughtError } from "../../util/handlers"

const MotorApiForm = ({
    state,
    showTypes = false,
    placeholders = false,
    lastInput = false,
    styles = {
        container: ``,
        nowrap: false,
        direction: ``,
        year: ``,
        make: ``,
        model: ``,
        type: ``,
    },
    formProps,
    valueStore = false,
}) => {
    const headers = {
        apikey: NEW_API_KEY,
    }
    const componentForm = useFormContext()

    const {
        watch,
        formState: { errors },
        control,
        setValue,
    } = componentForm

    const _defaultValues = {
        year: "",
        make: "",
        model: "",
        type: "",
    }
    const defaultValues = {
        year: state[valueStore]?.year || _defaultValues.year,
        make: state[valueStore]?.make || _defaultValues.make,
        model: state[valueStore]?.model || _defaultValues.model,
        type: state[valueStore]?.submodel || _defaultValues.type,
    }

    const [defaults, setDefaults] = useState(defaultValues)

    const [years, setYears] = useState([])
    const year = watch("year")
    const prevYear = useRef(defaultValues.Year)
    const [makes, setMakes] = useState([])
    const make = watch("make")
    const prevMake = useRef(defaultValues.make)
    const [models, setModels] = useState([])
    const model = watch("model")
    const prevModel = useRef(defaultValues.model)
    const [types, setTypes] = useState([])
    const type = watch("type")

    {
        /* TODO: revisit component architecture to correctly handle useEffect dependencies */
    }

    useEffect(() => {
        axios
            .get(`${NEW_API_URL}/motor/years`, { headers })
            .then((res) => {
                setYears(res.data.Body)
            })
            .catch((err) => {
                handleCaughtError("error getting years from Motor API", err)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (year === prevYear.current) return

        setValue("make", "")
        prevYear.current = year

        if (!year) return

        const postData = {
            path: `/v1/Information/YMME/Years/${year}/Makes`,
        }

        axios
            .post(`${NEW_API_URL}/motor/makes`, postData, { headers })
            .then((res) => {
                setMakes(res.data.Body)
                //console.log(res.data.Body, defaultValues.make)
                if (defaults.make === state[valueStore]?.make) {
                    setDefaults({ ...defaults, make: "" })
                }
                setValue("make", defaults.make, { shouldValidate: true })
            })
            .catch((err) => {
                handleCaughtError("error getting make from Motor API", err)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [year])

    useEffect(() => {
        if (make === prevMake.current) return

        setValue("model", "")
        prevMake.current = make

        if (!make || !makes.length) return

        const path = makes.filter((s) => {
            return s.MakeName.toUpperCase() == make.toUpperCase()
        })
        const postData = {
            path: path[0].Links[0].Href,
        }

        axios
            .post(`${NEW_API_URL}/motor/models`, postData, { headers })
            .then((res) => {
                setModels(res.data.Body || [])
                if (defaults.model === state[valueStore]?.model) {
                    setDefaults({ ...defaults, model: "" })
                    setValue("model", defaults.model, { shouldValidate: true })
                }
            })
            .catch((err) => {
                handleCaughtError("error getting model from Motor API", err)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [make, makes])

    useEffect(() => {
        if (!showTypes || model === prevModel.current) return

        setValue("type", "")
        prevModel.current = model

        if (!model || !models.length) return

        const path = models.filter((s) => {
            return s.ModelName === model
        })
        const postData = {
            path: path[0]?.Links[0].Href,
        }

        axios
            .post(`${NEW_API_URL}/motor/types`, postData, { headers })
            .then((res) => {
                setTypes(res.data)
                if (defaults.type === state[valueStore]?.submodel) {
                    setDefaults({ ...defaults, type: "" })
                    setValue("type", defaults.type, { shouldValidate: true })
                }
            })
            .catch((err) => {
                handleCaughtError("error getting type from Motor API", err)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [model, models])

    return (
        <Grid
            container
            direction={styles.direction || "row"}
            justifyContent="space-between"
            spacing={{ xs: 2, sm: 2, md: 4 }}
            wrap={styles.nowrap ? "nowrap" : "wrap"}
            css={css`
                ${styles.container}
            `}
        >
            <Grid
                item
                xs={6}
                css={css`
                    ${styles.year}
                `}
            >
                <label
                    css={css`
                        padding-top: 10px;
                        z-index: 2;
                    `}
                >
                    Year*
                </label>
                <Controller
                    id="year"
                    name="year"
                    {...formProps}
                    defaultValue={defaults.year}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <Autocomplete
                            autoHighlight
                            autoSelect
                            options={years.map((s) => s.Year.toString())}
                            value={value || null}
                            onChange={(_, data) => onChange(data || "")}
                            onKeyDown={(e) => searchForNextInput(e)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    css={css`
                                        input {
                                            padding: 0px !important;
                                        }
                                    `}
                                    placeholder={
                                        placeholders
                                            ? "Select your car's year"
                                            : ""
                                    }
                                />
                            )}
                        />
                    )}
                />

                <Typography variant="body1" color="error" sx={{ pt: "3px" }}>
                    {errors.year?.message}
                </Typography>
            </Grid>

            <Grid
                item
                xs={6}
                css={css`
                    ${styles.make}
                `}
            >
                <label
                    css={css`
                        padding-top: 10px;
                        z-index: 2;
                    `}
                >
                    Make*
                </label>
                <Controller
                    id="make"
                    name="make"
                    {...formProps}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <Autocomplete
                            autoHighlight
                            autoSelect
                            readOnly={!year}
                            options={makes.map((s) => s.MakeName)}
                            value={value || null}
                            onKeyDown={(e) => searchForNextInput(e)}
                            onChange={(_, data) => onChange(data || "")}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder={
                                        placeholders
                                            ? "Select your car's make"
                                            : ""
                                    }
                                    css={css`
                                        input {
                                            padding: 0px !important;
                                        }
                                    `}
                                />
                            )}
                        />
                    )}
                />

                <Typography variant="body1" color="error" sx={{ pt: "3px" }}>
                    {errors.make?.message}
                </Typography>
            </Grid>

            <Grid
                item
                xs={6}
                css={css`
                    ${styles.model}
                `}
            >
                <label
                    css={css`
                        padding-top: 10px;
                        z-index: 2;
                    `}
                >
                    Model*
                </label>
                <Controller
                    id="model"
                    name="model"
                    control={control}
                    {...formProps}
                    render={({ field: { onChange, value } }) => {
                        return (
                            <Autocomplete
                                autoHighlight
                                autoSelect
                                readOnly={!make}
                                options={models.map((s) => s.ModelName)}
                                value={value || null}
                                onKeyDown={(e) => {
                                    if (lastInput && !showTypes) return
                                    searchForNextInput(e)
                                }}
                                onChange={(_, data) => onChange(data || "")}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder={
                                            placeholders
                                                ? "Select your car's model"
                                                : ""
                                        }
                                        css={css`
                                            input {
                                                padding: 0px !important;
                                            }
                                        `}
                                    />
                                )}
                            />
                        )
                    }}
                />

                <Typography variant="body1" color="error" sx={{ pt: "3px" }}>
                    {errors.model?.message}
                </Typography>
            </Grid>

            {showTypes && (
                <Grid
                    item
                    xs={6}
                    css={css`
                        ${styles.type}
                    `}
                >
                    <label
                        css={css`
                            padding-top: 10px;
                            z-index: 2;
                        `}
                    >
                        Type*
                    </label>
                    <Controller
                        id="type"
                        name="type"
                        control={control}
                        {...formProps}
                        render={({ field: { onChange, value } }) => (
                            <Autocomplete
                                autoHighlight
                                autoSelect
                                readOnly={!model}
                                isOptionEqualToValue={(option, value) => {
                                    return option === value
                                }}
                                options={types.map((s) => s)}
                                onKeyDown={(e) => {
                                    if (lastInput && showTypes) return
                                    searchForNextInput(e)
                                }}
                                value={value || null}
                                onChange={(_, data) => onChange(data || "")}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder={
                                            placeholders
                                                ? "Select your car's type"
                                                : ""
                                        }
                                        css={css`
                                            input {
                                                padding: 0px !important;
                                            }
                                        `}
                                    />
                                )}
                            />
                        )}
                    />

                    <Typography
                        variant="body1"
                        color="error"
                        sx={{ pt: "3px" }}
                    >
                        {errors.type?.message}
                    </Typography>
                </Grid>
            )}
        </Grid>
    )
}

export default MotorApiForm
