/** @format */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { useEffect, useRef, useState } from "react"

import SubMenuHeader from "./SubMenuHeader"
import SubMenuMobile from "./SubMenuMobile"
import Link from "../../Link"

import ClickAwayListener from "@mui/material/ClickAwayListener"
import Grow from "@mui/material/Grow"
import Paper from "@mui/material/Paper"
import Popper from "@mui/material/Popper"
import MenuItem from "@mui/material/MenuItem"
import MenuList from "@mui/material/MenuList"
import Stack from "@mui/material/Stack"

const SubMenu = ({ subMenuHeader, subMenu, isMobile }) => {
    const [open, setOpen] = useState(false)
    const anchorRef = useRef(null)

    const handleClick = () => setOpen((prevState) => !prevState)

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }

        setOpen(false)
    }

    const handleListKeyDown = (event) => {
        if (event.key === "Tab") {
            event.preventDefault()
            setOpen(false)
        } else if (event.key === "Escape") {
            setOpen(false)
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(open)
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus()
        }

        prevOpen.current = open
    }, [open])

    return (
        <>
            {isMobile && (
                <SubMenuMobile
                    ref={anchorRef}
                    subMenuHeader={subMenuHeader}
                    subMenu={subMenu}
                    open={open}
                    handleClick={handleClick}
                />
            )}
            {!isMobile && (
                <Stack
                    direction="row"
                    spacing={2}
                    onMouseEnter={() => setOpen(true)}
                    onMouseLeave={() => setOpen(false)}
                >
                    <SubMenuHeader open={open}>
                        <Link
                            ref={anchorRef}
                            id="composition-button"
                            href={"/services"}
                            aria-controls={
                                open ? "composition-menu" : undefined
                            }
                            aria-expanded={open ? "true" : undefined}
                            aria-haspopup="true"
                            sx={{
                                color: "#595959",
                                "&:focus-visible": { outline: "none" },
                            }}
                        >
                            {subMenuHeader}
                        </Link>
                    </SubMenuHeader>

                    <Popper
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                        css={css`
                            z-index: 3;
                        `}
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin: "left top",
                                }}
                            >
                                <Paper
                                    sx={{
                                        width: "150%",
                                        background: "#fff",
                                        border: "#006bb7 .5px solid",
                                        borderRadius: "5%",
                                        boxShadow:
                                            "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                    }}
                                >
                                    <ClickAwayListener
                                        onClickAway={handleClose}
                                    >
                                        <MenuList
                                            autoFocusItem={open}
                                            id="composition-menu"
                                            aria-labelledby="composition-button"
                                            onKeyDown={handleListKeyDown}
                                            sx={{ p: 0 }}
                                        >
                                            {subMenu.items.map(
                                                ({ text, navLink }) => (
                                                    <Link
                                                        key={text}
                                                        href={navLink.path}
                                                        noLinkStyle
                                                    >
                                                        <MenuItem
                                                            key={text}
                                                            onClick={
                                                                handleClose
                                                            }
                                                            sx={{
                                                                p: "1rem 1.3rem !important",
                                                                fontWeight:
                                                                    "bold",
                                                                color: "#006bb7",
                                                                "&:hover": {
                                                                    backgroundColor:
                                                                        "#006bb7",
                                                                    color: "#fff",
                                                                    boxShadow:
                                                                        "none !important",
                                                                },
                                                                borderRadius:
                                                                    "5%",
                                                            }}
                                                        >
                                                            {text}
                                                        </MenuItem>
                                                    </Link>
                                                )
                                            )}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </Stack>
            )}
        </>
    )
}

export default SubMenu
