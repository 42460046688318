/** @format */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { RadioButtonUnchecked, TaskAlt } from "@mui/icons-material"
import { Stack, Step, StepConnector, StepLabel, Stepper } from "@mui/material"
import { stepConnectorClasses } from "@mui/material/StepConnector"
import { styled } from "@mui/material/styles"
import PropTypes from "prop-types"

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: "calc(-50% + 16px)",
        right: "calc(50% + 16px)",
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: "#004e83",
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: "#004e83",
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#eaeaf0",
        borderTopWidth: 3,
        borderRadius: 1,
    },
}))

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
}))

function QontoStepIcon(props) {
    const { active, completed, className } = props

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {!completed && !active && (
                <RadioButtonUnchecked
                    fontSize="small"
                    css={css`
                        color: #595959;
                    `}
                />
            )}
            {!completed && active && (
                <RadioButtonUnchecked
                    fontSize="small"
                    css={css`
                        color: #af0e11;
                    `}
                />
            )}
            {completed && !active && (
                <TaskAlt
                    css={css`
                        color: #004e83;
                    `}
                />
            )}
            {completed && active && (
                <TaskAlt
                    css={css`
                        color: #004e83;
                    `}
                />
            )}
        </QontoStepIconRoot>
    )
}

QontoStepIcon.propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
    completed: PropTypes.bool,
}

const Steppers = ({ steps = [], step }) => {
    return (
        <>
            <Stack
                sx={{ width: "100%" }}
                spacing={4}
                css={css`
                    margin: 25px 0px;
                    justify-content: center;
                `}
            >
                <Stepper
                    alternativeLabel
                    activeStep={step - 1}
                    connector={<QontoConnector />}
                >
                    {steps.map(({ shortTitle }) => (
                        <Step key={shortTitle}>
                            <StepLabel
                                StepIconComponent={QontoStepIcon}
                                css={css`
                                    color: #004e83;
                                `}
                            >
                                {shortTitle}
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Stack>
        </>
    )
}

export default Steppers
