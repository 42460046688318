import { Grid, useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/styles"
import { useContext } from "react"

import { OetContext, PreviewContext } from "../../context"
import OptimizedImage from "../OptimizedImage"
import { COUPON_STATUS } from "./coupon"
import { Heading } from "./heading"
import { Markdown } from "./markdown"
import { isContentActive } from "./util"

export const OETCoupon = ({ coupon, description, heading }) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("md"))
    const { state } = useContext(OetContext)
    const { state: { preview } = {} } = useContext(PreviewContext)

    let { couponImage, disclaimerContent, promotionImage, status } =
        coupon.fields
    const couponIsActive =
        status &&
        status === COUPON_STATUS.active &&
        isContentActive({ ...coupon.fields, preview })

    if (!couponIsActive) return null

    couponImage = couponImage?.fields || couponImage
    promotionImage = promotionImage?.fields || promotionImage

    const { height, ...couponImageProps } = couponImage || {} //remove height universally
    const { height: promotionImageHeight, ...promotionImageProps } =
        promotionImage || {} //remove height universally

    const image = isMobile ? couponImageProps : promotionImageProps

    return (
        <Grid
            container
            item
            alignItems="flex-start"
            justifyContent="center"
            spacing={4}
            sx={{
                p: "1.5rem",
            }}
            xs={12}
        >
            {(heading || description) && (
                <Grid item xs={12} md={8}>
                    {heading && <Heading {...heading.fields} />}

                    {description && (
                        <Markdown
                            alignment="left"
                            content={description}
                            // CONTEXT IS PASSED SO ANY INTERPOLATED TEXT
                            // THAT COMES FROM CONTENTFUL CAN BE TRANSLATED
                            // ex. {{ preferredMethod }} is interpolated within the coupon markdown
                            context={{
                                preferredMethod:
                                    state?.userInfo?.preferredMethod,
                            }}
                        />
                    )}
                </Grid>
            )}
            <Grid item xs={12} md={4}>
                <OptimizedImage
                    {...{ ...image }}
                    containerStyle={{
                        display: "flex",
                        margin: isMobile ? "0 auto" : 0,
                        maxWidth: isMobile ? "50%" : "100%",
                    }}
                />
            </Grid>
            {disclaimerContent && (
                <Grid
                    container
                    item
                    xs={12}
                    sx={{
                        "& .disclaimer-content > p": {
                            fontSize: ".75rem",
                            fontWeight: 400,
                            lineHeight: 1.66,
                            m: 0,
                        },
                    }}
                >
                    <Markdown
                        alignment="left"
                        content={disclaimerContent}
                        className="disclaimer-content"
                    />
                </Grid>
            )}
        </Grid>
    )
}
