import { Box, Grid, Stack, Typography } from "@mui/material"

import {
    cleanPhone,
    findState,
    onPhoneClick,
    reformatPhone,
} from "../../../util"
import Link from "../../Link"

export const OetStoreDetails = ({ location }) => {
    if (!location) return null

    return (
        <Grid container item spacing={1}>
            <Grid
                container
                item
                alignItems="center"
                justifyContent="space-between"
            >
                <Grid item>
                    <Typography
                        variant="h6"
                        color="primary"
                        fontFamily="Gilroy Bold"
                        sx={{ m: 0 }}
                    >
                        {location.storeName}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body3" color="primary">
                        <Link
                            href={`tel:${cleanPhone(location.organicPhone)}`}
                            onClick={() => onPhoneClick(location)}
                        >
                            {reformatPhone(location.organicPhone)}
                        </Link>
                    </Typography>
                </Grid>
            </Grid>
            <Grid
                container
                item
                alignItems="flex-start"
                justifyContent="space-between"
                sx={{ mb: 1 }}
                columnSpacing={{ xs: 1, sm: 1, md: 0 }}
                rowSpacing={{ xs: 1, sm: 1, md: 0 }}
            >
                <Grid item>
                    <Stack>
                        <Typography variant="body3">
                            {location.storeAddress}
                        </Typography>
                        <Typography variant="body3">
                            {location.storeCity},{" "}
                            {findState(location.storeState) ||
                                location.storeState}{" "}
                            {location.storePostcode}
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item>
                    <Stack sx={{ alignItems: "flex-end", display: "flex" }}>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            {consolidateStoreHours(location.hours)}
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            {weekendHours(
                                location.hours["Saturday"],
                                location.hours["Sunday"]
                            )}
                        </Box>
                    </Stack>
                </Grid>
            </Grid>
        </Grid>
    )
}

export const consolidateStoreHours = (data) => {
    const days = ["Tuesday", "Wednesday", "Thursday", "Friday"]
    let mondayHours = data["Monday"]
    let sameOperatingHours = true
    days.forEach((day) => {
        if (
            data[day].open !== mondayHours.open ||
            data[day].close !== mondayHours.close
        ) {
            sameOperatingHours = false
        }
    })
    return sameOperatingHours ? (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Typography variant="body3">
                    M - F : {mondayHours.open} - {mondayHours.close}
                </Typography>
            </div>
        </div>
    ) : (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Typography variant="body3">
                    Monday {data["Monday"].open} - {data["Monday"].close}
                </Typography>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Typography variant="body3">
                    Tuesday {data["Tuesday"].open} - {data["Tuesday"].close}
                </Typography>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                {" "}
                <Typography variant="body3">
                    Wednesday {data["Wednesday"].open} -{" "}
                    {data["Wednesday"].close}
                </Typography>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                {" "}
                <Typography variant="body3">
                    Thursday {data["Thursday"].open} - {data["Thursday"].close}
                </Typography>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                {" "}
                <Typography variant="body3">
                    Friday {data["Friday"].open} - {data["Friday"].close}
                </Typography>
            </div>
        </div>
    )
}

export const weekendHours = (Sat, Sun) => {
    if (Sat.open === "Closed" && Sun.open === "Closed") {
        return <Typography variant="body3">Sat - Sun: Closed</Typography>
    }

    if (Sat.open !== "Closed" && Sun.open === "Closed") {
        return (
            <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        <Typography variant="body3">
                            Saturday : {Sat.open} - {Sat.close}{" "}
                        </Typography>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        <Typography variant="body3">Sun: Closed</Typography>
                    </div>
                </div>
            </>
        )
    }

    if (Sat.open !== "Closed" && Sun.open !== "Closed") {
        return (
            <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        <Typography variant="body3">
                            Saturday : {Sat.open} - {Sat.close}
                        </Typography>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            <Typography variant="body3">
                                Sunday : {Sun.open} - {Sun.close}
                            </Typography>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
