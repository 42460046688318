/** @format */

/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { Button, ButtonBase, Typography } from "@mui/material"
import { useRouter } from "next/router"

import { NextLinkComposed } from "../../Link"
import { dataLayer } from "../../../util/index"

const ActionButton = ({
    bgColor,
    bgColorHover,
    clickableArea,
    handleOnClick,
    topBgColor,
    inTopBar,
    inPopper,
    fontColor,
    reverse,
    border,
    label,
    gtmValues,
    href,
    children,
}) => {
    const topBarStyles = {
        button: `
                padding: 2px;
                align-items: center;
                width: 100%;
                margin: 0 5px 0 0;
                background-color: ${topBgColor} !important;
                min-width: 110px;
                @media (max-width: 767px) {
                    width: 100%;
                    padding: 10px;
                    display: flex !important;
                    justify-content: ${inPopper ? "center" : "flex-start"
            } !important;
                    ${inPopper ? "height: auto !important;" : ""} 
                    border: none;
                }
                @media (min-width: 768px) and (max-width: 991px) {
                    padding: 12px !important;
                    margin-right: 2px !important;
                }
            `,
        Typography: `                                
                @media (max-width: 767px) {
                    font-size: 1rem;
                    font-weight: bold;
                }
                margin: 0 .3em 0 0;
                text-align: center;
                font-weight: bold;
                ${fontColor ? `color: ${fontColor};` : "color: #ffffff;"}
            `,
    }
    const regularStyles = {
        button: `
            color: #fff !important;
            padding: 8px;
            width: 100%;
            // max-width: 250px;
            max-height: 40px;
            height: 100%;
            text-align: center;
            & svg {
                width: 1.4em !important;
                height: 1.4em !important;
            }
            background-color: ${bgColor} !important;
            &:hover {
                background-color:${bgColorHover}
                !important;
            }
            `,
        Typography: `
            @media (max-width: 600px) {
                font-size: .9rem;
                margin-left: 15px;
            }
            font-weight: bold;
        `,
    }
    const styles = inTopBar ? topBarStyles : regularStyles
    const router = useRouter()

    const onClick = (e) => {
        // Adding the relevant GTM information to the datalayer
        dataLayer({
            gtm_storeid: router?.query?.storeid,
            gtm_pagerefsource: window.document.referrer,
            ...gtmValues,
        })
        handleOnClick && handleOnClick(e)
    }

    const renderButton = () => {
        return clickableArea ?
            // This allows the hero images to be clickable 
            // and deliver functionality like booking appointments
            (<ButtonBase
                LinkComponent={!href ? undefined : NextLinkComposed}
                disableRipple
                to={href}
                onClick={onClick}
                sx={{
                    background: 'none',
                    border: 'none',
                    height: '100%',
                    width: '100%'
                }}
            >
                {children}
            </ButtonBase>)
            : (<Button
                LinkComponent={!href ? undefined : NextLinkComposed}
                size="medium"
                to={href}
                variant="contained"
                disableElevation
                fullWidth
                color={inTopBar ? "secondary" : "primary"}
                css={css`
                display: flex;
                gap: 0.5em;
                @media (max-width: 600px) {
                    justify-content: center;
                }
                font-weight: bold;
                flex-direction: ${reverse && "row-reverse"
                    };
                ${styles.button};
                ${border
                        ? "border: 3px solid #af0e11 !important;"
                        : "border-radius: 0 !important;"
                    }
`}
                onClick={onClick}
            >
                {children}
                <Typography
                    variant="button"
                    component="span"
                    lineHeight={inTopBar && 1.2}
                    css={css`
                    ${styles.Typography}
`}
                >
                    {label}
                </Typography>
            </Button>)
    }
    return (
        renderButton()
    )
}

export default ActionButton
