/** @format */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import {
    Box,
    ButtonBase,
    ClickAwayListener,
    Grid,
    Popper,
    Typography,
    useMediaQuery,
} from "@mui/material"
import { useTheme } from "@mui/styles"
import {
    bindToggle,
    bindPopper,
    usePopupState,
} from "material-ui-popup-state/hooks"
import { useTranslation } from "next-i18next"
import MD from "react-markdown"

import SubMenu from "./SubMenu"
import CustomButtonRounded from "../../Buttons/CustomButtonRounded"
import Link from "../../Link"
import SearchLocation from "../../SearchLocation"
import { handleOnClickAway, menuClass, menuText } from ".."

const Links = ({ inPopper, toggleDrawer }) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
    const isTablet = useMediaQuery(theme.breakpoints.down("md"))
    const { t } = useTranslation()
    const popupState = usePopupState({
        variant: "popper",
        popupId: "contact-us-popup",
    })

    let menus = t(`content:header.navBarLinksCollection.items`, "")
    menus = menus || []

    let contactUsBody = t("content:header.contactUsCard.body")
    let contactUsElement = contactUsBody !==
        "content:header.contactUsCard.body" && (
        <MD components={{ p: "span" }}>{contactUsBody}</MD>
    )

    return (
        <Grid
            container
            wrap={inPopper ? "wrap" : "nowrap"}
            direction={{ xs: "column", md: "row" }}
            justifyContent="center"
            alignItems="center"
            padding={inPopper && "0.5em"}
            css={css`
                margin-bottom: 0.5em;
            `}
        >
            <Grid
                item
                container
                xs={12}
                direction={{ xs: "column", sm: "row" }}
                rowSpacing={{ xs: 1.5, sm: 0 }}
                columnSpacing={1.5}
                justifyContent="center"
                alignItems="center"
                css={css`
                    & .menu-button:hover {
                        box-shadow: 0 10px 0 0 #dd1215;
                    }

                    & a {
                        text-decoration: none;
                        font-weight: bold;
                        &:hover,
                        &.active {
                            text-decoration: none;
                            > * {
                                box-shadow: 0 10px 0 0 #dd1215;
                            }
                        }
                    }
                `}
            >
                {menus.map(
                    ({
                        link,
                        title,
                        target = `_self`,
                        icon,
                        text,
                        subMenu,
                    }) => {
                        return (
                            <Grid
                                item
                                key={`link-${title}`}
                                css={css`
                                    @media (max-width: 850px) {
                                        flex-basis: 16.7777%;
                                    }
                                `}
                            >
                                {subMenu && (
                                    <SubMenu
                                        subMenuHeader={text}
                                        subMenu={subMenu}
                                        isMobile={isMobile}
                                    />
                                )}
                                {!subMenu && (
                                    <Link
                                        href={link || "/"}
                                        key={`link_${link}`}
                                        target={target}
                                        noLinkStyle
                                        onClick={() =>
                                            toggleDrawer && toggleDrawer()
                                        }
                                    >
                                        <Typography
                                            variant={t("typ_var.nav_label")}
                                            component="div"
                                            css={css`
                                                margin: 2px 6px !important;
                                            `}
                                        >
                                            <Grid
                                                container
                                                alignItems={"center"}
                                            >
                                                {icon && (
                                                    <Grid
                                                        item
                                                        className={`${icon} icon-blue icon-medium`}
                                                        display={{
                                                            xs: "block",
                                                            sm: "none",
                                                        }}
                                                        css={css`
                                                            margin-right: 0.5em;
                                                        `}
                                                    >
                                                        <i
                                                            className={`fa-solid fa-${icon} fa-2x`}
                                                        />
                                                    </Grid>
                                                )}
                                                <Grid item>{title}</Grid>
                                            </Grid>
                                        </Typography>
                                    </Link>
                                )}
                            </Grid>
                        )
                    }
                )}
                <Grid
                    item
                    justifyContent={{ xs: "center", sm: undefined }}
                    css={css`
                        @media (max-width: 850px) {
                            flex-basis: 16.7777%;
                        }
                    `}
                >
                    <ButtonBase
                        disableRipple
                        className={menuClass}
                        css={css`
                            align-items: baseline;
                            box-sizing: border-box;
                            ${inPopper
                                ? `border-bottom: 1px solid #ebebeb;`
                                : ""}
                            margin: 2px 6px;
                            width: auto;
                            white-space: nowrap;
                            @media (max-width: 599px) {
                                width: 100%;
                            }
                        `}
                        {...bindToggle(popupState)}
                    >
                        <Typography
                            className={menuText}
                            css={css`
                                margin: 0;
                            `}
                            fontSize="1rem"
                            fontWeight={700}
                            variant={t("typ_var.nav_label")}
                        >
                            CONTACT US
                        </Typography>

                        <span
                            className="header-arrow-icons icon-down-dir"
                            css={css`
                                height: 100%;
                                margin-left: 4px;

                                &:before {
                                    content: "\\25bc";
                                    color: gray;
                                    font-size: 9px;
                                }

                                @media (min-width: 768px) and (max-width: 991px) {
                                    display: none;
                                }
                            `}
                        ></span>
                    </ButtonBase>

                    <ClickAwayListener
                        onClickAway={(e) => handleOnClickAway(e, popupState)}
                    >
                        <Popper
                            {...bindPopper(popupState)}
                            placement={isMobile ? "bottom" : "bottom-start"}
                            css={css`
                                box-sizing: border-box;
                                z-index: 1000;

                                @media (max-width: 767px) {
                                    min-width: 97%;
                                }
                            `}
                        >
                            <Box
                                css={css`
                                    background-color: #fff;
                                    border: 2px solid #595959;
                                    box-sizing: border-box;
                                    display: flex;
                                    flex-direction: column;
                                    min-width: 20rem;
                                    padding: 12px;
                                `}
                            >
                                <Typography
                                    variant={t("typ_var.body_2")}
                                    css={css`
                                        color: #005999;
                                        font-weight: bold;
                                        font-size: 0.9rem;
                                    `}
                                >
                                    {t(["content:header.contactUsCard.title"])}
                                </Typography>

                                <Typography
                                    variant={t("typ_var.body_2")}
                                    css={css`
                                        font-size: 1rem;
                                    `}
                                >
                                    {t([
                                        "content:header.contactUsCard.subtitle.text",
                                    ])}
                                </Typography>

                                <div
                                    css={css`
                                        margin: 0.5em 0;
                                    `}
                                >
                                    <SearchLocation
                                        navigateToPage
                                        showFindButton
                                        onPlaceChange={() => {
                                            popupState.close()
                                            toggleDrawer && toggleDrawer()
                                        }}
                                    />
                                </div>

                                <Typography
                                    css={css`
                                        border-top: 2px solid #214e70;
                                        margin-top: 4px;
                                    `}
                                >
                                    {contactUsElement}
                                </Typography>
                            </Box>
                        </Popper>
                    </ClickAwayListener>
                </Grid>
                <Grid
                    item
                    css={css`
                        margin-left: auto;
                        @media (max-width: 850px) {
                            margin: 0;
                        }
                    `}
                >
                    <CustomButtonRounded
                        label="Book An Appointment"
                        path="/locations"
                        variant="contained"
                        color="secondary"
                        icon={!isTablet && "icons icon-calendar-plus"}
                        iconPlacement="left"
                    >
                        <Typography
                            variant="button"
                            component="span"
                            lineHeight={1.2}
                        >
                            {"Book An Appointment"}
                        </Typography>
                    </CustomButtonRounded>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Links
