/** @format */

/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"

import { Box, Typography } from "@mui/material"
import Link from "next/link"
import Breadcrumbs from "nextjs-breadcrumbs"
import React from "react"
import { containerCSS } from "../../util"

const BreadCrumb = ({ navigations, showonmobile = false }) => {
    navigations = navigations || []

    let breadcrumbs

    if (!navigations.length)
        breadcrumbs = (
            <Breadcrumbs
                listClassName="breadcrumbs"
                transformLabel={(label) => {
                    return <div>{label.replaceAll("-", " ")}</div>
                }}
            />
        )

    const newList = [
        {
            href: `/`,
            description: "Home",
            title: "Home Page",
        },
        ...navigations,
    ]

    return (
        <Box
            className={showonmobile ? "" : "hidden-xs"}
            css={css`
                width: 100%;
                background-color: #ebebeb;
                margin-bottom: 1rem;

                nav {
                    &,
                    & > ol,
                    & > ol > li {
                        display: flex;
                        align-items: center;
                    }

                    & > ol {
                        padding: 0;
                    }

                    & > ol > li {
                        text-transform: uppercase;
                        font-weight: normal;

                        &:after {
                            content: "/";
                            margin: 0 0.25em;
                        }

                        > * {
                            margin: 0;
                            font-family: "Gilroy Medium";
                            color: rgb(89, 89, 89);
                        }

                        &:last-child {
                            font-weight: bold;

                            &:after {
                                content: "";
                                margin: none;
                            }

                            > * {
                                &:hover {
                                    cursor: auto;
                                    text-decoration: none;
                                }
                            }
                        }
                    }
                }
            `}
        >
            <Box
                css={css`
                    ${containerCSS}
                    padding: 0;
                `}
            >
                {breadcrumbs || (
                    <Box component={"nav"}>
                        <Box component={"ol"}>
                            {newList.map(
                                ({ href, title, description }, index) => {
                                    const label = (
                                        <Typography
                                            title={title}
                                            component={
                                                index + 1 === newList.length
                                                    ? "div"
                                                    : "a"
                                            }
                                        >
                                            {description}
                                        </Typography>
                                    )
                                    return (
                                        <Box
                                            component="li"
                                            key={`bread-crum-items${index}`}
                                        >
                                            {" "}
                                            {index + 1 === newList.length ? (
                                                label
                                            ) : (
                                                <Link href={href} passHref>
                                                    {label}
                                                </Link>
                                            )}
                                        </Box>
                                    )
                                }
                            )}
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    )
}

export default BreadCrumb
