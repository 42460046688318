import { useTranslation } from "next-i18next"
import Head from "next/head"
import { useRouter } from "next/router"
import { useContext } from "react"
import { Context } from "../../context"
import { getEnvironmentVariable } from "../../util/env"
import { PAGE_SCHEMA } from "../../util/schema"

const Seo = ({
    noFollow,
    noIndex,
    schema,
    shareImage,
    canonicalUrl,
    ...props
}) => {
    const { t } = useTranslation()
    const { state } = useContext(Context)
    const router = useRouter()

    const { content = props?.context } = state
    let { title } = props
    title = t(title, content)

    let { description } = props
    description = t(description, content)

    const botInstructions = []
    if (noIndex) botInstructions.push("noindex")
    if (noFollow) botInstructions.push("nofollow")

    const siteUrl = getEnvironmentVariable({ key: "SITE_URL" })

    return (
        <Head>
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@maaco" />
            <meta name="twitter:creator" content="@maaco" />

            {title && (
                <>
                    <title>{title}</title>
                    <meta property="og:title" content={title} />
                    <meta name="twitter:title" content={title} />
                </>
            )}

            {description && (
                <>
                    <meta name="description" content={description} />
                    <meta property="og:description" content={description} />
                    <meta name="twitter:description" content={description} />
                </>
            )}

            {shareImage && (
                <>
                    <meta property="og:image" content={shareImage} />
                    <meta name="twitter:image" content={shareImage} />
                    <meta name="image" content={shareImage} />
                </>
            )}

            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                    __html: JSON.stringify(schema) ?? PAGE_SCHEMA,
                }}
            />

            {router && router.pathname && (
                <link
                    rel="canonical"
                    href={
                        canonicalUrl ??
                        `${siteUrl}${router.asPath.split("?")[0]}`
                    }
                />
            )}

            {botInstructions.length && (
                <>
                    <meta
                        name="robots"
                        content={botInstructions.join(",")}
                    ></meta>
                    <meta
                        name="googlebot"
                        content={botInstructions.join(",")}
                    />
                </>
            )}
        </Head>
    )
}

export default Seo
