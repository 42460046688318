/** @format */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { yupResolver } from "@hookform/resolvers/yup"
import {
    AdapterDateFns,
    DesktopDatePicker,
    LocalizationProvider,
} from "@mui/lab"
import {
    Autocomplete,
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Grid,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material"
import { createFilterOptions } from "@mui/material/Autocomplete"
import axios from "axios"
import { format } from "date-fns"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import { useCallback, useContext, useEffect, useRef, useState } from "react"
import Geocode from "react-geocode"
import { Controller, useForm } from "react-hook-form"
import * as Yup from "yup"

import { Context } from "../../context"
import {
    NEW_API_KEY,
    NEW_API_URL,
    containerCSS,
    dataLayer,
    getVisitedURL,
    GOOGLE_MAP_API_KEY,
    GTM_EVENTS,
    reformatPhone,
} from "../../util"
import { API } from "../../util/api"
import { getEnvironmentVariable } from "../../util/env"
import { handleCaughtError } from "../../util/handlers"
import Link from "../Link"

Geocode.setApiKey(GOOGLE_MAP_API_KEY)

const INITIAL_STATE = {
    serviceName: 1,
    comments_marketing: `no`,
    name_marketing: `no`,
    selectedCenter: null,
    zipcode: ``,
    searchCity: ``,
    searchState: ``,
    contact: ``,
}

const TimeOfVisitData = [
    "07:30 AM",
    "08:00 AM",
    "08:30 AM",
    "09:00 AM",
    "09:30 AM",
    "10:00 AM",
    "10:30 AM",
    "11:00 AM",
    "11:30 AM",
    "12:00 PM",
    "12:30 PM",
    "01:00 PM",
    "01:30 PM",
    "02:00 PM",
    "02:30 PM",
    "03:00 PM",
    "03:30 PM",
    "04:00 PM",
    "04:30 PM",
    "05:00 PM",
    "05:30 PM",
    "06:00 PM",
]

const VisitTypeData = [
    {
        label: "Paint - Overall",
        value: "paint_overall",
    },
    {
        label: "Paint - Spot",
        value: "paint_spot",
    },
    {
        label: "Bodywork",
        value: "bodywork",
    },
]

const CustomerServiceForm = () => {
    const { state } = useContext(Context)
    const [formValue, setFormValue] = useState({ ...INITIAL_STATE })
    const [gtmEventStartedInLifecycle, setGtmEventStartedInLifecycle] =
        useState(false)
    const [showSuccessMsg, setShowSuccessMessage] = useState(false)
    const [showErrorsMsg, setShowErrorMessage] = useState(false)
    const { t } = useTranslation()

    const postalCodeRegEx = new RegExp(t("zip_code_validation"))

    const phoneRegExp = /^\(\d{3}\)[ ]\d{3}[-]\d{4}$/

    const emailRegExp =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    const stateSchema = Yup.object().shape({
        stateAbbreviation: Yup.string()
            .nullable()
            .required(`Enter ${t("state")}`),
        stateName: Yup.string()
            .nullable()
            .required(`Enter ${t("state")}`),
    })

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required("Enter first name").trim(),
        lastName: Yup.string().required("Enter last name").trim(),
        streetAddress: Yup.string().required("Enter Street Address Name"),
        city: Yup.string().required("Enter City"),
        state: stateSchema.nullable().required(`Enter ${t("state")}`),
        contact: Yup.string().required("Please select contact type"),
        postalCode: Yup.string()
            .matches(postalCodeRegEx, `${t("zip_code")} is not valid`)
            .required("Enter Postal Code"),
        feedback: Yup.string().required("Enter Feedback"),
        email: Yup.string()
            .matches(emailRegExp, "Email is not valid")
            .required(),
        phone: Yup.string()
            .matches(phoneRegExp, "Phone number is not valid")
            .required(),
    })

    const {
        register,
        handleSubmit,
        control,
        reset,
        formState: { errors, isDirty },
        getValues,
        watch,
        setValue,
        trigger,
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: "all",
    })

    const [maacoCenters, setMaacoCenters] = useState([])
    const [ControlledAutoCompleteInput, setControlledAutoCompleteInput] =
        useState({ stateName: "", stateAbbreviation: "" })
    const [autoCompleteInput, setAutoCompleteInput] = useState({
        stateName: "",
        stateAbbreviation: "",
    })

    const router = useRouter()

    const getGtmValues = useCallback(
        (selectedCenter) => {
            return {
                gtm_storeid:
                    router.query.storeid ?? selectedCenter?.storeId?.toString(),
                gtm_dma: selectedCenter?.dma,
                gtm_pagerefsource: window.document.referrer,
            }
        },
        [router]
    )

    useEffect(() => {
        if (!isDirty || gtmEventStartedInLifecycle) return

        dataLayer({
            event: GTM_EVENTS.customerServiceStart,
            ...getGtmValues(),
        })

        setGtmEventStartedInLifecycle(true)
    }, [isDirty, getGtmValues, gtmEventStartedInLifecycle])

    const onHandleChange = ({ target: { name, value, checked } }) => {
        if ([`comments_marketing`, `name_marketing`].indexOf(name) > -1) {
            formValue[name] = checked ? `yes` : `no`
        } else {
            formValue[name] = value
        }

        setFormValue({ ...formValue })
        setValue(name, value, { shouldDirty: true })
    }

    const validatePostalCode = (value) => {
        if (value.match(postalCodeRegEx)) {
            return true
        } else {
            return false
        }
    }

    const getMaacoStores = async (res) => {
        try {
            const stores = await API.getStores({
                lat: res.results[0].geometry.location.lat,
                lng: res.results[0].geometry.location.lng,
                country: getEnvironmentVariable({ key: "COUNTRY_CODE" }),
            })
            setMaacoCenters(stores)
        } catch (err) {
            handleCaughtError("Error getting stores from API", err)
        }
    }

    const onLocationClick = () => {
        let key = ``
        if (formValue.zipcode) {
            key = formValue.zipcode
        } else if (formValue.searchCity && formValue.searchState) {
            key = `${formValue.searchCity},${formValue.searchState}`
        }
        if (key) {
            Geocode.fromAddress(key)
                .then((res) => {
                    setFormValue({ ...formValue, selectedCenter: null })
                    getMaacoStores(res)
                })
                .catch((error) => {
                    handleCaughtError(
                        "Error getting Google geocode from address",
                        error
                    )
                })
        }
    }

    const handleFormSubmit = async (e) => {
        await handleSubmit(onSubmitClick)(e)
        return false
    }

    const watchPhone = watch("phone", false)
    const watchFeedback = watch("feedback", false)

    useEffect(() => {
        if (watchFeedback && watchFeedback !== "") {
            setValue(`feedback`, watchFeedback || "", {
                shouldValidate: true,
                shouldDirty: true,
            })
        }
    }, [watchFeedback, setValue])

    useEffect(() => {
        if (watchPhone && watchPhone !== "") {
            setValue("phone", reformatPhone(watchPhone), {
                shouldValidate: true,
                shouldDirty: true,
            })
        }
    }, [watchPhone, setValue])

    const onSubmitClick = async () => {
        const values = { ...getValues(), ...formValue }

        await trigger()
        if (readyToSubmit(true)) {
            return
        }
        const selectedCenter = values.selectedCenter || {}
        const data = {
            comment: values.feedback,
            title: {
                1: `priceOrQuoteInquiry`,
                2: `specificMaacoCenter`,
                3: `generalQuestionsComments`,
            }[values.serviceName],
            storeId: selectedCenter.storeId,
            visitType: values.visitType || "",
            timeOfVisit: values.visitTime || "",
            dateOfVisit: values.visitDate || "",
            firstName: values.firstName,
            lastName: values.lastName,
            customerAddress: values.streetAddress,
            customerCity: values.city,
            customerState: values.state.stateName,
            customerPostcode: values.postalCode,
            customerEmail: values.email,
            customerPhone: values.phone,
            contact: values.contact,
            commentsMarketing: values.comments_marketing,
            nameMarketing: values.name_marketing,
            visitedUrl: getVisitedURL(window.location),
            userIp: sessionStorage.getItem("userIp"),
        }

        axios
            .post(`${NEW_API_URL}/feedback`, data, {
                headers: {
                    apiKey: NEW_API_KEY,
                    session_id: sessionStorage.getItem("sessionId"),
                },
            })
            .then((successData) => {
                dataLayer({
                    event: GTM_EVENTS.customerServiceComplete,
                    ...getGtmValues(selectedCenter),
                })
                setShowSuccessMessage(successData.status === 201)
                setShowErrorMessage(successData.status !== 201)
                resetAllFormData()
            })
            .catch((e) => {
                setShowSuccessMessage(false)
                setShowErrorMessage(true)
                handleCaughtError("Error submitting customer form ", e)
            })
    }

    const resetAllFormData = () => {
        setFormValue({ ...INITIAL_STATE })
        setMaacoCenters([])
        let values = getValues()
        Object.keys(getValues()).forEach((fieldName) => {
            values[fieldName] = ``
        })
        reset(
            {
                ...values,
            },
            {
                keepValues: false,
                keepErrors: false,
                keepTouched: false,
                keepDefaultValues: true,
            }
        )
    }

    const readyToSubmit = (activeSubmitButton = false) => {
        if (!activeSubmitButton) {
            //Checks that the required fields are not empty
            const {
                firstName,
                lastName,
                streetAddress,
                city,
                state,
                postalCode,
                email,
                phone,
                contact,
                feedback,
                selectedCenter,
            } = { ...getValues(), ...formValue }
            const required = {
                firstName,
                lastName,
                streetAddress,
                city,
                state,
                postalCode,
                email,
                phone,
                contact,
                feedback,
                selectedCenter,
            }
            return Object.values(required).some((value) => !value)
        }

        return !(
            formValue.selectedCenter !== null &&
            Object.keys(errors).length === 0
        )
    }

    const [autoFillFlag, setAutoFillFlag] = useState(false)
    const stateInputRef = useRef(null)
    useEffect(() => {
        if (stateInputRef.current && autoFillFlag) {
            stateInputRef.current.blur()
            setAutoFillFlag(false)
        }
    }, [autoFillFlag])

    const searchStateInputRef = useRef(null)
    useEffect(() => {
        if (searchStateInputRef.current && autoFillFlag) {
            searchStateInputRef.current.blur()
            setAutoFillFlag(false)
        }
    }, [autoFillFlag])

    const findStateinOptions = (value) => {
        return state.states.find(
            (state) => state.stateName.toLowerCase() === value
        )
    }

    return (
        <>
            <Grid
                css={css`
                    ${containerCSS};
                    color: #595959;
                `}
            >
                <Grid
                    container
                    css={css`
                        justify-content: center;
                    `}
                >
                    <Grid item xs={12} container>
                        <Grid item xs={12}>
                            <Typography
                                variant={`body1`}
                                css={css`
                                    font-weight: bold;
                                    color: #214e70;
                                    margin-top: 15px;
                                `}
                            >
                                {`WHAT DO YOU NEED HELP WITH?`}
                            </Typography>
                            <Select
                                defaultValue={1}
                                name={`serviceName`}
                                fullWidth
                                onChange={onHandleChange}
                            >
                                {[
                                    {
                                        id: 1,
                                        title: `Price or Quote Inquiry`,
                                    },
                                    {
                                        id: 2,
                                        title: `About or for a specific Maaco Center`,
                                    },
                                    {
                                        id: 3,
                                        title: `General Questions or Comments`,
                                    },
                                ].map(({ id, title }) => (
                                    <MenuItem key={`service_${id}`} value={id}>
                                        <Typography
                                            variant="body1"
                                            component={"span"}
                                            css={css`
                                                padding-left: 10px;
                                                padding-top: 5px;
                                            `}
                                        >
                                            {title}
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </Select>

                            <Typography
                                variant={`body1`}
                                css={css`
                                    font-weight: bold;
                                    color: #214e70;
                                    margin-top: 15px;
                                `}
                            >
                                {`MAACO CENTER LOCATION INFORMATION*`}
                            </Typography>

                            {(isDirty || formValue.serviceName === 2) &&
                            !formValue.selectedCenter ? (
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    container
                                    alignItems={"center"}
                                >
                                    <Typography color="red" variant={`body1`}>
                                        {`Please Choose your Maaco Store by ${t(
                                            "zip_code"
                                        ).toLowerCase()} or ${t(
                                            "state"
                                        ).toLowerCase()} and city`}
                                    </Typography>
                                </Grid>
                            ) : null}
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            container
                            css={css`
                                margin-top: 15px;
                            `}
                        >
                            <Grid
                                item
                                xs={12}
                                sm={3}
                                justifyContent={`center`}
                                alignItems={`center`}
                            >
                                <Typography
                                    variant={`subtitle2`}
                                    css={css`
                                        color: #214e70;
                                        margin-bottom: -10px;
                                    `}
                                >
                                    {t("zip_code")}
                                </Typography>

                                <TextField
                                    variant={`outlined`}
                                    name={`zipcode`}
                                    //placeholder={`Zip Code`}
                                    autoComplete="section-store postal-code"
                                    onChange={onHandleChange}
                                    value={formValue.zipcode}
                                    fullWidth
                                    // defaultValue={formValue.zipcode}
                                />

                                {formValue?.zipcode &&
                                (validatePostalCode(formValue?.zipcode) ===
                                    false ||
                                    formValue?.zipcode?.length !=
                                        t("zip_code_length")) ? (
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        container
                                        alignItems={"center"}
                                    >
                                        <Typography
                                            color="red"
                                            variant={`body1`}
                                        >
                                            {`Please Enter Valid ${t(
                                                "zip_code"
                                            )}`}
                                        </Typography>
                                    </Grid>
                                ) : null}
                            </Grid>

                            <Grid item xs={12} sm={1} alignSelf="center">
                                <Typography
                                    align="center"
                                    variant="body1"
                                    css={css`
                                        color: #000;
                                        font-weight: bold;
                                        margin-bottom: -10px;
                                    `}
                                >
                                    {`OR`}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <Typography
                                    variant={`subtitle2`}
                                    css={css`
                                        color: #214e70;
                                        margin-bottom: -10px;
                                    `}
                                >
                                    {`City`}
                                </Typography>

                                <TextField
                                    variant={`outlined`}
                                    name={`searchCity`}
                                    // placeholder={`City`}
                                    autoComplete="section-store address-level2"
                                    fullWidth
                                    onChange={onHandleChange}
                                    value={formValue.searchCity}
                                    css={css`
                                        margin-right: 10px;
                                    `}
                                />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <Typography
                                    variant={`subtitle2`}
                                    css={css`
                                        color: #214e70;
                                        margin-bottom: -10px;
                                        @media (max-width: 768px) {
                                            margin-left: 0px;
                                        }
                                        margin-left: 10px;
                                    `}
                                >
                                    {t("state")}
                                </Typography>

                                <Autocomplete
                                    name={`searchState`}
                                    autoHighlight
                                    autoSelect
                                    value={autoCompleteInput}
                                    css={css`
                                        background-color: #fff;
                                        @media (max-width: 768px) {
                                            margin-left: 0px;
                                        }
                                        margin-left: 10px;
                                        & .MuiOutlinedInput-root {
                                            padding: 0 0 0 0;
                                            padding-left: 0.6rem;
                                        }
                                    `}
                                    onInput={(event) => {
                                        const state = findStateinOptions(
                                            event.target.value
                                        )
                                        if (state) {
                                            setAutoFillFlag(true)
                                            onHandleChange({
                                                target: {
                                                    name: "searchState",
                                                    value: state
                                                        ? state.stateAbbreviation
                                                        : "",
                                                },
                                            })
                                            setAutoCompleteInput(state)
                                            searchStateInputRef.current?.focus()
                                        }
                                    }}
                                    onChange={(_, value) => {
                                        onHandleChange({
                                            target: {
                                                name: "searchState",
                                                value: value
                                                    ? value.stateAbbreviation
                                                    : "",
                                            },
                                        })
                                        setAutoCompleteInput(value)
                                    }}
                                    options={state.states}
                                    renderOption={(props, option) => (
                                        <li
                                            {...props}
                                            key={`state_nm_${option.stateAbbreviation}`}
                                            value={option.stateAbbreviation}
                                            name={`state`}
                                        >
                                            <Typography
                                                variant="body1"
                                                component={"span"}
                                                css={css`
                                                    padding-left: 10px;
                                                    padding-top: 5px;
                                                `}
                                            >
                                                {option.stateName}
                                            </Typography>
                                        </li>
                                    )}
                                    getOptionLabel={(option) =>
                                        option.stateName ? option.stateName : ""
                                    }
                                    filterOptions={createFilterOptions({
                                        stringify: (option) =>
                                            `${option.stateName} ${option.stateAbbreviation}`,
                                    })}
                                    fullWidth
                                    renderInput={(params) => (
                                        <TextField
                                            InputProps={params.InputProps}
                                            inputRef={searchStateInputRef}
                                            inputProps={{
                                                autoComplete:
                                                    "section-store address-level1",
                                            }}
                                            {...params}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={4}
                                container
                                alignItems={"center"}
                            >
                                <Button
                                    css={css`
                                        position: relative;
                                        @media (max-width: 768px) {
                                            height: 40px;
                                            width: 60%;
                                        }
                                        height: 35px;
                                        margin-bottom: 10px;
                                        margin-top: 10px;
                                        padding: 0 18px;
                                        width: 100%;
                                        outline: none;
                                        text-transform: uppercase;
                                        color: #a11816;
                                        font-weight: 600;
                                        border: 2px solid #a11816;
                                        white-space: nowrap;
                                        &:after {
                                            content: "";
                                            position: absolute;
                                            top: 0;
                                            right: -10px;
                                            width: 0;
                                            height: 0;
                                            border-top: 15px solid transparent;
                                            border-bottom: 15px solid
                                                transparent;
                                        }
                                    `}
                                    onClick={() => onLocationClick()}
                                >
                                    <Typography
                                        variant={`body1`}
                                        css={css`
                                            padding: 10px 10px 10px 10px;
                                            color: #a11816;
                                            font-weight: bold;
                                        `}
                                    >
                                        {`LOCATE STORE*`}
                                    </Typography>
                                </Button>
                            </Grid>

                            {(formValue.zipcode &&
                                formValue?.zipcode?.length === 5) ||
                            (formValue.searchCity && formValue.searchState)
                                ? maacoCenters.length === 0 && (
                                      <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          container
                                          alignItems={"center"}
                                      >
                                          <Typography
                                              color="red"
                                              variant={`body1`}
                                          >
                                              {`Please click Locate Store`}
                                          </Typography>
                                      </Grid>
                                  )
                                : null}
                        </Grid>

                        {maacoCenters.length ? (
                            <Grid
                                container
                                item
                                xs={12}
                                md={10}
                                css={css`
                                    margin-top: 1rem !important;
                                    padding-top: 8px;
                                    max-height: 240px;
                                    overflow-y: scroll;
                                `}
                            >
                                {maacoCenters.map((center) => {
                                    return (
                                        <Grid
                                            key={`centers_${center.storeId}`}
                                            container
                                            css={css`
                                                margin-top: 1rem !important;
                                            `}
                                        >
                                            <Grid item xs={2}>
                                                <Checkbox
                                                    name={`maacoCenter`}
                                                    value={center.storeId}
                                                    checked={
                                                        center.storeId ===
                                                        formValue.selectedCenter
                                                            ?.storeId
                                                    }
                                                    onChange={({
                                                        target: { checked },
                                                    }) =>
                                                        setFormValue({
                                                            ...formValue,
                                                            selectedCenter:
                                                                checked
                                                                    ? center
                                                                    : null,
                                                        })
                                                    }
                                                ></Checkbox>
                                            </Grid>
                                            <Grid item xs={7}>
                                                <Typography
                                                    variant={`h5`}
                                                    css={css`
                                                        margin: 0;
                                                        text-transform: uppercase;
                                                        color: #006bb7;
                                                    `}
                                                >
                                                    {`maaco ${center.storeCity}, ${center.storeState}`}
                                                </Typography>
                                                <Typography variant={`body1`}>
                                                    {`${center.storeAddress} `}
                                                </Typography>
                                                <Typography
                                                    variant={`body1`}
                                                    css={css`
                                                        margin-top: -10px;
                                                    `}
                                                >
                                                    {`${center.storeCity}, ${center.storeState} ${center.storePostcode}`}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={3}
                                                css={css`
                                                    color: #006bb7;
                                                    text-align: center;
                                                `}
                                            >
                                                <Typography
                                                    variant={`h4`}
                                                    fontWeight={"bold"}
                                                    css={css`
                                                        margin-top: -14px;
                                                    `}
                                                >
                                                    {t("distance_abbrev", {
                                                        count:
                                                            t("distance") ===
                                                            "kilometers"
                                                                ? +(
                                                                      Number.parseFloat(
                                                                          center.distance
                                                                      ) * 1.609
                                                                  ).toFixed(2)
                                                                : +Number.parseFloat(
                                                                      center.distance
                                                                  ).toFixed(2),
                                                    })}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        ) : (
                            ``
                        )}
                        {maacoCenters.length && !formValue.selectedCenter ? (
                            <Grid item xs={12} sm={12}>
                                <Typography variant={`body1`} color="red">
                                    {`Please select the Store`}
                                </Typography>
                            </Grid>
                        ) : null}
                        {formValue.serviceName === 2 && (
                            <>
                                <Grid item xs={12} sm={12}>
                                    <Typography
                                        variant={`body1`}
                                        css={css`
                                            font-weight: bold;
                                            color: #006bb7;
                                            margin-top: 12px;
                                        `}
                                    >
                                        {`Your Visit Details`}
                                    </Typography>
                                </Grid>

                                <Grid container>
                                    <Grid item xs={12} sm={4}>
                                        <Typography
                                            variant={`subtitle2`}
                                            css={css`
                                                color: #214e70;
                                                margin-bottom: -10px;
                                                margin-top: 10px;
                                            `}
                                        >
                                            {`Date of visit`}
                                        </Typography>

                                        <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                        >
                                            <DesktopDatePicker
                                                disabled={
                                                    formValue?.selectedCenter
                                                        ? false
                                                        : true
                                                }
                                                value={
                                                    formValue?.visitDate
                                                        ? formValue?.visitDate
                                                        : null
                                                }
                                                onChange={(newValue) => {
                                                    const formattedDate =
                                                        format(
                                                            newValue,
                                                            "MM/dd/yyyy"
                                                        )

                                                    setFormValue({
                                                        ...formValue,
                                                        visitDate:
                                                            formattedDate,
                                                    })
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        css={css`
                                                            width: 96%;
                                                            @media (max-width: 768px) {
                                                                width: 100%;
                                                            }
                                                            margin-right: 1rem;
                                                            position: relative;
                                                        `}
                                                        {...params}
                                                    />
                                                )}
                                                maxDate={new Date()}
                                            />
                                        </LocalizationProvider>

                                        <Typography
                                            variant="body1"
                                            color="error"
                                        >
                                            {errors.visitDate?.message}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <Typography
                                            variant={`subtitle2`}
                                            css={css`
                                                color: #214e70;
                                                margin-bottom: -10px;
                                                margin-top: 10px;
                                            `}
                                        >
                                            {`Time of visit`}
                                        </Typography>

                                        <Select
                                            disabled={
                                                formValue?.selectedCenter
                                                    ? false
                                                    : true
                                            }
                                            name={`visitTime`}
                                            onChange={onHandleChange}
                                            {...register(`visitTime`)}
                                            css={css`
                                                width: 96%;
                                                @media (max-width: 768px) {
                                                    width: 100%;
                                                }
                                            `}
                                        >
                                            {TimeOfVisitData.map((item, i) => (
                                                <MenuItem
                                                    key={`time_nm_${i}`}
                                                    value={item}
                                                >
                                                    <Typography
                                                        variant="body1"
                                                        component={"span"}
                                                        css={css`
                                                            padding-left: 10px;
                                                            padding-top: 5px;
                                                        `}
                                                    >
                                                        {item}
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                        </Select>

                                        <Typography
                                            variant="body1"
                                            color="error"
                                        >
                                            {errors.visitTime?.message}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <Typography
                                            variant={`subtitle2`}
                                            css={css`
                                                color: #214e70;
                                                margin-bottom: -10px;
                                                margin-top: 10px;
                                            `}
                                        >
                                            {`Visit type`}
                                        </Typography>
                                        <Select
                                            name={`visitType`}
                                            onChange={onHandleChange}
                                            {...register(`visitType`)}
                                            css={css`
                                                width: 100%;
                                            `}
                                        >
                                            {VisitTypeData.map((item, i) => (
                                                <MenuItem
                                                    key={`visitType_nm_${i}`}
                                                    value={item?.value}
                                                >
                                                    <Typography
                                                        variant="body1"
                                                        component={"span"}
                                                        css={css`
                                                            padding-left: 10px;
                                                            padding-top: 5px;
                                                        `}
                                                    >
                                                        {item?.label}
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <Typography
                                            variant="body1"
                                            color="error"
                                        >
                                            {errors.visitType?.message}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Grid>
            </Grid>

            <form
                id="userInfo"
                onSubmit={(e) => handleFormSubmit(e)}
                onReset={reset}
                noValidate
                css={css`
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                `}
            >
                <Grid
                    css={css`
                        ${containerCSS};
                        color: #595959;
                    `}
                >
                    <Grid
                        container
                        css={css`
                            justify-content: center;
                        `}
                    >
                        <Grid item xs={12}>
                            <Grid item xs={12} sm={12}>
                                <Typography
                                    variant={`body1`}
                                    css={css`
                                        font-weight: bold;
                                        color: #214e70;
                                    `}
                                >
                                    {`YOUR CONTACT DETAILS`}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} container>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    css={css`
                                        margin-top: 10px;
                                    `}
                                >
                                    <Typography
                                        variant={`subtitle2`}
                                        css={css`
                                            color: #214e70;
                                            margin-bottom: -10px;
                                        `}
                                    >
                                        {`First Name*`}
                                    </Typography>
                                    <TextField
                                        name={`firstname`}
                                        //placeholder={`First Name*`}
                                        {...register(`firstName`)}
                                        autoComplete="section-contact given-name"
                                        onChange={onHandleChange}
                                        variant={"outlined"}
                                        css={css`
                                            width: 100%;
                                            margin-right: 10px;
                                        `}
                                    />
                                    <Typography variant="body1" color="error">
                                        {errors.firstName?.message}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    css={css`
                                        margin-top: 10px;
                                    `}
                                >
                                    <Typography
                                        variant={`subtitle2`}
                                        css={css`
                                            color: #214e70;
                                            margin-bottom: -10px;
                                            margin-left: 10px;
                                            @media (max-width: 768px) {
                                                margin-left: 0px;
                                            }
                                        `}
                                    >
                                        {`Last Name*`}
                                    </Typography>
                                    <TextField
                                        name={`lastname`}
                                        //  placeholder={`Last Name*`}
                                        autoComplete="section-contact family-name"
                                        {...register(`lastName`)}
                                        onChange={onHandleChange}
                                        variant={"outlined"}
                                        css={css`
                                            width: 100%;
                                            margin-left: 10px;
                                            @media (max-width: 768px) {
                                                margin-left: 0px;
                                            }
                                        `}
                                    />
                                    <Typography variant="body1" color="error">
                                        {errors.lastName?.message}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid item container>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    css={css`
                                        margin-top: 10px;
                                    `}
                                >
                                    <Typography
                                        variant={`subtitle2`}
                                        css={css`
                                            color: #214e70;
                                            margin-bottom: -10px;
                                        `}
                                    >
                                        {`Street Address*`}
                                    </Typography>
                                    <TextField
                                        name={`streetAddress`}
                                        //  placeholder={`Street Address*`}
                                        {...register(`streetAddress`)}
                                        autoComplete="section-contact street-address"
                                        onChange={onHandleChange}
                                        variant={"outlined"}
                                        css={css`
                                            width: 100%;
                                            margin-right: 10px;
                                        `}
                                    />
                                    <Typography variant="body1" color="error">
                                        {errors.streetAddress?.message}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    css={css`
                                        margin-top: 10px;
                                    `}
                                >
                                    <Typography
                                        variant={`subtitle2`}
                                        css={css`
                                            color: #214e70;
                                            margin-bottom: -10px;
                                            margin-left: 10px;
                                            @media (max-width: 768px) {
                                                margin-left: 0px;
                                            }
                                        `}
                                    >
                                        {`City*`}
                                    </Typography>
                                    <TextField
                                        //placeholder={`City*`}
                                        onChange={onHandleChange}
                                        {...register(`city`)}
                                        autoComplete="section-contact address-level2"
                                        variant={"outlined"}
                                        css={css`
                                            width: 100%;
                                            margin-left: 10px;
                                            @media (max-width: 768px) {
                                                margin-left: 0px;
                                            }
                                        `}
                                    />
                                    <Typography variant="body1" color="error">
                                        {errors.city?.message}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid item container>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    css={css`
                                        margin-top: 10px;
                                    `}
                                >
                                    <Typography
                                        variant={`subtitle2`}
                                        css={css`
                                            color: #214e70;
                                            margin-bottom: -10px;
                                        `}
                                    >
                                        {`${t("state")}*`}
                                    </Typography>
                                    <Controller
                                        name={`state`}
                                        control={control}
                                        render={({
                                            field: { onChange, ...field },
                                        }) => (
                                            <Autocomplete
                                                {...field}
                                                autoHighlight
                                                autoComplete
                                                autoSelect
                                                value={
                                                    ControlledAutoCompleteInput
                                                }
                                                onInput={(event) => {
                                                    const state =
                                                        findStateinOptions(
                                                            event.target.value
                                                        )
                                                    if (state) {
                                                        setAutoFillFlag(true)
                                                        onChange(state)
                                                        setControlledAutoCompleteInput(
                                                            state
                                                        )
                                                        stateInputRef.current?.focus()
                                                    }
                                                }}
                                                onChange={(_, data) => {
                                                    onChange(data)
                                                    setControlledAutoCompleteInput(
                                                        data
                                                    )
                                                }}
                                                css={css`
                                                    & .MuiOutlinedInput-root {
                                                        padding: 0 0 0 0;
                                                        padding-left: 0.6rem;
                                                    }
                                                `}
                                                options={state.states}
                                                renderOption={(
                                                    props,
                                                    option
                                                ) => (
                                                    <li
                                                        {...props}
                                                        key={`state_nm_${option.stateAbbreviation}`}
                                                        value={
                                                            option.stateAbbreviation
                                                        }
                                                        name={`state`}
                                                    >
                                                        <Typography
                                                            variant="body1"
                                                            component={"span"}
                                                            css={css`
                                                                padding-left: 10px;
                                                                padding-top: 5px;
                                                            `}
                                                        >
                                                            {option.stateName}
                                                        </Typography>
                                                    </li>
                                                )}
                                                getOptionLabel={(option) =>
                                                    option.stateName
                                                        ? option.stateName
                                                        : ""
                                                }
                                                filterOptions={createFilterOptions(
                                                    {
                                                        stringify: (option) =>
                                                            `${option.stateName} ${option.stateAbbreviation}`,
                                                    }
                                                )}
                                                fullWidth
                                                renderInput={(params) => (
                                                    <TextField
                                                        InputProps={{
                                                            ...params.InputProps,
                                                        }}
                                                        inputRef={stateInputRef}
                                                        inputProps={{
                                                            autoComplete:
                                                                "section-store address-level1",
                                                        }}
                                                        {...params}
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                    <Typography variant="body1" color="error">
                                        {errors.state?.message}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    css={css`
                                        margin-top: 10px;
                                    `}
                                >
                                    <Typography
                                        variant={`subtitle2`}
                                        css={css`
                                            color: #214e70;
                                            margin-bottom: -10px;
                                            margin-left: 10px;
                                            @media (max-width: 768px) {
                                                margin-left: 0px;
                                            }
                                        `}
                                    >
                                        {`${t("zip_code")}*`}
                                    </Typography>
                                    <TextField
                                        name={`postalCode`}
                                        {...register(`postalCode`)}
                                        //placeholder={`Postal Code*`}
                                        autoComplete="section-contact postal-code"
                                        onChange={onHandleChange}
                                        variant={"outlined"}
                                        css={css`
                                            width: 100%;
                                            margin-left: 10px;
                                            @media (max-width: 768px) {
                                                margin-left: 0px;
                                            }
                                        `}
                                    />
                                    <Typography variant="body1" color="error">
                                        {errors.postalCode?.message}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid item container>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    css={css`
                                        margin-top: 10px;
                                    `}
                                >
                                    <Typography
                                        variant={`subtitle2`}
                                        css={css`
                                            color: #214e70;
                                            margin-bottom: -10px;
                                        `}
                                    >
                                        {`Email Address*`}
                                    </Typography>
                                    <TextField
                                        name={`email`}
                                        //  placeholder={`Email Address*`}
                                        onChange={onHandleChange}
                                        autoComplete="section-contact email"
                                        {...register(`email`)}
                                        variant={"outlined"}
                                        css={css`
                                            width: 100%;
                                            margin-right: 10px;
                                        `}
                                    />
                                    <Typography variant="body1" color="error">
                                        {errors.email?.message}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    css={css`
                                        margin-top: 10px;
                                    `}
                                >
                                    <Typography
                                        variant={`subtitle2`}
                                        css={css`
                                            color: #214e70;
                                            margin-bottom: -10px;
                                            margin-left: 10px;
                                            @media (max-width: 768px) {
                                                margin-left: 0px;
                                            }
                                        `}
                                    >
                                        {`Phone Number*`}
                                    </Typography>
                                    <TextField
                                        name={`phone`}
                                        // placeholder={`Phone Number*`}
                                        onChange={onHandleChange}
                                        autoComplete="section-contact tel"
                                        {...register(`phone`)}
                                        variant={"outlined"}
                                        css={css`
                                            width: 100%;
                                            margin-left: 10px;
                                            @media (max-width: 768px) {
                                                margin-left: 0px;
                                            }
                                        `}
                                    />
                                    <Typography variant="body1" color="error">
                                        {errors.phone?.message}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography
                                    variant={`subtitle2`}
                                    css={css`
                                        color: #214e70;
                                        margin-bottom: -10px;
                                        margin-top: 10px;
                                    `}
                                >
                                    {`How may we contact you?*`}
                                </Typography>
                                <Select
                                    {...register(`contact`)}
                                    onChange={onHandleChange}
                                    value={formValue.contact}
                                    name={`contact`}
                                    fullWidth
                                >
                                    <MenuItem value={`email`}>
                                        <Typography
                                            variant="body1"
                                            component={"span"}
                                            css={css`
                                                padding-left: 10px;
                                                padding-top: 5px;
                                            `}
                                        >
                                            {`Email`}
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem value={`phone`}>
                                        <Typography
                                            variant="body1"
                                            component={"span"}
                                            css={css`
                                                padding-left: 10px;
                                                padding-top: 5px;
                                            `}
                                        >
                                            {`Phone`}
                                        </Typography>
                                    </MenuItem>
                                </Select>
                                <Typography variant="body1" color="error">
                                    {errors?.contact?.message}
                                </Typography>
                            </Grid>

                            <Grid container item xs={12}>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        rows={5}
                                        onChange={onHandleChange}
                                        multiline
                                        variant={"outlined"}
                                        css={css`
                                            width: 100%;
                                            & > div {
                                                height: auto;
                                            }
                                        `}
                                        name={`feedback`}
                                        {...register(`feedback`)}
                                        placeholder={`Please enter your comments and feedback*`}
                                    />
                                    <Typography
                                        variant={`body1`}
                                        css={css`
                                            font-size: 0.8rem;
                                        `}
                                    >
                                        {`${
                                            2000 - getValues()?.feedback?.length
                                        }/2000 Character Limit`}
                                    </Typography>
                                    <Typography variant="body1" color="error">
                                        {errors.feedback?.message}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                container
                                css={css`
                                    & .MuiFormControlLabel-label {
                                        font-size: 0.8rem;
                                    }
                                `}
                            >
                                <Grid item xs={12}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    onChange={onHandleChange}
                                                    name={`comments_marketing`}
                                                    checked={
                                                        formValue.comments_marketing ===
                                                        `yes`
                                                    }
                                                />
                                            }
                                            label="YOU MAY USE MY COMMENTS ON PUBLIC MARKETING MATERIAL SUCH AS THE MAACO WEBSITE"
                                        />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    onChange={onHandleChange}
                                                    name={`name_marketing`}
                                                    checked={
                                                        formValue.name_marketing ===
                                                        `yes`
                                                    }
                                                />
                                            }
                                            label="YOU MAY USE MY NAME ON PUBLIC MARKETING MATERIAL SUCH AS THE MAACO WEBSITE"
                                        />
                                    </FormGroup>
                                </Grid>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                container
                                alignItems={"center"}
                                justifyContent={"center"}
                                flexDirection={`column`}
                            >
                                <Button
                                    type="submit"
                                    disabled={readyToSubmit()}
                                    className={`btn`}
                                    variant={`body1`}
                                    css={css`
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        color: #a11816;
                                        border: 3px solid #a11816 !important;
                                        padding: 0 0 0 0 !important;

                                        &:disabled {
                                            color: #fff;
                                            border: none !important;
                                        }
                                        &:hover {
                                            background-color: #a11816;
                                            color: #fff;
                                        }
                                    `}
                                >
                                    <Typography variant={`h5`}>
                                        {`SUBMIT`}
                                    </Typography>
                                </Button>

                                {showSuccessMsg && (
                                    <Typography
                                        css={css`
                                            margin: 15px;
                                            color: #7a841b;
                                        `}
                                    >
                                        {`Thank you. Your message was sent successfully.`}
                                    </Typography>
                                )}
                                {showErrorsMsg && (
                                    <Typography
                                        css={css`
                                            margin: 15px;
                                            color: #a11816;
                                        `}
                                    >
                                        {`Your message did not send. Please fill in all the required fields, select a store, and try again.`}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    align="center"
                                    variant="body1"
                                    css={css`
                                        width: 100%;
                                        margin-top: 20px;
                                        font-size: 0.8rem;
                                    `}
                                >
                                    {`*By clicking Submit, I agree to receive marketing emails from Maaco and accept the terms of Maaco’s `}
                                    <Link href="/privacy-policy" noLinkStyle>
                                        Privacy Policy.
                                    </Link>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    align="center"
                                    variant="body1"
                                    css={css`
                                        width: 100%;
                                        margin-top: 20px;
                                        font-size: 0.8rem;
                                    `}
                                >
                                    {`PLEASE NOTE: COMMENTS SUBMITTED ON THIS SITE
                                    MAY BE EDITED FOR SPELLING, GRAMMAR AND OR
                                    LENGTH PRIOR TO BEING UTILIZED IN ANY
                                    MARKETING MATERIAL. BY CLICKING SUBMIT, I
                                    AGREE TO MAACO'S `}
                                    <Link href="/privacy-policy">
                                        PRIVACY POLICY
                                    </Link>
                                    ,{" "}
                                    <Link href="/estimate-disclaimer">
                                        {`MAACO'S ESTIMATE TOOL DISCLAIMER`}
                                    </Link>
                                    , AND{" "}
                                    <Link href="/mobileterms">
                                        TEXT PROGRAM TERMS OF USE
                                    </Link>
                                    .
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}

export default CustomerServiceForm
