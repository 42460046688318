/** @jsxImportSource @emotion/react */
/** @format */

import { css } from "@emotion/react"
import { Grid } from "@mui/material"
import { getObjectTypename } from "../../util/contentful/api"
import { CouponEmailButton } from "../Buttons/CouponEmail"
import { CouponTextButton } from "../Buttons/CouponText"
import { ContentLink, CtaButton, InputField } from "./index"

export const Action = ({ fields, ...props }) => {
    const action = fields || props
    const __typename = getObjectTypename(props) || "InputField"

    const actionMap = {
        InputField: InputField,
        CtaButton: CtaButton,
        Link: ContentLink,
        "Global Coupon Email Button": CouponEmailButton,
        "Global Coupon Text Button": CouponTextButton,
    }
    const ActionType = actionMap[__typename]

    if (ActionType) return <ActionType {...{ ...action, ...props }} />

    console.log("Action Not Found", { __typename, ...action, ...props })
    return null
}

export const Actions = ({ actions, alignment, ...props }) => {
    if (!actions?.length) return null

    return (
        <Grid
            item
            container
            spacing={2}
            css={css`
                justify-content: ${alignment};
            `}
        >
            {actions.map((actionItem, index) => {
                const action = actionItem.fields || actionItem

                return (
                    <Grid
                        key={`action-${index}`}
                        item
                        xs={action.width && 12}
                        sm={action.width && (action.width * 12) / 100}
                    >
                        <Grid item>
                            <Action {...{ ...actionItem, ...props }} />
                        </Grid>
                    </Grid>
                )
            })}
        </Grid>
    )
}
