/** @jsxImportSource @emotion/react */
/** @format */

import { Grid, Typography } from "@mui/material"
import { useContext } from "react"

import { PreviewContext } from "../../context"
import OptimizedImage from "../OptimizedImage"
import { Actions } from "./actions"
import { isContentActive } from "./util"

export const COUPON_STATUS = {
    active: "ACTIVE",
    inActive: "INACTIVE",
    pending: "PENDING",
}

export const ContentCoupon = ({
    count,
    hideActions,
    status,
    storeId,
    store,
    ...props
}) => {
    const { state: { preview } = {} } = useContext(PreviewContext)
    const coupon = props.fields || props
    const couponIsActive =
        status &&
        status === COUPON_STATUS.active &&
        isContentActive({ ...coupon, preview })

    if (!couponIsActive) return null

    let { disclaimerContent, buttons, couponImage, promotionImage } = coupon
    couponImage = couponImage?.fields || couponImage
    promotionImage = promotionImage?.fields || promotionImage

    const actions = !hideActions && [
        <Actions
            key={`actions-${storeId}`}
            {...{ actions: buttons, coupon, storeId, store }}
        />,
    ]

    const { height, ...couponImageProps } = couponImage || {} //remove height universally
    const { height: promotionImageHeight, ...promotionImageProps } =
        promotionImage || {} //remove height universally

    const image = storeId ? couponImageProps : promotionImageProps
    const restrictWidth =
        !storeId && // we're on the promo page
        (!count || count === 1)

    return (
        <>
            <Grid
                item
                container
                md={restrictWidth ? 6 : 12}
                alignSelf={restrictWidth ? "center" : "flex-start"}
                alignItems="center"
                justifyContent="center"
                direction="column"
                style={{
                    backgroundColor: "#ebebeb",
                    padding: "1.5rem",
                }}
            >
                <Grid item width="100%">
                    <OptimizedImage {...{ ...image }} />
                </Grid>

                {actions && <Grid item>{actions.map((el) => el)}</Grid>}

                {!storeId && (
                    <Grid item>
                        <Typography variant={"body2"}>
                            {disclaimerContent}
                        </Typography>
                    </Grid>
                )}
            </Grid>
        </>
    )
}
