/** @format */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { Room } from "@mui/icons-material"
import { Grid, Typography } from "@mui/material"
import { useTranslation } from "next-i18next"
import SearchLocation from "../../SearchLocation"
import CountrySelector from "../countrySelector"
import { PhoneNumber } from "../phoneNumber"

const FindLocation = ({ hideSearchBar = false }) => {
    const { t } = useTranslation()

    const phoneNumber = <PhoneNumber />

    return (
        <Grid container wrap="nowrap" alignItems="center">
            <Grid
                item
                sm={phoneNumber ? 8 : "auto"}
                md={phoneNumber ? 7 : "auto"}
                container
                wrap="nowrap"
                justifyContent="flex-start"
                css={css`
                    height: 34px;
                    margin-right: 10px;
                    & .locationsSearchbox {
                        border: 1px solid #8b8b8b;

                        //TODO seems to be unused
                        & > div {
                            margin: 0;
                        
                        }
                        }
                    }
                `}
            >
                {hideSearchBar ? null : (
                    <>
                        <Typography
                            component="p"
                            variant="h5"
                            color="gray.light"
                            alignSelf="end"
                            display="flex"
                            alignItems="center"
                            justifyContent="right"
                            css={css`
                                margin: 0 8px 0 6px;
                                padding-right: 0.5em;
                                border-right: 0.12rem solid
                                    rgba(84, 84, 84, 0.9);
                                text-align: right;
                                height: 100%;
                                min-width: 135px;
                            `}
                        >
                            {t(["content:header.findYourMaaco"])}
                        </Typography>

                        <SearchLocation
                            navigateToPage
                            props={{
                                size: "small",
                                css: css`
                                    height: auto;
                                    margin: 0;
                                    & > input {
                                        padding-top: 0;
                                        padding-bottom: 0;
                                    }
                                `,
                            }}
                            endAdornment={
                                //TODO should this be the same icon globally?
                                <Room
                                    htmlColor="#b30f11"
                                    css={css`
                                        margin-right: -10px;
                                    `}
                                />
                            }
                            showFindButton
                        />
                    </>
                )}
            </Grid>

            <Grid item container sm={4} md={5} justifyContent="flex-end">
                {phoneNumber && (
                    <Grid item container xs={9} alignItems={`flex-end`}>
                        {phoneNumber}
                    </Grid>
                )}

                <Grid
                    item
                    container
                    xs={3}
                    justifyContent={phoneNumber && `flex-end`}
                >
                    <CountrySelector />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default FindLocation
