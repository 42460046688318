import SearchLocation from "../SearchLocation"

export const InputField = ({ name, ...props }) => {
    const inputFieldMap = {
        "Home Location Search and Estimate Section - Location Search Card Input Field":
            {
                Component: SearchLocation,
                Props: { showFindButton: true, navigateToPage: true },
            },
        "Schedule an Appointment Input Field": {
            Component: SearchLocation,
            Props: { showFindButton: true, navigateToPage: true },
        },
        "Offers Page Input Field": {
            Component: SearchLocation,
            Props: { showFindButton: true, navigateToPage: true },
        },
        "Search - Location Filter - Input": {
            Component: SearchLocation,
            Props: { navigateToPage: true },
        },
    }
    const InputType = inputFieldMap[name]

    if (InputType)
        return <InputType.Component {...{ ...InputType.Props, ...props }} />

    console.log("Input Field Not Found", { name, ...props })
    return null
}
