/** @format */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { yupResolver } from "@hookform/resolvers/yup"
import { Button, Grid, Typography } from "@mui/material"
import axios from "axios"
import { useForm, Controller } from "react-hook-form"
import * as Yup from "yup"

import {
    couponsDelivered,
    getVisitedURL,
    GTM_EVENTS,
    NEW_API_KEY,
    NEW_API_URL,
    reformatPhone,
} from "../../../util"
import { handleCaughtError } from "../../../util/handlers"

const Phone = ({ disclaimer, offers, handleCloseText, storeId }) => {
    const phoneRegExp = /^\(\d{3}\)[ ]\d{3}[-]\d{4}$/

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required(`First name is required`),
        lastName: Yup.string().required(`Last name is required`),
        phone: Yup.string()
            .matches(phoneRegExp, "Phone number is not valid")
            .required(`Phone number is required`),
    })

    const {
        register,
        handleSubmit,
        reset,
        control,
        formState: { errors, touchedFields },
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: "all",
    })

    const sendCoupon = (data) => {
        const config = {
            headers: {
                apikey: NEW_API_KEY,
                session_id: sessionStorage.getItem("sessionId"),
            },
        }

        let phone = data.phone.replace(/[^A-Z0-9]/gi, "")

        const postData = {
            storeId,
            firstName: data.firstName,
            lastName: data.lastName,
            phone: `1${phone}`,
            offers,
            email: "",
            visitedUrl: getVisitedURL(window.location),
            userIp: sessionStorage.getItem("userIp"),
        }

        axios
            .post(
                `${NEW_API_URL}/stand-offer/assign-formatted`,
                postData,
                config
            )
            .then((res) => {
                couponsDelivered({ event: GTM_EVENTS.couponText, offers })
                handleCloseText()
                reset()
            })
            .catch((e) => {
                handleCaughtError("error at sending coupon", e)
            })
    }

    return (
        <form
            id="coupon1"
            onSubmit={handleSubmit(sendCoupon)}
            onReset={reset}
            noValidate
            autoComplete="off"
        >
            <Grid container columnSpacing={2} sx={{ mt: "0.75rem" }}>
                <Grid item xs={12} sm={6}>
                    <input
                        {...register("firstName")}
                        name="firstName"
                        type="text"
                        placeholder="First Name"
                        css={css`
                            margin: 0;
                        `}
                    />
                    <Typography
                        variant="body1"
                        color="error"
                        sx={{ minHeight: "1.5rem" }}
                    >
                        {errors.firstName?.message}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <input
                        {...register("lastName")}
                        name="lastName"
                        type="text"
                        placeholder="Last Name"
                        css={css`
                            margin: 0;
                        `}
                    />
                    <Typography
                        variant="body1"
                        color="error"
                        sx={{ minHeight: "1.5rem" }}
                    >
                        {errors.lastName?.message}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="phone"
                        control={control}
                        render={({ field }) => (
                            <input
                                {...field}
                                type="text"
                                placeholder="Mobile Number*"
                                onChange={(e) =>
                                    field.onChange(
                                        reformatPhone(e.target.value)
                                    )
                                }
                                css={css`
                                    margin: 0;
                                `}
                            />
                        )}
                    />
                    <Typography
                        variant="body1"
                        color="error"
                        sx={{ minHeight: "1.5rem" }}
                    >
                        {touchedFields.phone && errors.phone?.message}
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{ mb: "1.5rem" }}>
                    {disclaimer}
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Button
                    type="submit"
                    form="coupon1"
                    fullWidth
                    color="info"
                    variant="contained"
                    css={css`
                        padding-bottom: 8px;
                        margin-top: 10px !important;
                        width: 100%;
                        background-color: #595959 !important;
                        color: #fff !important;
                        border-radius: 0 !important;
                    `}
                >{`TEXT ME THE COUPON`}</Button>
            </Grid>
        </form>
    )
}

export default Phone
