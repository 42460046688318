/** @format */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { Grid, Typography } from "@mui/material"
import { useTranslation } from "next-i18next"
import { useContext } from "react"

import { Context } from "../../context"
import {
    constructOfferLink,
    constructStateLink,
    reformatPhone,
} from "../../util"
import BookAnAppointment from "../Buttons/BookAnAppointment"
import EstimateOnline from "../Buttons/EstimateOnline"
import LinkButton from "../Buttons/LinkButton"
import Link from "../Link"
import { hoursAccordion } from "../util"

export const Stores = ({ ...props }) => {
    const { state, dispatch } = useContext(Context)
    const { t } = useTranslation()

    const { states } = state

    let { stores } = props
    stores = stores || state.stores

    return (
        <>
            {stores?.map((store, key) => {
                return (
                    <Grid
                        onClick={() => {
                            dispatch({ type: "CURRENT_STORE", payload: store })
                        }}
                        container
                        key={key}
                        css={css`
                            padding: 1em;
                            border: 1px solid #8b8b8b;
                            font-size: 1.3rem;
                            background-color: #ebebeb;
                            &:hover {
                                background-color: #fff;
                            }
                        `}
                    >
                        <Grid item container flexWrap={"nowrap"}>
                            <Grid item container flexDirection={"column"}>
                                <Typography
                                    variant="h6"
                                    color="primary"
                                    css={css(`
                                color: #095081; font-size: 1.2rem;font-weight: bold;margin-bottom:0;
                            `)}
                                >
                                    {store.storeName}
                                </Typography>

                                <Typography
                                    display="block"
                                    variant="body1"
                                    css={css(
                                        `color: #095081; font-size: 1rem;margin-top:0;margin-bottom: 1rem;`
                                    )}
                                >
                                    {reformatPhone(store.organicPhone)}
                                </Typography>
                            </Grid>

                            <Grid
                                item
                                css={css`
                                    margin-left: 0.5em;
                                `}
                            >
                                {store.distance && (
                                    <Typography
                                        display="inline"
                                        variant="body1"
                                        css={css`
                                            font-size: 0.8rem;
                                            white-space: nowrap;
                                        `}
                                        color={"#595959"}
                                    >
                                        {t("distance_abbrev", {
                                            count:
                                                t("distance") === "kilometers"
                                                    ? +(
                                                          Number.parseFloat(
                                                              store.distance
                                                          ) * 1.609
                                                      ).toFixed(1)
                                                    : +Number.parseFloat(
                                                          store.distance
                                                      ).toFixed(2),
                                        })}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>

                        <Grid item container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Typography
                                    display="block"
                                    variant="body1"
                                    css={css`
                                        color: #595959;
                                        font-size: 0.9rem;
                                    `}
                                >
                                    {store.storeAddress}
                                    <br />
                                    {`${store.storeCity}, ${store.storeState} ${store.storePostcode}`}
                                </Typography>

                                <Typography
                                    css={css`
                                        color: #095081;
                                        font-weight: bold;
                                        text-decoration: underline;
                                    `}
                                    display="block"
                                    variant="body1"
                                    component="span"
                                >
                                    <Link
                                        target="_blank"
                                        href={`https://www.google.com/maps/dir/?api=1&destination=${store.storeAddress},${store.storeCity},${store.storeState}`}
                                        noLinkStyle
                                    >
                                        Get Directions
                                    </Link>
                                </Typography>

                                <Typography
                                    css={css`
                                        font-size: 1.2rem;
                                        color: #595959;
                                        font-weight: 600;
                                    `}
                                >
                                    {`Store Hours:`}
                                </Typography>

                                {store.hours && hoursAccordion(store.hours)}
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                md={6}
                                css={css`
                                    flex-direction: column;
                                    display: flex;
                                    & button,
                                    a {
                                        min-height: 35px;
                                        max-width: none;
                                        margin: 0.25em 0;
                                    }
                                `}
                            >
                                {Boolean(store.scheduleActive) &&
                                    !store.isComingSoon && (
                                        <BookAnAppointment
                                            dmaName={store.dma}
                                            storeID={store.storeId}
                                            shouldOpenAppointments={true}
                                            handleOnClick={() => {
                                                dispatch({
                                                    type: "CURRENT_STORE",
                                                    payload: store,
                                                })
                                            }}
                                        />
                                    )}

                                {store.oetActive && !store.isComingSoon && (
                                    <EstimateOnline
                                        dmaName={store.dma}
                                        storeID={store.storeId}
                                    />
                                )}

                                <LinkButton
                                    linkURL={constructStateLink(store, states)}
                                    label={"GO TO SHOP PAGE"}
                                />

                                <LinkButton
                                    linkURL={
                                        store.isComingSoon
                                            ? constructStateLink(store, states)
                                            : constructOfferLink(store, states)
                                    }
                                    label={"VIEW OFFERS"}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                )
            })}
        </>
    )
}
