/** @format */
/** @jsxImportSource @emotion/react */
import { Box, Grid, Stack } from "@mui/material"
import { useContext, useEffect, useRef, useState } from "react"
import { useFormContext } from "react-hook-form"

import { OetContext } from "../../context"
import { searchForNextInput } from "../../util"
import { handleCaughtError } from "../../util/handlers"
import { OET_API } from "../../util/oetApi"
import { FormAutoComplete } from "./utils/form-elements"

const OETMotorApiForm = ({ vehicleState, setVehicleState }) => {
    const { dispatch } = useContext(OetContext)
    const { watch, defaultValues, setValue, getValues } = useFormContext()

    const [years, setYears] = useState([])
    const year = watch("year")
    const prevYear = useRef(defaultValues?.year)
    const [makes, setMakes] = useState([])
    const make = watch("make")
    const prevMake = useRef(defaultValues?.make)
    const [models, setModels] = useState([])

    useEffect(() => {
        if (Object.keys(vehicleState).length > 0) {
            setValue("year", vehicleState.year)
            setValue("make", vehicleState.make)
            setValue("model", vehicleState.model)
        }
        return () => {
            const values = getValues()
            setVehicleState({
                year: values.year,
                make: values.make,
                model: values.model,
            })
        }
    }, [])

    useEffect(() => {
        OET_API.getYears()
            .then((res) => {
                setYears(res)
            })
            .catch((err) => {
                dispatch({
                    type: "ERRORS",
                    payload: "Error: Could not fetch years. Please try again.",
                })
                handleCaughtError("error getting years from Motor API", err)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    useEffect(() => {
        if (year === prevYear.current) return

        setValue("make", "")
        prevYear.current = year

        if (!year) return

        const postData = {
            path: `/v1/Information/YMME/Years/${year}/Makes`,
        }

        OET_API.getMakes(postData)
            .then((res) => {
                setMakes(res)
            })
            .catch((err) => {
                dispatch({
                    type: "ERRORS",
                    payload: "Error: Could not fetch makes. Please try again.",
                })
                handleCaughtError("error getting make from Motor API", err)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, year])

    useEffect(() => {
        if (make === prevMake.current) return

        setValue("model", "")
        prevMake.current = make

        if (!year || (!make && !makes.length)) return

        const path = makes.filter((s) => {
            return s.MakeName.toUpperCase() == make.toUpperCase()
        })

        if (!path.length) return

        const postData = {
            path: path[0].Links[0].Href,
        }

        OET_API.getModels(postData)
            .then((res) => {
                setModels(res || [])
            })
            .catch((err) => {
                dispatch({
                    type: "ERRORS",
                    payload:
                        "Error: Could not fetch models for the year and make selected. Please try again.",
                })
                handleCaughtError("error getting model from Motor API", err)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, make, makes])

    return (
        <Grid item xs={12} width={"100%"}>
            <Stack
                sx={{
                    paddingY: 1,
                    paddingX: 3,
                    backgroundColor: "#E2F1FF",
                    borderRadius: "5px",
                    gap: {
                        xs: 2,
                        md: 0,
                    },
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: {
                            xs: "column",
                            sm: "row",
                        },
                        alignItems: { xs: "start", sm: "center" },
                        gap: { xs: 1, sm: 2 },
                    }}
                >
                    <FormAutoComplete
                        name="year"
                        options={{
                            onKeyDown: searchForNextInput,
                            autoCompleteOptions: years,
                            autoCompleteOptionsMapFunction: (s) =>
                                s.Year.toString(),
                        }}
                    />
                    <FormAutoComplete
                        name="make"
                        options={{
                            onKeyDown: searchForNextInput,
                            autoCompleteOptions: makes,
                            autoCompleteOptionsMapFunction: (s) => s.MakeName,
                        }}
                    />
                    <FormAutoComplete
                        name="model"
                        options={{
                            onKeyDown: searchForNextInput,
                            autoCompleteOptions: models,
                            autoCompleteOptionsMapFunction: (s) => s.ModelName,
                        }}
                    />
                </Box>
            </Stack>
        </Grid>
    )
}

export default OETMotorApiForm
