/** @format */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { Close, Room } from "@mui/icons-material"
import { Box, IconButton, Modal, Typography } from "@mui/material"

import SearchLocation from "../../SearchLocation"

const style = {
    position: "absolute",
    top: "20%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    outline: "none",
    "& .css-82yno2": {
        width: "100%",
        height: "45px",
    },
}

export default function LocationFindModal({ open, handleClose }) {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {`Find a Maaco shop near you`}
                </Typography>
                <SearchLocation
                    navigateToPage
                    endAdornment={
                        <Room
                            sx={{
                                color: (theme) => theme.palette.primary.main,
                            }}
                            css={css`
                                margin-right: -10px;
                            `}
                        />
                    }
                    showFindButton
                    onPlaceChange={handleClose}
                />
            </Box>
        </Modal>
    )
}
