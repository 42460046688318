/** @format */

/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { useRouter } from "next/router"
import { useContext, useEffect, useState } from "react"

import { Context } from "../../../context"
import { GTM_EVENTS } from "../../../util"
import Appointments from "../../Estimate/Appointments"
import ActionButton from "../ActionButton"

const BookAnAppointment = ({
    clickableArea,
    handleOnClick,
    handleClose,
    inTopBar,
    inPopper,
    storeID = "",
    dmaName = "",
    path,
    shouldOpenAppointments = false,
    ...props
}) => {
    const { state } = useContext(Context)
    const router = useRouter()

    const [openBookAnAppointment, setOpenBookAnAppointment] = useState(false)
    const { currentStore: store } = state

    const iconStyles = inTopBar
        ? `
                margin-top: -2px;
                padding: 0 7px 0 10px;
                font-size: 1.7rem;
        `
        : `
                font-size: 1.7rem;
        `

    useEffect(() => {
        if (!storeID || router.query.appointment !== "show") return

        setOpenBookAnAppointment(true)
    }, [router.query.appointment, storeID])

    const onClick =
        (handleOnClick || shouldOpenAppointments) &&
        (() => {
            if (handleOnClick) handleOnClick()

            if (shouldOpenAppointments && store?.storeId && store?.scheduleActive && !store?.isComingSoon) {
                setOpenBookAnAppointment(true)
            }
        })

    const content = clickableArea ? (
        <>{props.children}</>
    ) : (
        <span
            className={`icons icon-calendar-plus ${inTopBar && "hidden-sm"}`}
            css={css`
                ${iconStyles}
            `}
        ></span>
    )

    return (
        <>
            <ActionButton
                clickableArea={clickableArea}
                href={path}
                handleOnClick={onClick}
                inTopBar={inTopBar}
                inPopper={inPopper}
                bgColor="#AF0E11"
                bgColorHover="#9a1816"
                topBgColor="#AF0E11"
                label={props.label || "Book an Appointment"}
                gtmValues={{
                    event: GTM_EVENTS.appointmentCtaClick,
                    gtm_storeid: store?.storeId ?? storeID,
                    gtm_dma: store?.dma ?? dmaName,
                }}
            >
                {content}
            </ActionButton>

            {openBookAnAppointment && (
                <Appointments
                    handleClose={() => {
                        setOpenBookAnAppointment(false)

                        if (handleClose) handleClose()
                    }}
                    open={openBookAnAppointment}
                    location={{
                        data: store,
                    }}
                    setOpen={() => {
                        setOpenBookAnAppointment(true)
                    }}
                    endpoint={"/maaco/sch"}
                />
            )}
        </>
    )
}

export default BookAnAppointment
