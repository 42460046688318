const debounce = require("lodash.debounce")

/**
 * Returns a new function that uses a separate debounced version of the given
 * function for each unique value derived from the arguments by a given resolver
 * function
 *
 * @param {function} func The original function to debounce
 * @param {function} resolveCacheKey The resolver function; based on arguments
 * passed to the output function, determines which calls should be handled by
 * the same debounced function internally
 * @param {number} wait The number of milliseconds to delay
 */
function debounceBy(func, resolveCacheKey, wait) {
    const cache = {}

    return (...args) => {
        const cacheKey = resolveCacheKey(...args)

        cache[cacheKey] ??= debounce(func, wait)
        return cache[cacheKey](...args)
    }
}

module.exports = {
    debounceBy,
}
