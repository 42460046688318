import { Divider, Stack, Typography } from "@mui/material"
import React, { useContext } from "react"

import { OetContext } from "../../../../context"
import { reformatPhone } from "../../../../util"
import {
    paintPackages,
    OET_SUB_FORMS,
} from "../../../../util/globalOETVariables"
import Link from "../../../Link"
import EstimateSummaryCategory from "./EstimateSummaryCategory"
import EstimateAppointmentButton from "../EstimateAppointmentButton"

const EstimateSummary = () => {
    const { state } = useContext(OetContext)

    if (
        !state?.userInfo ||
        !state?.vehicleInfo ||
        !state?.location ||
        !state?.service
    ) {
        return null
    }

    return (
        <Stack
            direction="column"
            sx={{
                border: "1px solid",
                borderColor: "primary.main",
                borderRadius: "10px",
                p: 3,
            }}
            spacing={2}
        >
            <EstimateSummaryCategory name="info">
                <Stack direction="column" spacing={2}>
                    <Stack direction="column">
                        <Typography variant="body2">
                            {`${state.userInfo.firstName} ${state.userInfo.lastName}`}
                        </Typography>
                        <Typography variant="body2">
                            {state.userInfo.email}
                        </Typography>
                        <Typography variant="body2">
                            {reformatPhone(state.userInfo.phone)}
                        </Typography>
                    </Stack>
                    {state.userInfo.preferredMethod && (
                        <Typography variant="body2">
                            Contact via {state.userInfo.preferredMethod}
                        </Typography>
                    )}
                </Stack>
            </EstimateSummaryCategory>

            <Divider />

            {state.vehicleInfo.year &&
                state.vehicleInfo.make &&
                state.vehicleInfo.model && (
                    <EstimateSummaryCategory name="vehicle">
                        <Stack direction="column">
                            <Typography variant="body2">{`${state.vehicleInfo.year} ${state.vehicleInfo.make} ${state.vehicleInfo.model}`}</Typography>
                        </Stack>
                    </EstimateSummaryCategory>
                )}

            {state.vehicleInfo.notes && (
                <EstimateSummaryCategory name="notes">
                    <Stack direction="column">
                        <Typography variant="body2">
                            {state.vehicleInfo.notes}
                        </Typography>
                    </Stack>
                </EstimateSummaryCategory>
            )}

            <Divider />

            {state.service?.type === OET_SUB_FORMS.wholeVehicle && (
                <>
                    <EstimateSummaryCategory name="service">
                        <Stack direction="column">
                            {state.service.type &&
                                state.service.type.length && (
                                    <Typography variant="body2">
                                        {paintPackages[state.service.paintType]}
                                    </Typography>
                                )}
                        </Stack>
                    </EstimateSummaryCategory>

                    <Divider />
                </>
            )}
            {/* ONLY SHOW THIS SECTION IF THE USER HAS ADDED INSURANCE INFO */}
            {state.service?.insuranceProvider && (
                <>
                    <EstimateSummaryCategory name="insurance">
                        <Stack direction="column">
                            {state.service.insuranceProvider && (
                                <Typography
                                    variant="body2"
                                    textTransform="capitalize"
                                >
                                    {state.service.insuranceProvider}
                                </Typography>
                            )}
                            {state.service.claimNumber && (
                                <Typography
                                    variant="body2"
                                    textTransform="capitalize"
                                >
                                    Claim #: {state.service.claimNumber}
                                </Typography>
                            )}
                        </Stack>
                    </EstimateSummaryCategory>

                    <Divider />
                </>
            )}
            <EstimateSummaryCategory name="store">
                <Stack direction="column" spacing={1}>
                    <Stack direction="column">
                        <Typography variant="body2">
                            {state.location.storeAddress}
                        </Typography>
                        <Typography variant="body2">{`${state.location.storeCity}, ${state.location.storeState} ${state.location.storePostcode}`}</Typography>
                    </Stack>
                    <Typography
                        variant="body3"
                        color="primary"
                        component="span"
                    >
                        <Link
                            target="_blank"
                            href={`https://www.google.com/maps/dir/?api=1&destination=${state.location.storeAddress},${state.location.storeCity},${state.location.storeState}`}
                        >
                            Get directions
                        </Link>
                    </Typography>
                </Stack>
            </EstimateSummaryCategory>
            {/* DO NOT SHOW APPOINTMENT BUTTON IF CUSTOMER ALREADY BOOKED AN APPOINTMENT OR STORE'S ONLINE SCHEDULING IS NOT ACTIVE */}
            {!state.service.appointmentDate &&
                !state.service.appointmentTime &&
                state.location.scheduleActive && <EstimateAppointmentButton />}
        </Stack>
    )
}

export default EstimateSummary
