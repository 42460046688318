import { useTranslation } from "next-i18next"
import { useContext } from "react"
import { useSWRConfig } from "swr"

import { Context } from "../../../context"
import { getUtmObject, GTM_EVENTS, cleanPhone, dataLayer, reformatPhone } from "../../../util"
import { StyledButton } from "../Button"

export const PhoneNumberLink = ({ children, ...props }) => {
    const { state } = useContext(Context)
    const { fallback } = useSWRConfig()
    const { t } = useTranslation()

    const { currentStore = fallback?.store } = state
    let { gtmValues, text, path } = props

    text = t(children, currentStore)
    text = reformatPhone(text)
    path = `tel:${cleanPhone(text)}`

    const handleClick = (e) => {
        const UTM_Params = getUtmObject()
        // Adding the relevant GTM information to the datalayer
        dataLayer({
            event: GTM_EVENTS.call,
            gtm_storeid: currentStore?.storeId,
            gtm_dma: currentStore?.dma,
            gtm_pagerefsource: window.document.referrer,
            mca_cmp: UTM_Params?.mca_cmp,
            ...gtmValues,
        })
    }

    return <StyledButton {...{ ...props, onClick: handleClick, text, href: path }} />
}
