/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { useMediaQuery, Grid, Typography } from "@mui/material"
import TopBar from "./Topbar"
import Footer from "./Footer"

const ErrorUILayout = ({ children, header = true, footer = true }) => (
    <>
        {header && <TopBar />}
        <div
            css={css`
                margin: 0;
                color: #000;
                background: #fff;
                height: 50vh;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                @media (min-width: 400px) {
                    flex-direction: row;
                }
            `}
        >
            {children}
        </div>
        {footer && <Footer />}
    </>
)

const ErrorUIContent = ({ children }) => {
    const mobileSizeScreen = useMediaQuery("(max-width:500px)")

    return (
        <Grid container direction="column" alignItems="center">
            <Grid
                container
                item
                direction={mobileSizeScreen ? "column" : "row"}
                justifyContent="center"
            >
                {children}
            </Grid>
        </Grid>
    )
}

const ErrorUIHeading = ({ children }) => {
    const mobileSizeScreen = useMediaQuery("(max-width:500px)")

    return (
        <Grid
            container
            item
            xs={1}
            css={
                !mobileSizeScreen &&
                css`
                    border-right: 1px solid #000;
                    margin-right: 1rem;
                    padding: 10px 6rem 10px 0;
                `
            }
            alignItems={!mobileSizeScreen && "center"}
            justifyContent={mobileSizeScreen && "center"}
        >
            <Typography
                variant="h1"
                sx={{
                    textDecoration: mobileSizeScreen && "underline",
                }}
            >
                {children}
            </Typography>
        </Grid>
    )
}

const ErrorUIMessages = ({ children, ...props }) => (
    <Grid
        container
        item
        direction="column"
        xs={2.5}
        justifyContent="center"
        {...props}
    >
        {children}
    </Grid>
)

const ErrorUIMessage = ({ children, ...props }) => (
    <Grid item sx={{ m: 1.5 }} {...props}>
        <Typography
            variant="p"
            css={css`
                padding: 0;
            `}
        >
            {children}
        </Typography>
    </Grid>
)

/**
 * Presentational component for error page UI
 *
 * Can be used as an all-in-one component (`<ErrorUI>`) or as a compound
 * component with the following structure:
 *
 * ```jsx
 *  <ErrorUI.Layout>
 *      <ErrorUI.Content>
 *          <ErrorUI.Heading>
 *              Heading or Error Code
 *          </ErrorUI.Heading>
 *          <ErrorUI.Messages>
 *              <ErrorUI.Message>
 *                  Message 1
 *              </ErrorUI.Message>
 *              <ErrorUI.Message>
 *                  Message 2
 *              </ErrorUI.Message>
 *          </ErrorUI.Messages>
 *      </ErrorUI.Content>
 *  </ErrorUI.Layout>
 * ```
 */
const ErrorUI = ({ heading, children }) => {
    return (
        <ErrorUILayout>
            <ErrorUIContent>
                <ErrorUIHeading>{heading}</ErrorUIHeading>
                <ErrorUIMessages>
                    <ErrorUIMessage>{children}</ErrorUIMessage>
                </ErrorUIMessages>
            </ErrorUIContent>
        </ErrorUILayout>
    )
}

ErrorUI.Layout = ErrorUILayout
ErrorUI.Content = ErrorUIContent
ErrorUI.Heading = ErrorUIHeading
ErrorUI.Messages = ErrorUIMessages
ErrorUI.Message = ErrorUIMessage

export { ErrorUI }
