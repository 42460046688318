const getEnvironmentVariables = () => {
    return process.env
}

const getEnvironmentVariable = ({ key, isOptional = false }) => {
    // note: for NextJs all env vars have to be accessed by dot-notation on the front-end
    // we can define a stringified JSON in the next.config.js to allow us to access dynamically
    let currentProcessEnv = getEnvironmentVariables()
    const hasJson = process.env.json

    if (hasJson) currentProcessEnv = JSON.parse(hasJson)

    const unvalidatedEnvironmentVariable = currentProcessEnv[key]
    if (!unvalidatedEnvironmentVariable && !isOptional) {
        throw new Error(
            `Couldn't find environment variable: ${key} in ${JSON.stringify(
                currentProcessEnv
            )}`
        )
    } else {
        return unvalidatedEnvironmentVariable
    }
}

module.exports = {
    getEnvironmentVariable,
}
