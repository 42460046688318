import { getYear } from "date-fns"

import { VEHICLE_TYPES } from "../components/OET/Steps/VehicleInfo"
import { APPOINTMENT_API } from "./appointmentApi"
import { getFeatureFlagByLocation } from "./contentful/api"
import { COUPON_API } from "./couponApi"
import { OET_OUTCOMES, OET_STEPS } from "./globalOETVariables"
import { handleError } from "./handlers"
import { OET_API } from "./oetApi"
import { cleanPhone, dataLayer, GTM_EVENTS } from "./"

const oetStepToGtmEvent = [
    GTM_EVENTS.oetStepOne,
    GTM_EVENTS.oetStepTwo,
    GTM_EVENTS.oetStepThree,
    GTM_EVENTS.oetStepFour,
    GTM_EVENTS.oetComplete,
]

// GTM DATA LAYER HANDLER
export const handleDataLayerSubmissionEvent = ({ step, state }) => {
    const oet_email = state?.userInfo?.email
        ? { oet_email: state?.userInfo?.email }
        : {}

    dataLayer({
        event: oetStepToGtmEvent[step - 1] || GTM_EVENTS.oetStepComplete,
        ...oet_email,
        gtm_storeid: state?.location?.storeId ?? "",
        gtm_pagerefsource: window.document.referrer,
        gtm_dma: state?.location?.dma ?? "",
        gtm_stepname: state?.estimateStep,
    })
}

export const isAgedOut = (year1, year2) => {
    const differenceInYears = year1 - year2
    return differenceInYears < 1 || differenceInYears > 24
}

// TODO: REMOVE ONCE FEATURE TESTING IS COMPLETE
export const getFeatureFlag = async (storeId, dispatch) => {
    const featureFlag = await getFeatureFlagByLocation(storeId)
    if (featureFlag) {
        dispatch({
            type: "FEATURE_FLAG",
            payload: featureFlag.key,
        })

        return featureFlag.key
    } else {
        dispatch({
            type: "FEATURE_FLAG",
            method: "reset",
        })
    }
}

const generateStepError = (step) => {
    switch (step) {
        case OET_STEPS.selectLocation:
            return handleError(
                { message: `error - ${step}: Store selection is required` },
                false,
                "Please Select the Store"
            )
        default:
            return handleError(
                { message: `error - ${step}: Required form data is missing` },
                false,
                "Whoops, something went wrong while submitting the form. Please try again."
            )
    }
}

export const handleOetStepSubmission = async (step, data) => {
    if (!data || !step) {
        return generateStepError(step ?? "")
    }

    const OET_API_STEPS = {
        [OET_STEPS.selectLocation]: "selectLocation",
        [OET_STEPS.yourInfo]: "customerInfo",
        [OET_STEPS.vehicleInfo]: "vehicleInfo",
        [OET_STEPS.vehicleServices]: "vehicleInfoServices",
    }

    const formattedData = {
        stepName: OET_API_STEPS[step],
        ...data,
    }

    switch (step) {
        case OET_STEPS.yourInfo: {
            const customerInfoData = {
                ...formattedData,
                emailEstimate: true,
                emailPromotions: true,
                phone: cleanPhone(data.phone),
            }

            return { oetResponse: await OET_API.saveOetStep(customerInfoData) }
        }
        case OET_STEPS.vehicleInfo: {
            if (!formattedData.hasVinNumber) delete formattedData.vin
            delete formattedData.hasVinNumber

            const carAgedOut = isAgedOut(
                getYear(new Date()),
                Number(formattedData.year)
            )
            const isFastPath =
                formattedData.vehicleType !== VEHICLE_TYPES.CAR ||
                (formattedData.vehicleType === VEHICLE_TYPES.CAR && carAgedOut)

            if (formattedData.vehicleType === VEHICLE_TYPES.OTHER) {
                delete formattedData.year
                delete formattedData.make
                delete formattedData.model
            } else {
                delete formattedData.notes
            }

            delete formattedData.vehicleType

            return {
                oetResponse: await OET_API.saveOetStep({
                    ...formattedData,
                    isOetComplete: isFastPath,
                }),
            }
        }
        case OET_STEPS.vehicleServices: {
            let appointmentSet = false
            let couponSent = false
            const errors = []

            const {
                claimNumber,
                comment,
                insuranceAction,
                insuranceProvider,
                leadId,
                spotRepairAction,
                stepName,
                storeId,
                undecidedAction,
                userIp,
                vehicleService,
                visitedUrl,
                oetWholeVehiclePricing,
            } = formattedData
            const paintType = formattedData.paintType
                ? { paintType: `${formattedData.paintType} paint` }
                : null
            const estimateAmount =
                paintType && oetWholeVehiclePricing
                    ? {
                          estimateAmount:
                              oetWholeVehiclePricing[
                                  `${formattedData.paintType}Paint`
                              ],
                      }
                    : null

            const oetSubmissionData = {
                stepName,
                storeId,
                leadId,
                serviceType: vehicleService.toLowerCase(),
                ...paintType,
                ...estimateAmount,
                atRisk: spotRepairAction === OET_OUTCOMES.atRisk,
                isOetComplete: true,
                insuranceProvider: insuranceProvider,
                claimNumber: claimNumber,
                customerAction:
                    (spotRepairAction || undecidedAction) ===
                    OET_OUTCOMES.customerAction,
                followUpRequested:
                    insuranceAction === OET_OUTCOMES.followUpInsurance ||
                    spotRepairAction === OET_OUTCOMES.followUpSpot ||
                    undecidedAction === OET_OUTCOMES.followUpUndecided,
                followUpComment: comment,
                userIp,
                visitedUrl,
            }

            const oetResponse = await OET_API.saveOetStep(oetSubmissionData)

            if (formattedData.coupon) {
                try {
                    await COUPON_API.sendStandardCoupon({
                        ...formattedData,
                        leadId,
                        isOetCoupon: true,
                    })
                    couponSent = true
                } catch (error) {
                    errors.push({ type: "Coupon", error })
                }
            }

            if (
                formattedData.appointmentTime &&
                formattedData.appointmentDate
            ) {
                try {
                    await APPOINTMENT_API.setOetAppt(formattedData)
                    appointmentSet = true
                } catch (error) {
                    errors.push({ type: "Appointment", error })
                }
            }

            return { oetResponse, couponSent, appointmentSet, errors }
        }
        default:
            return { oetResponse: await OET_API.saveOetStep(formattedData) }
    }
}
