/** @format */
/** @jsxImportSource @emotion/react */
import { Box, Button } from "@mui/material"
import { useTranslation } from "next-i18next"
import Link from "next/link"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"

import { getPageContents } from "../components/contentful/pageProps"
import { ErrorUI } from "../components/ErrorUI"
import FourZeroFour from "../components/FourZeroFour"
import { API } from "../util/api"
import { handleCaughtError } from "../util/handlers"
import { redirectUrlList } from "../util/locationsRedirects"

const FourOhFour = (notFound, { content }) => {
    const [queryValues, setQueryValues] = useState({
        redirect: "",
        timeout: "",
    })
    const [showSuggestion, setShowSuggestion] = useState(false)
    const { i18n } = useTranslation()
    const router = useRouter()
    const isLocationPage = router.asPath.includes("/locations/")

    let seo = null
    // Passing SEO for pages that are listed as noindex,nofollow
    if (content) {
        seo = content.fields?.seo?.fields || content.seo?.fields
    }

    // Revalidate and refresh the page
    const revalidate = async () => {
        await fetch(`/api/revalidate?path=${router.asPath}`)
        router.replace(router.asPath)
    }

    // Updates the 404 page content if the failed URL includes "locations" path
    // First try to find a match on another Maaco site and redirect to the match
    // If there are no matches, and the URL is a store page, fetch the store data.
    // If store data DOES NOT come back, show a link to the current site's location page.
    // If store data DOES come back, show the revalidation refresh button.
    useEffect(() => {
        //short circuit if the router path doesn't include locations
        if (!isLocationPage) {
            return
        }

        //Attempting to find a valid redirect URL from the list of states and dma pages
        redirectUrlList(i18n)
            .then(async (urlList) => {
                const redirect = urlList?.find((url) => {
                    //For locations in state, it should search alt site for url pattern match
                    if (url.source.includes("/:path*")) {
                        return router.asPath.match(
                            new RegExp(url.source.replace("/:path*", ".*"))
                        )
                    }
                    //For DMA Pages it should look for an exact match
                    return router.asPath === url.source + "/"
                })

                if (redirect) {
                    setQueryValues({
                        redirect: `https://${
                            redirect.destination.split("/")[2]
                        }${router.asPath}`,
                        timeout: 10,
                    })
                    setShowSuggestion(true)
                    return
                }

                // IF THERE ISN'T A REDIRECT, TRY TO FETCH STORE DETAILS
                try {
                    const [storeId] = router.asPath.match(/\d+/g)
                    if (isLocationPage && storeId) {
                        const store = await API.getStore({
                            selectedStoreId: storeId,
                        })
                        const storeDataPresent =
                            !!store && Object.entries(store)?.length > 0

                        // Show suggestion instead of revalidation button and text
                        // If no store details are returned from API
                        setShowSuggestion(!storeDataPresent)
                    }
                } catch (error) {
                    // Show suggestion instead of revalidation button and text
                    setShowSuggestion(true)
                    handleCaughtError("Error getting store data ", error)
                }
            })
            .catch((error) => {
                handleCaughtError("Error getting redirect URL list ", error)
            })
    }, [router.isReady, i18n, router.asPath, isLocationPage])

    const messages = showSuggestion ? (
        <>
            <ErrorUI.Message>This page was not found.</ErrorUI.Message>
            <ErrorUI.Message>
                Were you looking for{" "}
                <Link href="/locations" passHref>
                    MAACO store locations
                </Link>
                ?
            </ErrorUI.Message>
        </>
    ) : notFound && isLocationPage ? (
        <>
            <ErrorUI.Message>
                There was a problem accessing this page.
            </ErrorUI.Message>
            <ErrorUI.Message>
                <Box sx={{ width: "100%" }}>
                    <Button
                        variant="contained"
                        onClick={() => revalidate()}
                        sx={{ width: "100%" }}
                    >
                        Refresh to try again
                    </Button>
                </Box>
            </ErrorUI.Message>
        </>
    ) : null

    return (
        <FourZeroFour
            queryValues={queryValues}
            //Optional message
            messages={messages}
            seo={seo}
        />
    )
}

export async function getStaticProps({ ...props }) {
    return await getPageContents({ ...props })
}

export default FourOhFour
