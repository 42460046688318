import { API } from "./api"

const redirectUrlList = async (i18n) => {
    let state_slug_redirects = []
    let dma_slug_redirects = []

    const other_lngs = i18n.languages.filter((lng) => lng !== i18n.language)

    for (const lng of other_lngs) {
        const redirect_url = i18n.t("maaco_url", {
            lng,
            defaultValue: [],
        })

        state_slug_redirects = i18n
            .t("states", { lng, defaultValue: [] })
            .map(({ stateAbbreviation }) => ({
                source: `/locations/${stateAbbreviation.toLowerCase()}/:path*`,
                destination: `https://${redirect_url.toLowerCase()}/locations/${stateAbbreviation.toLowerCase()}/:path*`,
                permanent: true,
            }))

        dma_slug_redirects = await API.getDmaList()
            ?.map((area) => ({
                source: `/locations/${area}`,
                destination: `https://${redirect_url.toLowerCase()}/locations/${area}/`,
                permanent: true,
            }))
    }

    return [...state_slug_redirects, ...dma_slug_redirects]
}
module.exports = { redirectUrlList }
