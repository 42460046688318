/** @jsxImportSource @emotion/react */
/** @format */

import { css } from "@emotion/react"
import { Grid, Typography } from "@mui/material"
import MD from "react-markdown"

import { containerCSS } from "../../util"
import Link from "../Link"
import OptimizedImage from "../OptimizedImage"
import { CtaButton } from "./ctaButton"
import { Heading } from "./heading"

export const Hero = ({
    actions: { fields: actions = {}, sys: { contentType = {} } = {} } = {},
    mediaAlignment,
    title,
    subtitle,
    body,
    ...props
}) => {
    const { sys } = contentType
    let { media, showAction } = props
    media = media?.fields || media

    if (!media) return null

    const mediaFile = media.file || media

    const href = actions?.path || actions?.url
    const clickable = sys?.id === "ctaButton" && !showAction
    const bannerWidth = clickable ? "100%" : undefined

    let bannerOnly = mediaAlignment !== "background" && (
        <OptimizedImage
            priority
            className="image-w"
            src={mediaFile.url}
            alt={media.alt || media.description || media.title}
            border="0"
            containerStyle={{ height: "100%", width: bannerWidth }}
            height="100%"
            width={bannerWidth}
        />
    )

    const clickableBanner = (banner) => {
        if (href) {
            return (
                <Link href={href || "#"} noLinkStyle>
                    <Typography
                        component="div"
                        style={{
                            display: "block",
                            height: "100%",
                            cursor: "pointer",
                        }}
                    >
                        {banner}
                    </Typography>
                </Link>
            )
        }
        const { children, ...actionProps } = actions
        return (
            <CtaButton clickableArea {...actionProps}>
                {banner}
            </CtaButton>
        )
    }

    bannerOnly =
        bannerOnly && (href || clickable)
            ? clickableBanner(bannerOnly)
            : bannerOnly

    const content = bannerOnly || (
        <Grid
            container
            alignItems={"center"}
            justifyContent={"end"}
            css={css`
                ${containerCSS}
                height: 100%;
                color: #fff;
            `}
        >
            <Grid item xs={12} sm={5}>
                {title && (
                    <Heading {...{ text: title, fontSizeMultiplier: 2.4 }} />
                )}

                {subtitle && (
                    <Heading {...{ text: subtitle, fontSizeMultiplier: 1.7 }} />
                )}

                {body && <MD>{body}</MD>}
                {actions && showAction && <CtaButton {...actions} />}
            </Grid>
        </Grid>
    )

    const bannerHeight =
        mediaFile.details &&
        (mediaFile.details.image.height / mediaFile.details.image.width) * 100

    const background =
        mediaAlignment === "background" && mediaFile
            ? `linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url(${mediaFile.url}?fm=webp)`
            : "#214E70"

    return (
        <>
            <Grid
                item
                display={{ xs: "none", sm: "block" }}
                xs={12}
                sx={{
                    background,
                    backgroundSize: "cover",
                    height: {
                        xs: "inherit",
                        sm: `${bannerHeight}vw`,
                    },
                }}
            >
                {content}
            </Grid>
        </>
    )
}
