/** @format */

import { forwardRef } from 'react'

/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { Button } from "@mui/material"

import { NextLinkComposed } from '../../Link'
import { ContentLink } from "../../contentful"

export const StyledButton = forwardRef(({
    ariaLabel,
    children,
    display,
    href,
    text,
    target,
    variant,
    ...props
}, ref) => {
    let { label } = props
    label = label || children || text

    if (variant === "link")
        return <ContentLink {...{ text: label, url: href, ...props }} />

    return (
        <Button
            {...props}
            aria-label={ariaLabel || label}
            LinkComponent={!href ? undefined : NextLinkComposed}
            to={href}
            target={target}
            css={css`
                ${variant === "outlined" ? "border: 2px solid #006bb7;" : ""}
                display: ${display};
                justify-content: center;
                align-items: center;

                color: #214e70 !important;
                background-color: #fff !important;

                width: auto;
                height: 36px;
                padding: 0 0.5em;

                font-weight: bold;
                font-size: 0.9rem;
                font-family: "Gilroy", "Gotham", Roboto, "Helvetica Neue", Arial,
                    sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
                    "Segoe UI Symbol";
                text-transform: uppercase; //TODO should this be an option

                &,
                &:hover {
                    text-decoration: none;
                }

                &:hover {
                    color: #fff !important;
                    background-color: #006bb7 !important;
                }
            `}
        >
            {label}
        </Button>
    )
})

StyledButton.displayName = 'StyledButton'
