const axios = require("axios")

const { NEW_API_URL, NEW_API_KEY } = require(".")
const { handleError } = require("./handlers")
const { getEnvironmentVariable } = require("./env")

const OET_API = {
    ////////////////////////
    // STEP SUBMISSION API
    ////////////////////////
    saveOetStep: async (formData) => {
        try {
            const { data } = await axios.request({
                url: `${NEW_API_URL}/oet/base`,
                method: formData.leadId ? "PUT" : "POST",
                data: formData,
                headers: {
                    apikey: NEW_API_KEY,
                    session_id: sessionStorage.getItem("sessionId"),
                },
            })

            return data
        } catch (error) {
            return handleError(error)
        }
    },
    ////////////////////////
    // SELECT LOCATION APIS
    ////////////////////////
    setLocation: async (formData) => {
        try {
            const {
                data: { leadId },
            } = await axios.request({
                url: `${NEW_API_URL}/oet/select-location`,
                method: formData.leadId ? "PUT" : "POST",
                data: formData,
                headers: {
                    apikey: NEW_API_KEY,
                    session_id: sessionStorage.getItem("sessionId"),
                },
            })

            return leadId
        } catch (error) {
            return handleError(error)
        }
    },
    getOetStore: async (storeId) => {
        try {
            const { data } = await axios.get(
                `${NEW_API_URL}/oet/stores/${storeId}`,
                {
                    headers: {
                        apikey: NEW_API_KEY,
                    },
                }
            )

            return data
        } catch (error) {
            return handleError(error)
        }
    },
    ////////////////////////
    // CUSTOMER INFO APIS
    ////////////////////////
    setCustomerInfo: async (formData) => {
        const {
            contactByText,
            firstName,
            lastName,
            email,
            phone,
            preferredMethod,
            preferredTime,
        } = formData
        const locale = getEnvironmentVariable({
            key: "COUNTRY_CODE",
        })

        const reformattedFormData = {
            ...formData,
            firstName,
            lastName,
            email,
            phone: phone.replace(/[^A-Z0-9]/gi, ""),
            // TODO: REMOVE TERNARY OPERATOR ONCE ZETA APPROVES CA TEXTING DISCLAIMERS
            contactByText: locale === "us" ? contactByText : true,
            preferredMethod,
            preferredTime,
            emailEstimate: true,
            emailPromotions: true,
        }

        try {
            const {
                data: { leadId },
            } = await axios.request({
                url: `${NEW_API_URL}/oet/customer-info`,
                method: reformattedFormData.leadId ? "PUT" : "POST",
                data: reformattedFormData,
                headers: {
                    apikey: NEW_API_KEY,
                    session_id: sessionStorage.getItem("sessionId"),
                },
            })

            return leadId
        } catch (error) {
            return handleError(error)
        }
    },
    ////////////////////////
    // VEHICLE INFO  1/2 APIS
    ////////////////////////
    // SET VEHICLE INFO TO LEAD
    setVehicleInfo: async (formData) => {
        const reformattedFormData = {
            ...formData,
            submodel: "car",
            carDrivable: true,
            useInsurance: false,
        }
        delete reformattedFormData.storeId
        delete reformattedFormData.hasVinNumber
        delete reformattedFormData.vin

        try {
            const {
                data: { leadId },
            } = await axios.request({
                url: `${NEW_API_URL}/oet/vehicle-info`,
                method: formData.leadId ? "PUT" : "POST",
                data: reformattedFormData,
                headers: {
                    apikey: NEW_API_KEY,
                    session_id: sessionStorage.getItem("sessionId"),
                },
            })

            return leadId
        } catch (error) {
            return handleError(error)
        }
    },
    ////////////////////////
    // MOTOR APIS
    ////////////////////////
    getYears: async () => {
        try {
            const {
                data: { Body },
            } = await axios.get(`${NEW_API_URL}/motor/years`, {
                headers: {
                    apikey: NEW_API_KEY,
                },
            })

            return Body
        } catch (error) {
            return handleError(error)
        }
    },
    getMakes: async (formData) => {
        try {
            const {
                data: { Body },
            } = await axios.post(`${NEW_API_URL}/motor/makes`, formData, {
                headers: {
                    apikey: NEW_API_KEY,
                },
            })

            return Body
        } catch (error) {
            return handleError(error)
        }
    },
    getModels: async (formData) => {
        try {
            const {
                data: { Body },
            } = await axios.post(`${NEW_API_URL}/motor/models`, formData, {
                headers: {
                    apikey: NEW_API_KEY,
                },
            })

            return Body
        } catch (error) {
            return handleError(error)
        }
    },
    getVehicleByVIN: async (vin) => {
        try {
            const {
                data: { vehicles },
            } = await axios.post(
                `${NEW_API_URL}/oet/vin`,
                { vin },
                {
                    headers: {
                        apikey: NEW_API_KEY,
                    },
                }
            )

            return vehicles
        } catch (error) {
            return handleError(error)
        }
    },
    ////////////////////////
    // ESTIMATE API
    ////////////////////////
    getOetEstimate: async (leadId) => {
        try {
            const {
                data: { estimateAmount, overLimit },
            } = await axios.post(
                `${NEW_API_URL}/oet/estimate-repair`,
                { leadId },
                {
                    headers: {
                        apikey: NEW_API_KEY,
                        session_id: sessionStorage.getItem("sessionId"),
                    },
                }
            )

            return { estimateAmount, overLimit }
        } catch (error) {
            return handleError(error)
        }
    },
    ////////////////////////
    // ABANDON API
    ////////////////////////
    postOetAbandon: async (formData) => {
        try {
            await axios.post(`${NEW_API_URL}/oet/abandon`, formData, {
                headers: {
                    apikey: NEW_API_KEY,
                    session_id: sessionStorage.getItem("sessionId"),
                },
            })
        } catch (error) {
            return handleError(error)
        }
    },
}

module.exports = {
    OET_API,
}
