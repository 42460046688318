import {
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    Typography,
} from "@mui/material"
import { Controller, useFormContext } from "react-hook-form"
import MD from "react-markdown"
import rehypeRaw from "rehype-raw"

import { useDataLayerEvent } from "../../../../hooks/useDataLayerEvent"
import { OET_STEPS, serviceTypes } from "../../../../util/globalOETVariables"
import { useContext, useEffect, useRef } from "react"
import { OetContext } from "../../../../context"

const VehicleInfoServices = () => {
    const {
        control,
        formState: { errors },
        watch,
    } = useFormContext()
    const { dispatch } = useContext(OetContext)
    const prevService = useRef("")

    const service = watch("vehicleService")
    // isDirty and touchedFields will not work here because we're setting the state
    // Setting the state changes the form default values and triggers
    // isDirty to go from true to false and touchedFields to reset
    // State change is needed for the DynamicFormContent
    useDataLayerEvent({
        step: OET_STEPS.vehicleServices,
        dataLayerEventTrigger:
            service !== prevService.current && prevService.current === "",
    })

    useEffect(() => {
        if (service !== prevService.current && prevService.current === "") {
            // tracking that initial value was changed
            prevService.current = service
        }
        if (service) {
            dispatch({
                type: "SERVICE_DETAIL",
                payload: {
                    type: service,
                },
            })
        }
    }, [dispatch, service])

    return (
        <Grid item xs={12} sx={{ mb: 4 }}>
            <FormLabel id="vehicle-info-service-label" sx={{ mb: 1 }}>
                <Typography fontFamily="Gilroy Medium" variant="body1">
                    Which of the following best describes your situation?*
                </Typography>
            </FormLabel>

            <Controller
                name="vehicleService"
                control={control}
                render={({ field: { onChange, ...field } }) => (
                    <RadioGroup
                        {...field}
                        aria-labelledby="vehicle-info-service-label"
                        onChange={(_, data) => onChange(data)}
                    >
                        {serviceTypes.map(({ label, value }, index) => (
                            <FormControlLabel
                                key={`vehicle-info-service--item-${index}`}
                                control={<Radio size="small" />}
                                value={value}
                                label={
                                    <MD rehypePlugins={[rehypeRaw]}>{label}</MD>
                                }
                                componentsProps={{
                                    typography: {
                                        sx: {
                                            display: "inline-flex",
                                            my: 0,
                                            mt: `0 !important`,
                                            py: 1,
                                            "& p": { m: 0 },
                                        },
                                    },
                                }}
                            />
                        ))}
                    </RadioGroup>
                )}
            />

            {errors.vehicleService && (
                <Typography variant="body1" color="error">
                    {errors.vehicleService?.message}
                </Typography>
            )}
        </Grid>
    )
}

export default VehicleInfoServices
