/** @format */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { Grid } from "@mui/material"
import { useContext } from "react"
import { useSWRConfig } from "swr"

import { Context } from "../../context"
import { containerCSS } from "../../util"
import { ContentGrid, Heading } from "../contentful"

export const StoreServices = ({ config, ...props }) => {
    const { state } = useContext(Context)
    const { fallback } = useSWRConfig()

    const { currentStore: store = fallback?.store } = state

    if (!store) return null

    const { services } = store

    let { heading = {} } = props
    heading = heading?.fields || heading

    return (
        <div css={css(containerCSS)} id={`services-section`}>
            <Grid container justifyContent={"center"}>
                <Grid item xs={12}>
                    <Heading {...heading} />
                </Grid>

                <ContentGrid items={services} {...config} />
            </Grid>
        </div>
    )
}
