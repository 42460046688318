/** @format */
/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react"
import { TextField, Typography } from "@mui/material"
import { useFormContext } from "react-hook-form"
import { reformatPhone, searchForNextInput } from "../../util"

const InputComponent = ({
    name,
    required = "",
    multiline = false,
    fullWidth = false,
    rows = 1,
    cssStyle,
    fullInputName,
    placeholder,
    formProps,
    lastInput = false,
    value = "",
}) => {
    const componentForm = useFormContext()

    const {
        setValue,
        formState: { errors },
        register,
    } = componentForm

    const setInputComponentValue = (name, value) => {
        if (!(name === "phone")) {
            setValue(name, value, { shouldDirty: true })
            return
        }
        setValue("phone", reformatPhone(value), { shouldDirty: true })
    }

    return (
        <>
            <label
                css={css`
                    padding-top: 10px;
                    z-index: 2;
                `}
            >
                {`${fullInputName}${required && "*"}`}
            </label>
            <TextField
                {...register(name)}
                type="text"
                placeholder={placeholder}
                multiline={multiline}
                {...formProps}
                fullWidth={fullWidth}
                rows={rows}
                name={name}
                defaultValue={value}
                onInput={(e) => {
                    setInputComponentValue(name, e.target.value)
                }}
                onChange={(e) => {
                    setInputComponentValue(name, e.target.value)
                }}
                css={css`
                    margin: 0;
                    ${cssStyle}
                `}
                onKeyDown={(e) => {
                    if (lastInput) return
                    searchForNextInput(e)
                }}
            />
            <Typography variant="body1" color="error">
                {errors[name]?.message}
            </Typography>
        </>
    )
}

export default InputComponent
