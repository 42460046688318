import { Grid } from "@mui/material"
import { Cities } from "../Locations/cities"
import { DmaStores } from "../Locations/dmaStores"
import { MsoStores } from "../Locations/msoStores"
import { SearchResults } from "../Locations/search"
import { States } from "../Locations/states"
import { Stores } from "../Locations/stores"

export const List = ({ name, ...props }) => {
    const listMap = {
        "API States": {
            Component: States,
        },
        "API Cities": {
            Component: Cities,
        },
        "API Stores": {
            Component: Stores,
        },
        "MSO Stores": {
            Component: MsoStores,
        },
        "DMA Stores": {
            Component: DmaStores,
        },
        "Search Results": {
            Component: SearchResults,
        },
    }
    const listType = listMap[name]

    if (listType)
        return (
            <Grid
                item
                container
                flexDirection={"column"}
                flexWrap={"nowrap"}
                style={{
                    overflowY: "auto",
                }}
            >
                {listType ? <listType.Component {...props} /> : null}
            </Grid>
        )

    console.log("List Not Found", { name, ...props })
    return null
}
