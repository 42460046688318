/** @format */
/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react"
import { Phone } from "@mui/icons-material"
import { Typography } from "@mui/material"
import { useTranslation } from "next-i18next"

import Link from "../Link"
import { dataLayer, getUtmObject, GTM_EVENTS } from "../../util"

export const PhoneNumber = ({ isMobile }) => {
    const { t } = useTranslation()

    const headerActions = t("content:header.actionsCollection.items", "")

    const phoneNumberAction =
        headerActions &&
        headerActions.find(
            (action) => action.name === "Site Wide Header Phone Number Link"
        )

    if (!phoneNumberAction) return null

    const handleClick = (e) => {
        const UTM_Params = getUtmObject()
        // Adding the relevant GTM information to the datalayer
        dataLayer({
            event: GTM_EVENTS.call,
            gtm_storeid: '',
            gtm_dma: '',
            gtm_pagerefsource: window.document.referrer,
            mca_cmp: UTM_Params?.mca_cmp,
        })
    }

    if (isMobile)
        return (
            <Link href={phoneNumberAction.url} onClick={handleClick}>
                <Phone
                    css={css`
                        color: #006bb7;
                        font-size: 2rem !important;
                    `}
                ></Phone>
            </Link>
        )

    return (
        <>
            <Typography
                variant={t("typ_var.body_2")}
                color="primary.dark"
                css={css`
                    @media (min-width: 992px) and (max-width: 1199px) {
                        font-size: 0.8rem;
                    }
                `}
            >
                {t(["content:header.callForInquiries"])}
            </Typography>

            <Typography
                alignItems={`flex-end`}
                justifyContent={`flex-end`}
                component="p"
                variant={t("typ_var._h5")}
                color="primary.dark"
                css={css`
                    margin: 0;
                    display: flex;
                    font-size: 1.2rem;
                `}
            >
                <Link href={phoneNumberAction.url} onClick={handleClick}>{phoneNumberAction.text}</Link>
            </Typography>
        </>
    )
}
