/** @jsxImportSource @emotion/react */
/** @format */

import { css } from "@emotion/react"
import { Grid, Typography, useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/styles"
import Link from "next/link"
import { useContext } from "react"
import { Context } from "../../context"
import { slugify } from "../../util"

export const States = ({ ...props }) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
    const { state } = useContext(Context)
    let { states } = props

    if (!states) {
        states = state.states
    }

    const columnCount = isMobile ? 2 : 4
    states = states || []

    const splitLocations = (list) => {
        let skip = 0
        const take = Math.ceil(list.length / columnCount) || list.length

        let columnIndex = 1
        const columns = []
        while (columnIndex <= columnCount) {
            columns.push(list.slice(skip, take * columnIndex))
            skip = skip + take
            columnIndex++
        }

        return columns
    }

    const renderLocationList = (list) =>
        list.map((location, k) => {
            return (
                <Link
                    href={`/locations/${slugify(
                        `${location.stateAbbreviation.toLowerCase()}`
                    )}`}
                    passHref
                    key={k}
                >
                    <Typography
                        css={css`
                            color: #005275 !important;
                            font-size: 1.2rem;
                            text-decoration: none;
                            &:hover {
                                text-decoration: underline;
                            }
                            display: block;
                        `}
                        component={"a"}
                    >
                        {location.stateName}
                    </Typography>
                </Link>
            )
        })

    return (
        <Grid container>
            {splitLocations(states, columnCount).map((column, index) => {
                return (
                    <Grid item xs={12 / columnCount} key={index}>
                        <ul
                            css={css`
                                padding: 0;
                            `}
                        >
                            {renderLocationList(column)}
                        </ul>
                    </Grid>
                )
            })}
        </Grid>
    )
}
