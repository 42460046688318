import React from "react"

import { getObjectTypename } from "../../util/contentful/api"
import { Accordion } from "./accordion"
import { Action, Actions } from "./actions"
import { Card } from "./card"
import { ContentSection } from "./contentSection"
import { ContentCoupon } from "./coupon"
import { CouponGroup } from "./coupon-group"
import { CtaButton } from "./ctaButton"
import { DynamicComponent } from "./dynamic"
import { Form } from "./form"
import { ContentGrid, GridItems } from "./grid"
import { Heading } from "./heading"
import { Hero } from "./hero"
import { IconText } from "./iconText"
import { ImageBanner } from "./imageBanner"
import { InputField } from "./inputField"
import { ContentLink } from "./link"
import { List } from "./list"
import { Map } from "./map"
import { OETCoupon } from "./oetCoupon"
import { OETEstimateSummary } from "./oetEstimateSummary"
import { OETForm } from "./oetForm"
import { OETSection } from "./oetSection"
import { ContentPage, Pages } from "./page"
import { RichText } from "./richText"
import { Section } from "./section"
import { SectionDivider } from "./sectionDivider"
import { ServicesHero } from "./servicesHero"
import { Socials } from "./socials"
import { Testimonial } from "./testimonial"
import { isContentActive } from "./util"

export const BlockType = {
    Accordion: {
        Component: Accordion,
    },
    Card: {
        Component: Card,
    },
    ContentSection: {
        Component: ContentSection,
    },
    Coupon: {
        Component: ContentCoupon,
    },
    CouponGroup: {
        Component: CouponGroup,
    },
    DynamicComponent: {
        Component: DynamicComponent,
    },
    Form: {
        Component: Form,
    },
    Grid: {
        Component: ContentGrid,
    },
    Heading: {
        Component: Heading,
    },
    Hero: {
        Component: Hero,
    },
    IconText: {
        Component: IconText,
    },
    ImageBanner: {
        Component: ImageBanner,
    },
    Link: {
        Component: ContentLink,
    },
    List: {
        Component: List,
    },
    Map: {
        Component: Map,
    },
    OetCoupon: {
        Component: OETCoupon,
    },
    OetEstimateSummary: {
        Component: OETEstimateSummary,
    },
    OetForm: {
        Component: OETForm,
    },
    OetSection: {
        Component: OETSection,
    },
    RichText: {
        Component: RichText,
    },
    Section: {
        Component: Section,
    },
    SectionDivider: {
        Component: SectionDivider,
    },
    ServicesHero: {
        Component: ServicesHero,
    },
    SocialMedia: {
        Component: Socials,
    },
    Testimonial: {
        Component: Testimonial,
    },
}

export const getHeroBanner = (banner) => {
    return banner.map((hero, index) => {
        const fields = hero.fields || hero
        const HeroComponent = getComponent(hero)

        return <HeroComponent {...fields} key={index} />
    })
}

export const getComponent = ({ ...props }) => {
    const componentItem = props.fields || props
    const __typename = getObjectTypename(props)
    const ItemType = BlockType[__typename]

    if (ItemType) return ItemType.Component
    console.log("Component Not Found", {
        __typename,
        ...componentItem,
        ...props,
    })
    return null
}

export const renderComponent = ({ component, ...props }) => {
    const TypeComponent = getComponent(component)
    const componentKey = props.key ? { key: props.key } : null

    return (
        TypeComponent && (
            <React.Fragment {...componentKey}>
                <TypeComponent {...{ ...component.fields, ...props }} />
            </React.Fragment>
        )
    )
}

export {
    Accordion,
    Action,
    Actions,
    Card,
    ContentGrid,
    ContentLink,
    ContentCoupon,
    CouponGroup,
    DynamicComponent,
    GridItems,
    ContentPage,
    ContentSection,
    CtaButton,
    Form,
    Heading,
    Hero,
    IconText,
    ImageBanner,
    InputField,
    isContentActive,
    List,
    OETCoupon,
    OETEstimateSummary,
    OETForm,
    OETSection,
    Pages,
    RichText,
    Section,
    SectionDivider,
    ServicesHero,
    Socials,
    Testimonial,
}
