import { getYear } from "date-fns"

import { VEHICLE_TYPES } from "../Steps/VehicleInfo"
import { OET_OUTCOMES, OET_SUB_FORMS } from "../../../util/globalOETVariables"
import { isAgedOut } from "../../../util/oetApiHelpers"

export const oetOutcomeMapper = ({
    appointmentDate,
    appointmentTime,
    insuranceAction,
    spotRepairAction,
    undecidedAction,
    vehicleService,
    vehicleType,
    year,
}) => {
    if (vehicleType) {
        if (vehicleType === VEHICLE_TYPES.CAR) {
            const carAgedOut = isAgedOut(getYear(new Date()), Number(year))

            return carAgedOut ? OET_OUTCOMES.fastPathAge : null
        }

        if (vehicleType === VEHICLE_TYPES.EV) {
            return OET_OUTCOMES.fastPathEV
        }

        if (vehicleType === VEHICLE_TYPES.OTHER) {
            return OET_OUTCOMES.fastPathOther
        }

        return null
    }

    if (
        (insuranceAction || spotRepairAction || undecidedAction) ===
            OET_OUTCOMES.appointment &&
        !(appointmentTime && appointmentDate)
    ) {
        return OET_OUTCOMES.appointmentContactStore
    }

    if (appointmentTime && appointmentDate) {
        return OET_OUTCOMES.appointment
    }

    if (vehicleService === OET_SUB_FORMS.wholeVehicle) {
        return OET_OUTCOMES.wholeVehicle
    }

    return insuranceAction || spotRepairAction || undecidedAction
}
