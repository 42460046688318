/** @format */
/** @jsxImportSource @emotion/react */

import { UTCDate } from "@date-fns/utc"
import { css } from "@emotion/react"
import { CheckCircleRounded, KeyboardArrowDownRounded } from "@mui/icons-material"
import { Button, Grid, Typography } from "@mui/material"
import { format, parse } from "date-fns"
import { zonedTimeToUtc } from "date-fns-tz"
import React, { useEffect, useRef, useState } from "react"

const Time = ({
    data,
    setPage,
    selectedDate,
    setSelectedDate,
    handleSelect = () => { },
    storeData: {
        timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
    } = {},
}) => {
    const timeSlotRef = useRef(null)
    const [openTimeSlots, setOpenTimeSlots] = useState(true)

    useEffect(() => {
        document.addEventListener("click", handleOutsideClick, false)
        return () => {
            document.removeEventListener("click", handleOutsideClick, false)
        }
    }, [])

    const handleOutsideClick = (e) => {
        if (timeSlotRef.current && !timeSlotRef.current.contains(e.target)) {
            setOpenTimeSlots(false)
        }
    }

    const timeCSS = (date) => css`
        font-weight: ${selectedDate.date === date ? "800" : "500"};
        border-top: ${selectedDate.date === date
            ? "5px solid #AF0E11"
            : "1px solid #AF0E11"};
        transition: "0.3s";
        color: ${selectedDate.date === date ? "#006AB4" : "#000"};
    `

    const currentUserUtcDateTime = new UTCDate()
    const parsedDate = parse(data.date, "yyyy-MM-dd", new Date())

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{
                position: "relative",
                backgroundColor: "#f3f3f3",
                height: 85,
                borderTop: "1px solid #AF0E11",
                transition: "0.3s",
                "&:hover": {
                    borderTop: "5px solid #AF0E11",
                },
            }}
            css={timeCSS(data.date)}
            onClick={(e) => {
                e.stopPropagation()
                setSelectedDate({
                    ...selectedDate,
                    date: data.date,
                })
                setOpenTimeSlots(true)
            }}
        >
            <Typography
                variant="body1"
                sx={{
                    fontWeight: "bold",
                    textTransform: "uppercase",
                }}
                color="secondary"
            >
                {format(parsedDate, "E")}
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    fontWeight: 400,
                    textTransform: "uppercase",
                    transition: "0.3s",
                    "&:hover": {
                        fontWeight: 500,
                    },
                }}
            >
                {format(parsedDate, "MM-dd")}
            </Typography>

            {selectedDate.date === data.date ? (
                <CheckCircleRounded fontSize="small" color="green" />
            ) : (
                <KeyboardArrowDownRounded fontSize="small" />
            )}

            {selectedDate.date === data.date && openTimeSlots && (
                <div
                    css={css`
                        position: absolute;
                        height: 250px;
                        width: 120px;
                        background: linear-gradient(
                            0deg,
                            #006bb7,
                            #006bb7 50%,
                            #214e70 85%,
                            #214e70
                        );
                        z-index: 10;
                        top: 100px;
                        padding-top: 10px;
                        color: #fff;
                    `}
                >
                    <p
                        css={css`
                            text-transform: uppercase;
                            text-align: center;
                            font-weight: bold;
                            font-size: 0.8rem;
                            margin: 0px 0px 8px;
                        `}
                    >
                        Select One
                    </p>

                    {openTimeSlots && (
                        <div
                            css={css`
                                overflow: scroll;
                                height: 170px;
                            `}
                            ref={timeSlotRef}
                        >
                            {data.times.map((time, index) => {
                                let disable =
                                    time.slot >= 3 || time.slot === "closed"
                                        ? true
                                        : false

                                if (selectedDate && selectedDate.date) {
                                    const dateTimeSlot = parse(
                                        selectedDate.date + " " + time.time,
                                        "yyyy-MM-dd hh:mm a",
                                        new Date()
                                    )
                                    const utcTimeSlot = zonedTimeToUtc(
                                        dateTimeSlot,
                                        timezone
                                    )

                                    if (currentUserUtcDateTime >= utcTimeSlot) {
                                        disable = true
                                    }
                                }

                                return (
                                    <Button
                                        key={index}
                                        disabled={disable}
                                        variant={
                                            selectedDate.time === time.time
                                                ? "contained"
                                                : "text"
                                        }
                                        color="info"
                                        sx={[
                                            {
                                                "&:hover": {
                                                    color: "#fff !important",
                                                    background:
                                                        "rgba(0,0,0,0.2) !important",
                                                },
                                            },
                                            {
                                                margin: 0,
                                                padding: "10px",
                                                height: "40px",
                                                backgroundColor: "transparent",
                                                color: "#fff !important",
                                                "&.MuiButton-contained": {
                                                    background:
                                                        "rgba(0,0,0,0.3) !important",
                                                },
                                                "&.Mui-disabled": {
                                                    color: "#595959 !important",
                                                },
                                                alignItems: "center",
                                                width: "100%",
                                            },
                                        ]}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setSelectedDate({
                                                ...selectedDate,
                                                time: time.time,
                                                slot: time.slot,
                                            })
                                            setOpenTimeSlots(true)
                                        }}
                                    >
                                        {time.time}
                                    </Button>
                                )
                            })}
                        </div>
                    )}

                    <Button
                        color="info"
                        variant="outlined"
                        disabled={selectedDate.time === "" ? true : false}
                        onClick={() => {
                            if (selectedDate.time !== "") {
                                setPage("Schedule")
                            }
                            handleSelect()
                            setOpenTimeSlots(false)
                        }}
                        sx={[
                            {
                                "&:hover": {
                                    color: "#004E83 !important",
                                    backgroundColor: "white !important",
                                },
                            },
                            selectedDate.time === "" && {
                                "&:hover": {
                                    backgroundColor: "grey !important",
                                },
                            },
                            {
                                marginLeft: "10px !important",
                                backgroundColor: "transparent !important",
                                border: "1px solid #fff !important",
                                borderRadius: "0px !important",
                                pt: "0px !important",
                                color: "#fff !important",
                                "&.Mui-disabled": {
                                    opacity: 1,
                                    borderColor: "#ebebeb !important",
                                    color: "#fff !important",
                                    backgroundColor: "#595959 !important",
                                },
                            },
                        ]}
                    >
                        Schedule
                    </Button>
                    <div></div>
                </div>
            )}
        </Grid>
    )
}

export default Time
