// courtesy of Anthony Umbriac
const orderStoresByDistance = (latitude, longitude, stores) => {
    const earthRadius = 3958.761

    const degreesToRadians = (degrees) => {
        return (degrees * Math.PI) / 180
    }
    const storesWithCalculatedDistance = stores.map((store) => {
        const deltaLatitude =
            degreesToRadians(store.storeLat) - degreesToRadians(latitude)
        const deltaLongitude =
            degreesToRadians(store.storeLong) - degreesToRadians(longitude)

        const meanLatitude =
            (degreesToRadians(store.storeLat) + degreesToRadians(latitude)) / 2

        const distance =
            earthRadius *
            Math.sqrt(
                Math.pow(deltaLatitude, 2) +
                    Math.cos(meanLatitude) * Math.pow(deltaLongitude, 2)
            )

        return {
            ...store,
            distance: distance,
        }
    })

    return storesWithCalculatedDistance.sort((a, b) => a.distance - b.distance)
}

export default orderStoresByDistance
