import { Divider, Grid, Typography } from "@mui/material"
import { enCA, enUS } from "date-fns/locale"
import { format } from "date-fns"
import { formatInTimeZone } from "date-fns-tz"
import { useContext } from "react"
import { useTranslation } from "react-i18next"

import { OetContext } from "../../../../context"
import {
    cleanPhone,
    findState,
    onPhoneClick,
    reformatPhone,
} from "../../../../util"
import Link from "../../../Link"
import { OetStoreDetails } from "../../utils/storeHelpers"
import { AddToCalendarButton } from "../../../Buttons/AddToCalendar"

export const OETAppointmentConfirmation = () => {
    const { state: { location, service } = {} } = useContext(OetContext)

    const { t } = useTranslation()

    if (!location) return null

    const parsedAppointmentDate = service.appointmentDate
        ? Date.parse(service.appointmentDate)
        : null

    return (
        <Grid
            container
            item
            sx={{
                backgroundColor: "#E8E8E8",
                boxSizing: "border-box",
                p: 2,
            }}
        >
            <Grid
                container
                item
                alignItems="flex-start"
                justifyContent="space-between"
                sx={{ pt: 0 }}
            >
                {service.appointmentDate && service.appointmentTime && (
                    <>
                        <Grid item>
                            <Typography
                                variant="h6"
                                color="primary"
                                fontFamily="Gilroy Bold"
                                sx={{ m: 0 }}
                            >
                                Date:{" "}
                                <Typography
                                    variant="span"
                                    fontFamily="Gilroy Medium"
                                    sx={{ m: 0 }}
                                >
                                    {format(parsedAppointmentDate, "PPPP", {
                                        locale:
                                            t("locale") === "en-CA"
                                                ? enCA
                                                : enUS,
                                    })}
                                </Typography>
                            </Typography>
                            <Typography
                                variant="h6"
                                color="primary"
                                fontFamily="Gilroy Bold"
                                sx={{ m: 0 }}
                            >
                                Starting Time:{" "}
                                <Typography
                                    variant="span"
                                    fontFamily="Gilroy Medium"
                                    sx={{ m: 0 }}
                                >
                                    {service.appointmentTime
                                        .toLowerCase()
                                        .replace(/^0+/, "")
                                        .replace(" ", "") +
                                        " " +
                                        formatInTimeZone(
                                            parsedAppointmentDate,
                                            location.timezone,
                                            "zzz"
                                        )}
                                </Typography>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <AddToCalendarButton
                                location={location}
                                description={`Location: ${
                                    location.storeAddress
                                }, ${location.storeCity}, ${
                                    findState(location.storeState) ||
                                    location.storeState
                                }, ${
                                    location.storePostcode
                                } // Phone: ${reformatPhone(
                                    location.organicPhone
                                )} // Service: ${service.type}`}
                                appointmentDate={parsedAppointmentDate}
                                appointmentTime={service.appointmentTime}
                            />
                        </Grid>
                    </>
                )}

                {/* IF LOCATION DOES NOT HAVE ONLINE SCHEDULING ACTIVE, SHOW CONTACT INFO */}
                {(!location.scheduleActive || !service.setAppointment) && (
                    <Grid item xs sx={{ textAlign: "center" }}>
                        <Typography variant="h4" component="span">
                            Please call the shop to book an appointment
                            <Typography
                                color="primary"
                                component="span"
                                variant="h4"
                                sx={{ ml: 1 }}
                            >
                                <Link
                                    href={`tel:${cleanPhone(
                                        location.organicPhone
                                    )}`}
                                    onClick={() => onPhoneClick(location)}
                                >
                                    {reformatPhone(location.organicPhone)}
                                </Link>
                            </Typography>
                        </Typography>
                    </Grid>
                )}
            </Grid>
            <Grid item sx={{ width: "100%", py: 2 }}>
                <Divider />
            </Grid>
            {/* STORE DETAILS */}
            <OetStoreDetails location={location} />
        </Grid>
    )
}
