/** @format */

import isEqual from "lodash.isequal"
import { useTranslation } from "next-i18next"
import React, {
    createContext,
    useCallback,
    useEffect,
    useReducer,
    useState,
} from "react"

import { VEHICLE_TYPES } from "../components/OET/Steps/VehicleInfo"
import { API } from "../util/api"
import { getEnvironmentVariable } from "../util/env"
import { OET_STEPS, OET_STEP_LIST } from "../util/globalOETVariables"

export const OET_CACHE_IN_DAYS =
    getEnvironmentVariable({
        key: "OET_CACHE_IN_DAYS",
    }) ?? 3

export const appInitialState = {
    states: [],
    stores: null,
    // when assigning default values to destructured objects, null is still a value. Undefined allows us to assign default values.
    currentStore: undefined,
    content: undefined,
    inquiryInfo: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        services: "",
        inquiry: "",
    },
}

function appReducer(state, action) {
    switch (action.type) {
        case "STATES": {
            return {
                ...state,
                states: action.payload,
            }
        }
        case "CURRENT_STORE":
            if (state.currentStore?.storeId === action.payload?.storeId) {
                return state
            }

            return {
                ...state,
                currentStore: action.payload,
                content: {
                    ...state.content,
                    store: action.payload,
                    storeId: action.payload?.storeId,
                },
            }
        case "INQUIRY_INFO": {
            const inquiryInfo =
                action.method === "reset"
                    ? appInitialState.inquiryInfo
                    : action.payload

            localStorage.setItem("inquiryInfo", JSON.stringify(inquiryInfo))

            return {
                ...state,
                inquiryInfo,
            }
        }
        case "STORES": {
            const stores = action.payload
            const { setInitialStore } = action.options || {
                setInitialStore: true,
            }

            if (!isEqual(state.stores, stores)) {
                const currentStore = setInitialStore
                    ? stores?.find((store) => {
                          return store.storeId === state.currentStore?.storeId
                      }) ||
                      (stores && stores[0])
                    : state.currentStore

                return {
                    ...state,
                    stores,
                    currentStore,
                }
            }

            return state
        }
        case "CONTENT":
            return {
                ...state,
                content: {
                    ...state.content,
                    ...action.payload,
                },
            }
        default:
            return { ...state }
    }
}

const Context = createContext({ ...appInitialState })

const Provider = (props) => {
    const { t } = useTranslation()
    const [state, dispatch] = useReducer(appReducer, {
        ...appInitialState,
    })
    const [fetchingStates, setFetchingStates] = useState(false)

    const getStates = useCallback(async () => {
        const states = await API.getStates({ isServerCall: false })
        const statesReturned =
            states && states?.length
                ? states
                : t("states", { defaultValue: [] })

        dispatch({ type: "STATES", payload: statesReturned })
        setFetchingStates(false)

        return statesReturned
    }, [t])

    useEffect(() => {
        if (!window) return

        if (state.inquiryInfo === appInitialState.inquiryInfo) {
            const inquiryInfo = JSON.parse(localStorage.getItem("inquiryInfo"))

            if (!inquiryInfo) return

            state.inquiryInfo = inquiryInfo
        }
    }, [state])

    useEffect(() => {
        if (state?.states?.length || fetchingStates) return

        getStates()

        return () => {
            setFetchingStates(true)
        }
    }, [fetchingStates, getStates, state?.states])

    return (
        <Context.Provider value={{ getStates, state, dispatch }}>
            {props.children}
        </Context.Provider>
    )
}

const oetInitialState = {
    estimateStep: OET_STEPS.selectLocation,
    estimateSubStep: null,
    estimateSteps: null,
    estimateClicked: false,
    featureFlag: null,
    formSubmitting: false,
    loading: false,
    leadId: "",
    userInfo: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        contactByText: false,
        emailEstimate: true,
        emailPromotions: true,
        preferredMethod: "",
        preferredTime: [],
    },
    vehicleInfo: {
        make: "",
        model: "",
        submodel: "",
        year: "",
        vin: "",
        hasVinNumber: false,
        vehicleType: VEHICLE_TYPES.CAR,
        notes: "",
    },
    service: {
        type: "",
        paintType: "",
        spotRepairAction: "",
        insuranceAction: "",
        claimNumber: "",
        insuranceProvider: "",
        undecidedAction: "",
        followUp: false,
        comment: "",
        coupon: null,
        customerAction: false,
        atRisk: false,
        setAppointment: false,
        appointmentDate: null,
        appointmentTime: "",
    },
    location: null,
    estimateAmount: null,
    abVariant: 0,
    overLimit: null,
    estimateOutcome: null,
    error: "",
}

function oetReducer(state, action) {
    switch (action.type) {
        case "RESET_OET": {
            // FIXME: also clear out context values on reset.
            // check for abandon page call on app.js and set the local storage values from there also.
            state = oetReducer(state, {
                type: "ESTIMATE_STEP",
                method: "reset",
            })

            state = oetReducer(state, {
                type: "FEATURE_FLAG",
                method: "reset",
            })

            state = oetReducer(state, {
                type: "STORE_LOCATION",
                method: "reset",
            })

            state = oetReducer(state, {
                type: "LEAD_ID",
                method: "reset",
            })

            state = oetReducer(state, { type: "USER_INFO", method: "reset" })
            state = oetReducer(state, { type: "VEHICLE_INFO", method: "reset" })
            state = oetReducer(state, {
                type: "SERVICE_DETAIL",
                method: "reset",
            })

            state = oetReducer(state, { type: "ESTIMATE", method: "reset" })

            return state
        }
        case "ESTIMATE_STEP": {
            let { estimateStep, estimateSteps = null } =
                action.method === "reset" ? {} : action.payload
            estimateStep =
                action.method === "reset"
                    ? oetInitialState.estimateStep
                    : estimateStep

            localStorage.setItem("estimateStep", estimateStep)
            localStorage.setItem("estimateSteps", estimateSteps)

            return {
                ...state,
                estimateStep,
                estimateSteps,
            }
        }
        case "FEATURE_FLAG": {
            const featureFlag =
                action.method === "reset"
                    ? oetInitialState.featureFlag
                    : {
                          key: action.payload,
                      }

            localStorage.setItem("featureFlag", JSON.stringify(featureFlag))

            return {
                ...state,
                featureFlag,
            }
        }
        case "USER_INFO": {
            const userInfo =
                action.method === "reset"
                    ? oetInitialState.userInfo
                    : action.payload

            localStorage.setItem("userInfo", JSON.stringify(userInfo))

            return {
                ...state,
                userInfo,
            }
        }
        case "VEHICLE_INFO": {
            const vehicleInfo =
                action.method === "reset"
                    ? oetInitialState.vehicleInfo
                    : action.payload

            localStorage.setItem("vehicleInfo", JSON.stringify(vehicleInfo))

            return {
                ...state,
                vehicleInfo,
            }
        }
        case "SERVICE_DETAIL": {
            const service =
                action.method === "reset"
                    ? oetInitialState.service
                    : action.payload

            localStorage.setItem("vehicleService", JSON.stringify(service))

            return {
                ...state,
                service,
            }
        }
        case "STORE_LOCATION": {
            const location =
                action.method === "reset"
                    ? oetInitialState.location
                    : {
                          ...action.payload,
                      }

            localStorage.setItem("location", JSON.stringify(location))

            return {
                ...state,
                location,
            }
        }
        case "LEAD_ID": {
            const leadId =
                action.method === "reset"
                    ? oetInitialState.leadId
                    : action.payload

            localStorage.setItem("leadId", JSON.stringify(leadId))

            return {
                ...state,
                leadId,
            }
        }
        case "LOADING":
            return {
                ...state,
                loading: action.payload,
            }
        case "FORM_SUBMITTING":
            return {
                ...state,
                formSubmitting: action.payload,
            }
        case "ESTIMATE": {
            const estimateAmount =
                action.method === "reset"
                    ? oetInitialState.estimateAmount
                    : action.payload

            localStorage.setItem(
                "estimateAmount",
                JSON.stringify(estimateAmount)
            )

            return {
                ...state,
                estimateAmount,
            }
        }
        case "ESTIMATE_OUTCOME": {
            const estimateOutcome =
                action.method === "reset"
                    ? oetInitialState.estimateOutcome
                    : action.payload

            localStorage.setItem(
                "estimateOutcome",
                JSON.stringify(estimateOutcome)
            )

            return {
                ...state,
                estimateOutcome,
            }
        }
        case "OVER_LIMIT":
            return {
                ...state,
                overLimit: action.payload,
            }
        case "ERRORS":
            return {
                ...state,
                error: action.payload,
            }
        default:
            return { ...state }
    }
}

const OetContext = createContext({ ...oetInitialState })

const OetProvider = (props) => {
    const [state, dispatch] = useReducer(oetReducer, {
        ...oetInitialState,
    })

    useEffect(() => {
        if (!window) return

        if (state.location === oetInitialState.location) {
            const location = JSON.parse(localStorage.getItem("location"))

            if (!location) return

            state.location = location
        }

        if (state.featureFlag === oetInitialState.featureFlag) {
            const featureFlag =
                JSON.parse(localStorage.getItem("featureFlag")) ?? null

            state.featureFlag = featureFlag
        }

        if (state.leadId === oetInitialState.leadId) {
            const leadId = JSON.parse(localStorage.getItem("leadId"))

            state.leadId = leadId
        }

        if (state.estimateStep === oetInitialState.estimateStep) {
            const estimateStep = localStorage.getItem("estimateStep")
            const estimateSteps = JSON.parse(
                localStorage.getItem("estimateSteps")
            )

            if (!estimateStep) return

            const estimateIndex = Number(
                OET_STEP_LIST.findIndex(({ contentfulSteps }) =>
                    contentfulSteps.includes(estimateStep)
                )
            )

            if (estimateIndex + 1 === estimateSteps) {
                const newState = oetReducer(state, { type: "RESET_OET" })
                state.location = newState.location

                return
            }

            state.estimateStep = estimateStep
            state.estimateSteps = estimateSteps
        }

        if (state.estimateSubStep === oetInitialState.estimateSubStep) {
            const estimateSubStep = JSON.parse(
                localStorage.getItem("estimateSubStep")
            )

            state.estimateSubStep = estimateSubStep
        }

        if (state.userInfo === oetInitialState.userInfo) {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"))

            if (!userInfo) return
            state.userInfo = userInfo
        }

        if (state.vehicleInfo === oetInitialState.vehicleInfo) {
            const vehicleInfo = JSON.parse(localStorage.getItem("vehicleInfo"))

            if (!vehicleInfo) return
            state.vehicleInfo = vehicleInfo
        }

        if (state.service === oetInitialState.service) {
            const service = JSON.parse(localStorage.getItem("vehicleService"))

            if (!service) return
            state.service = service
        }

        if (state.estimateAmount === oetInitialState.estimateAmount) {
            const estimateAmount = JSON.parse(
                localStorage.getItem("estimateAmount")
            )

            if (!estimateAmount) return
            state.estimateAmount = Math.floor(
                Number(estimateAmount)
            ).toLocaleString()
        }

        if (state.estimateOutcome === oetInitialState.estimateOutcome) {
            const estimateOutcome = JSON.parse(
                localStorage.getItem("estimateOutcome")
            )

            if (!estimateOutcome) return
            state.estimateOutcome = estimateOutcome
        }
    }, [state])

    return (
        <OetContext.Provider value={{ state, dispatch }}>
            {props.children}
        </OetContext.Provider>
    )
}

export const previewInitialState = {
    preview: false,
}

function previewReducer(state, action) {
    switch (action.type) {
        case "PREVIEW": {
            return {
                ...state,
                preview: action.payload,
            }
        }
        default:
            return { ...state }
    }
}

const PreviewContext = createContext({ ...previewInitialState })

const PreviewProvider = ({ children, preview }) => {
    const [state, dispatch] = useReducer(previewReducer, {
        ...previewInitialState,
    })

    useEffect(() => {
        state.preview = preview
    }, [preview, state])

    return (
        <PreviewContext.Provider value={{ state, dispatch }}>
            {children}
        </PreviewContext.Provider>
    )
}

export {
    OetContext,
    OetProvider,
    Context,
    Provider,
    PreviewContext,
    PreviewProvider,
}
