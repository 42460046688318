import { Grid } from "@mui/material"
import React from "react"

import { getComponent } from "."

export const GridItems = ({ locale, variant, columns, items, gridHeight }) => {
    const count =
        columns ||
        (["grid", "table"].indexOf(variant) >= 0
            ? items.filter((item) => {
                const gridItem = item.fields || item

                return !gridItem.hideOnDesktop
            }).length
            : 1)

    return items.map(({ ...props }, index) => {
        const gridChild = props.fields || props
        const { hideOnMobile, hideOnDesktop } = gridChild

        let ItemComponent = getComponent(props)

        if (!ItemComponent) return null

        ItemComponent = (
            <ItemComponent
                {...{
                    ...gridChild,
                    locale,
                    gridHeight,
                    variant:
                        gridChild.variant ||
                        (variant === "table" && "outlined") ||
                        null,
                }}
            />
        )

        const itemTypeEl = (
            <Grid
                item
                container
                flexDirection={"column"}
                justifyContent={gridChild.verticalAlignment || "stretch"}
                display={{
                    xs: hideOnMobile ? "none" : "flex",
                    sm: hideOnDesktop ? "none" : "flex",
                }}
                xs={12}
                sm={count ? 12 / count : "auto"}
                sx={{
                    height: { sm: (gridHeight && "100%") || undefined },
                }}
            >
                {ItemComponent}
            </Grid>
        )

        return (
            <React.Fragment key={`item-${index}`}>{itemTypeEl}</React.Fragment>
        )
    })
}

export const ContentGrid = ({
    locale,
    variant,
    columns,
    itemsCollection = {},
    ...props
}) => {
    let {
        gridHeight, // passed in recursively
        height,
        items,
        spacing = 3
    } = props
    height = height || gridHeight
    items = items || itemsCollection.items || []
    spacing = variant !== "table" ? parseInt(spacing) : 3
    let rowSpacing = variant !== "table" ? 3 : 0

    return (
        (items?.length > 0 && (
            <Grid
                container
                justifyContent={"center"}
                direction={columns === 1 ? "column" : "row"}
                flexWrap={columns === 1 ? "nowrap" : "wrap"}
                columnSpacing={spacing}
                rowSpacing={rowSpacing}
                sx={{
                    height: { sm: (height && `${height}vh`) || undefined },
                }}
            >
                <GridItems
                    {...{
                        locale,
                        variant,
                        columns,
                        items,
                        gridHeight: height,
                    }}
                />
            </Grid>
        )) ||
        null
    )
}
