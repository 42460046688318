/** @format */
const { fbEvent } = require("@rivercode/facebook-conversion-api-nextjs")
const dateFn = require("date-fns")

const apiURL = require("../apiURL")
const { getEnvironmentVariable } = require("./env")
const { handleCaughtError } = require("./handlers")

// USED TO DETERMINE WHERE FEATURE FLAGS ARE APPLIED
const FEATURE_FLAGS = {
    startingAt: "startingAt",
    insuranceCapture: "insuranceCapture",
    fastPath: "fastPath",
    coupons: "coupons",
    ccc: "CCC",
}

const CERTIFICATION_TYPE = {
    diamond: "Diamond",
    gold: "Gold",
    platinum: "Platinum",
    ICBC: "ICBC",
    BBB: "Better Business Bureau Accreditation",
}

const reformatPhone = (phone) => {
    if (phone) {
        let x = phone.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
        return !x[2]
            ? x[1]
            : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "")
    }
}

const cleanPhone = (phone) => {
    if (phone) {
        return phone.replace(/[^\d]/g, "")
    }
}

const formatDMA = (dma) => dma.replaceAll("-", " ").replace("area", "")

const couponPayload = (coupon) => {
    if (!coupon) return
    const {
        couponImage,
        couponShortCode,
        disclaimerContent,
        disclaimerTitle,
        expirationDate,
        id,
        startDate,
        isLocked,
        isRecurring,
        title,
        type,
    } = coupon
    return {
        id,
        title,
        type,
        startDate: startDate ?? "",
        endDate: expirationDate,
        isLocked: `${isLocked}`,
        isRecurring: `${isRecurring}`,
        disclaimerTitle: disclaimerTitle ?? title,
        disclaimerContent: disclaimerContent ?? title,
        couponShortCode,
        url: `http:${couponImage?.fields?.file.url}` ?? couponImage,
    }
}

const mapStoreSocialMedia = (mediaLinks, store) => {
    return mediaLinks.map((linkItem) => {
        const { fields = {} } = linkItem

        let link = ""
        switch (fields.icon) {
            case "square-facebook":
                link = store.servicesFacebookLink
                break
            case "google-plus-g":
                link = store["servicesGoogle+Link"]
                break
            case "instagram":
                link = store.servicesInstagramLink
                break
            case "yelp":
                link = store.servicesYelpLink
                break
            // TODO: Integrate once Yellow Pages icon is supported
            // case 'book-open': <-- this is a placeholder and will need updating
            //     link = store.servicesYPLink <-- this is the actual field name
            //     break;
            case "twitter":
                link = store.servicesTwitterLink
                break
            default:
                break
        }

        return { ...fields, link }
    })
}

const MOBILE_WIDTH = 878

////////////////////////////////////////
// ENVIRONMENT VARIABLES
////////////////////////////////////////
const API_KEY = getEnvironmentVariable({ key: "API_KEY" })
const newApiUrl = getEnvironmentVariable({ key: "NEW_API_URL" })
const NEW_API_KEY = getEnvironmentVariable({ key: "NEW_API_KEY" })

// new stage api
const NEW_API_URL = `${newApiUrl}/v1/legacy/maaco`
const API_URL = `${apiURL}/api/v1`
const API_URL_V2 = `${apiURL}/api/v2`

const GOOGLE_MAP_API_KEY = getEnvironmentVariable({
    key: "GOOGLE_MAP_API_KEY",
})
const GA_ID = getEnvironmentVariable({
    key: "GOOGLE_ANALYTICS_ID",
})
const GTM_ID = getEnvironmentVariable({
    key: "GOOGLE_TAG_MANAGER_ID",
})

const SHOW_COOKIE_BANNER =
    JSON.parse(
        getEnvironmentVariable({
            key: "SHOW_COOKIE_BANNER",
            isOptional: true,
        })
    ) || false

/**
 * TODO: remove this at some point after the DVM-1642 PR is merged
 */
const MOCK_ERRORS = getEnvironmentVariable({
    key: "MOCK_ERRORS",
    isOptional: true,
})

////////////////////////////////////////
// ENVIRONMENT VARIABLES - END
////////////////////////////////////////

const getUtmObject = () => {
    const UTM_Params = sessionStorage.getItem("UTM_OBJECT")
    return UTM_Params ? JSON.parse(UTM_Params) : {}
}

////////////////////////////////////////
// EVENT NAMES
////////////////////////////////////////
const GTM_EVENTS = {
    appointmentCtaClick: "est_appt_cta_click",
    appointmentCtaComplete: "est_appt_complete",
    call: "call_click",
    careersFormStart: "careers_form_start",
    careersFormComplete: "careers_form_complete",
    couponEmail: "coupon_email",
    couponText: "coupon_text",
    customerServiceStart: "customer_service_started",
    customerServiceComplete: "customer_service_complete",
    inquiryComplete: "inquiry_complete",
    inquiryStart: "inquiry_start",
    locationSearch: "location_search",
    oetCtaClick: "oet_cta_click",
    oetStepStarted: "oet_step_started",
    oetStepComplete: "oet_step_complete",
    // GTM EVENTS PRIOR TO STEP RE-ORDERING
    // oetStepOne: "oet_step_one_your_info_submit",
    // oetStepTwo: "oet_step_two_vehicle_info_submit",
    // oetStepThree: "oet_step_three_choose_service_submit",
    // oetStepFour: "oet_step_four_vehicle_condition_submit",
    // oetStepFive: "oet_step_five_select_location_submit",

    // RE-ORDERED GTM EVENTS
    oetStepOne: "oet_step_one_select_location_submit",
    oetStepTwo: "oet_step_two_your_info_submit",
    oetStepThree: "oet_step_three_vehicle_info_submit",
    oetStepFour: "oet_step_four_choose_service_submit",
    oetStepFive: "oet_step_five_vehicle_condition_submit",

    oetComplete: "oet_complete",
    pageView: "pageview",
}

////////////////////////////////////////
// EVENT NAMES - END
////////////////////////////////////////

////////////////////////////////////////
// FACEBOOK CAPI EVENTS
////////////////////////////////////////

const triggerFBEvent = (eventData) => {
    const { event, ...data } = eventData

    const GTM_FB_MAP = {
        [GTM_EVENTS.pageView]: "PageView",
        [GTM_EVENTS.appointmentCtaComplete]: "Schedule",
        [GTM_EVENTS.customerServiceComplete]: "Contact",
        [GTM_EVENTS.couponEmail]: "CompleteRegistration",
        [GTM_EVENTS.couponText]: "CompleteRegistration",
        [GTM_EVENTS.inquiryComplete]: "SubmitApplication",
        [GTM_EVENTS.oetComplete]: "Lead",
        [GTM_EVENTS.oetCtaClick]: "OET Start",
        [GTM_EVENTS.call]: "OnlineCall",
        [GTM_EVENTS.locationSearch]: "FindLocation",
    }

    const eventName = GTM_FB_MAP[event]

    if (eventName) {
        fbEvent({
            eventName,
            ...data,
        })
    }
}

////////////////////////////////////////
// FACEBOOK CAPI EVENTS - END
////////////////////////////////////////

////////////////////////////////////////
// GTM EVENTS
////////////////////////////////////////
const dataLayer = (event) => {
    const UTM_Params = getUtmObject()
    const { utm_source, utm_medium, utm_campaign, utm_content, utm_term } =
        UTM_Params
    window.dataLayer?.push({
        ...event,
        utm_source,
        utm_medium,
        utm_campaign,
        utm_content,
        utm_term,
    })
    triggerFBEvent({
        ...event,
        utm_source,
        utm_medium,
        utm_campaign,
        utm_content,
        utm_term,
    })
}

const onPhoneClick = (store) => {
    const UTM_Params = getUtmObject()
    // Adding the relevant GTM information to the datalayer
    dataLayer({
        event: GTM_EVENTS.call,
        gtm_storeid: store.storeId,
        gtm_pagerefsource: window.document.referrer,
        gtm_dma: store.dma,
        mca_cmp: UTM_Params?.mca_cmp,
    })
}

const couponsDelivered = ({ event, offers, store = "" }) => {
    offers.forEach(({ id, title, type }) => {
        dataLayer({
            event,
            coupon_id: id,
            form_data_coupon_value: title,
            form_data_coupon_title: title,
            form_data_coupon_topic: type,
            gtm_storeid: store.storeId,
            gtm_pagerefsource: window.document.referrer,
            gtm_dma: store.dma,
        })
    })
}

// how to gtm courtesy of NextJS https://github.com/vercel/next.js/blob/canary/examples/with-google-tag-manager/
const pageview = (page, { title }) => {
    dataLayer({
        event: GTM_EVENTS.pageView,
        page,
        title,
    })
}
////////////////////////////////////////
// GTM EVENTS - END
////////////////////////////////////////

const debounce = (fn, delay) => {
    let timeout = -1

    return (...args) => {
        if (timeout !== -1) {
            clearTimeout(timeout)
        }

        timeout = setTimeout(fn, delay, ...args)
    }
}

const positionsMap = {
    "right passenger fender": "right_front_fender",
    "right passenger side door": "right_front_door",
    "right rear passenger side door": "right_rear_door",
    "right passenger side quarter panel": "right_rear_fender",
    "left driver fender": "left_front_fender",
    "left driver side door": "left_front_door",
    "left rear driver side door": "left_rear_door",
    "left driver side quarter panel": "left_rear_fender",
    "front bumper": "front_bumper",
    hood: "hood",
    roof: "roof",
    trunk: "tailgate",
}

const STATIC_IMAGE_PATH =
    "https://static.maaco.com/resources/front/resources/images/"

const STATIC_IMAGE_ICONS_PATH =
    "https://static.maaco.com/resources/front/resources/icons/"

const STATIC_IMAGE_PATH2 = "https://static.maaco.com/resources/uploads/"

const containerCSS = `
    padding: 25px 15px;
    margin-right: auto;
    margin-left: auto;
    @media (min-width: 768px){
        padding-left: 0.5em;
        padding-right: 0.5em;
        width: 750px;
    }
    @media (min-width: 992px){
        padding-left: 0;
        padding-right: 0;
        width: 970px;
    }
    @media (min-width: 1200px) {
      width: 1170px;
    }
`

const slugify = (str) => {
    //replace all special characters | symbols with a space
    str = str
        .replace(/[`~!@#$%^&*()_\-+=\[\]{};:'"\\|\/,.<>?\s]/g, " ")
        .toLowerCase()
    // trim spaces at start and end of string
    str = str.replace(/^\s+|\s+$/gm, "")
    // replace space with dash/hyphen
    str = str.replace(/\s+/g, "-")
    return str
}

const findState = (inputState, state) => {
    const stateFilter = state?.states?.find(
        (index) => index.stateName === inputState
    )

    return stateFilter?.stateAbbreviation
}

const getStateFromList = (state_list, store) => {
    const state = state_list.find((x) => store?.storeState === x.stateName)

    return state
}

const constructStateLink = (store, state_list = []) => {
    const state = getStateFromList(state_list, store)

    if (!state) return ""

    const link = `/locations/${slugify(
        `${state.stateAbbreviation?.toLowerCase()}`
    )}/${slugify(`${store?.storeCity} ${store?.storeId}`)}/`

    return link
}

const constructOfferLink = (store, state_list = []) => {
    const state = getStateFromList(state_list, store)

    if (!state) return ""

    const link = `/locations/${slugify(
        `${state.stateAbbreviation?.toLowerCase()}`
    )}/${slugify(`${store?.storeCity} ${store?.storeId}`)}/offers/`

    return link
}

const calculateAvailableDates = (
    selectedStore,
    startingDate,
    setStartingDate = () => {},
    beginDate,
    setBeginDate = () => {},
    setAvailableDates,
    incDecDay = 1
) => {
    const availableDates = []
    let lastUpdatedDate = startingDate
    let bDate = null
    for (let i = 1; i <= 4; i++) {
        const todayDayName = dateFn.format(lastUpdatedDate, "EEEE")
        let storeHours = null
        if (selectedStore.hours) {
            storeHours = selectedStore.hours[todayDayName]
        } else {
            storeHours = selectedStore.appointmentSchedule
                ? selectedStore.appointmentSchedule[todayDayName]
                : {
                      is_open:
                          todayDayName === `Sunday`
                              ? selectedStore.hoursSundayOpen === "CLOSED"
                                  ? 0
                                  : 1
                              : 1,
                      open:
                          todayDayName === `Saturday`
                              ? selectedStore.hoursSaturdayOpen
                              : selectedStore.hoursWeekdayOpen,
                      close:
                          todayDayName === `Saturday`
                              ? selectedStore.hoursSaturdayClose
                              : selectedStore.hoursWeekdayClose,
                  }
        }
        if (storeHours.is_open === 0) {
            lastUpdatedDate = dateFn.addDays(lastUpdatedDate, incDecDay)
            i--
            continue
        }
        setStartingDate(lastUpdatedDate)
        const slots = []
        const startSlots = dateFn.parse(
            `${dateFn.format(lastUpdatedDate, "dd/MM/yyyy")} ${
                storeHours.open
            }`,
            `dd/MM/yyyy h:mm bbb`,
            new Date()
        )
        const endSlots = dateFn.parse(
            `${dateFn.format(lastUpdatedDate, "dd/MM/yyyy")} ${
                storeHours.close
            }`,
            `dd/MM/yyyy h:mm bbb`,
            new Date()
        )
        let currentSlot = startSlots
        for (;;) {
            if (
                dateFn.differenceInMilliseconds(endSlots, currentSlot) === 0 ||
                endSlots == "Invalid Date"
            ) {
                break
            }
            slots.push(currentSlot)
            currentSlot = dateFn.setMinutes(
                currentSlot,
                currentSlot.getMinutes() + 15
            )
        }
        availableDates.push({ date: lastUpdatedDate, slots })
        if (beginDate == null && bDate === null) {
            setBeginDate(lastUpdatedDate)
            bDate = lastUpdatedDate
        }
        lastUpdatedDate = dateFn.addDays(lastUpdatedDate, incDecDay)
    }
    setAvailableDates(availableDates)
}

const DEFAULT_MAP_LOCATION = {
    lat: 33.4517,
    lng: -112.0745,
}

//If the user presses the 'enter' key, try to focus on the next input in a form
//If it doesn't find a form or a subsequent input, it will do the default action for the enter key.
const searchForNextInput = (e) => {
    if (e.key.toLowerCase() === "enter") {
        const form = e.target?.form
        if (!form) return
        const index = [...form].indexOf(e.target)
        var i = 1
        try {
            while (
                !["TEXTAREA", "INPUT"].includes(
                    form.elements[index + i].nodeName
                )
            ) {
                i = i + 1
            }
            form.elements[index + i].focus()
            e.preventDefault()
        } catch (err) {
            handleCaughtError("Error tracking inputs ", err)
        }
    }
}

const getVisitedURL = (defaultURL) => {
    const rawLeadGenUrlString = sessionStorage.getItem("rawLeadGenUrlString")
    const searchParams = defaultURL.search.slice(1, defaultURL.search.length)
    const paramsAreSame = rawLeadGenUrlString === searchParams
    if (rawLeadGenUrlString && !paramsAreSame) {
        return defaultURL.search.length
            ? `${defaultURL.href}&${rawLeadGenUrlString}`
            : `${defaultURL.href}?${rawLeadGenUrlString}`
    }
    return defaultURL.href
}

//This function takes an array of items (array) and divides it into sections based on size argument (chunkSize) provided.
//It return the array in sections. Example: If given an array of 12 items and a chunkSize of 4,
//it will return 3 sections of 4 array items.
const chunkArray = (chunkCount, array) => {
    const chunks = []
    const chunkLength = array.length / chunkCount

    for (let i = 0; i < array.length; i += chunkLength) {
        const chunk = array.slice(i, i + chunkLength)
        chunks.push(chunk)
    }

    return chunks
}

const waitThisManySeconds = (seconds) => {
    return new Promise((resolve) => setTimeout(resolve, 1000 * seconds))
}

const getProperCasing = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

module.exports = {
    CERTIFICATION_TYPE,
    reformatPhone,
    cleanPhone,
    formatDMA,
    couponPayload,
    mapStoreSocialMedia,
    MOBILE_WIDTH,
    NEW_API_KEY,
    API_KEY,
    NEW_API_URL,
    API_URL,
    API_URL_V2,
    FEATURE_FLAGS,
    GOOGLE_MAP_API_KEY,
    GA_ID,
    GTM_ID,
    SHOW_COOKIE_BANNER,
    MOCK_ERRORS,
    getUtmObject,
    GTM_EVENTS,
    dataLayer,
    onPhoneClick,
    couponsDelivered,
    pageview,
    debounce,
    positionsMap,
    STATIC_IMAGE_PATH,
    STATIC_IMAGE_ICONS_PATH,
    STATIC_IMAGE_PATH2,
    containerCSS,
    slugify,
    findState,
    constructStateLink,
    constructOfferLink,
    calculateAvailableDates,
    DEFAULT_MAP_LOCATION,
    searchForNextInput,
    getVisitedURL,
    chunkArray,
    waitThisManySeconds,
    getProperCasing,
}
