/** @jsxImportSource @emotion/react */
/** @format */

import { css } from "@emotion/react"
import { Grid, Typography, useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/styles"
import { useContext } from "react"
import { useSWRConfig } from "swr"

import { Context } from "../../context"
import { mapStoreSocialMedia } from "../../util"
import Link from "../Link"

export const Socials = ({
    text,
    connectWithUs,
    size,
    socialMediaLinks,
    color = "#214e70",
    icons = {},
    socialMediaLinksCollection = {},
}) => {
    const { state } = useContext(Context)
    const { fallback } = useSWRConfig()

    const { currentStore: store = fallback?.store } = state
    let storeLinks
    if (store && socialMediaLinks) {
        storeLinks = mapStoreSocialMedia(socialMediaLinks, store);
    }
    const items =
        storeLinks ||
        socialMediaLinks ||
        socialMediaLinksCollection.items ||
        icons.items ||
        []
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

    size = size || (isMobile ? "1.5" : "2")

    return (
        <Grid
            item
            container
            direction="row"
            css={css`
                margin: 1em 0;
                align-items: baseline;
            `}
        >
            {connectWithUs || text && <Grid
                item
                css={css`
                    margin-right: 0.5em;
                `}
            >
                <Typography variant="h5">{connectWithUs || text}</Typography>
            </Grid>}

            <Grid item>
                {items.map(({ text, link, icon }) => {
                    return link?.length > 0 && icon && (
                        <Link
                            key={`${icon}-icon`}
                            href={link}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {(
                                <i
                                    className={`fa-brands fa-${icon}`}
                                    css={css`
                                        color: ${color || "inherit"};
                                        font-size: ${size}em;
                                        margin: 0 0.25em;
                                    `}
                                />
                            ) || icon}
                        </Link>
                    )
                })}
            </Grid>
        </Grid>
    )
}
