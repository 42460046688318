import { OET_FORMS } from "../../util/globalOETVariables"
import { DynamicFormContent } from "../Forms/DynamicFormContent"
import StoreLocations from "../OET/Steps/StoreLocations"
import UserInfo from "../OET/Steps/UserInfo"
import VehicleInfo from "../OET/Steps/VehicleInfo"
import VehicleInfoServices from "../OET/Steps/VehicleInfoServices"

const formMap = {
    [OET_FORMS.selectLocation]: StoreLocations,
    [OET_FORMS.customerInfo]: UserInfo,
    [OET_FORMS.vehicleInfo]: VehicleInfo,
    [OET_FORMS.vehicleServices]: VehicleInfoServices,
    [OET_FORMS.wholeVehicle]: DynamicFormContent,
    [OET_FORMS.spotRepair]: DynamicFormContent,
    [OET_FORMS.insurance]: DynamicFormContent,
    [OET_FORMS.undecided]: DynamicFormContent,
}

export const OETForm = ({ name, ...props }) => {
    const OETForm = formMap[name]

    if (OETForm) return <OETForm {...{ name, ...props }} />

    console.log("OETForm Not Found", { name, ...props })
    return null
}
