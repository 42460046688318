/** @format */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { Button } from "@mui/material"
import { useTranslation } from "next-i18next"
import { useEffect, useState } from "react"
import { ErrorUI } from "../ErrorUI"

/**
 * A countdown timer to redirect from the 404 page
 */
const NotFoundCountdown = ({ queryValues }) => {
    const [timeout, setTimeout] = useState("")
    const [pause, setPause] = useState(false)
    const { t } = useTranslation()

    useEffect(() => {
        if (validateQueryValues(queryValues)) {
            setTimeout(queryValues.timeout)
        }
    }, [queryValues])

    useEffect(() => {
        const timer =
            timeout > 0 &&
            setInterval(() => {
                if (!pause) {
                    return setTimeout(timeout - 1)
                } else {
                    return setTimeout(timeout)
                }
            }, 1000)

        return () => {
            clearInterval(timer)
            if (timeout === 1) {
                window.location = decodeURIComponent(queryValues.redirect)
            }
        }
    }, [timeout, pause, queryValues.redirect])

    const validateQueryValues = (values) => {
        return values?.redirect?.length > 0 && values?.timeout > 0
    }

    return (
        <ErrorUI.Content>
            <ErrorUI.Heading>404</ErrorUI.Heading>
            <ErrorUI.Messages spacing={1.2} xs={6}>
                <ErrorUI.Message>
                    This page is not on our Maaco {t("country")} site.
                </ErrorUI.Message>
                <ErrorUI.Message>
                    If you meant to go to{" "}
                    <i>&quot;{queryValues.redirect}&quot;</i>, we&apos;ll
                    redirect you to that page in {timeout} seconds.
                </ErrorUI.Message>
                <ErrorUI.Message>
                    <Button
                        onClick={() => setPause(!pause)}
                        variant="contained"
                        color="secondary"
                        css={css`
                            padding: 0px;
                            margin: 0;
                        `}
                    >
                        {!pause ? "CANCEL" : "RESUME"}
                    </Button>
                </ErrorUI.Message>
            </ErrorUI.Messages>
        </ErrorUI.Content>
    )
}

export default NotFoundCountdown
