/** @format */
/** @jsxImportSource @emotion/react */

import { UTCDate } from "@date-fns/utc"
import { css } from "@emotion/react"
import { ArrowBackIos, ArrowForwardIos, Close } from "@mui/icons-material"
import { Box, Button, Grid, Modal, Typography } from "@mui/material"
import axios from "axios"
import { addDays, format, parse } from "date-fns"
import { zonedTimeToUtc } from "date-fns-tz"
import { useEffect, useState } from "react"

import {
    MOBILE_WIDTH,
    NEW_API_KEY,
    NEW_API_URL,
    reformatPhone
} from "../../../util"
import { handleCaughtError } from "../../../util/handlers"
import CustomerInquiryForm from "../../Forms/CustomerInquiryForm"
import ScheduleAppt from "./ScheduleAppt"
import Time from "./Time"

const Appointments = ({
    open,
    handleClose,
    location,
    endpoint,
    date = null,
    valueStore = "",
}) => {

    const [appData, setAppData] = useState([])
    const [openCustomerInquiryForm, setOpenCustomerInquiryForm] = useState(false)
    const [page, setPage] = useState(date ? "Schedule" : "Appt")
    const [selectedDate, setSelectedDate] = useState(
        date || {
            time: "",
            date: "",
            slot: "",
        }
    )
    const [dateRange, setDateRange] = useState(0)
    const [storeData, setStoreData] = useState({})

    const [windowObj, setWindowObj] = useState({
        innerWidth: MOBILE_WIDTH,
    })

    useEffect(() => {
        const setWinObj = () =>
            setWindowObj({
                innerWidth: window.innerWidth,
            })
        addEventListener("resize", setWinObj)
        setWinObj()
        return () => removeEventListener("resize", setWinObj)
    }, [])

    const mobileView = windowObj.innerWidth < MOBILE_WIDTH ? true : false

    useEffect(() => {
        if (!storeData || !storeData.storeState) return

        const config = {
            headers: {
                apikey: NEW_API_KEY,
            },
        }

        let newDate = format(addDays(new UTCDate(), dateRange), "yyyy-MM-dd")

        axios
            .get(
                `${NEW_API_URL}/sch/${location.data.storeId}?s=${newDate}`,
                config
            )
            .then((res) => {
                const result = []
                const storeTimeData = res.data

                if (!storeTimeData || !storeTimeData.length) {
                    setAppData(result)
                    return
                }

                const currentUserUtcDateTime = new UTCDate()
                const currentUserTimezone =
                    Intl.DateTimeFormat().resolvedOptions().timeZone
                const { timezone: storeTimezone } = storeData

                storeTimeData.forEach((item) => {
                    if (!item.times || !item.times.length) return

                    const currDate = item.date // this is already in the correct format
                    const storeClosingDateTimeString =
                        currDate + " " + item.times[item.times.length - 1].time

                    const storeClosingDateTime = parse(
                        storeClosingDateTimeString,
                        "yyyy-MM-dd hh:mm a",
                        new Date()
                    )
                    const storeClosingUtcDateTime = zonedTimeToUtc(
                        storeClosingDateTime,
                        storeTimezone || currentUserTimezone
                    )

                    if (currentUserUtcDateTime < storeClosingUtcDateTime) {
                        result.push(item)

                        return
                    }
                })

                setAppData(result)
            })
            .catch((e) => {
                handleCaughtError("Error at appointment api", e)
            })
    }, [open, dateRange, storeData, location.data.storeId])

    useEffect(() => {
        const config = {
            headers: {
                apikey: NEW_API_KEY,
            },
        }

        if (!location.data) return

        const id = location.data.storeId

        if (id) {
            axios
                .get(`${NEW_API_URL}/stores/${id}`, config)
                .then((res) => {
                    setStoreData(res.data[0])
                })
                .catch((e) => {
                    handleCaughtError("Error at store", e)
                })
        }
    }, [location])

    return (
        <>
            <Modal
                open={open}
                onClose={() => {
                    setPage("Appt")
                    setSelectedDate({
                        date: "",
                        time: "",
                        slot: "",
                    })
                    handleClose()
                    setDateRange(0)
                }}
                sx={{
                    overflowY: "scroll",
                }}
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: `${mobileView && page === "Schedule" ? "100%" : "50%"
                        }`,
                        left: "50%",
                        transform: `translate(-50%, -50%)`,
                        width: `${mobileView ? "95%" : "98vh"}`,
                        height: "auto",
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        pb: "1vh",
                    }}
                >
                    <Grid
                        container
                        sx={{
                            backgroundColor: "#AF0E11",
                        }}
                    >
                        <Grid
                            item
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >
                            <Typography
                                variant="h6"
                                css={css`
                                    color: #fff;
                                    min-height: 25.6px;
                                `}
                                sx={{ mt: "13px", ml: "13px" }}
                            >
                                {storeData.storeAddress &&
                                    `${storeData.storeAddress}, ${storeData.storeCity},  ${storeData.storeState}  ${storeData.storePostcode}`}
                            </Typography>
                        </Grid>
                        <Close
                            css={css`
                                cursor: pointer;
                                color: #fff;
                                position: absolute;
                                top: 10px;
                                right: 5px;
                            `}
                            onClick={() => {
                                setPage("Appt")
                                setSelectedDate({
                                    date: "",
                                    time: "",
                                    slot: "",
                                })
                                handleClose()
                                setDateRange(0)
                            }}
                        />
                    </Grid>
                    {page === "Appt" && (
                        <>
                            <Grid container alignItems="center" sx={{ ml: 2 }}>
                                <Typography
                                    variant="body1"
                                    css={css`
                                        margin: 10px;
                                        font-weight: bold;
                                        text-transform: uppercase;
                                        min-height: 24px;
                                    `}
                                >
                                    {storeData.storeCity &&
                                        `${storeData.storeCity},  ${storeData.storeState}  ${storeData.storePostcode}`}
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                            >
                                {appData.map((data, i) => {
                                    if (i >= 0 && i <= 3) {
                                        return (
                                            <Grid
                                                item
                                                xs={3}
                                                sx={{ m: 0, p: "3px" }}
                                                key={i}
                                            >
                                                {" "}
                                                <Time
                                                    key={i}
                                                    data={data}
                                                    setPage={setPage}
                                                    setSelectedDate={(date) =>
                                                        setSelectedDate(date)
                                                    }
                                                    selectedDate={selectedDate}
                                                    storeData={storeData}
                                                />{" "}
                                            </Grid>
                                        )
                                    }
                                })}
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                wrap="nowrap"
                            >
                                <Grid
                                    item
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    alignContent="center"
                                >
                                    <ArrowBackIos
                                        fontSize="medium"
                                        sx={{
                                            color: "#AF0E11",
                                            ml: 1,
                                        }}
                                    />
                                    <Button
                                        variant="text"
                                        sx={{
                                            color: "#595959",
                                            width: "115px !important",
                                            padding: 0,
                                            margin: 0,
                                            "&.Mui-disabled": {
                                                color: "#595959 !important",
                                            },
                                        }}
                                        disabled={dateRange === 0 ? true : false}
                                        onClick={() => {
                                            const newRange = dateRange - 4
                                            setDateRange(newRange)
                                            setSelectedDate({
                                                date: "",
                                                time: "",
                                            })
                                        }}
                                    >
                                        Earlier Dates
                                    </Button>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    alignContent="center"
                                >
                                    <Button
                                        xs={3}
                                        variant="text"
                                        sx={{
                                            color: "#595959",
                                            padding: 0,
                                            margin: 0,
                                        }}
                                        onClick={() => {
                                            const newRange = dateRange + 4
                                            setDateRange(newRange)
                                            setSelectedDate({
                                                date: "",
                                                time: "",
                                            })
                                        }}
                                    >
                                        More Dates
                                    </Button>
                                    <ArrowForwardIos
                                        sx={{ color: "#AF0E11", mr: 1 }}
                                        fontSize="medium"
                                    />
                                </Grid>
                            </Grid>
                            <div
                                css={css`
                                    width: 100%;
                                    height: 1px;
                                    background-color: #af0e11;
                                    margin: 16px 0px;
                                `}
                            ></div>
                            <Grid container justifyContent="center">
                                <Typography
                                    variant="body1"
                                    sx={{
                                        m: " 0 .7em .7em .7em",
                                    }}
                                >
                                    Not ready to book online?{" "}
                                    <Button
                                        variant="text"
                                        onClick={() => {
                                            setOpenCustomerInquiryForm(true)
                                        }}
                                        sx={{
                                            padding: 0,
                                            margin: 0,
                                            height: "2rem",
                                            "&.MuiButton-textPrimary": {
                                                padding: "0px !important",
                                                minWidth: "140px !important",
                                            },
                                        }}
                                    >
                                        CONTACT US
                                    </Button>
                                </Typography>
                            </Grid>
                        </>
                    )}
                    {page === "Schedule" && (
                        <ScheduleAppt
                            location={storeData}
                            mobileView={mobileView}
                            selectedDate={selectedDate}
                            valueStore={valueStore}
                            setSelectedDate={setSelectedDate}
                            setPage={setPage}
                            endpoint={endpoint === "/maaco/sch" ? "/maaco" : "/oet"}
                        />
                    )}
                    {page === "Complete" && (
                        <div
                            css={css`
                                padding: 8px;
                            `}
                        >
                            <Grid container justifyContent="flex-start">
                                <Grid item xs={6} container alignItems="flex-end">
                                    <Typography variant="body1">
                                        Date/Time:
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1" color="primary">
                                        {`${storeData.storeCity},  ${storeData.storeState}`}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="flex-start">
                                <Grid item xs={6}>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            fontWeight: 800,
                                        }}
                                    >
                                        {selectedDate.date}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            fontWeight: 800,
                                        }}
                                    >
                                        {`${reformatPhone(storeData.organicPhone)}`}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="flex-start">
                                <Grid item xs={6}>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            fontWeight: 800,
                                        }}
                                    >
                                        {selectedDate.time}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">
                                        {`${storeData.storeAddress}`}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container justifyContent="center">
                                <Typography
                                    align="center"
                                    variant="h5"
                                    sx={{
                                        color: "#7a841b",
                                        m: "15px",
                                    }}
                                >
                                    Your appointment was made successfully!
                                    <br />
                                    You will receive an email confirming your
                                    appointment details.
                                </Typography>
                                <Button
                                    variant="outlined"
                                    form="userInfo"
                                    color="primary"
                                    fullWidth
                                    sx={[
                                        {
                                            "&:hover": {
                                                color: "#fff !important",
                                                backgroundColor:
                                                    "#214e70 !important",
                                            },
                                        },
                                        {
                                            border: "1px solid #006bb7 !important",
                                            color: "#214e70 !important",
                                            backgroundColor: "#fff !important",
                                            margin: "6px 0px",
                                            pt: "0px !important",
                                            width: "270px",
                                        },
                                    ]}
                                    onClick={() => {
                                        setPage("Appt")
                                        setSelectedDate({
                                            date: "",
                                            time: "",
                                        })
                                        handleClose()
                                        setDateRange(0)
                                    }}
                                >
                                    Close this window
                                </Button>
                            </Grid>
                        </div>
                    )}
                </Box>
            </Modal>
            {openCustomerInquiryForm && (
                <CustomerInquiryForm
                    onClose={() =>
                        setOpenCustomerInquiryForm(false)
                    }
                    storeID={location.data.storeId}
                />
            )}
        </>
    )
}

export default Appointments
