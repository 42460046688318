// API_URL will be picked from the environment file based on the build command.

const { getEnvironmentVariable } = require("./util/env")

const getApiUrl = () => {
    const apiURL = getEnvironmentVariable({
        key: "API_URL",
    })

    return apiURL
}

module.exports = getApiUrl()
