/** @format */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { Typography } from "@mui/material"
import React from "react"

import Link from "../Link"

// <Link href="/services/auto-painting/">
//     <Typography
//         variant="navigationLabel"
//         color="primary"
//         css={css`
//             margin-left: 10px;
//         `}
//     ></Typography>
// </Link>

const Links = ({ links }) =>
    links.map(({ link, title, target }) => (
        <Link href={link} key={`link_${link}`} noLinkStyle>
            <Typography
                variant="navigationLabel"
                color="primary"
                css={css`
                    padding-bottom: 10px;
                    display: flex;
                    margin: 0;
                    font-weight: 700;
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                    @media (max-width: 767px) {
                        font-size: 1.3rem;
                    }
                `}
                component='p'
                title={title}
                target={target}
            >
                {title}
            </Typography>
        </Link>
    ))

export default Links
