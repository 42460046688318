import BookAnAppointment from "../Buttons/BookAnAppointment"
import ContactUs from "../Buttons/ContactUs"
import CustomButtonRounded from "../Buttons/CustomButtonRounded"
import { CouponEmailButton } from "../Buttons/CouponEmail"
import { CouponTextButton } from "../Buttons/CouponText"
import EstimateOnline from "../Buttons/EstimateOnline"
import LinkButton from "../Buttons/LinkButton"
import { PhoneNumberLink } from "../Buttons/PhoneNumberLink"

export const CtaButton = ({
    children,
    name,
    path,
    style,
    text,
    variant,
    ...props
}) => {
    const ctaButtonMap = {
        "Online Estimate Button - Secondary Filled": {
            Component: EstimateOnline,
        },
        "Book an Appointment Button - Primary Filled": {
            Component: BookAnAppointment,
        },
        "Custom Button Rounded": {
            Component: CustomButtonRounded,
        },
        "Global Coupon Email Button": {
            Component: CouponEmailButton,
        },
        "Global Coupon Text Button": {
            Component: CouponTextButton,
        },
        "Store Phone Number Link": {
            Component: PhoneNumberLink,
        },
        "Store - Book an Appointment Button": {
            Component: BookAnAppointment,
            Props: {
                shouldOpenAppointments: true,
            },
        },
        "Store - Contact Us Button": {
            Component: ContactUs,
        },
    }
    const ButtonType = ctaButtonMap[name]

    return (
        (ButtonType && (
            <ButtonType.Component
                {...{
                    children,
                    name,
                    path,
                    style,
                    text,
                    variant,
                    ...props,
                    ...ButtonType.Props,
                }}
            />
        )) || (
            <LinkButton
                {...{
                    style,
                    display: "inline-flex",
                    label: children || text,
                    linkURL: path || "#",
                    variant: variant,
                }}
            />
        )
    )
}
