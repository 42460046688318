// TODO: Use this method to integrate with error logging (Sentry or DataDog, etc)
const handleCaughtError = (message, error) => {
  const err = (error && error.toJSON && error.toJSON()) || error
  console.log(message, err)
}

const handleError = (error, isServerCall = true, returnValue = []) => {
  const err = (error && error.toJSON && error.toJSON()) || error
  console.log(err)

  if (isServerCall) {
    throw error
  }

  return returnValue
}

module.exports = {
  handleCaughtError,
  handleError
}