/** @format */
/** @jsxImportSource @emotion/react */
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar"
import EvStationIcon from "@mui/icons-material/EvStation"
import InterestsIcon from "@mui/icons-material/Interests"
import { CardActionArea } from "@mui/material"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormLabel from "@mui/material/FormLabel"
import Grid from "@mui/material/Grid"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { useState } from "react"
import { Controller, useFormContext } from "react-hook-form"

import { useDataLayerEvent } from "../../../../hooks/useDataLayerEvent"
import { OET_STEPS } from "../../../../util/globalOETVariables"
import OETMotorApiForm from "../../../Forms/OETMotorApiForm"
import { FormInput } from "../../../Forms/utils/form-elements"
import VINEntryForm from "../../../Forms/VINEntryForm"

export const VEHICLE_TYPES = {
    CAR: "car",
    EV: "ev",
    OTHER: "other",
}

const vehicleOptions = [
    {
        title: "Car, Truck, or SUV",
        type: VEHICLE_TYPES.CAR,
        icon: <DirectionsCarIcon fontSize="large" />,
    },
    {
        subtitle: "Car or truck",
        title: "Electric Vehicle (EV)",
        type: VEHICLE_TYPES.EV,
        icon: <EvStationIcon fontSize="large" />,
    },
    {
        subtitle: "We paint more than cars!",
        title: "Other",
        type: VEHICLE_TYPES.OTHER,
        icon: <InterestsIcon fontSize="large" />,
    },
]

const VehicleTypeCard = ({
    subtitle,
    title,
    type,
    icon,
    selected,
    setSelected,
}) => {
    return (
        <Card
            sx={{
                height: 150,
                width: 206,
                backgroundColor: selected
                    ? "rgba(33, 150, 243, .12)"
                    : undefined,
                border: selected
                    ? "1px solid rgba(33, 150, 243, .5)"
                    : undefined,
            }}
        >
            <CardActionArea
                sx={{ height: "100%" }}
                onClick={() => setSelected(type)}
            >
                <CardContent sx={{ boxSizing: "border-box", height: "100%" }}>
                    <Stack
                        justifyContent="flex-end"
                        spacing={1}
                        sx={{ height: "100%" }}
                    >
                        {selected && (
                            <CheckCircleOutlineIcon
                                sx={{
                                    color: "#2196F3",
                                    position: "absolute",
                                    top: 16,
                                    left: 16,
                                }}
                            />
                        )}
                        <Stack spacing={1} alignItems="center">
                            {icon}
                            <Stack alignItems="center">
                                <Typography variant="body1">{title}</Typography>
                                {subtitle && (
                                    <Typography
                                        variant="caption"
                                        lineHeight="1.2"
                                        fontSize=".6rem"
                                    >
                                        {subtitle}
                                    </Typography>
                                )}
                            </Stack>
                        </Stack>
                    </Stack>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

const VehicleInfo = () => {
    const [vehicleState, setVehicleState] = useState({})
    const [vehicleType, setVehicleType] = useState(VEHICLE_TYPES.CAR)

    const {
        control,
        formState: { errors, isDirty, touchedFields },
        setValue,
        watch,
    } = useFormContext()

    const hasVinNumber = watch("hasVinNumber")
    const notes = watch("notes")
    const year = watch("year")
    const make = watch("make")
    const model = watch("model")

    useDataLayerEvent({
        step: OET_STEPS.vehicleInfo,
        dataLayerEventTrigger: isDirty && !!Object.keys(touchedFields).length,
    })

    const handleSelectionChange = (selection) => {
        if (selection !== VEHICLE_TYPES.OTHER && !!notes?.length) {
            setValue("notes", "")
        }

        if (selection === VEHICLE_TYPES.OTHER && year && make && model) {
            setValue("year", "")
            setValue("make", "")
            setValue("model", "")
        }

        setVehicleType(selection)
        setValue("vehicleType", selection)
    }

    return (
        <div id="vehicleInfo">
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                spacing={{ xs: 2, sm: 2, md: 4 }}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    spacing={3}
                    item
                    xs={12}
                    sx={{ mb: 4 }}
                >
                    {vehicleOptions.map(({ subtitle, title, type, icon }) => (
                        <Grid item key={title}>
                            <VehicleTypeCard
                                icon={icon}
                                selected={vehicleType === type}
                                setSelected={handleSelectionChange}
                                subtitle={subtitle}
                                title={title}
                                type={type}
                            />
                        </Grid>
                    ))}
                </Grid>
                {vehicleType !== VEHICLE_TYPES.OTHER && (
                    <>
                        <Grid item xs={12}>
                            <OETMotorApiForm
                                vehicleState={vehicleState}
                                setVehicleState={setVehicleState}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormLabel id="vin-number-label" sx={{ mb: 1 }}>
                                <Typography variant="h6">
                                    Would you prefer to use the VIN number to
                                    provide your vehicle information?
                                </Typography>
                            </FormLabel>

                            <Controller
                                name="hasVinNumber"
                                control={control}
                                render={({ field: { onChange, ...field } }) => (
                                    <RadioGroup
                                        {...field}
                                        aria-labelledby="vin-number-label"
                                        onChange={(_, data) =>
                                            onChange(JSON.parse(data))
                                        }
                                        row
                                    >
                                        <FormControlLabel
                                            value={true}
                                            control={<Radio />}
                                            label="Yes"
                                        />
                                        <FormControlLabel
                                            value={false}
                                            control={<Radio />}
                                            label="No, I’ll enter vehicle details manually"
                                        />
                                    </RadioGroup>
                                )}
                            />

                            <Typography variant="body1" color="error">
                                {errors.hasVinNumber?.message}
                            </Typography>
                        </Grid>
                        {hasVinNumber && <VINEntryForm />}
                    </>
                )}
                {vehicleType === VEHICLE_TYPES.OTHER && (
                    <Grid item xs={12}>
                        <Stack
                            sx={{
                                py: 2,
                                px: 3,
                                backgroundColor: "#E2F1FF",
                                borderRadius: "5px",
                            }}
                        >
                            <FormInput
                                characterCount={150}
                                name="notes"
                                labelText="What item do you want painted?*"
                                labelId="other-input"
                                variant="outlined"
                            />
                        </Stack>
                    </Grid>
                )}
            </Grid>
        </div>
    )
}

export default VehicleInfo
