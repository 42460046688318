/** @format */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { Search } from "@mui/icons-material"
import {
    Button,
    Grid,
    InputAdornment,
    OutlinedInput,
    Typography,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import {
    Autocomplete,
    GoogleMap,
    InfoWindow,
    Marker,
    useJsApiLoader,
} from "@react-google-maps/api"
import { useTranslation } from "next-i18next"
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react"
import Geocode from "react-geocode"

import { Context, OetContext } from "../../context"
import { GOOGLE_MAP_API_KEY } from "../../util"
import { handleCaughtError } from "../../util/handlers"
import Link, { NextLinkComposed } from "../Link"

// Use styles hook
const useStyles = makeStyles((theme) => ({
    mapInput: {
        marginTop: "1rem",
        marginBottom: "1rem",
    },
    distance: {
        color: "#808080",
    },
    directions: {
        color: "#D40E00",
        textDecoration: "underline",
    },
    separator: {
        height: 1,
        backgroundColor: "#eee",
        width: "100%",
        marginTop: "1rem",
        marginBottom: "1rem",
    },
    select: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
    },
    noStores: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    title: {
        textTransform: "capitalize",
    },
}))

// Default map container styling
const containerStyle = {
    width: "100%",
    height: "100%",
    position: "relative",
    overflow: "hidden",
    top: 0,
}

const GoogleMapLocation = ({
    hideAutoComplete = true,
    hideStores = true,
    options = {
        streetViewControl: false,
        mapTypeControl: false,
        zoomControl: false,
        fullscreenControl: false,
    },
    showInfo = true,
    zoom,
    showAllStores = false,
}) => {
    const { state, dispatch } = useContext(Context)
    const { content: { dmaName, msoName } = {}, currentStore } = state
    const stores = useMemo(() => state?.stores ?? [], [state.stores])
    const dmaMsoPage = dmaName || msoName || (showAllStores && !currentStore)

    // get the styles
    const classes = useStyles()
    const { t } = useTranslation()

    // User pin location
    // Default center of map on load
    const center = t("country_center_lat_long")
    const [centerLatLng, setCenterLatLng] = useState(center)
    const [centerAddress, setCenterAddress] = useState(null)
    // Initializing store if current is available
    // This is only being used for the info window
    const [selectedStore, setSelectedStore] = useState(currentStore)

    // Makes sure map is loaded using useJsApiLoader
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: GOOGLE_MAP_API_KEY,
    })

    // Set the initial ip address as a location fallback
    let ip = null

    // Get the input from address with react geo code
    Geocode.setApiKey(GOOGLE_MAP_API_KEY)

    // State to hold the map instance
    const [map, setMap] = useState(null)

    // Set reference to input field
    const autocompleteInput = useRef(null)

    // State to hold autocomplete data
    const [autoComplete, setAutoComplete] = useState(null)

    // Ensuring the map zoom and center is based on the store marker locations
    // This functionality is only needed for DMA and MSO pages
    // Added this functionality for OET
    const getBounds = useCallback(() => {
        const bounds = new google.maps.LatLngBounds()

        stores.forEach((store) => {
            const { storeLat, storeLong } = store
            bounds.extend(new google.maps.LatLng(storeLat, storeLong))
        })

        map?.fitBounds(bounds)
    }, [map, stores])

    // Setting center coordinates based on currentStore changes
    const setMapCenter = (selection) => {
        if (!selection) return

        const arr = []

        const longitude = selection.storeLong || selection.longitude
        const latitude = selection.storeLat || selection.latitude

        if (longitude && latitude) {
            // setting selected store for info window

            setSelectedStore(selection)
            setCenterAddress(null)
            setCenterLatLng({
                lat: latitude,
                lng: longitude,
            })
            return
        }

        const city = selection.storeCity || selection.locationCity
        if (city) {
            arr.push(city)
        }

        const state = selection.storeState || selection.locationState
        if (state) {
            arr.push(state)
        }

        const postalCode =
            selection.storePostcode || selection.locationPostalCode
        if (postalCode) {
            arr.push(postalCode)
        }

        if (selection.storeCountry) {
            arr.push(selection.storeCountry)
        }

        const centerLocation = arr.join(" ").toString()
        // setting selected store for info window
        setSelectedStore(selection)
        setCenterLatLng(null)
        setCenterAddress(centerLocation)
    }

    useEffect(() => {
        if (map) {
            if (dmaMsoPage) {
                getBounds()
            } else if (stores?.length && (centerLatLng || centerAddress)) {
                if (centerLatLng) {
                    const { lat, lng } = centerLatLng

                    map.setCenter({
                        lat,
                        lng,
                    })
                    map.setZoom(zoom || 12)
                    setMap(map)
                } else if (centerAddress) {
                    Geocode.fromAddress(centerAddress)
                        .then((response) => {
                            const { lat, lng } =
                                response.results[0].geometry.location
                            // Set the map to the new location in autocomplete
                            map.setCenter({
                                lat,
                                lng,
                            })
                            map.setZoom(zoom || 10)
                            setMap(map)
                        })
                        .catch((err) => {
                            handleCaughtError(
                                "Error getting Google geocode from address ",
                                err
                            )
                        })
                }
            } else {
                map.setCenter(center)
                map.setZoom(zoom || 4)
            }
        }
    }, [
        center,
        centerLatLng,
        centerAddress,
        dmaMsoPage,
        getBounds,
        map,
        stores,
        zoom,
    ])

    useEffect(() => {
        if (!currentStore) {
            setSelectedStore(null)
        }

        setMapCenter(currentStore)
    }, [currentStore])

    // On loading of the map only
    const onLoad = useCallback(function callback(mapInstance) {
        setMap(mapInstance)
    }, [])

    // On loading of the map only
    const autocompleteOnLoad = useCallback(function callback(autocomplete) {
        setAutoComplete(autocomplete)
    }, [])

    // When the component unmounts, set the map to null
    const onUnmount = useCallback(function callback(map) {
        setMap(null)
    }, [])

    // Handle changing the location in the search box
    const onPlaceChanged = () => {
        // Use the google api first for address specific
        if (autoComplete.getPlace().geometry !== undefined) {
            const lat = autoComplete.getPlace().geometry.location.lat()
            const lng = autoComplete.getPlace().geometry.location.lng()

            // Set the map to the new location in autocomplete
            map.setCenter({
                lat,
                lng,
            })
        } else {
            // Fallback to geocode api for non address specific locations (landmarks, etc.)
            Geocode.fromAddress(autocompleteInput.current.value)
                .then((response) => {
                    const { lat, lng } = response.results[0].geometry.location

                    // Set the map to the new location in autocomplete
                    map.setCenter({
                        lat,
                        lng,
                    })
                })
                .catch((err) => {
                    handleCaughtError(
                        "Error getting Google geocode from address ",
                        err
                    )
                })
        }
    }

    const handleStoreSelection = (store, state, dispatch) => {
        dispatch({
            type: "LOADING",
            payload: true,
        })
        dispatch({
            type: "CURRENT_USER",
            payload: {
                ...state.currentUser,
                store,
                storeId: parseInt(store.storeId),
            },
        })
        localStorage.setItem(
            "currentUser",
            JSON.stringify({
                ...state.currentUser,
                store,
                storeId: parseInt(store.storeId),
            })
        )
        if (state.expressCheckout.order_id) {
            dispatch({
                type: "PRODUCT_STEP",
                payload: 5,
            })
        }
    }

    const selectStore = (store) => {
        dispatch({ type: "CURRENT_STORE", payload: store })
    }

    if (!isLoaded) return null

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            css={css`
                height: 100%;
            `}
        >
            <Grid
                item
                xs={12}
                css={css`
                    height: 100%;
                `}
            >
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={centerLatLng || centerAddress}
                    zoom={zoom || 11}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                    options={options}
                >
                    {stores && stores.length
                        ? stores.map((store, key) => (
                            <Marker
                                key={key}
                                position={{
                                    lat: store.storeLat || store.latitude,
                                    lng: store.storeLong || store.longitude,
                                }}
                                animation="bounce"
                                clickable={true}
                                optimized={false}
                                title={store.storeName}
                                onClick={() => selectStore(store)}
                            >
                                {showInfo &&
                                    selectedStore &&
                                    selectedStore.geoAddress ===
                                    store.geoAddress && (
                                        <InfoWindow
                                            onCloseClick={() =>
                                                selectStore(null)
                                            }
                                            position={{
                                                lat:
                                                    store.storeLat ||
                                                    store.latitude,
                                                lng:
                                                    store.storeLong ||
                                                    store.longitude,
                                            }}
                                            options={{
                                                pane: "mapPane",
                                                //pixelOffset: new window.google.maps.Size(-130, 25),
                                                boxStyle: {
                                                    width: "150px",
                                                },
                                                closeBoxURL: ``,
                                            }}
                                        >
                                            <Grid container>
                                                <Grid>
                                                    {/* TODO should this be a link? */}
                                                    <Typography
                                                        component={"h6"}
                                                        css={css`
                                                              color: #095081 !important;
                                                              font-size: 1rem !important;
                                                              font-weight: bold !important;
                                                              margin-bottom: 0 !important;
                                                          `}
                                                    >
                                                        {selectedStore.storeName ||
                                                            selectedStore
                                                                ?.extra
                                                                ?.Center_Name ||
                                                            `Maaco ${selectedStore.storeCity}`}
                                                    </Typography>
                                                    <Typography
                                                        display="block"
                                                        variant="body1"
                                                        css={css`
                                                              color: #595959;
                                                              font-size: 0.9rem;
                                                          `}
                                                    >
                                                        {
                                                            selectedStore.storeAddress
                                                        }
                                                        <br />
                                                        {`${selectedStore.storeCity}, ${selectedStore.storeState} ${selectedStore.storePostcode}`}
                                                    </Typography>
                                                    <Typography
                                                        css={css`
                                                              color: #095081;
                                                          `}
                                                        display="block"
                                                        variant="body1"
                                                        component="span"
                                                    >
                                                        <Link
                                                            href={`https://www.google.com/maps/dir/?api=1&destination=${store.storeAddress},${store.storeCity},${store.storeState}`}
                                                        >
                                                            Get Directions
                                                        </Link>
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </InfoWindow>
                                    )}
                            </Marker>
                        ))
                        : null}
                </GoogleMap>

                {!hideAutoComplete && (
                    <Autocomplete
                        className={`locationsSearchbox`}
                        onLoad={autocompleteOnLoad}
                        onPlaceChanged={onPlaceChanged}
                    >
                        <>
                            <OutlinedInput
                                inputRef={autocompleteInput}
                                fullWidth
                                placeholder={t("google_search")}
                                className={classes.mapInput}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <Search
                                            style={{
                                                color: "rgba(0, 0, 0, 0.23)",
                                            }}
                                        />
                                    </InputAdornment>
                                }
                            />
                        </>
                    </Autocomplete>
                )}
            </Grid>
            {!hideStores &&
                (stores && stores.length ? (
                    stores.map((store, key) => {
                        let days = [
                            "Sunday",
                            "Monday",
                            "Tuesday",
                            "Wednesday",
                            "Thursday",
                            "Friday",
                            "Saturday",
                        ]
                        let date = new Date()
                        let day = days[date.getDay()]
                        return (
                            <Grid container key={key}>
                                <Grid item xs={6}>
                                    <Typography
                                        variant="h6"
                                        color="primary"
                                        className={classes.title}
                                    >
                                        {store.storeCity.toLowerCase()}
                                    </Typography>
                                    <Typography
                                        display="block"
                                        variant="body1"
                                        className={classes.title}
                                    >
                                        {store.storeAddress.toLowerCase()}
                                    </Typography>
                                    <Typography
                                        display="block"
                                        variant="body1"
                                        className={classes.title}
                                    >
                                        {`${store.storeCity.toLowerCase()}, ${store.storeState.toLowerCase()} ${store.storePostcode
                                            }`}
                                    </Typography>
                                    <Typography display="block" variant="body1">
                                        {`Open until ${store.hours &&
                                            store.hours[day].close
                                            }`}
                                    </Typography>
                                    {store.distance && (
                                        <Typography
                                            display="inline"
                                            variant="body1"
                                            className={classes.distance}
                                        >
                                            {`${Number.parseFloat(
                                                store.distance
                                            ).toFixed(2)} mi away `}
                                        </Typography>
                                    )}
                                    <Typography
                                        display="inline"
                                        variant="body1"
                                        className={classes.directions}
                                    >
                                        Get Directions
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} className={classes.select}>
                                    <Button
                                        LinkComponent={NextLinkComposed}
                                        to="/product-selection"
                                        onClick={() =>
                                            handleStoreSelection(
                                                store,
                                                state,
                                                dispatch
                                            )
                                        }
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                    >
                                        SELECT
                                    </Button>
                                </Grid>
                                <div className={classes.separator}></div>
                            </Grid>
                        )
                    })
                ) : (
                    <Grid item xs={12} className={classes.noStores}>
                        <Typography variant="h6" color="primary">
                            No Stores
                        </Typography>
                    </Grid>
                ))}
        </Grid>
    )
}

export default GoogleMapLocation
