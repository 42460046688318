import { Button } from "@mui/material"
import { useContext, useState } from "react"

import { OetContext } from "../../../../context"
import { dataLayer, GTM_EVENTS } from "../../../../util"
import Appointments from "../../../Estimate/Appointments"

const EstimateAppointmentButton = ({ width = "100%" }) => {
    const { state } = useContext(OetContext)
    const [open, setOpen] = useState(false)

    const handleOpen = () => {
        dataLayer({
            event: GTM_EVENTS.appointmentCtaClick,
            gtm_storeid: state?.location?.storeId,
            gtm_dma: state?.location?.dma,
            gtm_pagerefsource: window.document.referrer,
        })
        setOpen(true)
    }
    const handleClose = () => setOpen(false)

    return (
        <>
            <Button
                variant="contained"
                color="secondary"
                sx={{
                    mt: 2,
                    width: { xs: "100%", md: width },
                }}
                onClick={handleOpen}
            >
                Make an Appointment
            </Button>

            <Appointments
                handleClose={handleClose}
                open={open}
                valueStore="vehicleInfo"
                location={{
                    data: state.location,
                }}
                setOpen={setOpen}
                endpoint="/sch"
            />
        </>
    )
}

export default EstimateAppointmentButton
