import axios from "axios"
import { debounceBy } from "../../util/debounceBy"

/**
 * @typedef ErrorReportPayload
 * @property {string | undefined} error The string representation of the error
 * @property {string | undefined} message The error message
 * @property {string | undefined} stack The error stack trace
 */

/**
 * Sends an error report with the given payload
 * TODO: implement proper error reporting service (e.g. Sentry) here
 *
 * @param {ErrorReportPayload} errorPayload
 */
export const reportError = (errorPayload) =>
    axios
        .post("/api/error-report", errorPayload)
        .then((res) => {
            console.log(res?.data?.message)
        })
        .catch((err) => {
            console.error("Unable to report error: ", err)
        })

/**
 * @typedef {Object} ErrorDetails
 * @property {Error} error The error object
 * @property {ErrorReportPayload} reportPayload Error report payload constructed
 * from the error
 */

/**
 * Extracts information from the given error or Promise rejection event
 *
 * @param {ErrorEvent | PromiseRejectionEvent} event
 * @returns {ErrorDetails} Object containing details about the event's error
 */
function getEventErrorDetails(event) {
    /** Can be either an `ErrorEvent` or a `PromiseRejectionEvent` */
    const error = event.error ?? event.reason

    // Since the `error` property can be `null`, derive a message from the
    // event itself if necessary
    const message = error?.message ?? event?.message

    const reportPayload = {
        error: error?.toString(),
        message,
        stack: error?.stack,
    }

    return { error, reportPayload }
}

/**
 * Handles both errors and unhandled promise rejections
 *
 * Debounce of 1 second applied to each error type to avoid spamming the error
 * reporting endpoint with ResizeObserver errors, which seem to originate from
 * Vercel's preview deployment commenting features and occur in high volume
 */
export const handleWindowError = debounceBy(
    (event) => {
        const { error, reportPayload } = getEventErrorDetails(event)

        console.error(error)
        reportError(reportPayload)
    },
    // Errors with different unique combinations of message+stack will be
    // debounced separately
    (event) => {
        const { error, message, stack } =
            getEventErrorDetails(event).reportPayload

        return `${error} ${message} ${stack}`
    },
    1000
)
