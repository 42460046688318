import { Grid, useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/styles"
import GoogleMapLocation from "../GoogleMapLocation"

export const Map = ({
    height,
    hideOnMobile,
    mapTypeControl = false,
    ...props
}) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

    if (isMobile && hideOnMobile) return null

    return (
        <Grid item height={(height && `${height}px`) || "100%"}>
            <GoogleMapLocation
                {...props}
                options={{
                    streetViewControl: false,
                    mapTypeControl,
                    zoomControl: true,
                    fullscreenControl: true,
                }}
            />
        </Grid>
    )
}
