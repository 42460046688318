import { useCallback, useContext, useEffect, useMemo, useRef } from "react"

import { OetContext } from "../context"
import { dataLayer, GTM_EVENTS } from "../util"

export function useDataLayerEvent({ step, dataLayerEventTrigger }) {
    const { state } = useContext(OetContext)
    const stepSubmitted = useRef()
    // USE MEMO FOR EMAIL AND LOCATION, AS THESE VALUES DO NOT CHANGE
    // UNLESS A USER GOES BACK IN OET AND UPDATES THEM
    const oet_email = useMemo(
        () =>
            state?.userInfo?.email ? { oet_email: state?.userInfo?.email } : {},
        [state?.userInfo?.email]
    )
    const location = useMemo(() => state?.location, [state?.location])

    const handleDataLayerEvent = useCallback(() => {
        // PREVENT DUPLICATION
        if (stepSubmitted.current !== step) {
            stepSubmitted.current = step

            dataLayer({
                event: GTM_EVENTS.oetStepStarted,
                ...oet_email,
                gtm_storeid: location?.storeId ?? "",
                gtm_pagerefsource: window.document.referrer,
                gtm_dma: location?.dma ?? "",
                gtm_stepname: state?.estimateStep,
            })
        }
    }, [oet_email, location, state?.estimateStep, step])

    useEffect(() => {
        if (dataLayerEventTrigger) {
            handleDataLayerEvent()
        }
    }, [dataLayerEventTrigger, handleDataLayerEvent])
}
