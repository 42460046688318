/** @format */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import {
    Box,
    Checkbox,
    FormControlLabel,
    FormLabel,
    Grid,
    ListItemText,
    MenuItem,
    Radio,
    RadioGroup,
    Stack,
    Select,
    TextField,
    Typography,
} from "@mui/material"
import { Controller, useFormContext } from "react-hook-form"

import { useDataLayerEvent } from "../../../../hooks/useDataLayerEvent"
import { OET_STEPS } from "../../../../util/globalOETVariables"
import { reformatPhone } from "../../../../util"
import { RichText } from "../../../contentful"
import { generateLabel } from "../../../Forms/utils/form-elements"

const contactTimes = [
    { label: "Mornings (9AM-12pm)", value: "9:00 AM - 12:00 PM" },
    { label: "Lunch Time (12PM-1PM)", value: "12:00 PM - 1:00 PM" },
    { label: "Afternoons (1PM-5PM)", value: "1:00 PM - 5:00 PM" },
    { label: "No Preference", value: "noPreference" },
]

const renderSelected = (selected) =>
    contactTimes
        .filter(({ value }) => selected.includes(value))
        .map(({ label }) => label)
        .join(", ")

const UserInfo = ({
    legalDisclaimer = "",
    makeDisclaimerAFormField = false,
}) => {
    const {
        control,
        register,
        getValues,
        setValue,
        formState: { errors, isDirty, touchedFields },
        watch,
    } = useFormContext()

    useDataLayerEvent({
        step: OET_STEPS.yourInfo,
        dataLayerEventTrigger: isDirty && !!Object.keys(touchedFields).length,
    })

    const { preferredMethod } = getValues()
    const preferredTime = watch("preferredTime")
    const isEmailPreferred = preferredMethod === "email"

    const onChangeFormHandler = (event) => {
        const name = event.target.getAttribute("name")

        let value = event.target.value
        if (name === "phone" && value.length > 1) {
            value = reformatPhone(value)
        }
        setValue(name, value, {
            shouldValidate: true,
            shouldDirty: true,
        })
    }

    const checkboxGenerator = () => {
        if (!legalDisclaimer) {
            return <></>
        }
        return (
            <Grid
                container
                item
                xs={12}
                sx={{ py: 4, px: { sm: 0, md: 4 }, flexWrap: "nowrap" }}
            >
                {!!makeDisclaimerAFormField && (
                    <Grid item>
                        <Controller
                            defaultValue={false}
                            name="contactByText"
                            control={control}
                            render={({ field: props }) => (
                                <Checkbox
                                    css={css`
                                        padding: 0 10px 0 0;
                                    `}
                                    {...props}
                                    checked={props.value}
                                    onChange={(e) =>
                                        props.onChange(e.target.checked)
                                    }
                                />
                            )}
                        />
                    </Grid>
                )}
                <Grid item container>
                    <RichText {...legalDisclaimer} />
                    {errors?.contactByText && (
                        <Grid item sx={{ pt: 1 }}>
                            <Typography variant="body1" color="error">
                                {errors.contactByText.message}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid container spacing={{ xs: 2, md: 4 }}>
            <Grid item sm={6} xs={12}>
                {generateLabel("First Name*", "first-name")}

                <TextField
                    {...register("firstName")}
                    fullWidth
                    size="small"
                    id="first-name"
                    name="firstName"
                    type="text"
                    onChange={(e) => onChangeFormHandler(e)}
                />

                {errors.firstName && (
                    <Typography variant="body1" color="error">
                        {errors.firstName.message}
                    </Typography>
                )}
            </Grid>

            <Grid item sm={6} xs={12}>
                {generateLabel("Last Name*", "last-name")}

                <TextField
                    {...register("lastName")}
                    fullWidth
                    size="small"
                    id="last-name"
                    name="lastName"
                    type="text"
                    onChange={(e) => onChangeFormHandler(e)}
                />

                {errors.lastName && (
                    <Typography variant="body1" color="error">
                        {errors.lastName?.message}
                    </Typography>
                )}
            </Grid>

            <Grid item xs={12}>
                <FormLabel id="preferred-contact-method-label" sx={{ mb: 1 }}>
                    <Typography fontFamily="Gilroy Medium" variant="body1">
                        Preferred Method of Contact
                    </Typography>
                </FormLabel>

                <Controller
                    name="preferredMethod"
                    control={control}
                    render={({ field: { onChange, ...field } }) => (
                        <RadioGroup
                            {...field}
                            aria-labelledby="preferred-contact-method-label"
                            onChange={(e) => onChangeFormHandler(e)}
                            row
                        >
                            <FormControlLabel
                                value="call"
                                control={<Radio />}
                                label="Call Me"
                            />
                            <FormControlLabel
                                value="text"
                                control={<Radio />}
                                label="Text Me"
                            />
                            <FormControlLabel
                                value="email"
                                control={<Radio />}
                                label="Email Me"
                            />
                        </RadioGroup>
                    )}
                />

                {errors.preferredMethod && (
                    <Typography variant="body1" color="error">
                        {errors.preferredMethod?.message}
                    </Typography>
                )}
            </Grid>

            {preferredMethod.length > 0 && (
                <Grid item xs={12}>
                    <Stack
                        sx={{
                            backgroundColor: "#E2F1FF",
                            borderRadius: "5px",
                            paddingX: 3,
                            paddingBottom: 2,
                        }}
                    >
                        <Box
                            sx={{
                                order: isEmailPreferred ? 2 : null,
                                display: "flex",
                                gap: {
                                    xs: 0,
                                    md: 4,
                                },
                                flexDirection: {
                                    xs: "column",
                                    sm: "column",
                                    md: "row",
                                },
                            }}
                        >
                            <Box sx={{ width: "100%" }}>
                                {generateLabel(
                                    isEmailPreferred
                                        ? "Phone Number (We need this for our records, but will contact you via email)"
                                        : "Phone Number*",
                                    "phone"
                                )}

                                <TextField
                                    {...register("phone")}
                                    fullWidth
                                    size="small"
                                    id="phone"
                                    name="phone"
                                    type="text"
                                    onChange={(e) => onChangeFormHandler(e)}
                                />

                                {errors.phone && (
                                    <Typography variant="body1" color="error">
                                        {errors.phone?.message}
                                    </Typography>
                                )}
                            </Box>

                            {preferredMethod !== "email" && (
                                <Box sx={{ width: "100%" }}>
                                    {generateLabel(
                                        "What time of day works best for you?*",
                                        "preferred-time-label",
                                        true
                                    )}

                                    <Controller
                                        name="preferredTime"
                                        id="preferredTime"
                                        control={control}
                                        render={({
                                            field: { onChange, ...field },
                                        }) => (
                                            <Select
                                                {...field}
                                                labelId="preferred-time-label"
                                                onChange={(e) => {
                                                    onChange(e.target.value)
                                                }}
                                                renderValue={renderSelected}
                                                fullWidth
                                                multiple
                                            >
                                                {contactTimes.map(
                                                    ({ label, value }) => (
                                                        <MenuItem
                                                            value={value}
                                                            key={`preferredTime-${label}`}
                                                            disabled={
                                                                (preferredTime?.length >
                                                                    0 &&
                                                                    value ===
                                                                        "noPreference" &&
                                                                    !preferredTime?.includes(
                                                                        "noPreference"
                                                                    )) ||
                                                                (preferredTime?.includes(
                                                                    "noPreference"
                                                                ) &&
                                                                    value !==
                                                                        "noPreference")
                                                            }
                                                        >
                                                            <Checkbox
                                                                checked={
                                                                    preferredTime?.indexOf(
                                                                        value
                                                                    ) > -1
                                                                }
                                                            />
                                                            <ListItemText
                                                                primary={
                                                                    <Typography
                                                                        variant="body1"
                                                                        component="span"
                                                                        css={css`
                                                                            padding-left: 10px;
                                                                            padding-top: 5px;
                                                                        `}
                                                                    >
                                                                        {label}
                                                                    </Typography>
                                                                }
                                                            />
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        )}
                                    />

                                    {errors.preferredTime && (
                                        <Typography
                                            variant="body1"
                                            color="error"
                                        >
                                            {errors.preferredTime?.message}
                                        </Typography>
                                    )}
                                </Box>
                            )}
                        </Box>

                        <Grid
                            sx={{
                                order: isEmailPreferred ? 1 : null,
                            }}
                        >
                            {generateLabel(
                                isEmailPreferred
                                    ? "Email*"
                                    : "Email (We need this for our records, but will contact you via a phone call)",
                                "email"
                            )}

                            <TextField
                                {...register("email")}
                                fullWidth
                                size="small"
                                id="email"
                                name="email"
                                type="text"
                                onChange={(e) => onChangeFormHandler(e)}
                            />

                            {errors.email && (
                                <Typography variant="body1" color="error">
                                    {errors.email?.message}
                                </Typography>
                            )}
                        </Grid>
                    </Stack>
                    {checkboxGenerator()}
                </Grid>
            )}
        </Grid>
    )
}

export default UserInfo
