import { useContext, useMemo, useCallback } from "react"

import { OetContext } from "../../../context"
import { getFormSchema } from "../utils/form-schema"
import { BaseForm } from "./form-base"

// Use this component in order for nested forms to gain react-hook-form context
export const FormWrapper = ({
    formName,
    handleFormSubmit,
    resetValues = false,
    children,
}) => {
    const { state } = useContext(OetContext)

    const formValues = useMemo(() => {
        return getFormSchema({ state, formName })
    }, [formName, state])

    const handleFormSubmission = useCallback(
        (data) => {
            handleFormSubmit(data)
        },
        [handleFormSubmit]
    )

    return (
        <BaseForm
            defaultValues={formValues?.defaultValues}
            formName={formName}
            handleFormSubmit={handleFormSubmission}
            resetValues={resetValues}
            schema={formValues?.schema}
        >
            {children}
        </BaseForm>
    )
}
