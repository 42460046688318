const axios = require("axios")

const { handleError } = require("./handlers")
const { NEW_API_URL, NEW_API_KEY, couponPayload } = require(".")

const COUPON_API = {
    ////////////////////////////////////////
    // SEND STANDARD COUPON (WITH STORE ID)
    ////////////////////////////////////////
    sendStandardCoupon: async (couponData) => {
        const {
            storeId,
            firstName,
            lastName,
            isOetCoupon,
            leadId,
            preferredMethod,
            email,
            phone,
            coupon,
            visitedUrl,
            userIp,
        } = couponData

        try {
            const { data } = await axios.post(
                `${NEW_API_URL}/stand-offer/assign-formatted`,
                {
                    storeId,
                    firstName,
                    lastName,
                    isOetCoupon,
                    leadId,
                    email: preferredMethod === "email" ? email : "",
                    phone:
                        preferredMethod === "text" || preferredMethod === "call"
                            ? `1${phone.replace(/[^A-Z0-9]/gi, "")}`
                            : "",
                    offers: [couponPayload(coupon.coupon.fields)],
                    visitedUrl,
                    userIp,
                },
                {
                    headers: {
                        apikey: NEW_API_KEY,
                        session_id: sessionStorage.getItem("sessionId"),
                    },
                }
            )

            return data
        } catch (error) {
            return handleError(error)
        }
    },
    ////////////////////////////////////////
    // SEND PROMOTION COUPON (NO STORE ID)
    ////////////////////////////////////////
    sendPromotionCoupon: async (couponData) => {
        try {
            const { data } = await axios.get(
                `${NEW_API_URL}/offer/assign-formatted`,
                couponData,
                {
                    headers: {
                        apikey: NEW_API_KEY,
                        session_id: sessionStorage.getItem("sessionId"),
                    },
                }
            )

            return data.length > 0 ? data[0] : data
        } catch (error) {
            return handleError(error)
        }
    },
}

module.exports = {
    COUPON_API,
}
