/** @jsxImportSource @emotion/react */
/** @format */

import { Section } from "."

export const ServicesHero = ({
    subheadingColumnOne,
    subheadingColumnThree,
    actionsColumnOneCollection = {},
    iconTextCollection = {},
    actionsColumnThreeCollection = {},
    ...props
}) => {
    let {
        actionsColumnOne,
        headingColumnOne = {},
        headingColumnThree = {},
        iconText,
        actionsColumnThree,
    } = props
    actionsColumnOne =
        actionsColumnOne || actionsColumnOneCollection.items || []
    headingColumnOne = headingColumnOne.fields || headingColumnOne
    headingColumnThree = headingColumnThree.fields || headingColumnThree
    iconText = iconText || iconTextCollection.items || []
    actionsColumnThree =
        actionsColumnThree || actionsColumnThreeCollection.items || []

    const sectionObject = {
        ...props,
        verticalPadding: "both",
        blocks: [
            {
                __typename: "Grid",
                columns: 3,
                spacing: 3,
                items: [
                    {
                        __typename: "Card",
                        title: headingColumnOne?.text,
                        titleConfig: headingColumnOne,
                        body: subheadingColumnOne,
                        actions: actionsColumnOne,
                        hideOnMobile: !actionsColumnOne.length,
                    },
                    {
                        __typename: "Grid",
                        items: iconText,
                        verticalAlignment: "center",
                        hideOnMobile: true,
                    },
                    {
                        __typename: "Card",
                        title: headingColumnThree?.text,
                        titleConfig: headingColumnThree,
                        body: subheadingColumnThree,
                        actions: actionsColumnThree,
                        hideOnMobile: !actionsColumnThree.length,
                    },
                ],
            },
        ],
    }

    return <Section {...sectionObject} />
}
