import ExpandMoreSharpIcon from "@mui/icons-material/ExpandMoreSharp"
import Typography from "@mui/material/Typography"

const SubMenuHeader = ({ open, isMobile = false, children }) => {
    return (
        <Typography component="span" variant="navigationLabel" sx={{ mr: 0 }}>
            {children}{" "}
            <ExpandMoreSharpIcon
                sx={{
                    display: "inline-block",
                    width: isMobile ? "1.5rem" : "1.75rem",
                    height: isMobile ? "1.5rem" : "1.75rem",
                    verticalAlign: "middle",
                    transition: "all .3s",
                    transform: open ? "rotateZ(180deg)" : "rotateZ(0deg)",
                }}
            />
        </Typography>
    )
}

export default SubMenuHeader
