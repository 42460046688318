/** @format */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { yupResolver } from "@hookform/resolvers/yup"
import {
    Button,
    Grid,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material"
import axios from "axios"
import { useTranslation } from "next-i18next"
import Link from "next/link"
import { useRouter } from "next/router"
import { useCallback, useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import * as Yup from "yup"

import { Context } from "../../context"
import {
    containerCSS,
    dataLayer,
    getVisitedURL,
    GTM_EVENTS,
    NEW_API_KEY,
    NEW_API_URL,
    reformatPhone,
} from "../../util"
import { API } from "../../util/api"
import { handleCaughtError } from "../../util/handlers"
import { getEnvironmentVariable } from "../../util/env"

const PostionsDataArr = [
    { id: 1, title: `General Manager` },
    { id: 2, title: `Production Manager` },
    { id: 3, title: `Detailer` },
    { id: 4, title: `Sander` },
    { id: 5, title: `Stager/Prepper` },
    { id: 6, title: `Painter` },
    {
        id: 7,
        title: `Paint Line Technician`,
    },
    {
        id: 8,
        title: `Auto Body Technician`,
    },
    { id: 9, title: `Sales Associate` },
]

export const FranchiseCareersForm = () => {
    const { state } = useContext(Context)
    const router = useRouter()
    const [showSuccessMsg, setShowSuccessMessage] = useState(false)
    const [showErrorsMsg, setShowErrorMessage] = useState(false)
    const [componentState, setComponentState] = useState({
        selectedPosition: ``,
        selectedState: ``,
        cities: [],
        selectedCity: ``,
        locations: [],
        selectedLocation: ``,
    })

    const phoneRegExp = /^\(\d{3}\)[ ]\d{3}[-]\d{4}$/

    const emailRegExp =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    const { t } = useTranslation()
    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required("Enter first name").trim(),
        lastName: Yup.string().required("Enter last name").trim(),
        message: Yup.string().required("Enter message").trim(),
        positionType: Yup.string().required("Please select position"),
        city: Yup.string().required("Please select city"),
        stateName: Yup.string().required(
            `Please select ${t("state").toLowerCase()}`
        ),
        location: Yup.string().required("Please select location"),
        email: Yup.string()
            .matches(emailRegExp, "Email is not valid")
            .required(),
        phoneNumber: Yup.string()
            .matches(phoneRegExp, "Phone number is not valid")
            .required(
                `Phone number is required and must be contain only numbers.`
            ),
    })

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isDirty },
        getValues,
        watch,
        setValue,
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: "all",
    })

    const getGtmValues = useCallback(() => {
        return {
            gtm_storeid: router.query.storeid,
            gtm_dma: "",
            gtm_pagerefsource: window.document.referrer,
        }
    }, [router])

    const onStateChange = async (value) => {
        state.states.forEach(async (location) => {
            if (
                location.stateAbbreviation.toLowerCase() === value.toLowerCase()
            ) {
                try {
                    const cities = await API.getCities(location?.stateName)
                    setComponentState({
                        ...componentState,
                        cities,
                        selectedState: location,
                    })
                } catch (err) {
                    handleCaughtError("Error getting cities from API", err)
                }
            }
        })
    }

    const onLocationChange = (locationValue) => {
        componentState?.locations.forEach((location) => {
            if (
                location?.storeAddress?.toLowerCase() ===
                locationValue.toLowerCase()
            ) {
                setComponentState({
                    ...componentState,
                    selectedLocation: location,
                })
            }
        })
    }

    const onCityChange = async (cityName) => {
        try {
            const locations = await API.getLocations({
                city: cityName,
                state: componentState.selectedState?.stateName,
                country: getEnvironmentVariable({ key: "COUNTRY_CODE" }),
            })

            let filteredLocations = []
            if (locations?.length > 0) {
                filteredLocations = locations.filter(
                    (store) => store.storeCity.replaceAll(".", "") === cityName
                )
            }
            setComponentState({
                ...componentState,
                locations: filteredLocations,
                selectedCity: cityName,
            })
        } catch (err) {
            handleCaughtError("Error getting locations from API", err)
        }
    }
    const onSubmitClick = () => {
        const values = { ...getValues() }
        setShowErrorMessage(false)
        setShowSuccessMessage(false)
        const form = new FormData()
        form.append("areaOfInterest", componentState?.selectedPosition?.title)
        form.append("state", componentState?.selectedState?.stateName)
        form.append("storeId", componentState?.selectedLocation?.storeId)
        form.append("firstName", values?.firstName)
        form.append("lastName", values?.lastName)
        form.append("email", values?.email)
        form.append("phone", values?.phoneNumber)
        form.append("message", values?.message)
        form.append("city", componentState?.selectedCity)
        form.append("visitedUrl", getVisitedURL(window.location))
        form.append("userIp", sessionStorage.getItem("userIp"))
        if (values?.resumeName?.[0]) {
            form.append("resume", values?.resumeName?.[0])
        }
        if (Object.entries(errors).length !== 0) {
            return
        } else {
            axios
                .post(`${NEW_API_URL}/career`, form, {
                    headers: {
                        apiKey: NEW_API_KEY,
                        session_id: sessionStorage.getItem("sessionId"),
                    },
                })
                .then((response) => {
                    if (response?.status === 200 || response?.status === 201) {
                        dataLayer({
                            event: GTM_EVENTS.careersFormComplete,
                            ...getGtmValues(),
                        })
                        setShowSuccessMessage(response?.data)
                    } else if (
                        response?.status !== 200 &&
                        response?.status !== 201
                    ) {
                        setShowErrorMessage(true)
                    }

                    resetAllFormData()
                })
                .catch((e) => {
                    setShowSuccessMessage(false)
                    setShowErrorMessage(true)
                    handleCaughtError("Error in submitting career form ", e)
                })
        }
    }

    const resetAllFormData = () => {
        setComponentState({
            selectedPosition: ``,
            selectedState: ``,
            cities: [],
            selectedCity: ``,
            locations: [],
            selectedLocation: ``,
        })
        let values = getValues()
        Object.keys(getValues()).forEach((fieldName) => {
            values[fieldName] = ``
        })
        reset(
            {
                ...values,
            },
            {
                keepValues: false,
                keepErrors: false,
                keepTouched: false,
                keepDefaultValues: true,
            }
        )
    }

    useEffect(() => {
        if (!isDirty) {
            return
        }
        dataLayer({
            event: GTM_EVENTS.careersFormStart,
            ...getGtmValues(),
        })
    }, [isDirty, getGtmValues])

    const watchPhone = watch("phoneNumber", false)

    useEffect(() => {
        if (watchPhone && watchPhone !== "") {
            setValue("phoneNumber", reformatPhone(watchPhone), {
                shouldValidate: true,
                shouldDirty: true,
            })
        }
    }, [watchPhone, setValue])

    return (
        <Grid
            container
            css={css`
                padding: 0 0 25px 0;
                background-color: #ebebeb;
                color: #595959;
            `}
        >
            <Grid item xs={12}>
                <form
                    id="careerInfo"
                    autoComplete="off"
                    onSubmit={handleSubmit(onSubmitClick)}
                >
                    <div css={css(containerCSS)}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography
                                    css={css`
                                        color: #214e70;
                                        font-weight: bold;
                                    `}
                                    variant={`h4`}
                                >
                                    {`INTERESTED IN A POSITION?`}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                container
                                justifyContent={`center`}
                                alignItems={`center`}
                            >
                                <Typography variant={`body1`}>
                                    {`Submit a resume for future opportunities!`}
                                </Typography>
                            </Grid>

                            <Grid
                                item
                                sm={4}
                                xs={12}
                                css={css`
                                    & .MuiOutlinedInput-root {
                                        width: 90%;
                                    }
                                    @media (min-width: 768px) {
                                        .dropdownItem {
                                            height: 90px;
                                        }
                                    }
                                `}
                            >
                                <Typography
                                    variant={`body2`}
                                    fontWeight={`bold`}
                                >{`* Denotes required field`}</Typography>
                                <div className="dropdownItem">
                                    <Typography
                                        variant={`subtitle2`}
                                        css={css`
                                            color: #214e70;
                                            margin: 10px 0 -10px 0px;
                                        `}
                                    >
                                        {`Position`}
                                    </Typography>
                                    <Select
                                        // placeholder={`Position`}
                                        {...register(`positionType`)}
                                        onChange={(event) => {
                                            PostionsDataArr?.forEach(
                                                (item, index) => {
                                                    if (
                                                        index ===
                                                        event.target.value - 1
                                                    ) {
                                                        setComponentState({
                                                            ...componentState,
                                                            selectedPosition:
                                                                item,
                                                        })
                                                    }
                                                }
                                            )
                                        }}
                                        css={css`
                                            width: 100%;
                                            background-color: #fff;
                                        `}
                                        defaultValue=""
                                    >
                                        {PostionsDataArr?.map(
                                            ({ id, title }) => (
                                                <MenuItem key={id} value={id}>
                                                    <Typography
                                                        variant="body1"
                                                        component={"span"}
                                                        css={css`
                                                            padding-left: 10px;
                                                            padding-top: 5px;
                                                        `}
                                                    >
                                                        {title}
                                                    </Typography>
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                    <Typography
                                        variant="body1"
                                        color="error"
                                        css={css`
                                            margin-top: -15px;
                                            padding-top: 7px;
                                        `}
                                    >
                                        {errors?.positionType?.message}
                                    </Typography>
                                </div>
                                <div className="dropdownItem">
                                    <Typography
                                        variant={`subtitle2`}
                                        css={css`
                                            color: #214e70;
                                            margin-bottom: -10px;
                                            margin-top: 10px;
                                        `}
                                    >
                                        {t("state")}
                                    </Typography>
                                    <Select
                                        {...register(`stateName`)}
                                        onChange={(event) =>
                                            onStateChange(event.target.value)
                                        }
                                        css={css`
                                            width: 100%;
                                            background-color: #fff;
                                        `}
                                        defaultValue=""
                                    >
                                        {state?.states?.map(
                                            ({
                                                stateName,
                                                stateAbbreviation,
                                            }) => (
                                                <MenuItem
                                                    key={stateAbbreviation}
                                                    value={stateAbbreviation}
                                                >
                                                    <Typography
                                                        variant="body1"
                                                        component={"span"}
                                                        css={css`
                                                            padding-left: 10px;
                                                            padding-top: 5px;
                                                        `}
                                                    >
                                                        {stateName}
                                                    </Typography>
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                    <Typography
                                        variant="body1"
                                        color="error"
                                        css={css`
                                            margin-top: -15px;
                                            padding-top: 7px;
                                        `}
                                    >
                                        {errors?.stateName?.message}
                                    </Typography>
                                </div>
                                <div className="dropdownItem">
                                    <Typography
                                        variant={`subtitle2`}
                                        css={css`
                                            color: #214e70;
                                            margin-bottom: -10px;
                                            margin-top: 10px;
                                        `}
                                    >
                                        {`City`}
                                    </Typography>
                                    <Select
                                        {...register(`city`)}
                                        css={css`
                                            width: 100%;
                                            background-color: #fff;
                                        `}
                                        onChange={(event) =>
                                            onCityChange(event.target.value)
                                        }
                                        defaultValue=""
                                    >
                                        {componentState?.cities?.map(
                                            (cityName) => (
                                                <MenuItem
                                                    key={cityName}
                                                    value={cityName}
                                                >
                                                    <Typography
                                                        variant="body1"
                                                        component={"span"}
                                                        css={css`
                                                            padding-left: 10px;
                                                            padding-top: 5px;
                                                        `}
                                                    >
                                                        {cityName
                                                            ? cityName
                                                            : ""}
                                                    </Typography>
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                    <Typography
                                        variant="body1"
                                        color="error"
                                        css={css`
                                            margin-top: -15px;
                                            padding-top: 7px;
                                        `}
                                    >
                                        {errors?.city?.message}
                                    </Typography>
                                </div>
                                <div className="dropdownItem">
                                    <Typography
                                        variant={`subtitle2`}
                                        css={css`
                                            color: #214e70;
                                            margin: 10px 0 -10px;
                                        `}
                                    >
                                        {`Address`}
                                    </Typography>
                                    <Select
                                        {...register(`location`)}
                                        css={css`
                                            width: 100%;
                                            background-color: #fff;
                                        `}
                                        onChange={(event) =>
                                            onLocationChange(event.target.value)
                                        }
                                        defaultValue=""
                                    >
                                        {componentState?.locations?.map(
                                            ({ storeAddress }) => (
                                                <MenuItem
                                                    key={storeAddress}
                                                    value={storeAddress}
                                                >
                                                    <Typography
                                                        variant="body1"
                                                        component={"span"}
                                                        css={css`
                                                            padding-left: 10px;
                                                            padding-top: 5px;
                                                        `}
                                                    >
                                                        {storeAddress}
                                                    </Typography>
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                    <Typography
                                        variant="body1"
                                        color="error"
                                        css={css`
                                            margin-top: -15px;
                                            padding-top: 7px;
                                        `}
                                    >
                                        {errors?.location?.message}
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid
                                item
                                sm={8}
                                css={css`
                                    & .MuiOutlinedInput-root {
                                        height: 45px;
                                        background-color: #ffffff;
                                    }
                                    & .MuiInputBase-multiline {
                                        height: auto;
                                        textarea {
                                            height: 109px !important;
                                        }
                                    }
                                    & .MuiFormControl-root {
                                        width: 90%;
                                        margin-bottom: 0.5rem;
                                    }
                                    & .css-rpybyc {
                                        @media (min-width: 768px) {
                                            height: 101px;
                                        }
                                    }
                                    @media (min-width: 768px) {
                                        margin-top: 1.3rem;
                                    }
                                `}
                            >
                                <Grid container>
                                    <Grid item xs={12} sm={6}>
                                        <Typography
                                            variant={`subtitle2`}
                                            css={css`
                                                color: #214e70;
                                                margin: 10px 0px -10px;
                                            `}
                                        >
                                            {`First Name*`}
                                        </Typography>
                                        <TextField
                                            // placeholder={`First Name*`}
                                            {...register(`firstName`)}
                                            name={`firstName`}
                                            variant={`outlined`}
                                        />
                                        <Typography
                                            variant="body1"
                                            color="error"
                                            css={css`
                                                margin-top: -15px;
                                            `}
                                        >
                                            {errors?.firstName?.message}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography
                                            variant={`subtitle2`}
                                            css={css`
                                                color: #214e70;
                                                margin: 10px 0px -10px;
                                            `}
                                        >
                                            {`Last Name*`}
                                        </Typography>
                                        <TextField
                                            // placeholder={`Last Name*`}
                                            {...register(`lastName`)}
                                            name={`lastName`}
                                            variant={`outlined`}
                                        />
                                        <Typography
                                            variant="body1"
                                            color="error"
                                            css={css`
                                                margin-top: -15px;
                                            `}
                                        >
                                            {errors?.lastName?.message}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Typography
                                            variant={`subtitle2`}
                                            css={css`
                                                color: #214e70;
                                                margin: 10px 0px -10px;
                                            `}
                                        >
                                            {`Email address*`}
                                        </Typography>
                                        <TextField
                                            //placeholder={`Email address*`}
                                            name={`email`}
                                            {...register(`email`)}
                                            variant={`outlined`}
                                        />
                                        <Typography
                                            variant="body1"
                                            color="error"
                                            css={css`
                                                margin-top: -15px;
                                            `}
                                        >
                                            {errors?.email?.message}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography
                                            variant={`subtitle2`}
                                            css={css`
                                                color: #214e70;
                                                margin: 10px 0px -10px;
                                            `}
                                        >
                                            {`Phone number*`}
                                        </Typography>
                                        <TextField
                                            name={`phoneNumber`}
                                            {...register(`phoneNumber`)}
                                            variant={`outlined`}
                                        />
                                        <Typography
                                            variant="body1"
                                            color="error"
                                            css={css`
                                                margin-top: -15px;
                                            `}
                                        >
                                            {errors?.phoneNumber?.message}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography
                                            variant={`subtitle2`}
                                            css={css`
                                                color: #214e70;
                                                margin: 10px 0px -10px;
                                            `}
                                        >
                                            {`Message*`}
                                        </Typography>
                                        <TextField
                                            //placeholder={`Message*`}
                                            {...register(`message`)}
                                            name={`message`}
                                            multiline
                                            rows={5}
                                            variant={`outlined`}
                                        />
                                        <Typography
                                            variant="body1"
                                            color="error"
                                            css={css`
                                                margin-top: -15px;
                                            `}
                                        >
                                            {errors?.message?.message}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant={`body2`}>
                                            {`Attach your resume`}
                                            <br />
                                            {`(PDF or Word format only, not to exceed 2mb)`}
                                        </Typography>
                                        <TextField
                                            placeholder={`Resume*`}
                                            name={`resumeName`}
                                            {...register(`resumeName`)}
                                            rows={4}
                                            variant={`outlined`}
                                            type={`file`}
                                        />
                                        <Link href={`/privacy-policy`} passHref>
                                            <Typography
                                                variant={`body1`}
                                                css={css`
                                                    margin-top: -12px;
                                                    display: block;
                                                    font-weight: bold;
                                                    color: #005275;
                                                    margin-left: 10px;
                                                `}
                                                component={`a`}
                                                target={`_blank`}
                                            >{`Privacy policy`}</Typography>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sm={4}></Grid>

                            <Grid
                                item
                                container
                                justifyContent={`center`}
                                alignItems={`center`}
                                css={css`
                                    margin-top: 10px;
                                `}
                            >
                                <Button
                                    //onClick={onSubmitClick}
                                    form="careerInfo"
                                    type="submit"
                                    css={css`
                                        background-color: #595959 !important;
                                        border-color: #000;
                                        color: #fff !important;
                                        padding-top: 0px !important;
                                    `}
                                >
                                    <Typography
                                        variant={`body1`}
                                        css={css`
                                            color: #fff !important;
                                        `}
                                    >
                                        {`SUBMIT`}
                                    </Typography>
                                </Button>
                            </Grid>
                            <Grid
                                css={css`
                                    width: 100%;
                                    display: flex;
                                    justify-content: center;
                                `}
                            >
                                {showSuccessMsg && (
                                    <Typography
                                        css={css`
                                            margin: 15px;
                                            color: #7a841b;
                                        `}
                                    >
                                        {`Thank you. Your request was sent successfully.`}
                                    </Typography>
                                )}
                                {showErrorsMsg && (
                                    <Typography
                                        css={css`
                                            margin: 15px;
                                            color: #a11816;
                                        `}
                                    >
                                        {`Something went wrong. Please try again`}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    variant={`body2`}
                                    css={css`
                                        text-align: center;
                                        margin-top: 5px;
                                    `}
                                >
                                    {`*All hiring decisions for shop employees are made by the independent franchisee, and employees at the shop level are employees of the applicable franchisee (not MAACO corporate).`}
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                </form>
            </Grid>
        </Grid>
    )
}
