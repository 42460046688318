import { AccountCircle, Interests } from "@mui/icons-material"
import { Icon, Stack, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import Image from "next/image"
import React, { useContext } from "react"

import { OetContext } from "../../../../../context"

const EstimateSummaryCategory = ({ name, children }) => {
    const { state } = useContext(OetContext)

    const useStyles = makeStyles({
        iconRoot: { textAlign: "center" },
    })
    const classes = useStyles()

    const renderIcon = (alt, source) => (
        <Icon classes={{ root: classes.iconRoot }}>
            <Image
                alt={alt}
                height={20}
                width={20}
                loader={({ src, width, quality }) =>
                    `${src}?w=${width}&q=${quality || 75}&fm=webp`
                }
                src={source}
            />
        </Icon>
    )

    const SUMMARY_CATEGORIES = {
        info: {
            icon: <AccountCircle color="action" />,
            title: "Your info",
        },
        vehicle: {
            icon: renderIcon("vehicle icon", "/images/vehicle.svg"),
            title: "Vehicle info",
        },
        insurance: {
            icon: renderIcon("insurance shield icon", "/images/insurance.svg"),
            title: "Insurance details",
        },
        service: {
            icon: renderIcon(
                "services wrench screwdriver icon",
                "/images/services.svg"
            ),
            title: "Service requested",
        },
        store: {
            icon: renderIcon("store icon", "/images/store.svg"),
            title: "",
        },
        notes: {
            icon: <Interests color="action" />,
            title: "Other",
        },
    }

    return (
        <Stack direction="row" spacing={2}>
            {SUMMARY_CATEGORIES[name].icon}
            <Stack direction="column" spacing={1}>
                <Typography
                    fontFamily="Gilroy Medium"
                    sx={{ color: "primary.main" }}
                >
                    {name === "store"
                        ? state.location.storeName
                        : SUMMARY_CATEGORIES[name].title}
                </Typography>
                <Typography component="span" variant="body2">
                    {children}
                </Typography>
            </Stack>
        </Stack>
    )
}

export default EstimateSummaryCategory
