import { LinearProgress } from '@mui/material'

const Loader = ({ loading }) => {
  return (
    <>
      {loading && <LinearProgress sx={{
        position: "sticky",
        top: "0px",
        zIndex: 99
      }} />
      }
    </>
  )
}

export default Loader