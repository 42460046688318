import { forwardRef } from "react"

import Collapse from "@mui/material/Collapse"
import ListItemButton from "@mui/material/ListItemButton"
import MenuItem from "@mui/material/MenuItem"
import MenuList from "@mui/material/MenuList"
import SubMenuHeader from "./SubMenuHeader"

import Link from "../../Link"

const SubMenuMobile = forwardRef(
    ({ subMenuHeader, subMenu, open, handleClick }, ref) => {
        return (
            <>
                <ListItemButton
                    onClick={handleClick}
                    sx={{ justifyContent: "center", p: 0 }}
                    ref={ref}
                >
                    <SubMenuHeader open={open} isMobile>
                        {subMenuHeader}
                    </SubMenuHeader>
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <MenuList
                        autoFocusItem={open}
                        id="composition-menu"
                        aria-labelledby="composition-button"
                    >
                        {subMenu.items.map(({ text, navLink }) => (
                            <Link key={text} href={navLink.path} noLinkStyle>
                                <MenuItem
                                    key={text}
                                    dense
                                    sx={{
                                        width: "100%",
                                        justifyContent: "center",
                                    }}
                                >
                                    {text}
                                </MenuItem>
                            </Link>
                        ))}
                    </MenuList>
                </Collapse>
            </>
        )
    }
)

export default SubMenuMobile
