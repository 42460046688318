/** @format */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { Search } from "@mui/icons-material"
import { Grid, InputAdornment, OutlinedInput } from "@mui/material"
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react"
import Geocode from "react-geocode"

import { Context } from "../../context"
import {
    GOOGLE_MAP_API_KEY,
    GTM_EVENTS,
    MOBILE_WIDTH,
    dataLayer,
} from "../../util"
import { handleCaughtError } from "../../util/handlers"

const SearchLocation = ({
    onPlaceChange,
    props = {},
    inputProps = {},
    endAdornment = <Search style={{ color: "rgba(0, 0, 0, 0.23)" }} />,
    navigateToPage = false,
    showFindButton = false,
    findButtonStyle,
    findButtonLabel = "Find",
    placeholder,
}) => {
    const router = useRouter()
    const { t } = useTranslation()
    const { state: { states } = {}, getStates } = useContext(Context)

    const headerActions = t("content:header.actionsCollection.items", "")
    const searchLocationAction =
        headerActions &&
        headerActions.find(
            (action) => action.name === "Site Wide Header Input Field"
        )
    placeholder =
        placeholder ||
        searchLocationAction?.placeholder ||
        t("header.search_placeholder") ||
        "City, State, or Zip"

    const [winObj, setWinObj] = useState({
        innerWidth: MOBILE_WIDTH,
        encodeURIComponent: () => {},
    })

    useEffect(() => {
        const setWinObjValues = () => {
            setWinObj({
                innerWidth: window.innerWidth,
                encodeURIComponent: window.encodeURIComponent,
            })
        }
        addEventListener("resize", setWinObjValues)
        setWinObjValues()
        return () => removeEventListener("resize", setWinObjValues)
    }, [])

    // eslint-disable-next-line no-unused-vars
    const [userLocation, setUserLocation] = useState({ lat: null, lng: null })

    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: GOOGLE_MAP_API_KEY,
    })

    Geocode.setApiKey(GOOGLE_MAP_API_KEY)
    const autocompleteInput = useRef(null)

    const [autoComplete, setAutoComplete] = useState(null)
    const autocompleteOnLoad = useCallback(function callback(autocomplete) {
        setAutoComplete(autocomplete)
    }, [])

    const goToPage = (statesToFilter = []) => {
        const selectedPlace = autocompleteInput.current.value
        let stateFound = ""
        const selectedState = selectedPlace.split(",")[0].toLowerCase().trim()
        statesToFilter.forEach((item) => {
            const stateNm = item.stateName.toLowerCase()
            if (stateNm === selectedState) {
                stateFound = item.stateAbbreviation.toLowerCase()
            }
        })
        if (stateFound) {
            router.push(`/locations/${stateFound}`)
        } else {
            router.push(
                `/search/?key=${winObj.encodeURIComponent(
                    selectedPlace
                )}#coupon`
            )
        }
    }

    const onPlaceChanged = () => {
        // Send location search event to data layer
        dataLayer({
            event: GTM_EVENTS.locationSearch,
            gtm_storeid: router.query.storeid,
            gtm_dma: "",
            gtm_pagerefsource: window.document.referrer,
        })

        if (navigateToPage && autocompleteInput?.current?.value) {
            if (!states?.length) {
                getStates()
                    .then((res) => {
                        goToPage(res ?? [])
                    })
                    .catch((err) => {
                        handleCaughtError("error getting states from API", err)
                    })
            } else {
                goToPage(states)
            }
        }
        if (onPlaceChange && autocompleteInput?.current?.value) {
            onPlaceChange(autocompleteInput.current.value)
        }
        if (
            autoComplete.getPlace() &&
            autoComplete.getPlace().geometry !== undefined
        ) {
            const lat = autoComplete.getPlace().geometry.location.lat()
            const lng = autoComplete.getPlace().geometry.location.lng()
            setUserLocation({
                lat,
                lng,
            })
        } else {
            Geocode.fromAddress(autocompleteInput?.current?.value)
                .then((response) => {
                    const { lat, lng } = response.results[0].geometry.location

                    setUserLocation({
                        lat,
                        lng,
                    })
                })
                .catch((err) => {
                    handleCaughtError(
                        "error getting Google geocode from address",
                        err
                    )
                })
        }
    }

    return isLoaded ? (
        <Grid
            container
            direction="row"
            className={`search-location-container`}
            wrap="nowrap"
            css={css`
                width: auto; // override full-width
                flex-grow: 1;
            `}
        >
            <Autocomplete
                className={`locationsSearchbox`}
                css={css`
                    display: flex;
                    flex-grow: 1;
                    margin-right: 0.25em;
                `}
                onLoad={autocompleteOnLoad}
                onPlaceChanged={onPlaceChanged}
                options={{
                    types: [
                        "postal_code",
                        "locality",
                        "administrative_area_level_1",
                    ],
                    componentRestrictions: {
                        country: t("current_locale").toUpperCase(),
                    },
                }}
            >
                <OutlinedInput
                    inputRef={autocompleteInput}
                    inputProps={inputProps}
                    style={{ borderRadius: 0, backgroundColor: "#fff" }}
                    fullWidth
                    placeholder={placeholder || t("header.search_placeholder")}
                    css={css`
                        margin: 0;
                    `}
                    endAdornment={
                        <InputAdornment
                            position="end"
                            onClick={onPlaceChanged}
                            style={{ cursor: "pointer" }}
                        >
                            {endAdornment}
                        </InputAdornment>
                    }
                    {...props}
                />
            </Autocomplete>
            {showFindButton && (
                <button
                    type="submit"
                    css={css`
                        background-color: #fff;
                        border-radius: 0;
                        text-transform: uppercase;
                        border: 3px solid #006bb7;
                        color: #214e70;
                        padding: 0 1.5em;
                        cursor: pointer;
                        font-weight: 800;
                        &:hover {
                            background-color: #006bb7;
                            color: #fff;
                        }
                    `}
                    style={findButtonStyle ?? findButtonStyle}
                    onClick={onPlaceChanged}
                >
                    {findButtonLabel}
                </button>
            )}
        </Grid>
    ) : (
        <></>
    )
}

export default SearchLocation
