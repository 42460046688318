/** @format */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { yupResolver } from "@hookform/resolvers/yup"
import {
    Button,
    ButtonBase,
    Checkbox,
    Grid,
    Stack,
    Typography,
} from "@mui/material"
import axios from "axios"
import { format } from "date-fns"
import { formatInTimeZone } from "date-fns-tz"
import { enCA, enUS } from "date-fns/locale"
import { useContext, useState } from "react"
import { Controller, FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"

import { OetContext } from "../../../../context"
import {
    FEATURE_FLAGS,
    GTM_EVENTS,
    getVisitedURL,
    NEW_API_KEY,
    NEW_API_URL,
    reformatPhone,
} from "../../../../util"
import { handleCaughtError } from "../../../../util/handlers"
import { dataLayer } from "../../../../util/index"
import InputComponent from "../../../InputComponent"
import NextLink from "../../../Link"
import MotorApiForm from "../../../MotorApiForm"

const ScheduleAppt = ({
    mobileView = "false",
    location,
    selectedDate,
    setPage,
    endpoint,
    valueStore = "",
}) => {
    const { t } = useTranslation()
    const { state, dispatch } = useContext(OetContext)
    const [loading, setLoading] = useState(false)
    const { userInfo, vehicleInfo, service = {}, featureFlag } = state

    const values = {
        firstName: userInfo.firstName || "",
        lastName: userInfo.lastName || "",
        email: userInfo.email || "",
        phone: reformatPhone(userInfo.phone) || "",
        make: vehicleInfo.make || "",
        model: vehicleInfo.model || "",
        year: vehicleInfo.year || "",
        comment: "",
        contactByText: userInfo.contactByText || false,
    }

    // const [mobileView, setMobileView] = useState(false)

    const phoneRegExp = /^\(\d{3}\)[ ]\d{3}[-]\d{4}$/
    const emailRegExp =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required("Enter your first name.").trim(),
        lastName: Yup.string().required("Enter your last name.").trim(),
        email: Yup.string()
            .matches(emailRegExp, "Email address is not valid.")
            .required(),
        phone: Yup.string()
            .matches(phoneRegExp, "Phone number is not valid.")
            .required(
                `Phone number is required and must be contain only numbers.`
            ),
        make: Yup.string().required("Car make is required."),
        model: Yup.string().required("Car model is required."),
        year: Yup.string()
            .matches(/^[0-9]+$/, "Must be only digits.")
            .min(4, "Must be 4 digits.")
            .max(4, "Must be 4 digits.")
            .required("Year is required."),
        comment: Yup.string()
            .max(255, "Comment cannot exceed 255 characters.")
            .trim(),
        contactByText:
            (userInfo.preferredMethod === "call" ||
                userInfo.preferredMethod === "text") &&
            t("current_locale") === "us"
                ? Yup.bool().isTrue().required("Opt-in is required.")
                : Yup.bool().optional(),
    })

    const formObject = useForm({
        values,
        defaultValues: { ...userInfo, phone: reformatPhone(userInfo.phone) },
        resolver: yupResolver(validationSchema),
        mode: "all",
    })

    const {
        control,
        handleSubmit,
        reset,
        setValue,
        formState: { isValid },
        watch,
    } = formObject

    const onSubmitHandler = (data) => {
        const config = {
            headers: {
                apikey: NEW_API_KEY,
                session_id: sessionStorage.getItem("sessionId"),
            },
        }

        const leadId = state?.leadId
            ? { leadId: state?.leadId.toString() }
            : null
        const isOetComplete =
            featureFlag?.key === FEATURE_FLAGS.fastPath &&
            service?.isOetComplete
                ? { isOetComplete: true }
                : null
        // TODO: REMOVE DESTRUCTURE & TERNARY OPERATOR ONCE ZETA APPROVES CA TEXTING DISCLAIMERS
        let { contactByText } = data
        contactByText = t("current_locale") === "us" ? data.contactByText : true

        const postData = {
            ...leadId,
            // TODO: REMOVE ONCE FEATURE TESTING IS COMPLETE
            ...isOetComplete,
            storeId: location.storeId,
            date: selectedDate.date,
            time: selectedDate.time,
            slot: 1,
            ...data,
            contactByText,
            visitedUrl: getVisitedURL(window.location),
            userIp: sessionStorage.getItem("userIp"),
        }
        if (loading) {
            return
        }
        setLoading(true)
        // /oet
        axios
            .post(`${NEW_API_URL}${endpoint}/appointment`, postData, config)
            .then((res) => {
                dataLayer({
                    event: GTM_EVENTS.appointmentCtaComplete,
                    appt_email: data.email,
                    gtm_storeid: location.storeId,
                    gtm_pagerefsource: window.document.referrer,
                    gtm_dma: location.dma,
                })

                setPage("Complete")
                setValue("firstName", "", { shouldValidate: false })
                setValue("lastName", "", { shouldValidate: false })
                setValue("email", "", { shouldValidate: false })
                setValue("phone", "", { shouldValidate: false })
                setValue("year", "", { shouldValidate: false })
                setValue("make", "", { shouldValidate: false })
                setValue("model", "", { shouldValidate: false })
                setValue("comment", "", { shouldValidate: false })
                setValue("contactByText", false, { shouldValidate: false })
                setLoading(false)

                return
            })
            .catch((e) => {
                dispatch({
                    type: "ERRORS",
                    payload: `Error at making appointment. ${e.message}`,
                })
                handleCaughtError("Error at making appointment", e)
            })
    }

    //This function returns a neat string for a date, time, and timezone
    //It uses the locale from i18next to determine locale specific formatting
    //Arguments:
    //          date     (string): formatted "YYYY-MM-DD"
    //          time     (string): formatted like "08:45 am EDT"
    //          timezone (string): Full timezone database name formatted like "America/Costa_Rica"
    //
    const convertDateTime = (date, time, timezone) => {
        const splitDate = date.split("-")
        // new Date(year, month [, day [, hours[, minutes[, seconds[, ms]]]]])
        const dateObject = new Date(
            splitDate[0],
            splitDate[1] - 1,
            splitDate[2]
        ) // Note: months are 0-based
        const dateResult = format(dateObject, "PPPP", {
            locale: t("locale") === "en-CA" ? enCA : enUS,
        })
        return (
            <Typography component="span" sx={{ fontFamily: "Gilroy Bold" }}>
                {dateResult}
                <Typography component="span" variant="body2" display="inline">
                    {" "}
                    at
                </Typography>{" "}
                {time.toLowerCase().replace(/^0+/, "").replace(" ", "") +
                    " " +
                    formatInTimeZone(dateObject, timezone, "zzz")}
            </Typography>
        )
    }

    const inputFields = [
        {
            name: "firstName",
            fullInputName: "First Name",
            placeholder: "Your First Name",
            value: userInfo.firstName,
        },
        {
            name: "lastName",
            fullInputName: "Last Name",
            placeholder: "Your Last Name",
            value: userInfo.lastName,
        },
        {
            name: "email",
            fullInputName: "Email",
            placeholder: "you@example.com",
            value: userInfo.email,
        },
        {
            name: "phone",
            fullInputName: "Phone Number",
            placeholder: "(555) 123-4567",
            value: reformatPhone(userInfo.phone),
        },
    ]

    // TODO: REMOVE AND REPLACE DISCLAIMER WITH US VERSION ONCE ZETA APPROVES CA TEXTING DISCLAIMERS
    const renderDisclaimer = (locale) => {
        if (locale === "ca") {
            return (
                <Grid item>
                    <Typography
                        variant="body3"
                        sx={{ textTransform: "uppercase" }}
                    >
                        {`By clicking "CONFIRM YOUR APPOINTMENT", I consent to receive automated promotional and service messages via text and email from Maaco sent via automated dialing/sequencing systems. Further, I agree to Maaco's `}
                        <NextLink
                            target="_blank"
                            href="/privacy-policy/"
                        >{`Privacy Policy`}</NextLink>
                        ,{" "}
                        <NextLink
                            target="_blank"
                            href="/estimate-disclaimer/"
                        >{`Maaco's Estimate Tool Disclaimer`}</NextLink>
                        , and{" "}
                        <NextLink
                            target="_blank"
                            href="/mobileterms/"
                        >{`Text Program Terms of Use`}</NextLink>
                        {`. This consent is not required to purchase goods or services. Recurring messages. Reply STOP to stop at any time; HELP for help. Message and data rates apply.`}
                    </Typography>
                </Grid>
            )
        }

        return (
            <>
                <Grid item>
                    <Controller
                        name="contactByText"
                        control={control}
                        defaultValue={userInfo.contactByText}
                        render={({ field: props }) => (
                            <Checkbox
                                css={css`
                                    padding: 0 10px 0 0;
                                `}
                                {...props}
                                checked={props.value}
                                onChange={(e) =>
                                    props.onChange(e.target.checked)
                                }
                            />
                        )}
                    />
                    <Typography
                        variant="body3"
                        sx={{
                            fontFamily: "Gilroy Bold",
                            textTransform: "uppercase",
                        }}
                    >
                        Opt-In to Marketing Text Messages.{" "}
                    </Typography>
                    <Typography
                        variant="body3"
                        sx={{ textTransform: "uppercase" }}
                    >
                        {`I verify this is my mobile number and consent to receive recurring text messages from Maaco sent via automated dialing/sequencing systems regarding offers & service communications per Maaco's `}
                        <NextLink
                            target="_blank"
                            href="/privacy-policy/"
                        >{`Privacy Policy`}</NextLink>{" "}
                        and{" "}
                        <NextLink
                            target="_blank"
                            href="/mobileterms/"
                        >{`Text Program Terms of Use`}</NextLink>
                        {`. Consent for text communication is not required to purchase goods or services. Reply STOP to stop at any time; HELP for help. Message and data rates may apply.`}
                    </Typography>
                </Grid>

                <Grid item>
                    <Typography
                        variant="body3"
                        sx={{ textTransform: "uppercase" }}
                    >
                        {`By clicking "CONFIRM YOUR APPOINTMENT", I consent to receive automated promotional and service emails from Maaco sent via sequencing systems. Further, I agree to Maaco's `}
                        <NextLink
                            target="_blank"
                            href="/privacy-policy/"
                        >{`Privacy Policy`}</NextLink>
                        ,{" "}
                        <NextLink
                            target="_blank"
                            href="/estimate-disclaimer/"
                        >{`Maaco's Estimate Tool Disclaimer`}</NextLink>
                        .
                    </Typography>
                </Grid>
            </>
        )
    }

    return (
        <div
            css={css`
                overflow: scroll;
                height: auto;
                max-height: 85vh;
            `}
        >
            <Grid
                container
                direction={"column"}
                spacing={2}
                flexWrap={"nowrap"}
                css={css`
                    padding: 0.5em 1em;
                `}
            >
                <Grid item xs={12} sm={8}>
                    <Typography
                        variant="h4"
                        sx={{
                            fontWeight: "bold",
                            textTransform: "uppercase",
                        }}
                        color="secondary"
                    >
                        Appointment Details
                    </Typography>

                    <Typography variant="body2">
                        {"You've set your appointment on "}
                        {convertDateTime(
                            selectedDate.date,
                            selectedDate.time,
                            location.timezone
                        )}
                        <Typography component="span" variant="body3">
                            {" "}
                            (
                            <ButtonBase
                                disableRipple
                                onClick={() => setPage("Appt")}
                                sx={{
                                    color: "primary.main",
                                    fontSize: "inherit",
                                    fontWeight: "inherit",
                                    lineHeight: "inherit",
                                    textDecoration: "underline",
                                }}
                            >
                                change the date
                            </ButtonBase>
                            )
                        </Typography>{" "}
                        {mobileView ? <br /> : ""}
                        with our Maaco Auto Paint and Collision Center at
                        <Typography
                            component="span"
                            sx={{ fontFamily: "Gilroy Bold" }}
                            display="inline"
                        >
                            {" "}
                            {location.storeAddress}
                        </Typography>{" "}
                        in
                        <Typography
                            component="span"
                            sx={{ fontFamily: "Gilroy Bold" }}
                            display="inline"
                        >
                            {" "}
                            {location.storeCity}, {location.storeState}
                        </Typography>
                        .
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography
                        variant="h4"
                        sx={{
                            fontWeight: "bold",
                            textTransform: "uppercase",
                        }}
                        color="secondary"
                    >
                        {mobileView
                            ? "Complete your booking"
                            : "Let's complete booking your appointment"}
                    </Typography>

                    <Typography variant="body3" component="div" mb={1}>
                        *Required Fields
                    </Typography>

                    <form
                        id="userInfo"
                        onSubmit={handleSubmit(onSubmitHandler)}
                        onReset={reset}
                        noValidate
                        autoComplete="off"
                    >
                        <FormProvider {...formObject}>
                            <Grid container direction="column" spacing={2}>
                                <Grid
                                    item
                                    container
                                    spacing={1}
                                    direction={mobileView ? "column" : "row"}
                                >
                                    {inputFields.map((inputItem) => {
                                        return (
                                            <Grid
                                                item
                                                xs={6}
                                                key={inputItem.name}
                                            >
                                                <InputComponent
                                                    name={inputItem.name}
                                                    fullInputName={
                                                        inputItem.fullInputName
                                                    }
                                                    placeholder={
                                                        inputItem.placeholder
                                                    }
                                                    value={inputItem.value}
                                                    required
                                                    cssStyle={`
                                        width: 100%;
                                    `}
                                                />
                                            </Grid>
                                        )
                                    })}

                                    <Grid container item xs={12} wrap="nowrap">
                                        <MotorApiForm
                                            state={state}
                                            placeholders={true}
                                            valueStore={valueStore}
                                            styles={{
                                                direction: mobileView
                                                    ? `column`
                                                    : ``,
                                                nowrap: true,
                                                year: ``,
                                                make: ``,
                                                model: ``,
                                                type: ``,
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Stack direction="column">
                                            <InputComponent
                                                name={"comment"}
                                                fullInputName={"Comments"}
                                                placeholder={
                                                    "What else would you like us to know?"
                                                }
                                                multiline
                                                lastInput
                                                fullWidth
                                                formProps={{
                                                    inputProps: {
                                                        maxLength: 255,
                                                    },
                                                }}
                                                rows={4}
                                                cssStyle={`
                                    & .MuiOutlinedInput-root {
                                        height: auto;
                                    }
                                `}
                                            />
                                            {watch("comment")
                                                ? `${
                                                      watch("comment").length
                                                  } / 255`
                                                : ""}
                                        </Stack>
                                    </Grid>
                                </Grid>

                                {renderDisclaimer(t("current_locale"))}

                                <Grid
                                    item
                                    container
                                    justifyContent="flex-start"
                                >
                                    <Button
                                        disabled={!isValid}
                                        type="submit"
                                        variant="contained"
                                        form="userInfo"
                                        color="primary"
                                        sx={[
                                            {
                                                "&:hover": {
                                                    color: "#fff !important",
                                                    backgroundColor:
                                                        "#af0e11 !important",
                                                },
                                            },
                                            {
                                                border: "1px solid #af0e11 !important",
                                                color: "#af0e11 !important",
                                                backgroundColor:
                                                    "#fff !important",
                                                margin: "0",
                                                pt: "0px !important",
                                                borderRadius: "0px !important",
                                                width: "auto",
                                                height: "4em",
                                            },
                                        ]}
                                    >
                                        Confirm your Appointment
                                    </Button>
                                </Grid>
                            </Grid>
                        </FormProvider>
                    </form>
                </Grid>
            </Grid>
        </div>
    )
}

export default ScheduleAppt
