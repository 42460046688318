import { css } from "@emotion/react"
import { Grid } from "@mui/material"

import { CERTIFICATION_TYPE } from "../../util"
import LinkButton from "../Buttons/LinkButton"
import OptimizedImage from "../OptimizedImage"

export const mainCerts = [
    CERTIFICATION_TYPE.diamond,
    CERTIFICATION_TYPE.platinum,
    CERTIFICATION_TYPE.gold,
    CERTIFICATION_TYPE.ICBC,
    CERTIFICATION_TYPE.BBB,
]

// This is used to render the certification image at the top of a store's page
export const TopStoreCertification = ({ store }) => {
    if (!store) {
        return null
    }

    const maacoCertification = () => {
        switch (store.currentCertificationLevel) {
            case CERTIFICATION_TYPE.diamond:
                return "/images/Certification-Diamond.png"
            case CERTIFICATION_TYPE.platinum:
                return "/images/Certification-Platinum.png"
            case CERTIFICATION_TYPE.gold:
                return "/images/Certification-Gold.png"
            default:
                return
        }
    }

    const oemCertification = (name) => {
        if (store.oem?.length > 0) {
            const thirdPartyItems =
                store.oem?.filter((certification) => {
                    const cert = certification.fields || certification
                    cert.hideImage = false //override the value set from the about/certifications content

                    // Filter to show OEM by cert name or
                    // any OEM cert that is not a Maaco or ICBC cert
                    return name
                        ? cert.apiName === name
                        : mainCerts.indexOf(cert.apiName) === -1
                }) || []
            const image =
                thirdPartyItems[0]?.fields?.image?.fields ||
                thirdPartyItems[0]?.fields?.image
            return image ?? undefined
        }
    }

    const hasCertificateICBC = store.certificateICBC
    // Value can be default OR a third party cert. (like I-CAR Gold)
    const certOrder = store.certificationOrder
    const hasCertOrder =
        oemCertification(certOrder) && oemCertification(certOrder).file?.url

    // This is default ordering for which certs show at top of store page
    // 1. Maaco (if present)
    // 2. ICBC (if present)
    // 3. First OEM certification (if present)
    const topStoreCertification = (
        <>
            {maacoCertification() && (
                <Grid item>
                    <OptimizedImage
                        src={maacoCertification()}
                        alt={`Maaco Certification`}
                        containerStyle={{ display: "contents" }}
                    />
                    <LinkButton
                        linkURL="#certifications"
                        label={`Learn More`}
                        css={css`
                            width: 100%;
                        `}
                    />
                </Grid>
            )}

            {!maacoCertification() && hasCertificateICBC && (
                <Grid item>
                    <OptimizedImage
                        src={`/images/certificateicbc.png`}
                        alt={`ICBC`}
                        layout="fill"
                    />
                    <LinkButton
                        linkURL="#certifications"
                        label={`Learn More`}
                        css={css`
                            width: 100%;
                        `}
                    />
                </Grid>
            )}

            {!maacoCertification() &&
                !hasCertificateICBC &&
                oemCertification() &&
                oemCertification().file?.url && (
                    <Grid item>
                        <OptimizedImage
                            src={oemCertification().file?.url}
                            alt={oemCertification().description}
                            containerStyle={{
                                display: "contents",
                            }}
                        />
                        <LinkButton
                            linkURL="#certifications"
                            label={`Learn More`}
                            css={css`
                                width: 100%;
                            `}
                        />
                    </Grid>
                )}
        </>
    )

    // If a manual cert order override comes in from Contentful, it is managed here.
    return certOrder === "default" ? (
        topStoreCertification
    ) : (
        <>
            {hasCertOrder && (
                <Grid item>
                    <OptimizedImage
                        src={hasCertOrder}
                        alt={oemCertification(certOrder).description}
                        containerStyle={{
                            display: "contents",
                        }}
                    />
                    <LinkButton
                        linkURL="#certifications"
                        label={`Learn More`}
                        css={css`
                            width: 100%;
                        `}
                    />
                </Grid>
            )}

            {!oemCertification(certOrder) && topStoreCertification}
        </>
    )
}
