/** @format */

/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { HighlightOff } from "@mui/icons-material"
import { Grid, Modal } from "@mui/material"
import { Box } from "@mui/system"
import { useState } from "react"

import { couponPayload } from "../../../util"
import Phone from "../../SendCoupon/Phone"
import StandardOffersPhone from "../../SendCoupon/StandardOffersPhone"
import { TEXT_MSG } from "../../util"
import { StyledButton } from "../Button"

export const CouponTextButton = ({
    coupon,
    coupons,
    storeId,
    store,
    ...props
}) => {
    const [openText, setOpenText] = useState(false)
    const handleOpenText = () => setOpenText(true)
    const handleCloseText = () => setOpenText(false)
    const formattedCoupons = coupon ? [couponPayload(coupon)] : undefined
    const couponsToDeliver = formattedCoupons ?? coupons;

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
        "@media (max-width: 650px)": {
            width: "80%",
        },
    }

    if (!coupon && !coupons) return null

    return (
        <>
            <StyledButton {...{ ...props, onClick: handleOpenText }} />

            <Modal
                open={openText}
                onClose={handleCloseText}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid container>
                        <Grid item xs={12}>
                            <HighlightOff
                                css={css`
                                    position: absolute;
                                    right: -9px;
                                    top: 6px;
                                    width: 60px;
                                    height: 30px;
                                    cursor: pointer;
                                `}
                                onClick={handleCloseText}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            {store && (
                                <Phone
                                    {...{
                                        disclaimer: TEXT_MSG,
                                        handleCloseText,
                                        store,
                                        selectedCoupons: couponsToDeliver,
                                    }}
                                />
                            )}

                            {!store && (
                                <StandardOffersPhone
                                    {...{
                                        disclaimer: TEXT_MSG,
                                        storeId,
                                        handleCloseText,
                                        offers: couponsToDeliver,
                                    }}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </>
    )
}
