/** @format */
/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react"
import { yupResolver } from "@hookform/resolvers/yup"
import { Button, Grid, Typography } from "@mui/material"
import axios from "axios"
import { useForm, Controller } from "react-hook-form"
import * as Yup from "yup"

import {
    couponsDelivered,
    getVisitedURL,
    GTM_EVENTS,
    NEW_API_KEY,
    NEW_API_URL,
} from "../../../util"
import { handleCaughtError } from "../../../util/handlers"

const Email = ({ disclaimer, offers, handleCloseEmail, storeId }) => {
    const emailRegExp =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required(`First name is required`),
        lastName: Yup.string().required(`Last name is required`),
        email: Yup.string()
            .required(`Email is required`)
            .matches(emailRegExp, "Email is not valid"),
    })

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, touchedFields },
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: "all",
    })

    const sendCoupon = (data) => {
        const config = {
            headers: {
                apikey: NEW_API_KEY,
                session_id: sessionStorage.getItem("sessionId"),
            },
        }

        const postData = {
            storeId,
            firstName: data.firstName,
            lastName: data.lastName,
            phone: "",
            offers,
            email: data.email,
            visitedUrl: getVisitedURL(window.location),
            userIp: sessionStorage.getItem("userIp"),
        }

        axios
            .post(
                `${NEW_API_URL}/stand-offer/assign-formatted`,
                postData,
                config
            )
            .then((res) => {
                couponsDelivered({ event: GTM_EVENTS.couponEmail, offers })
                handleCloseEmail()
                reset()
            })
            .catch((e) => {
                handleCaughtError("error at sending coupon", e)
            })
    }

    return (
        <form
            id="coupon2"
            onSubmit={handleSubmit(sendCoupon)}
            onReset={reset}
            noValidate
            autoComplete="off"
        >
            <Grid container columnSpacing={2} sx={{ mt: "0.75rem" }}>
                <Grid item xs={12} sm={6}>
                    <input
                        {...register("firstName")}
                        name="firstName"
                        type="text"
                        placeholder="First Name"
                        css={css`
                            margin: 0;
                        `}
                    />
                    <Typography
                        variant="body1"
                        color="error"
                        sx={{ minHeight: "1.5rem" }}
                    >
                        {errors.firstName?.message}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <input
                        {...register("lastName")}
                        name="lastName"
                        type="text"
                        placeholder="Last Name"
                        css={css`
                            margin: 0;
                        `}
                    />
                    <Typography
                        variant="body1"
                        color="error"
                        sx={{ minHeight: "1.5rem" }}
                    >
                        {errors.lastName?.message}
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{ pt: 1 }}>
                    <input
                        {...register("email")}
                        defaultValue=""
                        name="email"
                        type="text"
                        placeholder="E-mail*"
                        css={css`
                            margin: 0;
                        `}
                    />
                    <Typography
                        variant="body1"
                        color="error"
                        sx={{ minHeight: "1.5rem" }}
                    >
                        {touchedFields.email && errors.email?.message}
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{ mb: "1.5rem" }}>
                    {disclaimer}
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Button
                    type="submit"
                    form="coupon2"
                    fullWidth
                    variant="contained"
                    color="primary"
                    css={css`
                        padding-bottom: 8px;
                        margin-top: 10px !important;
                        width: 100%;
                        background-color: #595959 !important;
                        color: #fff !important;
                        border-radius: 0 !important;
                    `}
                >{`EMAIL ME THE COUPON`}</Button>
            </Grid>
        </form>
    )
}

export default Email
