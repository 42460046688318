import { Grid } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"

import { OetContext } from "../../context"
import { COUPON_API } from "../../util/couponApi"
import { containerCSS, getVisitedURL } from "../../util"
import { ContentGrid, Heading, renderComponent } from "."

export const OETEstimateSummary = ({
    content,
    heading,
    name,
    summaryDetail,
    viewConditionally,
}) => {
    const {
        state: { userInfo, leadId, location, estimateOutcome },
    } = useContext(OetContext)
    const [coupon, setCoupon] = useState()
    heading = heading?.fields || heading

    useEffect(() => {
        if (coupon && leadId && location && userInfo) {
            COUPON_API.sendStandardCoupon({
                storeId: location.storeId,
                isOetCoupon: true,
                leadId,
                firstName: userInfo.firstName,
                lastName: userInfo.lastName,
                preferredMethod: userInfo.preferredMethod,
                email: userInfo.email,
                phone: userInfo.phone,
                coupon,
                visitedUrl: getVisitedURL(window.location),
                userIp: sessionStorage.getItem("userIp"),
            })
        }
    }, [coupon, leadId, location, userInfo])

    if (viewConditionally && estimateOutcome && estimateOutcome !== name) {
        return null
    }

    let elements = []

    if (content?.length) {
        const couponToSend = content.find(
            ({ fields }) => fields.name === "estimate page - offer"
        )?.fields

        if (couponToSend && !coupon) setCoupon(couponToSend)

        elements.push(
            <ContentGrid
                {...{
                    items: content,
                }}
            />
        )
    }

    return (
        <Grid container item spacing={4} xs={12}>
            {heading && (
                <Grid item container xs={12}>
                    <Heading
                        {...{
                            ...heading,
                            textTransform: "none",
                        }}
                    />
                </Grid>
            )}
            {summaryDetail && (
                <Grid item xs={12}>
                    {renderComponent({ component: summaryDetail })}
                </Grid>
            )}
            {elements.length > 0 && (
                <Grid
                    container
                    item
                    direction="column"
                    spacing={2}
                    sx={{ ...containerCSS, pt: 0, pb: 0 }}
                >
                    {elements.map((element, index) => (
                        <Grid
                            item
                            key={`oet-estimate-summary-content-${index}`}
                        >
                            <React.Fragment>{element}</React.Fragment>
                        </Grid>
                    ))}
                </Grid>
            )}
        </Grid>
    )
}
