const { parseISO } = require("date-fns")

const convertConfig = (config) => {
    return config?.length
        ? config?.reduce(
            (prev, { key, value }) => ({ ...prev, [key]: JSON.parse(value) }),
            {}
        )
        : config
}

const isContentActive = ({ startDate, expirationDate, preview }) => {
    if (!startDate && !expirationDate) return true

    const now = new Date()

    startDate = startDate && parseISO(startDate)
    expirationDate = expirationDate && parseISO(expirationDate)

    const isAfterStartDate = preview || !startDate || now >= startDate
    const isBeforeExpirationDate = !expirationDate || now <= expirationDate

    return isAfterStartDate && isBeforeExpirationDate
}

module.exports = {
    convertConfig,
    isContentActive
}