const axios = require("axios")
const { format } = require("date-fns")

const { handleError } = require("./handlers")
const { NEW_API_URL, NEW_API_KEY } = require(".")

const APPOINTMENT_API = {
    ////////////////////////////
    // GET APPOINTMENT SCHEDULE
    ////////////////////////////
    getAppointmentSchedule: async (storeId, date) => {
        try {
            const { data } = await axios.get(
                `${NEW_API_URL}/sch/${storeId}?s=${date}`,
                {
                    headers: {
                        apikey: NEW_API_KEY,
                    },
                }
            )

            return data
        } catch (error) {
            return handleError(error)
        }
    },
    ////////////////////////////
    // GET STORE BY ID
    ////////////////////////////
    getStoreById: async (storeId) => {
        try {
            const { data } = await axios.get(
                `${NEW_API_URL}/stores/${storeId}`,
                {
                    headers: {
                        apikey: NEW_API_KEY,
                    },
                }
            )

            return data.length > 0 ? data[0] : data
        } catch (error) {
            return handleError(error)
        }
    },
    ////////////////////////////
    // SET MAACO APPOINTMENT
    ////////////////////////////
    setMaacoAppt: async (formData) => {
        try {
            const { data } = await axios.post(
                `${NEW_API_URL}/maaco/appointment`,
                formData,
                {
                    headers: {
                        apikey: NEW_API_KEY,
                        session_id: sessionStorage.getItem("sessionId"),
                    },
                }
            )

            return data
        } catch (error) {
            return handleError(error)
        }
    },
    ////////////////////////////
    // SET OET APPOINTMENT
    ////////////////////////////
    setOetAppt: async (formData) => {
        const {
            storeId,
            leadId,
            appointmentDate,
            appointmentTime,
            firstName,
            lastName,
            email,
            phone,
            year,
            make,
            model,
            comment,
            visitedUrl,
            userIp,
        } = formData
        try {
            const { data } = await axios.post(
                `${NEW_API_URL}/oet/appointment`,
                {
                    storeId,
                    leadId,
                    // HAVE TO PARSE DATE HERE FOR SOME REASON.
                    // GETTING INVALID TIME WHEN PASSING SAME
                    // DATE STRING AS THE OETAPPT COMPONENT
                    date: format(Date.parse(appointmentDate), "yyyy-MM-dd"),
                    time: appointmentTime,
                    slot: 1,
                    firstName,
                    lastName,
                    email,
                    phone,
                    year,
                    make,
                    model,
                    comment,
                    visitedUrl,
                    userIp,
                },
                {
                    headers: {
                        apikey: NEW_API_KEY,
                        session_id: sessionStorage.getItem("sessionId"),
                    },
                }
            )

            return data
        } catch (error) {
            return handleError(error)
        }
    },
}

module.exports = {
    APPOINTMENT_API,
}
