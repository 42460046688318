import React, { useState } from "react"
import Image from "next/image"

/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"

/**
 * Optimized Image is a customized component utilizing the Next/Image component.
 * OptimizedImage uses custom loader in next.config file.
 * All images across application are now implemented using OptimizedImage.
 * NextJS Note: When wrapping a custom component in a <Link>, you need to use forwardRef to avoid console errors
 */

const OptimizedImage = React.forwardRef(({
    alt,
    containerCss,
    containerStyle,
    height,
    layout = "fill",
    objectFit,
    src,
    width,
    file,
    ...props
}, ref) => {
    const [paddingTop, setPaddingTop] = useState("0")

    const fillObjectFit = layout === "fill" && !objectFit && "contain"
    let image = (file?.url || src) && (
        <Image
            {...props}
            alt={alt}
            src={file?.url || src}
            layout={layout}
            objectFit={fillObjectFit || objectFit}
            height={layout !== "fill" ? height || 0 : undefined}
            width={layout !== "fill" ? width || 0 : undefined}
            loader={({ src, width, quality }) =>
                `${src}?w=${width}&q=${quality || 75}&fm=webp`
            }
            onLoad={({ target: { naturalWidth, naturalHeight } }) => {
                if (naturalHeight <= 1 || naturalWidth <= 1) return

                setPaddingTop(
                    `calc(100% / (${naturalWidth} / ${naturalHeight})`
                )
            }}
        />
    )

    if (layout === "fill") {
        image = (
            <div
                style={{
                    paddingTop: !height ? paddingTop : 0,
                    width: "100%",
                    position: "relative",
                    height,
                }}
            >
                {image}
            </div>
        )
    }

    return (
        <div css={css(containerCss)} style={containerStyle}>
            {image}
        </div>
    )
})

OptimizedImage.displayName = 'OptimizedImage'

export default OptimizedImage
