/** @jsxImportSource @emotion/react */
/** @format */

import { Section } from "./section"

export const ImageBanner = ({ heading, image, ...props }) => {
    return (
        <Section
            {...{
                ...props,
                backgroundImage: image,
                heading: { text: heading, level: "h4" },
                verticalPadding: "both",
            }}
        />
    )
}
