import resourcesToBackend from "i18next-resources-to-backend"

import { Content, queryContent } from "../contentful/graphql"
import { I18N_NAMESPACES, defaults } from "./utils"

const loadContentfulResources = async (locale) => {
    // load header and footer content; pages are handled individually
    const promises = []
    Object.values(Content).forEach((item) => {
        const query = queryContent(item, { locale })
        promises.push(query)
    })
    return Promise.all(promises).then((responses) => {
        return responses.reduce((accumulator, obj) => {
            return { ...accumulator, ...obj }
        }, {})
    })
}

let loadFunction
const CustomBackend = resourcesToBackend((lng, ns, clb) => {
    switch (ns) {
        case I18N_NAMESPACES.CONTENT:
            loadFunction = loadFunction || loadContentfulResources(lng)
            loadFunction
                .then((resources) => {
                    clb(null, resources)
                })
                .catch(clb)
            break
        default:
            import(`../../public/locales/${lng}/${ns}.json`)
                .then(({ default: resources }) => {
                    clb(null, resources)
                })
                .catch((error) => {
                    clb(error, null)
                })
    }
})

const initI18next = ({ countryCode, i18next }) => {
    return i18next.use(CustomBackend).init(defaults({ countryCode }))
}

export { initI18next, CustomBackend }
