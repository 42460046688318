// STEP NAMES MANAGED HERE AND USED TO PULL STEP CONTENT FROM CONTENTFUL
export const OET_STEPS = {
    selectLocation: "Select Location",
    yourInfo: "Your Info",
    vehicleInfo: "Vehicle Info",
    vehicleServices: "Vehicle Services",
    estimate: "Estimate Summary",
}

// OET STEPS FOR MANAGING THE STEPPER COMPONENT AND OET NAVIGATION
export const OET_STEP_LIST = [
    {
        shortTitle: OET_STEPS.selectLocation,
        contentfulSteps: [OET_STEPS.selectLocation],
    },
    {
        shortTitle: OET_STEPS.yourInfo,
        contentfulSteps: [OET_STEPS.yourInfo],
    },
    {
        shortTitle: OET_STEPS.vehicleInfo,
        contentfulSteps: [OET_STEPS.vehicleInfo, OET_STEPS.vehicleServices],
    },
    {
        shortTitle: "Done",
        contentfulSteps: [OET_STEPS.estimate],
    },
]

// FORM NAMES MANAGED IN CONTENTFUL
export const OET_SUB_FORMS = {
    wholeVehicle: "Whole Vehicle",
    spotRepair: "Spot Repair",
    insurance: "Insurance",
    undecided: "Undecided",
}

// FORM NAMES MANAGED IN CONTENTFUL
export const OET_FORMS = {
    selectLocation: "Select Location",
    customerInfo: "Customer Info",
    vehicleInfo: "Vehicle Info",
    vehicleServices: "Vehicle Services",
    ...OET_SUB_FORMS,
}

// USED FOR MATCHING CONTENTFUL RULES AND DISPLAYING COUPONS
export const OET_COUPON_RULES = {
    customerAction: "Customer Action",
    atRisk: "At Risk",
}

// USED TO MAP WHICH ESTIMATE PAGE CONTENT TO SHOW
export const OET_OUTCOMES = {
    appointment: "appointment",
    appointmentContactStore: "appointment - contact store",
    atRisk: "at risk",
    customerAction: "customer action",
    fastPathAge: "fast path - age",
    fastPathEV: "fast path - EV",
    fastPathOther: "fast path - other",
    followUpInsurance: "follow up - insurance",
    followUpSpot: "follow up - spot repair",
    followUpUndecided: "follow up - undecided",
    wholeVehicle: "whole vehicle",
}

/////////////////////////////////////////////////////////////////
// THE FOLLOWING ARE DROP DOWN OPTIONS FOR VEHICLE INFO STEP 2/2
/////////////////////////////////////////////////////////////////
// SERVICE TYPES
export const serviceTypes = [
    {
        label: `<p>I'm looking to paint my <strong>entire vehicle</strong></p>`,
        value: OET_SUB_FORMS.wholeVehicle,
    },
    {
        label: `<p>I want to paint or repair <strong>part of my vehicle</strong>, and will pay out-of pocket</p>`,
        value: OET_SUB_FORMS.spotRepair,
    },
    {
        label: `<p>I have or plan to submit an <strong>insurance claim</strong> for collision-related damage</p>`,
        value: OET_SUB_FORMS.insurance,
    },
    {
        label: `<p>I need help deciding whether to submit an insurance claim or pay out of pocket</p>`,
        value: OET_SUB_FORMS.undecided,
    },
]

// WHOLE VEHICLE OPTIONS
export const paintTypes = [
    { label: "Basic Paint ($)", value: "basic" },
    { label: "Preferred Paint ($$)", value: "preferred" },
    { label: "Premium Paint ($$$)", value: "premium" },
]

export const paintPackages = {
    basic: "Basic Paint Package",
    preferred: "Preferred Paint Package",
    premium: "Premium Paint Package",
}

// SPOT REPAIR OPTIONS
export const spotRepairOptions = [
    {
        label: "I want to schedule an appointment to get my free estimate in person",
        value: OET_OUTCOMES.appointment,
    },
    {
        label: "I want a Maaco team member to follow up with me",
        value: OET_OUTCOMES.followUpSpot,
    },
    {
        label: "I plan to call or visit Maaco",
        value: OET_OUTCOMES.customerAction,
    },
    {
        label: "I'm just browsing and don't know if I'll follow up",
        value: OET_OUTCOMES.atRisk,
    },
]

// INSURANCE OPTIONS
export const insuranceOptions = [
    { label: "Yes", value: OET_OUTCOMES.appointment },
    { label: "No", value: OET_OUTCOMES.followUpInsurance },
]

// UNDECIDED OPTIONS
export const undecidedOptions = [
    {
        label: "I want to schedule an appointment to get my free estimate in person",
        value: OET_OUTCOMES.appointment,
    },
    {
        label: "I want a Maaco team member to follow up with me",
        value: OET_OUTCOMES.followUpUndecided,
    },
    {
        label: "I plan to call or visit Maaco",
        value: OET_OUTCOMES.customerAction,
    },
]
