import {
    Box,
    Button,
    Stack,
    Typography,
    Grid,
    useMediaQuery,
} from "@mui/material"
import { ArrowBackIos } from "@mui/icons-material"
import { useTheme } from "@mui/styles"
import { useRouter } from "next/router"
import { useCallback, useContext, useEffect, useRef, useState } from "react"
import { useFormContext } from "react-hook-form"

import { Context, OetContext } from "../../../../context"
import { handleCaughtError } from "../../../../util/handlers"
import { getFeatureFlag } from "../../../../util/oetApiHelpers"
import { Map } from "../../../contentful/map"
import Loader from "../../../Loader"
import StoreDetail from "../../Components/StoreDetail"
import StoreSearch from "../../Components/StoreSearch"
import { OET_API } from "../../../../util/oetApi"

const StoreLocations = () => {
    const { state, dispatch } = useContext(OetContext)
    const { state: maacoState, dispatch: maacoDispatch } = useContext(Context)
    const mapContainer = useRef(null)
    const router = useRouter()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
    const {
        formState: { errors },
    } = useFormContext()
    const validOetStore = (store) => {
        if (store?.oetActive && !store?.isComingSoon) return store

        return null
    }

    const [loading, setLoading] = useState(false)
    // USING TO SET STORE INITIAL VALUE OF CURRENT STORE OR OET LOCATION
    // REFERENCING maacoState?.currentStore DIRECTLY CAUSES BUGGY MAP AND STORE LIST BEHAVIOR
    const selectedStore = validOetStore(
        state?.location || maacoState?.currentStore
    )

    // THIS IS USED TO MANAGE CURRENT STORE THAT IS
    // HEAVILY USED WITHIN THE MAP COMPONENT
    const handleSelectStore = useCallback(
        (data) => {
            if (!data || !data.storeId) {
                maacoDispatch({
                    type: "CURRENT_STORE",
                    payload: undefined,
                })
            } else {
                maacoDispatch({
                    type: "CURRENT_STORE",
                    payload: data,
                })
            }
        },
        [maacoDispatch]
    )

    // THIS IS USED TO MANAGE THE STORE LIST THAT IS
    // REFERENCED WITHIN THE MAP COMPONENT
    const handleSetStores = useCallback(
        (stores, placeChange) => {
            // IF placeChange IS TRUE, WE DO NOT WANT TO SET AN
            // INITIAL STORE OR USE selectedStore BY DEFAULT
            const preSelected = !placeChange ? selectedStore : null

            maacoDispatch({
                type: "STORES",
                payload: stores,
                options: {
                    setInitialStore: preSelected || false,
                },
            })

            if (mapContainer?.current && isMobile)
                mapContainer.current.scrollIntoView({
                    behavior: "smooth",
                    inline: "start",
                })
        },
        [isMobile, maacoDispatch, selectedStore]
    )

    useEffect(() => {
        // GET LOCATION FROM URL - OTHERWISE WE REFERENCE
        // state?.location IF THE USER HAS ALREADY SELECTED A STORE
        const storeId = router?.query?.storeid || state?.location?.storeId
        if (!router.isReady) return

        // IGNORE WILL BE TRUE WHEN SUBMITTING THE STORE LOCATION
        if (storeId && !state?.formSubmitting && !selectedStore) {
            OET_API.getOetStore(storeId)
                .then((res) => {
                    let location = res
                    // is store inactive
                    if (!location?.isActive) location = null

                    // is store oet active?
                    if (!location?.oetActive) location = null

                    // is store coming soon?
                    if (location?.isComingSoon) location = null

                    // is store temp closed?
                    if (location?.temporarilyClosed) location = null

                    maacoDispatch({
                        type: "CURRENT_STORE",
                        payload: location,
                    })
                })
                // TODO: REMOVE WHEN TESTING IS COMPLETE / FEATURE FLAGS ARE NO LONGER NEEDED
                .then(async () => {
                    await getFeatureFlag(storeId, dispatch)
                })
                .catch((e) => {
                    maacoDispatch({
                        type: "CURRENT_STORE",
                        payload: undefined,
                    })
                    dispatch({
                        type: "ERRORS",
                        payload:
                            e.message ??
                            "Could not get store information. Please refresh or try again.",
                    })
                    handleCaughtError("error at grabbing store id from url", e)
                })
        }
    }, [
        dispatch,
        maacoDispatch,
        router.isReady,
        router.query.storeid,
        selectedStore,
        state?.formSubmitting,
        state?.location,
    ])

    return (
        <Grid container justifyContent="center" spacing={6}>
            <Grid container item>
                <StoreSearch
                    selected={selectedStore}
                    setLoading={setLoading}
                    setStores={handleSetStores}
                />
            </Grid>
            {loading && (
                <Grid item xs={12}>
                    <Loader loading={loading} />
                </Grid>
            )}
            <Grid container item display="flex" spacing={3}>
                <Grid
                    sx={{
                        order: { xs: 2, md: 1 },
                        minHeight: { xs: "100%", md: "450px" },
                    }}
                    item
                    sm={6}
                    xs={12}
                >
                    {maacoState?.stores?.length > 0 && errors.length > 0 && (
                        <Typography
                            variant="body1"
                            color="error"
                            sx={{
                                mb: "10px",
                                justifyContent: "center",
                            }}
                        >
                            {errors}
                        </Typography>
                    )}
                    {/* SHOW LIST OF STORES FROM SEARCH */}
                    {!validOetStore(maacoState?.currentStore) &&
                        maacoState?.stores?.length > 0 && (
                            <Box
                                sx={{
                                    maxHeight: { xs: "500px", md: "450px" },
                                    overflow: "auto",
                                    overflowY: "scroll",
                                }}
                            >
                                {maacoState?.stores.map(
                                    (data, k) =>
                                        !data.isComingSoon &&
                                        data.oetActive && (
                                            <Box key={k} sx={{ mb: 2 }}>
                                                <StoreDetail
                                                    key={k}
                                                    selected={
                                                        data.storeId ===
                                                        state?.location?.storeId
                                                    }
                                                    data={data}
                                                    setSelected={(store) =>
                                                        handleSelectStore(store)
                                                    }
                                                />
                                            </Box>
                                        )
                                )}
                            </Box>
                        )}
                    {/* SHOW PRE-SELECTED LOCATION WITH OPTION TO GO BACK */}
                    {validOetStore(maacoState?.currentStore) &&
                        maacoState?.stores?.length > 0 && (
                            <Stack alignItems="flex-start" spacing={2}>
                                <Button
                                    variant="text"
                                    startIcon={<ArrowBackIos />}
                                    sx={{
                                        fontSize: 14.4,
                                        fontWeight: 400,
                                        textTransform: "none",
                                        width: "auto",
                                    }}
                                    onClick={() => handleSelectStore()}
                                >
                                    Find a different shop
                                </Button>
                                <StoreDetail
                                    data={maacoState?.currentStore}
                                    hideSelectButton={true}
                                />
                            </Stack>
                        )}
                </Grid>
                {/* SHOW MAP ONLY IF STORES HAVE BEEN LOADED */}
                {(maacoState?.stores?.find((store) => !!validOetStore(store)) ||
                    validOetStore(maacoState?.currentStore)) && (
                    <Grid
                        item
                        sm={6}
                        xs={12}
                        sx={{
                            height: { xs: "250px", md: "100%" },
                            order: { xs: 1, md: 2 },
                        }}
                        ref={mapContainer}
                    >
                        <Map mapTypeControl={true} showAllStores={true} />
                    </Grid>
                )}
            </Grid>
            {/* SHOW BUTTON ONLY IF STORE IS SELECTED */}
            {validOetStore(maacoState?.currentStore) &&
                maacoState?.stores?.length > 0 && (
                    <Grid item>
                        <Button
                            color="secondary"
                            disabled={state?.formSubmitting}
                            type="submit"
                            variant="contained"
                        >
                            Continue
                        </Button>
                    </Grid>
                )}
        </Grid>
    )
}

export default StoreLocations
