/** @format */

/** @jsxImportSource @emotion/react */
import { UTCDate } from "@date-fns/utc"
import { css } from "@emotion/react"
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material"
import { Button, Grid } from "@mui/material"
import axios from "axios"
import { addDays, format } from "date-fns"
import React, { useContext, useEffect, useState } from "react"

import { Context } from "../../context"
import { NEW_API_KEY, NEW_API_URL, calculateAvailableDates } from "../../util"
import { handleCaughtError } from "../../util/handlers"
import Appointments from "../Estimate/Appointments"
import Time from "../Estimate/Appointments/Time"

const ScheduleAppointment = () => {
    const [startingDate, setStartingDate] = useState(new Date())
    const [beginDate, setBeginDate] = useState(null)
    const [availableDates, setAvailableDates] = useState([])
    const [openScheduleAppointment, setOpenScheduleAppointment] = useState()
    const { state } = useContext(Context)
    const { currentStore: store } = state

    useEffect(() => {
        if (!store || beginDate) return

        calculateAvailableDates(
            store,
            startingDate,
            setStartingDate,
            beginDate,
            setBeginDate,
            setAvailableDates
        )
    }, [beginDate, startingDate, store])

    const [dateRange, setDateRange] = useState(0)
    const [appData, setAppData] = useState([])
    const [selectedDate, setSelectedDate] = useState({
        time: "",
        date: "",
        slot: "",
    })
    const handleSchedule = () => {
        setOpenScheduleAppointment(true)
    }

    useEffect(() => {
        if (store && store.storeId) {
            const config = {
                headers: {
                    apikey: NEW_API_KEY,
                    session_id: sessionStorage.getItem("sessionId"),
                },
            }

            let newDate = format(
                addDays(new UTCDate(), dateRange),
                "yyyy-MM-dd"
            )

            axios
                .get(
                    `${NEW_API_URL}/sch/${store.storeId}?s=${newDate}`,
                    config
                )
                .then((res) => {
                    setAppData(res.data)
                })
                .catch((e) => {
                    handleCaughtError("error at appointment api", e)
                })
        }
    }, [store, dateRange])

    return (
        <Grid item xs={12}>
            {availableDates.length > 0 ? (
                <Grid
                    container
                    justifyContent={`space-between`}
                    css={css`
                        padding: 4px 12px;
                        & > p {
                            text-transform: uppercase;
                            display: flex;
                            align-items: center;
                        }
                    `}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        {appData.map((data, i) => {
                            if (i >= 0 && i <= 3) {
                                return (
                                    <Grid
                                        item
                                        xs={3}
                                        sx={{ m: 0, p: "3px" }}
                                        key={i}
                                    >
                                        {" "}
                                        <Time
                                            key={i}
                                            data={data}
                                            setPage={() => { }}
                                            setSelectedDate={(date) =>
                                                setSelectedDate(date)
                                            }
                                            selectedDate={selectedDate}
                                            handleSelect={() => {
                                                handleSchedule()
                                            }}
                                            storeData={store}
                                        />{" "}
                                    </Grid>
                                )
                            }
                        })}
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                    >
                        <Grid item>
                            <ArrowBackIos
                                fontSize="small"
                                sx={{ color: "#AF0E11", ml: 1 }}
                            />
                            <Button
                                variant="text"
                                sx={{
                                    color: "#595959",
                                    width: 115,
                                    padding: 0,
                                    margin: 0,
                                }}
                                disabled={dateRange === 0 ? true : false}
                                onClick={() => {
                                    const newRange = dateRange - 4
                                    setDateRange(newRange)
                                    setSelectedDate({
                                        date: "",
                                        time: "",
                                        slot: "",
                                    })
                                }}
                            >
                                Earlier Dates
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="text"
                                sx={{
                                    color: "#595959",
                                    padding: 0,
                                    margin: 0,
                                }}
                                onClick={() => {
                                    const newRange = dateRange + 4
                                    setDateRange(newRange)
                                    setSelectedDate({
                                        date: "",
                                        time: "",
                                        slot: "",
                                    })
                                }}
                            >
                                More Dates
                            </Button>
                            <ArrowForwardIos
                                sx={{ color: "#AF0E11", mr: 1 }}
                                fontSize="small"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            ) : (
                "No Appointments Available"
            )}
            {openScheduleAppointment && (
                <Appointments
                    open={openScheduleAppointment}
                    location={{
                        data: store,
                    }}
                    handleClose={() => {
                        setOpenScheduleAppointment(false)
                    }}
                    endpoint={"/maaco/sch"}
                    date={selectedDate}
                />
            )}
        </Grid>
    )
}

export default ScheduleAppointment
