const { getEnvironmentVariable } = require("../env")

const getConfig = () => {
    return {
        isPreview: JSON.parse(
            getEnvironmentVariable({
                key: "CONTENTFUL_IS_PREVIEW",
                isOptional: true,
            }) || false
        ),
        space: getEnvironmentVariable({
            key: "CONTENTFUL_SPACE_ID",
        }),
        environment: getEnvironmentVariable({
            key: "CONTENTFUL_ENVIRONMENT",
        }),
        getLocale: () => {
            const countryCode = getEnvironmentVariable({
                key: "COUNTRY_CODE",
            })

            return countryCode === "ca" ? 'en-CA' : "en-US"
        },
        accessToken: (preview) =>
            !preview
                ? getEnvironmentVariable({
                    key: "CONTENTFUL_ACCESS_TOKEN",
                })
                : getEnvironmentVariable({
                    key: "CONTENTFUL_PREVIEW_ACCESS_TOKEN",
                }),
        host: (preview) =>
            !preview ? "cdn.contentful.com" : "preview.contentful.com",
    }
}

module.exports = getConfig()
