import NotFoundCountdown from "../NotFoundCountdown"
import Seo from "../SEO"
import { ErrorUI } from "../ErrorUI"

const FourZeroFour = ({ queryValues, messages, seo }) => {
    const validateQueryValues = (values) => {
        return values?.redirect?.length > 0 && values?.timeout > 0
    }

    return (
        <>
            <Seo
                title={`This page could not be found | Maaco`}
                description="This page could not be found."
                noIndex={seo?.noindex}
                noFollow={seo?.nofollow}
            />
            <ErrorUI.Layout>
                {validateQueryValues(queryValues) ? (
                    <NotFoundCountdown queryValues={queryValues} />
                ) : (
                    <ErrorUI.Content>
                        <ErrorUI.Heading>404</ErrorUI.Heading>
                        <ErrorUI.Messages>
                            {messages || (
                                <ErrorUI.Message>
                                    This page was not found.
                                </ErrorUI.Message>
                            )}
                        </ErrorUI.Messages>
                    </ErrorUI.Content>
                )}
            </ErrorUI.Layout>
        </>
    )
}

export default FourZeroFour
