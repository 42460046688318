import { Component } from "react"
import { withRouter } from "next/router"
import { ErrorUI } from "../ErrorUI"
import Link from "../Link"
import { reportError, handleWindowError } from "./utils"

/**
 * Handles any uncaught client-side exceptions
 *
 * For errors caught by React during render, logs/reports the caught error &
 * renders a fallback UI allowing the user to reload the page
 *
 * For errors outside React, logs/reports the error as long as the error happens
 * after this component mounts
 *
 * TODO: implement additional error boundary to use above `<Provider>`
 * component; this one relies on content from `<Provider>` to render the page
 * header/footer in `<ErrorUI>`, so it can't be used to handle errors in
 * `<Provider>` or its parents
 */
class AppErrorBoundary extends Component {
    state = { shouldShowError: false, hasRendered: false }

    static getDerivedStateFromError() {
        return { shouldShowError: true }
    }

    reset = () => {
        this.setState({ shouldShowError: false })
    }

    componentDidMount() {
        window.addEventListener("error", handleWindowError)
        window.addEventListener("unhandledrejection", handleWindowError)
        this.setState({ hasRendered: true })
    }

    componentWillUnmount() {
        window.removeEventListener("error", handleWindowError)
        window.removeEventListener("unhandledrejection", handleWindowError)
    }

    // Clear the error UI when the route changes (e.g. user clicks back button)
    componentDidUpdate(prevProps) {
        if (this.props.router.asPath !== prevProps.router.asPath) {
            this.reset()
        }
    }

    componentDidCatch(error, errorInfo) {
        console.error({ error, errorInfo })
        reportError({ error: error?.toString(), errorInfo })
    }

    render() {
        if (!this.state.shouldShowError) return this.props.children

        // In case the error was caught during initial hydration, render nothing
        // on first pass to avoid DOM mismatch/reconciliation issues
        if (!this.state.hasRendered) return null

        return (
            <ErrorUI heading="Error">
                Something went wrong while loading this page. Please{" "}
                <Link href={this.props.router.asPath} onClick={this.reset}>
                    reload the page
                </Link>{" "}
                or try again later.
            </ErrorUI>
        )
    }
}

export default withRouter(AppErrorBoundary)
