/** @jsxImportSource @emotion/react */
/** @format */

import { css } from "@emotion/react"
import { Grid, Typography } from "@mui/material"
import { useState } from "react"
import MD from "react-markdown"

import Link from "../Link"
import { Heading } from "."

export const Accordion = ({ heading, recordsCollection = {}, ...props }) => {
    let { records } = props
    records = records || recordsCollection.items || []

    const [selectedRow, setSelectedRow] = useState(null)

    return (
        <Grid
            css={css`
                color: #595959;
                margin-top: 1rem;
                @media (max-width: 767px) {
                    padding-right: 15px;
                    margin-bottom: 1rem;
                }
            `}
        >
            <Heading {...{ text: heading, level: "h4" }} />

            {records.map((record, index) => {
                const accordionItem = record.fields || record

                return (
                    <Grid
                        key={index}
                        container
                        css={css`
                            border: 1px solid #333;
                            ${index >= 0 && index !== records.length - 1
                                ? "border-bottom: none;"
                                : ""}
                            display: flex;
                            background-color: ${selectedRow === index
                                ? `#ebebeb`
                                : "#FFF"};
                        `}
                    >
                        <Grid
                            item
                            sm={11}
                            css={css`
                                padding: 0 15px;
                                @media (max-width: 767px) {
                                    width: 90%;
                                }
                            `}
                        >
                            <Typography
                                css={css`
                                    font-size: 0.9rem;
                                    font-weight: 500;
                                    margin: 0.5rem 0;
                                `}
                            >
                                {accordionItem.title}
                            </Typography>

                            <Typography
                                css={css`
                                    font-size: 0.9rem;
                                    display: ${index === selectedRow
                                        ? "block"
                                        : "none"};
                                `}
                                component="div"
                            >
                                <MD
                                    components={{
                                        a: ({ node, ...props }) => {
                                            return (
                                                <Link
                                                    {...props}
                                                    underline="hover"
                                                />
                                            )
                                        },
                                    }}
                                >
                                    {accordionItem.body}
                                </MD>
                            </Typography>
                        </Grid>

                        <Grid
                            item
                            sm={1}
                            container
                            onClick={() =>
                                setSelectedRow(
                                    selectedRow === index ? -1 : index
                                )
                            }
                            css={css`
                                border-left: 1px solid #333;
                                cursor: pointer;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                & > p {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    font-size: 2rem;
                                    color: #b30f11;
                                    font-weight: bold;
                                }
                                @media (max-width: 767px) {
                                    width: auto;
                                    flex: 1;
                                }
                            `}
                        >
                            {selectedRow === index ? (
                                <Typography>-</Typography>
                            ) : (
                                <Typography>+</Typography>
                            )}
                        </Grid>
                    </Grid>
                )
            })}
        </Grid>
    )
}
