import { Divider, Grid, Typography } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import { UTCDate } from "@date-fns/utc"
import { zonedTimeToUtc } from "date-fns-tz"
import { format, parse } from "date-fns"
import { Controller, useFormContext } from "react-hook-form"
import { useCallback, useContext, useEffect, useState } from "react"

import { OetContext } from "../../../../context"
import { APPOINTMENT_API } from "../../../../util/appointmentApi"
import { handleCaughtError } from "../../../../util/handlers"
import { cleanPhone, onPhoneClick, reformatPhone } from "../../../../util"
import { FormDropdown, generateLabel } from "../../../Forms/utils/form-elements"
import Link from "../../../Link"
import { OetStoreDetails } from "../../utils/storeHelpers"

// THIS IS USED FOR DISABLING DAYS ON CALENDAR
const dayMap = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
}

export const OETAppointment = () => {
    const [appointmentError, setAppointmentError] = useState(false)
    const [apptData, setApptData] = useState([])
    const [apptTimes, setApptTimes] = useState([])
    const [loadingApptData, setLoadingApptData] = useState(false)
    const { state: { formSubmitting, location } = {}, dispatch } =
        useContext(OetContext)
    const {
        control,
        formState: { errors },
        watch,
    } = useFormContext()

    const selectedDate = watch("appointmentDate")

    const disableClosedDays = useCallback(
        (date) => {
            const daysClosed = []
            for (const [key, value] of Object.entries(
                location.appointmentSchedule
            )) {
                if (!value.isOpen) {
                    daysClosed.push(dayMap[key])
                }
            }
            if (daysClosed.length) {
                // DISABLE DATES ON CALENDAR THAT ARE INCLUDED IN DAYSCLOSED ARRAY
                return daysClosed.includes(date.getDay())
            }
        },
        [location.appointmentSchedule]
    )

    const filterTimes = useCallback(
        (times, selectedDate, currentUserUtcDateTime = new UTCDate()) =>
            times
                ?.map((time) => {
                    // FILTER OUT SLOTS THAT ARE CLOSED OR HAVE MORE THAN 3
                    let disable =
                        time.slot >= 3 || time.slot === "closed" ? true : false

                    if (selectedDate) {
                        const dateTimeSlot = parse(
                            selectedDate + " " + time.time,
                            "yyyy-MM-dd hh:mm a",
                            new Date()
                        )

                        const utcTimeSlot = zonedTimeToUtc(
                            dateTimeSlot,
                            location.timezone
                        )

                        // FILTER OUT PAST TIMES
                        if (currentUserUtcDateTime >= utcTimeSlot) {
                            disable = true
                        }
                    }

                    return disable ? undefined : time
                })
                .filter((time) => time),
        [location.timezone]
    )

    const getApptSchedule = useCallback(
        (newDate) => {
            setLoadingApptData(true)

            APPOINTMENT_API.getAppointmentSchedule(location?.storeId, newDate)
                .then((storeTimeData) => {
                    const result = []

                    if (!storeTimeData || !storeTimeData.length) {
                        setApptData(result)
                        setApptTimes(result)
                        return
                    }

                    const currentUserUtcDateTime = new UTCDate()
                    const currentUserTimezone =
                        Intl.DateTimeFormat().resolvedOptions().timeZone
                    const { timezone: storeTimezone } = location

                    storeTimeData.forEach((item) => {
                        if (!item.times || !item.times.length) return

                        const currDate = item.date // this is already in the correct format
                        const storeClosingDateTimeString =
                            currDate +
                            " " +
                            item.times[item.times.length - 1].time

                        const storeClosingDateTime = parse(
                            storeClosingDateTimeString,
                            "yyyy-MM-dd hh:mm a",
                            new Date()
                        )
                        const storeClosingUtcDateTime = zonedTimeToUtc(
                            storeClosingDateTime,
                            storeTimezone || currentUserTimezone
                        )

                        if (currentUserUtcDateTime < storeClosingUtcDateTime) {
                            result.push(item)

                            return
                        }
                    })
                    setLoadingApptData(false)
                    setApptData(result)
                })
                .catch((e) => {
                    setLoadingApptData(false)
                    setApptTimes([])
                    dispatch({
                        type: "ERRORS",
                        payload:
                            e.message ??
                            "Could not fetch the store schedule. Please try again.",
                    })
                    handleCaughtError("Error at appointment api", e)
                })
        },
        [dispatch, location]
    )

    const fetchApptSchedule = useCallback(
        async (newDate) => {
            if (apptData?.length) {
                const dateInApptData = apptData.findIndex(
                    ({ date }) => date === newDate
                )

                if (dateInApptData !== -1) {
                    return
                }
            }

            return getApptSchedule(newDate)
        },
        [apptData, getApptSchedule]
    )

    useEffect(() => {
        setApptTimes([])

        if (
            location.scheduleActive &&
            apptData?.length &&
            selectedDate &&
            !loadingApptData
        ) {
            const formattedDate = format(Date.parse(selectedDate), "yyyy-MM-dd")
            const filteredAppointmentTimes = filterTimes(
                apptData?.find(({ date }) => date === formattedDate)?.times,
                formattedDate
            )
            setAppointmentError(!filteredAppointmentTimes?.length)
            setApptTimes(filteredAppointmentTimes)
        }
    }, [
        apptData,
        filterTimes,
        loadingApptData,
        location.scheduleActive,
        selectedDate,
    ])

    if (!location) return null

    return (
        <Grid
            container
            item
            spacing={1}
            sx={{
                backgroundColor: "#E8E8E8",
                boxSizing: "border-box",
                p: 2,
            }}
        >
            {/* STORE DETAILS */}
            <OetStoreDetails location={location} />
            <Grid item sx={{ width: "100%" }}>
                <Divider />
            </Grid>
            <Grid
                item
                container
                columnSpacing={{ xs: 1, sm: 1, md: 2 }}
                rowSpacing={{ xs: 1, sm: 1, md: 2 }}
            >
                {/* IF LOCATION HAS ONLINE SCHEDULING ACTIVE, SHOW SCHEDULING COMPONENTS */}
                {location.scheduleActive && (
                    <>
                        <Grid item xs={12} md={6}>
                            {generateLabel(
                                "Choose date*",
                                "appointment-date-label",
                                true
                            )}

                            <Controller
                                name="appointmentDate"
                                control={control}
                                render={({ field: { onChange, ...field } }) => (
                                    <DatePicker
                                        {...field}
                                        disabled={formSubmitting}
                                        disablePast
                                        labelId="appointment-date-label"
                                        onChange={(e) => {
                                            onChange(e)
                                            fetchApptSchedule(
                                                format(e, "yyyy-MM-dd")
                                            )
                                        }}
                                        inputProps={{
                                            "aria-label":
                                                "appointment-date-picker",
                                        }}
                                        shouldDisableDate={disableClosedDays}
                                        slotProps={{
                                            actionBar: {
                                                sx: {
                                                    justifyContent:
                                                        "space-between",
                                                },
                                            },
                                            calendarHeader: {
                                                sx: {
                                                    justifyContent:
                                                        "space-between",
                                                },
                                            },
                                            toolbar: {
                                                sx: {
                                                    gridArea:
                                                        "1 / 2 / auto / 4",
                                                },
                                            },
                                        }}
                                        sx={{ width: "100%" }}
                                    />
                                )}
                            />

                            {(errors.appointmentDate || appointmentError) && (
                                <Typography variant="body1" color="error">
                                    {appointmentError
                                        ? "No appointments available. Please choose another date"
                                        : errors.appointmentDate?.message}
                                </Typography>
                            )}
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormDropdown
                                disabled={
                                    formSubmitting ||
                                    loadingApptData ||
                                    !apptTimes?.length
                                }
                                dropdownOptions={
                                    apptTimes?.map(({ time }) => ({
                                        label: time,
                                        value: time,
                                    })) ?? []
                                }
                                dropdownLabel="Choose time*"
                                dropdownLabelKey="appointment-time"
                                isLoading={loadingApptData}
                                name="appointmentTime"
                                placeholder="Please select an appointment time"
                                showIfEmpty
                            />
                        </Grid>
                    </>
                )}
                {/* IF LOCATION DOES NOT HAVE ONLINE SCHEDULING ACTIVE, SHOW CONTACT INFO */}
                {!location.scheduleActive && (
                    <Grid item xs sx={{ textAlign: "center" }}>
                        <Typography variant="h4" component="span">
                            Please call the shop to book an appointment
                            <Typography
                                color="primary"
                                component="span"
                                variant="h4"
                                sx={{ ml: 1 }}
                            >
                                <Link
                                    href={`tel:${cleanPhone(
                                        location.organicPhone
                                    )}`}
                                    onClick={() => onPhoneClick(location)}
                                >
                                    {reformatPhone(location.organicPhone)}
                                </Link>
                            </Typography>
                        </Typography>
                    </Grid>
                )}
            </Grid>
        </Grid>
    )
}
