/** @format */
// import Font Awesome CSS
import "@fortawesome/fontawesome-free/css/brands.css"
import "@fortawesome/fontawesome-free/css/fontawesome.css"
import "@fortawesome/fontawesome-free/css/solid.css"
import { ThemeProvider } from "@mui/material/styles"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import {
    FBPixelScript,
    FBPixelProvider,
} from "@rivercode/facebook-conversion-api-nextjs/components"
import { appWithTranslation, useTranslation } from "next-i18next"
import Head from "next/head"
import Script from "next/script"

import { OetProvider, PreviewProvider, Provider } from "../context"
import i18nextConfig from "../next-i18next.config"
import "../public/fonts/styles.css"
import "../styles/globals.css"
import theme from "../theme"
import { GA_ID, GTM_ID, SHOW_COOKIE_BANNER } from "../util"
import { AppErrorBoundary } from "../components/AppErrorBoundary"

function MyApp({ Component, pageProps }) {
    const { ready } = useTranslation()
    if (!ready) return null

    return (
        <ThemeProvider theme={theme}>
            <FBPixelScript />
            <FBPixelProvider>
                {/* Needed for datepicker */}
                <PreviewProvider preview={pageProps?.preview}>
                    <OetProvider>
                        <Provider>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <AppErrorBoundary>
                                    <Head>
                                        <meta charSet="utf-8" />
                                        <meta
                                            name="viewport"
                                            content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=1.0"
                                        />
                                        <meta
                                            name="theme-color"
                                            content={theme.palette.primary.main}
                                        />
                                        <meta
                                            name="facebook-domain-verification"
                                            content="nl473f340ysin2wfgy1jo1no5zo6c6"
                                        />
                                        <meta
                                            name="google-site-verification"
                                            content="ICg310jDX4Z-vaMnGpTaFq7vGzvDFMJ809PfXvgtVDo"
                                        />

                                        <title>Maaco</title>
                                        <script
                                            id="gtag-dataLayer"
                                            dangerouslySetInnerHTML={{
                                                __html: `window.dataLayer = window.dataLayer || [];
                                function gtag(){dataLayer.push(arguments);}
                                gtag('js', new Date());
                                gtag('config', '${GA_ID}');`,
                                            }}
                                        />
                                        {/* Cookie Banner Consent Code */}
                                        {SHOW_COOKIE_BANNER && (
                                            <script
                                                id="cookie-banner-blocking-script"
                                                type="text/javascript"
                                                src="https://cdn-prod.securiti.ai/consent/auto_blocking/3ce629a5-84d7-4002-952a-cf4c582b12db/81c2eba2-247f-41df-a13f-72b74957a472.js"
                                                defer
                                            />
                                        )}
                                    </Head>
                                    {/* Google Tag Manager - Global base code */}
                                    <Script
                                        id="gtag-base"
                                        strategy="afterInteractive"
                                        dangerouslySetInnerHTML={{
                                            __html: `
                                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                                })(window,document,'script','dataLayer', '${GTM_ID}');
                            `,
                                        }}
                                    />
                                    {/* Google Analytics Code */}
                                    <Script
                                        id="ga-base"
                                        strategy="afterInteractive"
                                        src={`https://www.googletagmanager.com/gtag/js?id=${GA_ID}`}
                                    />
                                    {/* Clinch Pixel Code */}
                                    <Script
                                        id="clinch-global"
                                        strategy="afterInteractive"
                                        dangerouslySetInnerHTML={{
                                            __html: `
                                        !function(c, l, e, v, n, t, s)
                                        {
                                            if (c.clq) return; n = c.clq = function() { n.callMethod? n.callMethod.apply(n, arguments) : n.queue.push(arguments) };
                                            if (!c._clq) c._clq = n; n.push = n; n.loaded = !0; n.version = '2.0'; n.queue = [];
                                            t = l.createElement(e); t.async = !0; t.src = v; s = l.getElementsByTagName(e)[0];
                                            s.parentNode.insertBefore(t, s)
                                        }
                                        (window, document, 'script', 'https://cdn.clinch.co/a_js/client_pixels/clq/script.min.js');
                                        clq('init', 'wkc75TG', {'cid':'woz77TF', 'product':'ecommerce'});
                                        clq('track', 'PageView');
                                
                            `,
                                        }}
                                    />
                                    <Component {...pageProps} />
                                    {/* Cookie Banner UI Code */}
                                    {SHOW_COOKIE_BANNER && (
                                        <Script
                                            id="cookie-consent-script"
                                            type="text/javascript"
                                        >
                                            {`
                                            (function () {
                                            var s = document.createElement('script')
                                            s.src = 'https://cdn-prod.securiti.ai/consent/cookie-consent-sdk.js'
                                            s.setAttribute('data-tenant-uuid', '3ce629a5-84d7-4002-952a-cf4c582b12db')
                                            s.setAttribute('data-domain-uuid', '81c2eba2-247f-41df-a13f-72b74957a472')
                                            s.setAttribute('data-backend-url', 'https://app.securiti.ai')
                                            s.defer = true
                                            var parent_node = document.head || document.body
                                            parent_node.appendChild(s)
                                            s.addEventListener('load', function () { window.initCmp() })
                                            })()
                                        `}
                                        </Script>
                                    )}
                                </AppErrorBoundary>
                            </LocalizationProvider>
                        </Provider>
                    </OetProvider>
                </PreviewProvider>
            </FBPixelProvider>
        </ThemeProvider>
    )
}

export default appWithTranslation(MyApp, i18nextConfig)
