/** @format */
/** @jsxImportSource @emotion/react */
import { Search } from "@mui/icons-material"
import {
    Box,
    Button,
    Grid,
    InputAdornment,
    OutlinedInput,
    Stack,
    Typography,
} from "@mui/material"
import { useState } from "react"

import { OET_API } from "../../util/oetApi"
import { handleCaughtError } from "../../util/handlers"
import { useFormContext } from "react-hook-form"

const VINEntryForm = () => {
    const [errorsMsg, setErrorsMsg] = useState("")
    const [loading, setLoading] = useState(false)
    const [searchResults, setSearchResults] = useState()

    const {
        register,
        formState: { errors },
        setValue,
        watch,
    } = useFormContext()

    const vin = watch("vin")

    const searchVIN = (VIN) => {
        setErrorsMsg("")
        setLoading(true)
        setSearchResults()

        OET_API.getVehicleByVIN(VIN)
            .then((response) => {
                const vehicle = response[0]

                const { year, make, model } = vehicle

                setSearchResults({ year, make, model })
                setLoading(false)
                setValue("year", year, { shouldDirty: true })
                setValue("make", make, { shouldDirty: true })
                setValue("model", model, { shouldDirty: true })
            })
            .catch((error) => {
                setLoading(false)
                setErrorsMsg(
                    "We are unable to find your VIN number. Please check that your VIN number is correct and try again, or you can input car details manually instead."
                )
                handleCaughtError("Error getting car info from VIN ", error)
            })
    }

    const onChangeFormHandler = (event) => {
        const name = event.target.getAttribute("name")

        let value = event.target.value

        setValue(name, value, {
            shouldValidate: true,
            shouldDirty: true,
        })
    }

    return (
        <Grid item xs={12}>
            <Stack
                sx={{
                    padding: 3,
                    backgroundColor: "#E2F1FF",
                    borderRadius: "5px",
                    gap: {
                        xs: 2,
                        md: 0,
                    },
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: {
                            xs: "column",
                            sm: "row",
                        },
                        alignItems: { xs: "start", sm: "center" },
                        gap: { xs: 1, sm: 2 },
                    }}
                >
                    <OutlinedInput
                        {...register("vin")}
                        name={"vin"}
                        id={"vin-number"}
                        style={{
                            borderRadius: "5px",
                            backgroundColor: "#fff",
                        }}
                        fullWidth
                        placeholder={"Enter your vehicle’s VIN #"}
                        endAdornment={
                            <InputAdornment position="end">
                                <Search
                                    style={{
                                        color: "rgba(0, 0, 0, 0.23)",
                                    }}
                                />
                            </InputAdornment>
                        }
                        inputProps={{ maxLength: 17 }}
                        onChange={(e) => {
                            onChangeFormHandler(e)
                        }}
                    />
                    <Button
                        variant="contained"
                        color={"primary"}
                        sx={{
                            borderRadius: "5px",
                            margin: 0,
                        }}
                        onClick={() => searchVIN(vin)}
                        type="button"
                        disabled={loading}
                    >
                        Find
                    </Button>
                </Box>
                {errors?.vin && (
                    <Typography variant="body1" color="error">
                        {errors.vin?.message}
                    </Typography>
                )}
                {searchResults?.year &&
                    searchResults?.make &&
                    searchResults?.model && (
                        <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                        >
                            <Typography variant="h6" color="primary">
                                {searchResults.year} {searchResults.make}{" "}
                                {searchResults.model}
                            </Typography>
                        </Stack>
                    )}
                {errorsMsg?.length > 0 && (
                    <Stack>
                        <Typography variant="body1" color="error">
                            {errorsMsg}
                        </Typography>
                    </Stack>
                )}
            </Stack>
        </Grid>
    )
}

export default VINEntryForm
