import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import { Grid, Typography } from "@mui/material"

import Link from "../Link"

// THIS COMPONENT IS NOT THE SAME AS MARKDOWN.
// THE CONTENTFUL RICH TEXT CONTENT TYPE HAS EXTENSIVE CAPABILITIES
// IN REFERENCING, EMBEDDING, AND LINKING OTHER CONTENT TYPES.
// IT'S CURRENTLY BEING USED LIKE MARKDOWN, BUT CAN BE ENHANCED TO INCLUDE MORE
export const RichText = ({ alignment = "inherit", content, ...props }) => {
    content = content?.content || content

    if (!content?.length) return null

    const options = {
        preserveWhitespace: true,
        renderMark: {
            [MARKS.BOLD]: (text) => (
                <Typography
                    component="span"
                    variant="body1"
                    fontFamily="Gilroy Bold"
                >
                    {text}
                </Typography>
            ),
        },
        renderNode: {
            [BLOCKS.HEADING_1]: (node, children) => (
                <Typography variant="h1" sx={{ mb: 2 }} textAlign={alignment}>
                    {children}
                </Typography>
            ),
            [BLOCKS.HEADING_2]: (node, children) => (
                <Typography variant="h2" sx={{ mb: 2 }} textAlign={alignment}>
                    {children}
                </Typography>
            ),
            [BLOCKS.HEADING_3]: (node, children) => (
                <Typography variant="h3" sx={{ mb: 2 }} textAlign={alignment}>
                    {children}
                </Typography>
            ),
            [BLOCKS.HEADING_4]: (node, children) => (
                <Typography variant="h4" sx={{ mb: 2 }} textAlign={alignment}>
                    {children}
                </Typography>
            ),
            [BLOCKS.HEADING_5]: (node, children) => (
                <Typography variant="h5" textAlign={alignment}>
                    {children}
                </Typography>
            ),
            [BLOCKS.HEADING_6]: (node, children) => (
                <Typography variant="h6" textAlign={alignment}>
                    {children}
                </Typography>
            ),
            [BLOCKS.PARAGRAPH]: (node, children) => (
                <Typography variant="body1" textAlign={alignment}>
                    {children}
                </Typography>
            ),
            [INLINES.HYPERLINK]: (node, children) => (
                <Link href={node.data.uri} underline="hover">
                    {children}
                </Link>
            ),
        },
    }

    return content.map((item, index) => (
        <Grid
            container
            item
            key={`rich-text-block${index}`}
            justifyContent={alignment}
        >
            {documentToReactComponents(item, options)}
        </Grid>
    ))
}
