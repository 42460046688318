import { Grid } from "@mui/material"
import React, { useContext } from "react"

import { OetContext } from "../../context"
import { containerCSS } from "../../util"
import { ContentGrid, Heading, RichText } from "."

export const OETSection = ({
    description,
    displayRules,
    content,
    heading,
    viewConditionally,
    ...props
}) => {
    const {
        state: { service: { type = "" } = {} },
    } = useContext(OetContext)
    heading = heading?.fields || heading
    description = description?.fields || description

    if (viewConditionally && !displayRules?.includes(type)) {
        return null
    }

    let elements = []

    if (content.length) {
        elements.push(
            <ContentGrid
                {...{
                    items: content,
                }}
            />
        )
    }

    return (
        <Grid container item xs={12} {...props.sx}>
            {heading && (
                <Grid item container xs={12}>
                    <Heading
                        {...{
                            ...heading,
                            textTransform: "none",
                        }}
                    />
                </Grid>
            )}
            {description && (
                <Grid item xs={12}>
                    <RichText {...description} />
                </Grid>
            )}
            <Grid
                container
                item
                direction="column"
                sx={{ ...containerCSS, pt: 0, pb: 0 }}
            >
                {elements.length > 0 &&
                    elements.map((element, index) => (
                        <React.Fragment key={`element-${index}`}>
                            {element}
                        </React.Fragment>
                    ))}
            </Grid>
        </Grid>
    )
}
