import {
    Apple,
    EventAvailable,
    Google,
    LocalPostOffice,
} from "@mui/icons-material"
import {
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
} from "@mui/material"
import { google, outlook, office365, yahoo, ics } from "calendar-link"
import { setHours, setMinutes } from "date-fns"
import { zonedTimeToUtc } from "date-fns-tz"
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state"
import React, { useContext } from "react"

import { Context } from "../../../context"
import { getEnvironmentVariable } from "../../../util/env"
import { constructStateLink } from "../../../util"
import { NextLinkComposed } from "../../Link"
import { StyledButton } from "../Button"

const convertTo24Hours = (time) => {
    const isAm = time.match(/([A-Z])\w+/g).pop() === "AM"
    const removeAmPm = time.replace(/([A-Z])\w+/g, "")
    const [hours, minutes] = removeAmPm.split(":")

    const timeReturn = {
        hours: Number(hours),
        minutes: Number(minutes),
    }

    if (isAm) {
        timeReturn.hours = hours === "12" ? 0 : Number(hours)
    } else {
        timeReturn.hours = hours === "12" ? Number(hours) : Number(hours) + 12
    }

    return timeReturn
}

export const AddToCalendarButton = ({
    title,
    description,
    allDay = false,
    duration = [15, "minute"],
    appointmentDate,
    appointmentTime,
    location,
    url,
}) => {
    const { state: { states } = {} } = useContext(Context)

    const time = convertTo24Hours(appointmentTime)
    let startTime = setHours(appointmentDate, time.hours)
    startTime = setMinutes(startTime, time.minutes)
    const utcDate = zonedTimeToUtc(startTime, location.timezone)

    const event = {
        allDay,
        title: title ?? `Estimate Appointment with ${location.storeName}`,
        description,
        duration,
        start: utcDate,
        url:
            url ??
            `${getEnvironmentVariable({
                key: "SITE_URL",
            })}${constructStateLink(location, states)}`,
    }

    const calendarLinks = [
        {
            name: "Apple",
            icon: <Apple fontSize="small" />,
            link: ics(event),
        },
        {
            name: "Google",
            icon: <Google fontSize="small" />,
            link: google(event),
        },
        {
            name: "Office365",
            icon: <LocalPostOffice fontSize="small" />,
            link: office365(event),
        },
        {
            name: "Outlook",
            icon: <LocalPostOffice fontSize="small" />,
            link: outlook(event),
        },
        {
            name: "Yahoo",
            icon: <LocalPostOffice fontSize="small" />,
            link: yahoo(event),
        },
    ]

    return (
        <PopupState variant="popover" popupId="add-to-calendar-popup-menu">
            {(popupState) => (
                <React.Fragment>
                    <StyledButton
                        {...bindTrigger(popupState)}
                        endIcon={<EventAvailable />}
                        variant="text"
                    >
                        Add to calendar
                    </StyledButton>
                    <Menu {...bindMenu(popupState)}>
                        {calendarLinks.map(({ name, icon, link }, index) => (
                            <MenuItem
                                key={`calendar-link-${name}--${index}`}
                                onClick={popupState.close}
                            >
                                <ListItemButton
                                    LinkComponent={NextLinkComposed}
                                    to={link}
                                    title={`Add to ${name} calendar`}
                                    target="_blank"
                                    rel="noreferrer"
                                    sx={{ p: `8px 16px !important` }}
                                >
                                    <ListItemIcon>{icon}</ListItemIcon>
                                    <ListItemText>{name}</ListItemText>
                                </ListItemButton>
                            </MenuItem>
                        ))}
                    </Menu>
                </React.Fragment>
            )}
        </PopupState>
    )
}
