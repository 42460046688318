/** @format */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { Button, Box, Grid, Stack, Typography } from "@mui/material"
import { useTranslation } from "next-i18next"
import React, { useContext } from "react"

import { Context } from "../../../../context"
import { findState, reformatPhone } from "../../../../util"
import { consolidateStoreHours, weekendHours } from "../../utils/storeHelpers"

const StoreDetail = ({
    hideSelectButton = false,
    selected,
    data,
    setSelected,
}) => {
    const { t } = useTranslation()
    const { state } = useContext(Context)
    const showSelectButton = !hideSelectButton && setSelected

    if (!data) return null

    return (
        <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="flex-start"
            sx={{ backgroundColor: "#ebebeb", padding: 2, width: "100%" }}
            spacing={0}
        >
            <Grid
                container
                item
                alignItems="center"
                justifyContent="space-between"
                sx={{ mb: 1 }}
            >
                <Grid item>
                    <Typography
                        variant="h6"
                        color="primary"
                        fontFamily="Gilroy Medium"
                        sx={{ m: 0 }}
                    >
                        {data.storeName}
                    </Typography>
                </Grid>
                {data.distance > 0 && (
                    <Grid item>
                        <Typography
                            variant="body3"
                            css={css`
                                margin: 5px 1px;
                                text-align: right;
                            `}
                            color
                        >
                            {t("distance", {
                                count:
                                    t("distance") === "kilometers"
                                        ? +(
                                              Number.parseFloat(data.distance) *
                                              1.609
                                          ).toFixed(2)
                                        : +Number.parseFloat(
                                              data.distance
                                          ).toFixed(2),
                            })}
                        </Typography>
                    </Grid>
                )}
            </Grid>
            <Grid
                container
                item
                alignItems="flex-start"
                justifyContent="space-between"
                sx={{ mb: 1 }}
            >
                <Grid item xs={6}>
                    <Stack>
                        <Typography variant="body3">
                            {data.storeAddress}
                        </Typography>
                        <Typography variant="body3">
                            {data.storeCity},{" "}
                            {findState(data.storeState, state) ||
                                data.storeState}{" "}
                            {data.storePostcode}
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack sx={{ display: "flex" }}>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            {consolidateStoreHours(data.hours)}
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            {weekendHours(
                                data.hours["Saturday"],
                                data.hours["Sunday"]
                            )}
                        </Box>
                    </Stack>
                </Grid>
            </Grid>

            <Grid item sx={{ mb: showSelectButton ? 1 : 0 }}>
                <Typography variant="body3" color="primary">
                    {reformatPhone(data.organicPhone)}
                </Typography>
            </Grid>

            {showSelectButton && (
                <Grid item xs={12}>
                    <Button
                        variant={selected ? "contained" : "outlined"}
                        color="secondary"
                        css={css`
                            width: 240px;
                            background-color=#000000;
                            font-color: #af0e11;
                        `}
                        onClick={() => {
                            setSelected(data)
                        }}
                    >
                        Select location
                    </Button>
                </Grid>
            )}
        </Grid>
    )
}

export default StoreDetail
