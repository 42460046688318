import Link from "next/link"
import { Grid, Typography } from "@mui/material"

const DmaHeadingUI = ({ children, error, showLink }) => {
    return (
        <>
            <Grid
                container
                sx={{
                    px: ".5rem",
                    py: "2rem",
                    mt: "1.5rem",
                    bgcolor: "#B30F11",
                }}
                justifyContent="center"
            >
                <Grid item xs={12}>
                    <Typography variant="h4" color="white" align="center">
                        LOCATE EXCLUSIVE COUPONS AND OFFERS
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        variant="body1"
                        component="p"
                        color="white"
                        align="center"
                        sx={{ my: "1rem" }}
                    >
                        FOR A SHOP NEAR YOU
                    </Typography>
                </Grid>
                {children}
            </Grid>
            <Typography
                variant="h6"
                align="center"
                sx={{
                    p: ".75rem",
                    m: "0",
                    color: "red",
                }}
            >
                {error}
                {showLink && (
                    <Link href="/offers/">
                        Please find a location nearest you.
                    </Link>
                )}
            </Typography>
        </>
    )
}

export default DmaHeadingUI
