const API_PROMOS = [
    { path: "/20-percent-off-750-minimum" },
    { path: "/20-percent-off-1500-minimum" },
    { path: "/100-off-body-labor" },
    { path: "/100-off-body-labor-winter" },
    { path: "/499-fall-special" },
    { path: "/499-overall-paint-sale" },
    { path: "/549-fall-special" },
    { path: "/599-fall-special" },
    { path: "/dasher-deals" },
    { path: "/fresno-30-off-paint" },
    { path: "/summer-promo-499-paint-special" },
    { path: "/summer-promo-599-paint-special" },
    { path: "/summer-promo-699-paint-special" },
    { path: "/summer-promo-799-paint-special" },
    { path: "/summer-promo-899-paint-special" },
    { path: "/winter-promo-50-off" },
    { path: "/winter-promo-549-paint" },
    { path: "/tax-season-2023-100-off-body" },
    { path: "/tax-season-2023-50-off-first-panel" },
    { path: "/tax-season-2023-899-paint-special" },
    { path: "/tax-season-2023-799-paint-special" },
    { path: "/tax-season-2023-699-paint-special" },
    { path: "/tax-season-2023-599-paint-special" },
    { path: "/tax-season-2023-499-paint-special" },
]

const LEAD_GEN = [
    { path: "/landing/lead-gen-50-off-paint" },
    { path: "/landing/lead-gen-200-off-body-labor" },
    { path: "/landing/lead-gen-30-off-paint" },
]

module.exports = {
    API_PROMOS,
    LEAD_GEN,
}
