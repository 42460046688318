/** @format */
import { Checkbox, FormControlLabel, Grid, Typography } from "@mui/material"
import { useCallback, useContext, useEffect, useState } from "react"
import { useSWRConfig } from "swr"

import { Context, PreviewContext } from "../../context"
import { couponPayload } from "../../util"
import { CouponEmailButton } from "../Buttons/CouponEmail"
import { CouponTextButton } from "../Buttons/CouponText"
import { ContentCoupon } from "./coupon"
import { convertConfig, isContentActive } from "./util"

export const CouponGroup = ({ config = {}, coupons = [] }) => {
    const { state } = useContext(Context)
    const { state: { preview } = {} } = useContext(PreviewContext)
    const { fallback } = useSWRConfig()

    const mappedCoupons =
        coupons.length > 0
            ? coupons?.map((coupon) => coupon.fields ?? coupon)
            : []
    const [couponData, setCouponData] = useState(mappedCoupons ?? [])
    const { currentStore: store = fallback?.store } = state
    const newConfig = config?.length ? convertConfig(config) : config
    const { isTextInactive, isEmailInactive } = newConfig

    let { couponLimit } = newConfig
    couponLimit = couponLimit === 0 ? coupons.length : couponLimit || 4

    useEffect(() => {
        if (store) {
            // In case store coupons are not an array
            const storeCoupons = store.coupon?.length
                ? store.coupon
                : [store.coupon]
            setCouponData(storeCoupons)
        }
    }, [store])

    const onChangeSelectedCoupon = useCallback(
        (coupon) => {
            const newData = couponData.map((item) => {
                if (item?.id === coupon?.id) {
                    item.checked = !item.checked
                }

                return item
            })

            setCouponData(newData)
        },
        [couponData]
    )

    const couponsToDeliver = couponData
        ?.filter((item) => item?.checked)
        .map((coupon) => couponPayload(coupon))

    if (!couponData) return null

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            flexWrap="nowrap"
        >
            <Grid
                item
                container
                justifyContent="center"
                xs={12}
                sx={{ m: 1.25 }}
                spacing={store ? 0 : 2}
            >
                {couponData.slice(0, couponLimit).map((coupon, i) => {
                    const isCouponActive = isContentActive({
                        ...coupon,
                        preview,
                    })
                    const couponWidth = config.couponWidth ?? 12
                    if (!isCouponActive) return null

                    return (
                        <Grid
                            alignItems="center"
                            item
                            xs={12}
                            sm={store ? 3 : couponWidth}
                            key={`${i}_coupon`}
                            sx={{
                                backgroundColor: "common.white",
                                display: "flex",
                                flexDirection: "column",
                                m: store ? 1.76 : 0,
                                p: store ? 1.25 : 0,
                            }}
                        >
                            {/* TODO: ADD store to the list of props passed so the correct API is called if store is available */}
                            <ContentCoupon
                                {...{
                                    ...coupon,
                                    hideActions: true,
                                    isActive: isCouponActive,
                                    storeId: store?.storeId,
                                    // store,
                                }}
                            />

                            <FormControlLabel
                                control={<Checkbox color="green" />}
                                checked={coupon.checked}
                                onChange={() => {
                                    onChangeSelectedCoupon(coupon)
                                }}
                                label={
                                    !coupon.checked ? (
                                        "Select this coupon"
                                    ) : (
                                        <strong>Selected</strong>
                                    )
                                }
                                sx={{
                                    ml: 1,

                                    ".MuiFormControlLabel-label": {
                                        mt: 0,
                                    },
                                }}
                            />
                        </Grid>
                    )
                })}
            </Grid>

            <Grid
                item
                container
                xs={12}
                alignItems="center"
                justifyContent="center"
                sx={{
                    my: 1.25,
                }}
            >
                {!isEmailInactive && (
                    <CouponEmailButton
                        {...{
                            disabled: !couponsToDeliver.length,
                            coupons: couponsToDeliver,
                            store,
                        }}
                    >
                        EMAIL
                    </CouponEmailButton>
                )}

                {!isTextInactive && (
                    <CouponTextButton
                        {...{
                            disabled: !couponsToDeliver.length,
                            coupons: couponsToDeliver,
                            store,
                        }}
                    >
                        TEXT
                    </CouponTextButton>
                )}

                <Grid
                    item
                    container
                    xs={12}
                    alignItems="center"
                    justifyContent="center"
                >
                    <Typography
                        component={"div"}
                        variant="h3"
                        sx={{
                            m: 1,
                            textTransform: "uppercase",
                            fontSize: "0.85rem",
                        }}
                    >
                        {`SELECTED OFFERS: ${couponsToDeliver.length}`}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}
