const LOCALES = { us: "en-US", ca: "en-CA" }

const I18N_NAMESPACES = {
    FRONT: "front",
    MISC: "misc",
    CONTENT: "content",
}

const defaults = ({ countryCode }) => {
    const lng = countryCode ? LOCALES[countryCode] : LOCALES.us

    return {
        lng,
        fallbackLng: LOCALES.us,
        preload: [lng],
        defaultNS: I18N_NAMESPACES.FRONT,
        ns: Object.values(I18N_NAMESPACES),
        returnObjects: true,
        // debug: process.env.APP_ENV !== "production",
        i18n: {
            defaultLocale: lng,
            locales: [lng], // only load the lng we are building
        },
        interpolation: {
            format(value, format, lng) {
                // e.g. format "95" as "95%" in en-US
                if (format === "whole%") {
                    return Number(value / 100).toLocaleString(lng, {
                        style: "percent",
                        minimumFractionDigits: 2,
                    })
                }
                // e.g. format "0.95" as "95%" in en-US
                if (format === "real%") {
                    return Number(value).toLocaleString(lng, {
                        style: "percent",
                        minimumFractionDigits: 2,
                    })
                }

                if (format === "rounded") {
                    // const precision = value < 1 ? 2 : 0 // Keeping in case this is needed
                    return Math.round(value).toLocaleString(lng)
                }

                // e.g. round to 2 decimal places - "1.0789" to "1.08"
                if (format === "decimal") {
                    return value.toFixed(2).toLocaleString(lng)
                }

                return value
            },
        },
    }
}

module.exports = { I18N_NAMESPACES, defaults, LOCALES }
