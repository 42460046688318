import { Card } from "./index"

export const ContentSection = ({
    heading,
    headingAlign,
    headingColor,
    horizontalAlignment,
    imageAlign,
    subtitle,
    title: { fields: title } = {},
    ...props
}) => {
    return (
        <Card
            {...{
                ...props,
                contentAlignment: horizontalAlignment,
                imageAlignment: imageAlign,
                subtitle: { text: subtitle },
                title: title?.text || heading,
                titleAlignment:
                    title?.alignment || headingAlign || horizontalAlignment,
                titleColor: headingColor,
                titleConfig: title,
            }}
        />
    )
}
