/** @format */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { Grid, Typography } from "@mui/material"
import { useContext } from "react"

import Link from "../Link"
import { Context } from "../../context"
import { onPhoneClick, slugify } from "../../util"
import BookAnAppointment from "../Buttons/BookAnAppointment"

export const MsoStores = ({ stores }) => {
    const { state, dispatch } = useContext(Context)
    const { states, stores: contextStores } = state

    const findState = (inputState) => {
        const stateFilter = states.find(
            (index) => index.stateName === inputState
        )

        return stateFilter.stateAbbreviation.toLowerCase()
    }

    return (
        <Grid
            container
            columnSpacing={{ xs: 0, sm: 2, md: 2 }}
            rowSpacing={{ xs: 1, sm: 2, md: 2 }}
        >
            {(stores || contextStores || []).map((element) => (
                <Grid
                    item
                    key={element.storeId}
                    className="locationdma-storeinfo"
                    xs={12}
                    sm={6}
                    md={4}
                >
                    <div
                        css={css`
                            margin: 10px 0;
                            border: 1px solid #f4f4f4;
                            font-size: 1.3rem;
                            background-color: #f2f2f2;
                            transition: all 0.5s ease-in-out;
                            padding: 12px;
                        `}
                    >
                        <Typography
                            color="primary"
                            css={css`
                                display: flex;
                                margin: 0;
                                font-weight: 700;
                                text-decoration: none;
                                &:hover {
                                    text-decoration: underline;
                                }
                                @media (max-width: 767px) {
                                    font-size: 1.2rem;
                                }
                            `}
                            component="span"
                            variant="h6"
                        >
                            <Link
                                href={`/locations/${findState(
                                    element.storeState
                                )}/${slugify(
                                    `${element.storeCity} ${element.storeId}`
                                )}/`}
                                noLinkStyle
                            >
                                {`MAACO ${element.storeCity} ${element.storeId}`}
                            </Link>
                        </Typography>

                        <Typography
                            variant="h6"
                            color="primary"
                            css={css`
                                padding-bottom: 10px;
                                display: flex;
                                text-decoration: none;
                                &:hover {
                                    text-decoration: underline;
                                }
                                @media (max-width: 767px) {
                                    font-size: 1.2rem;
                                }
                            `}
                            component="span"
                        >
                            <Link
                                href={`tel:${element.organicPhone}`}
                                onClick={() => onPhoneClick(element)}
                            >
                                {element.organicPhone}
                            </Link>
                        </Typography>

                        <Typography variant="body1" width={250} component="div">
                            {element.geoAddress.substr(
                                0,
                                element.geoAddress.lastIndexOf(",")
                            )}
                        </Typography>

                        <Typography
                            variant="body1"
                            color="primary"
                            css={css`
                                display: flex;
                                padding-bottom: 10px;
                                margin: 0;
                                text-decoration: underline;
                                font-size: 0.75rem;
                                font-weight: 700;
                            `}
                            component="span"
                        >
                            <Link
                                target="_blank"
                                href={`https://www.google.com/maps/dir/?api=1&destination=${element.storeAddress},${element.storeCity},${element.storeState}`}
                            >
                                Get Directions
                            </Link>
                        </Typography>

                        {element.scheduleActive && !element.isComingSoon && (
                            <Grid>
                                <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    className={`mobile-t-center`}
                                    css={css`
                                        @media (max-width: 767px) {
                                            & button,
                                            a {
                                                width: 100%;
                                                min-height: 35px;
                                                margin-bottom: 3px;
                                            }
                                        }
                                    `}
                                >
                                    <Typography
                                        align="left"
                                        className="appointment-button"
                                    >
                                        <BookAnAppointment
                                            dmaName={element.dma}
                                            storeID={element.storeId}
                                            shouldOpenAppointments={true}
                                            handleOnClick={() => {
                                                dispatch({
                                                    type: "CURRENT_STORE",
                                                    payload: element,
                                                })
                                            }}
                                        />
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                    </div>
                </Grid>
            ))}
        </Grid>
    )
}
