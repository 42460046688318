/** @format */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { Grid, Typography } from "@mui/material"
import { useContext, useState, useMemo, useRef, useEffect } from "react"
import Geocode from "react-geocode"
import shuffle from "lodash.shuffle"
import DmaSearch from "./dmaSearch"
import Link from "../Link"
import { Context } from "../../context"
import getDeviceCoordinates from "../../util/getDeviceCoordinates"
import orderStoresByDistance from "../../util/orderStoresByDistance"
import {
    onPhoneClick,
    reformatPhone,
    slugify,
    GOOGLE_MAP_API_KEY,
} from "../../util"
import { handleCaughtError } from "../../util/handlers"
import BookAnAppointment from "../Buttons/BookAnAppointment"
import EstimateOnline from "../Buttons/EstimateOnline"
import { ContentCoupon } from "../contentful"
import DmaHeadingUI from "./dmaHeadingUI"

Geocode.setApiKey(GOOGLE_MAP_API_KEY)

export const DmaStores = ({ locale = "en-US", lang = "en-US", ...props }) => {
    const { state, dispatch } = useContext(Context)
    const { states: contextStates, stores: contextStores, content = {} } = state
    const { preSortedStoreList = false } = content
    const [showLink, setShowLink] = useState(false)
    const [error, setError] = useState("")
    const [storesByDistance, setStoresByDistance] = useState([])

    let { states = contextStates, stores = contextStores } = props

    const isSpanish = locale?.startsWith("es") || lang === "esp"

    const findState = (inputState) => {
        const stateFilter = states.find(
            (index) => index.stateName === inputState
        )

        return stateFilter?.stateAbbreviation.toLowerCase()
    }

    // Sync distance-based store sort w/ device coordinates on initial render
    useEffect(() => {
        let isNext = false

        if (!preSortedStoreList)
            getDeviceCoordinates()
                .then(({ latitude, longitude }) => {
                    if (!isNext) {
                        const sortedStores = orderStoresByDistance(
                            latitude,
                            longitude,
                            stores
                        )
                        setStoresByDistance(sortedStores)
                    }
                })
                .catch((e) => console.error(e))
        else setStoresByDistance(stores)

        return () => {
            isNext = true
        }
    }, [preSortedStoreList, stores])

    // Randomized store order; shuffle only once per page load
    const shuffledStores = useMemo(() => shuffle(stores), [stores])

    const handleError = (errorMessage, displayLink = false) => {
        setError(errorMessage)
        // displays a link to the offers page
        setShowLink(displayLink)
    }

    /**
     * Handles user selecting a location from the autocomplete
     * Sets distance-based store sort based on selected coordinates
     * Displays an error message instead if the closest store to the selected
     * coordinates is more than 150 miles away
     */
    const handleAutocompleteSelection = (placeString) => {
        Geocode.fromAddress(placeString)
            .then((response) => {
                const { lat, lng } = response.results[0].geometry.location

                const sortedStores = orderStoresByDistance(lat, lng, stores)

                const isOutsideDma =
                    sortedStores[sortedStores.length - 1].distance >= 150

                if (isOutsideDma) {
                    return handleError(
                        "This location is outside the current area. ",
                        true
                    )
                }

                setStoresByDistance(sortedStores)
            })
            .catch((e) => {
                handleError("Address not found", false)
                handleCaughtError(
                    "Error getting Google geocode from address",
                    e
                )
            })
    }

    /**
     * Clears all error messaging when user focuses the search input
     */
    const handleSearchFocus = () => handleError("", false)

    const storesToDisplay = storesByDistance.length
        ? storesByDistance
        : shuffledStores

    return (
        <>
            <DmaHeadingUI error={error} showLink={showLink}>
                <DmaSearch
                    onInputFocus={handleSearchFocus}
                    onPlaceChanged={handleAutocompleteSelection}
                />
            </DmaHeadingUI>
            <Grid
                container
                columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                rowSpacing={{ xs: 1, sm: 2, md: 2 }}
            >
                {storesToDisplay.map((store, index) => {
                    return (
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            key={`stores_${store.storeId}`}
                        >
                            <div
                                css={css`
                                    padding: 1em;
                                    border: 1px solid #f4f4f4;
                                    font-size: 1.3rem;
                                    background-color: #f2f2f2;
                                    transition: all 0.5s ease-in-out;
                                `}
                            >
                                <div>
                                    <Typography
                                        key={`${index}_coupon.storeCity/`}
                                        color="primary.dark"
                                        component="span"
                                        lineHeight="1.9rem"
                                        variant="h4"
                                    >
                                        <Link
                                            href={`/locations/${findState(
                                                store.storeState
                                            )}/${slugify(
                                                `${store.storeCity}`
                                            )}-${slugify(`${store.storeId}`)}`}
                                            noLinkStyle
                                        >
                                            {`Maaco ${store.storeCity} ${store.storeId}`}
                                        </Link>
                                    </Typography>

                                    <br />
                                    <Typography
                                        color="primary.light"
                                        component="span"
                                        variant="h5"
                                    >
                                        <Link
                                            href={`tel:${(
                                                store?.paidNumber || // DMA pages
                                                store?.organicPhone
                                            ) // MSO pages
                                                ?.replace(/ /g, ``)
                                                .replace(/-/g, ``)
                                                .replace(`(`, ``)
                                                .replace(`)`, ``)}`}
                                            onClick={() => onPhoneClick(store)}
                                        >
                                            {`${reformatPhone(
                                                store?.paidNumber || // DMA pages
                                                    store?.organicPhone // MSO pages
                                            )}`}
                                        </Link>
                                    </Typography>

                                    <Typography
                                        display="block"
                                        variant="body1"
                                        css={css`
                                            font-size: 0.9rem;
                                        `}
                                    >
                                        {store.storeAddress}
                                        <br />
                                        {`${store.storeCity}, ${store.storeState} ${store.storePostcode}`}
                                    </Typography>
                                    <Typography
                                        css={css`
                                            color: #095081;
                                        `}
                                        component="span"
                                        display="block"
                                        variant="body1"
                                    >
                                        <Link
                                            target="_blank"
                                            href={`https://www.google.com/maps/dir/?api=1&destination=${store.storeAddress},${store.storeCity},${store.storeState}`}
                                        >
                                            {isSpanish
                                                ? `Como Liegar`
                                                : `Get Directions`}
                                        </Link>
                                    </Typography>
                                    <Grid
                                        item
                                        xs={12}
                                        css={css`
                                            flex-direction: column;
                                            display: flex;
                                            margin-top: 10px !important;
                                            & a {
                                                min-height: 35px;
                                                margin-bottom: 3px;
                                            }
                                        `}
                                    >
                                        {store.scheduleActive &&
                                        !store.isComingSoon ? (
                                            <BookAnAppointment
                                                dmaName={store.dma}
                                                storeID={store.storeId}
                                                label={
                                                    isSpanish
                                                        ? `Reservar una cita`
                                                        : `Book an Appointment`
                                                }
                                                shouldOpenAppointments={true}
                                                handleOnClick={() => {
                                                    dispatch({
                                                        type: "CURRENT_STORE",
                                                        payload: store,
                                                    })
                                                }}
                                            />
                                        ) : (
                                            <div
                                                css={css`
                                                    min-height: 54px;
                                                `}
                                            />
                                        )}
                                        {store.oetActive &&
                                        !store.isComingSoon ? (
                                            <EstimateOnline
                                                dmaName={store.dma}
                                                label={
                                                    isSpanish
                                                        ? `Obtenga una cotización en línea`
                                                        : `Get Online Estimate`
                                                }
                                                storeID={store.storeId}
                                            />
                                        ) : (
                                            <div
                                                css={css`
                                                    min-height: 54px;
                                                `}
                                            />
                                        )}
                                    </Grid>
                                </div>

                                {store.coupon && (
                                    <div
                                        css={css`
                                            background: #006bb7;
                                            margin-top: 1em;
                                            position: relative;
                                            padding: 2.5rem 1em;
                                        `}
                                    >
                                        {/* TODO: ADD store to the list of props passed so the correct API is called */}
                                        <ContentCoupon
                                            {...{
                                                ...store.coupon,
                                                storeId: store.storeId,
                                                // store,
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </Grid>
                    )
                })}
            </Grid>
        </>
    )
}
