/** @format */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { Grid, Typography, useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/styles"
import Link from "next/link"
import { useContext } from "react"
import { useSWRConfig } from "swr"

import { Context } from "../../context"
import { slugify } from "../../util"

export const Cities = ({ ...props }) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
    const { fallback } = useSWRConfig()
    const { state: appContent } = useContext(Context)

    const { content = fallback?.context } = appContent
    const columnCount = isMobile ? 2 : 4

    let { cities, state } = props
    let stateAbbreviation = state?.stateAbbreviation.toLowerCase()

    if (!cities || !state) {
        cities = cities || content.cities
        stateAbbreviation =
            stateAbbreviation || content.stateAbbreviation?.toLowerCase()
    }

    const splitLocations = (list) => {
        let skip = 0
        const take = Math.ceil(list.length / columnCount) || list.length

        let columnIndex = 1
        const columns = []
        while (columnIndex <= columnCount) {
            columns.push(list.slice(skip, take * columnIndex))
            skip = skip + take
            columnIndex++
        }

        return columns
    }

    const renderCityInfo = (data) =>
        data?.length > 0 &&
        data.map((cityName, k) => {
            return (
                <Link
                    passHref
                    href={`/locations/${slugify(
                        `${stateAbbreviation}`
                    )}/${slugify(`${cityName}`)}`}
                    key={`${k}_city_name/`}
                >
                    <Typography
                        component={"a"}
                        css={css`
                            font-size: 1.2rem;
                            color: #005275;
                            text-decoration: none;
                            &:hover {
                                text-decoration: underline;
                            }
                            display: block;
                            margin-top: 10px;
                        `}
                    >
                        {cityName}
                    </Typography>
                </Link>
            )
        })

    if (!cities?.length) return null

    return (
        <Grid container>
            {splitLocations(cities, columnCount).map((column, index) => {
                return (
                    <Grid item xs={12 / columnCount} key={index}>
                        <ul
                            css={css`
                                padding: 0;
                            `}
                        >
                            {renderCityInfo(column)}
                        </ul>
                    </Grid>
                )
            })}
        </Grid>
    )
}
