/** @format */

import { useTranslation } from "next-i18next"
import React, { useContext } from "react"
import { useSWRConfig } from "swr"

import { Context, appInitialState } from "../../../context"
import { isExternalLink } from '../../Link'
import { StyledButton } from "../Button"

const LinkButton = ({
    linkURL,
    target = "_self",
    variant = "outlined",
    display = "flex",
    ...props
}) => {
    const { t } = useTranslation()
    const { state } = useContext(Context)
    const { fallback } = useSWRConfig()

    const { content = fallback?.context } = state

    const href =
        content !== appInitialState.content
            ? t(linkURL, { defaultValue: linkURL, ...content })
            : linkURL
    target = isExternalLink(href) ? '_blank' : target

    return (
        <StyledButton {...{ display, href, target, variant, ...props }} />
    )
}

export default LinkButton
