/** @format */
/** @jsxImportSource @emotion/react */
import React from "react"
import { css } from "@emotion/react"
import { Typography } from "@mui/material"

import Link from "../Link"

const Links = ({ links }) =>
    links.map(({ link, title }) => (
        <Link href={link} key={`link_${link}`} noLinkStyle>
            <Typography
                variant="navigationLabel"
                css={css`
                    position: relative;
                    padding: 0;
                    display: flex;
                    margin: 0 7.5px;
                    font-size: 0.9rem;
                    font-weight: normal;
                    text-decoration: none;
                    text-transform: none;
                    &:hover {
                        text-decoration: underline;
                    }
                    &:not(:first-type):before {
                        content: "|";
                        margin: 0 5px;
                        position: absolute;
                        left: -16px;
                        top: -1px;
                    }
                    @media (max-width: 767px) {
                        position: static;
                        width: calc(50% - 7.5px);
                        margin-top: 7.5px;
                        margin-left: 0;
                        font-size: 0.85rem;
                    }
                `}
                component='p'
                title={title}
            >
                {title}
            </Typography>
        </Link>
    ))

export default Links
