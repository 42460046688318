/** @format */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { Grid } from "@mui/material"
import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import { SWRConfig } from "swr"

import FourOhFour from "../../pages/404"
import BreadCrumb from "../BreadCrumb"
import Layout from "../Layout"
import Seo from "../SEO"
import { getComponent, getHeroBanner, isContentActive } from "./index"

export const PageTypes = {
    State: "State",
    City: "City",
    DMA: "DMA",
    DMACollision: "DMACollision",
    DMAPaint: "DMAPaint",
    Store: "Store",
    StoreOffers: "StoreOffers",
    MSO: "MSO",
}

export const Pages = {
    [PageTypes.State]: {
        name: "Locations - State Page",
    },
    [PageTypes.City]: {
        name: "Locations - City Page",
    },
    [PageTypes.DMA]: {
        name: "DMA - Regular",
    },
    [PageTypes.DMACollision]: {
        name: "DMA - Collision Repair",
    },
    [PageTypes.DMAPaint]: {
        name: "DMA - Overall Paint",
    },
    [PageTypes.Store]: {
        name: "Store",
    },
    [PageTypes.StoreOffers]: {
        name: "Store Offers",
    },
}

export const ContentPage = ({ preview, promos = [], ...props }) => {
    let {
        content,
        context,
        breadcrumbs,
        includeLayout = true,
        storeClosedBanner,
        store,
        schema,
    } = props

    if (!content) {
        return <FourOhFour />
    }

    const { sys: { locale } = {} } = content || {}

    let {
        blocks,
        hero,
        pageHero,
        promotion,
        seo = {},
        blocksCollection = {},
    } = content.fields || content
    seo = seo.fields || seo
    blocks = blocks || blocksCollection.items || []
    pageHero = hero || pageHero
    pageHero = pageHero ? [pageHero] : []
    promotion = promotion?.fields || promotion

    if (promotion) {
        promos.push(promotion)
    }

    if (promos?.length) {
        const activePromoHeroes = promos
            .filter((promo) => isContentActive({ ...promo, preview }))
            .map((promo) => {
                return promo.hero
            })
            .filter((hero) => hero)

        pageHero = activePromoHeroes
    }

    const hideBreadcrumbs = breadcrumbs && !breadcrumbs.length
    // Used tp populate shareImage in SEO component
    const hasHero = pageHero.length > 0
    const firstHeroImage = pageHero[0]?.fields?.media?.fields?.file?.url

    const pageContents = (
        <SWRConfig value={{ fallback: { context, store } }}>
            <Seo
                context={context}
                title={seo.title}
                description={seo.description}
                noIndex={seo.noindex}
                noFollow={seo.nofollow}
                shareImage={
                    hasHero && firstHeroImage
                        ? `https:${firstHeroImage}`
                        : undefined
                }
                schema={schema}
            />

            <Grid container>
                {hasHero && (
                    <Grid
                        item
                        xs={12}
                        css={css`
                            & .slick-prev,
                            .slick-next {
                                z-index: 99;
                            }
                            & .slick-prev {
                                left: 8px !important;
                            }
                            & .slick-next {
                                right: 8px !important;
                            }
                        `}
                    >
                        <Slider
                            {...{
                                dots: true,
                                infinite: true,
                                speed: 500,
                                slidesToShow: 1,
                                slidesToScroll: 1,
                            }}
                        >
                            {getHeroBanner(
                                storeClosedBanner?.length
                                    ? storeClosedBanner
                                    : pageHero
                            )}
                        </Slider>
                    </Grid>
                )}

                {/* unless passed in props, navigations will be based on the URL paths */}
                {includeLayout && !hideBreadcrumbs && (
                    <BreadCrumb navigations={breadcrumbs} />
                )}

                {blocks &&
                    blocks.length > 0 &&
                    blocks.map((block, index) => {
                        const TypeComponent = getComponent(block)

                        return (
                            TypeComponent && (
                                <React.Fragment key={`block-${index}`}>
                                    <TypeComponent
                                        {...{ ...block.fields, locale }}
                                    />
                                </React.Fragment>
                            )
                        )
                    })}
            </Grid>
        </SWRConfig>
    )

    if (!includeLayout) return pageContents

    return (
        <Layout context={context} preview={preview} {...props}>
            {pageContents}
        </Layout>
    )
}
