/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { ArrowDropDown } from "@mui/icons-material"
import { Button, Menu, MenuItem } from "@mui/material"
import { useTranslation } from "next-i18next"
import React, { useState } from "react"

import Link from "../Link"
import OptimizedImage from "../OptimizedImage"

function CountrySelector() {
    const [anchorEl, setAnchorEl] = useState(null)
    // Check for element
    const open = Boolean(anchorEl)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const { t } = useTranslation()

    let countryOptions = t("content:header.localeSwitcherCollection.items", "")
    countryOptions = countryOptions || []

    return (
        <div>
            <Button
                aria-owns={open ? "simple-menu" : null}
                aria-haspopup="true"
                aria-controls="simple-menu"
                onClick={handleClick}
                style={{
                    width: "50px",
                    padding: 0,
                    margin: 0,
                }}
                disableRipple={true}
                css={css`
                    margin-left: 0px;
                    margin-right: 0px;
                `}
            >
                <div
                    css={css`
                        ${t("on_ca_site")
                            ? `width: 40px;
                    height: 40px;
                    padding-top: 0.6em;`
                            : `width: 36px;
                    height: 36px;`}
                    `}
                >
                    {/* TODO this should come from Contentful + remove from JSON */}
                    <OptimizedImage
                        src={t("flag_location")}
                        style={{ objectFit: "cover" }}
                        alt={t("flag")}
                    />
                </div>
                <ArrowDropDown />
            </Button>

            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={open}
                onMouseLeave={handleClose}
                onClose={handleClose}
                disableScrollLock={true}
                css={css`
                    margin-left: 0.2em;
                `}
            >
                <MenuItem onClick={handleClose}>
                    {countryOptions?.map(({ link, title }) => (
                        <Link
                            key={`link_${link}`}
                            href={link}
                            title={title}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <div
                                css={css`
                                    width: 36px;
                                    height: 36px;
                                `}
                            >
                                <OptimizedImage
                                    src={t("flag_alt_location")}
                                    style={{ objectFit: "cover" }}
                                    alt={t("flag_alt")}
                                />
                            </div>
                        </Link>
                    ))}
                </MenuItem>
            </Menu>
        </div>
    )
}

export default CountrySelector
