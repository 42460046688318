import { yupResolver } from "@hookform/resolvers/yup"
import { Close } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import {
    Box,
    Modal,
    Grid,
    Typography,
    IconButton,
    Autocomplete,
    TextField,
} from "@mui/material"
import axios from "axios"
import PropTypes from "prop-types"
import { useContext, useState, useEffect } from "react"
import { Controller, FormProvider, useForm } from "react-hook-form"
import * as Yup from "yup"

import { Context } from "../../context"
import {
    getVisitedURL,
    waitThisManySeconds,
    dataLayer,
    GTM_EVENTS,
    NEW_API_URL,
    NEW_API_KEY,
} from "../../util"
import { handleCaughtError } from "../../util/handlers"
import Link from "../Link"
import InputComponent from "../InputComponent"

const boxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    maxHeight: "90vh",
    minWidth: { xs: "100%", sm: "250px" },
    backgroundColor: "white",
    border: "2px solid grey",
    outline: "none",
    boxShadow: 24,
    boxSizing: "border-box",
    overflow: "hidden",
    overflowY: "auto",
    p: 4,
    py: 1.5,

    "& label, input, textarea, p, i": {
        fontSize: "0.85em",
    },
}

const propTypes = {
    onClose: PropTypes.func,
    storeID: PropTypes.any,
}

const formData = {
    firstName: {
        name: "firstName",
        fullInputName: "First Name",
        placeholder: "Your first name",
        defaultValue: "",
        validationSchema: Yup.string()
            .required("First name is required.")
            .max(30, "Must be less than 30 characters.")
            .trim(),
    },
    lastName: {
        name: "lastName",
        fullInputName: "Last Name",
        placeholder: "Your last name",
        defaultValue: "",
        validationSchema: Yup.string()
            .required("Last name is required.")
            .max(30, "Must be less than 30 characters.")
            .trim(),
    },
    email: {
        name: "email",
        fullInputName: "Email",
        placeholder: "Email ",
        defaultValue: "",
        validationSchema: Yup.string()
            .matches(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                "Email is not valid"
            )
            .required(`Email is required.`),
    },
    phone: {
        name: "phone",
        fullInputName: "Phone Number",
        placeholder: "Phone number",
        defaultValue: "",
        validationSchema: Yup.string()
            .matches(/^\(\d{3}\)[ ]\d{3}[-]\d{4}$/, "Phone number is not valid")
            .required(
                `Phone number is required and must be contain only numbers.`
            ),
    },
    services: {
        name: "services",
        fullInputName: "Services*",
        placeholder: "Choose a service",
        validationSchema: Yup.string()
            .required("Please choose a service for your inquiry")
            .nullable()
            .trim(),
    },
    inquiry: {
        name: "inquiry",
        fullInputName: "Inquiry",
        placeholder: "What are you looking for?",
        validationSchema: Yup.string()
            .required("Let us know what you're looking for.")
            .max(3000, "Must be less than 3000 characters."),
    },
}

const mapFormFields = (fields) => (
    <Grid
        item
        container
        direction={{ xs: "column", md: "row" }}
        spacing={{ xs: 0, md: 2 }}
    >
        {fields.map((fieldItem) => {
            return (
                <Grid
                    item
                    key={fieldItem.name}
                    sx={{
                        "& label": {
                            pt: 0,
                        },
                    }}
                    xs={12}
                    md={6}
                >
                    <InputComponent
                        name={fieldItem.name}
                        fullInputName={fieldItem.fullInputName}
                        placeholder={fieldItem.placeholder}
                        required
                        cssStyle={`
                        width: 100% !important;
                        .MuiOutlinedInput-root {
                            height: 38px;
                        }
                    `}
                    />
                </Grid>
            )
        })}
    </Grid>
)

const CustomerInquiryForm = ({ onClose, storeID }) => {
    const { state, dispatch } = useContext(Context)
    const { currentStore, inquiryInfo } = state
    const { firstName, lastName, email, phone, services, inquiry } = formData

    const [apiFail, setApiFail] = useState(false)
    const [apiSuccess, setApiSuccess] = useState(false)
    const [loading, setLoading] = useState(false)
    const [gtmEventStartedInLifecycle, setGtmEventStartedInLifecycle] =
        useState(false)
    const [buttonStatusColor, setButtonStatusColor] = useState("grey")

    const ID = storeID.toString()

    const validationSchema = Yup.object().shape({
        firstName: firstName.validationSchema,
        lastName: lastName.validationSchema,
        email: email.validationSchema,
        phone: phone.validationSchema,
        services: services.validationSchema,
        inquiry: inquiry.validationSchema,
    })

    const formContext = useForm({
        resolver: yupResolver(validationSchema),
        mode: "all",
        defaultValues: {
            firstName: inquiryInfo?.firstName ?? firstName.defaultValue,
            lastName: inquiryInfo?.lastName ?? lastName.defaultValue,
            email: inquiryInfo?.email ?? email.defaultValue,
            phone: inquiryInfo?.phone ?? phone.defaultValue,
            services: inquiryInfo?.services ?? services.defaultValue,
            inquiry: inquiryInfo?.inquiry ?? inquiry.defaultValue,
        },
    })

    const {
        formState: { isValid, errors, isDirty },
        control,
        reset,
        trigger,
        getValues,
        watch,
    } = formContext

    const serviceOptions = ["Auto Painting", "Collision Repair", "Other"]

    const handleSubmit = async () => {
        await trigger()
        if (Object.keys(errors).length !== 0) return

        const formData = getValues()
        dispatch({
            type: "INQUIRY_INFO",
            payload: {
                ...state.inquiryInfo,
                ...formData,
            },
        })

        const config = {
            headers: {
                apikey: NEW_API_KEY,
                session_id: sessionStorage.getItem("sessionId"),
            },
        }

        const payload = {
            storeId: ID,
            visitType: formData.services,
            comment: formData.inquiry,
            firstName: formData.firstName,
            lastName: formData.lastName,
            customerEmail: formData.email,
            customerPhone: formData.phone.replace(/\D/g, ""),
            visitedUrl: getVisitedURL(window.location),
            userIp: sessionStorage.getItem("userIp"),
        }

        //Try to hit the API
        setLoading(true)
        try {
            const response = await axios.post(
                `${NEW_API_URL}/oet/contact-center`,
                payload,
                config
            )

            // TODO: provide error feedback
            if (![200, 201, 204].includes(response.status))
                handleCaughtError("Server error", response)

            //GTM stuff
            dataLayer({
                event: GTM_EVENTS.inquiryComplete,
                gtm_storeid: ID,
                gtm_pagerefsource: window.document.referrer,
                gtm_dma: currentStore?.dma,
            })

            //Reset the form and display a success message if the inquiry goes through
            // Close the modal after giving the user enough time to read the message
            reset()
            dispatch({
                type: "INQUIRY_INFO",
                method: "reset",
            })
            setLoading(false)
            setApiFail(false)
            setApiSuccess(true)
            await waitThisManySeconds(6)
            onClose()
        } catch (err) {
            setLoading(false)
            setApiFail(true)
            if (err !== "Server Error") {
                handleCaughtError("Unknown Error", err)
            }
        }
    }

    //Sets the background color for the button based on the API response status
    //  maybe there's a better way to do this?
    useEffect(() => {
        if (!apiFail && !apiSuccess) {
            setButtonStatusColor("grey")
            return
        }
        if (apiFail) {
            setButtonStatusColor("grey")
            return
        }
        if (apiSuccess) {
            setButtonStatusColor("#2e7d32")
            return
        }
    }, [apiFail, apiSuccess])

    //Call this when the form is dirtied, once per lifecycle
    useEffect(() => {
        if (!isDirty || gtmEventStartedInLifecycle) return
        dataLayer({
            event: GTM_EVENTS.inquiryStart,
            gtm_storeid: ID,
            gtm_pagerefsource: window.document.referrer,
            gtm_dma: currentStore?.dma,
        })
        setGtmEventStartedInLifecycle(true)
    }, [currentStore, gtmEventStartedInLifecycle, ID, isDirty])

    return (
        <Modal open onClose={onClose}>
            <Box sx={boxStyle}>
                <Grid container>
                    <Grid item container justifyContent="flex-end">
                        <IconButton
                            onClick={() => {
                                onClose()
                            }}
                            sx={{
                                borderRadius: 0,
                                height: "2rem",
                                width: "2rem",
                            }}
                        >
                            <Close />
                        </IconButton>
                    </Grid>

                    <Grid
                        item
                        container
                        justifyContent="center"
                        alignItems="center"
                        direction="column"
                        sx={{ mb: 1 }}
                    >
                        <Typography
                            align="center"
                            variant="h4"
                            sx={{ color: "#005275", mt: 0 }}
                        >
                            CONTACT US TODAY
                        </Typography>

                        <Typography align="center">
                            Whether you need Auto-Painting or Collision Repair,
                            Maaco is here to help!
                        </Typography>

                        <Typography
                            component="i"
                            sx={{ alignSelf: "flex-start", mt: 1 }}
                        >
                            *Denotes required field
                        </Typography>
                    </Grid>

                    <Grid item container direction="column">
                        <form>
                            <FormProvider {...formContext}>
                                <Grid item container direction="column">
                                    {mapFormFields([firstName, lastName])}
                                    {mapFormFields([email, phone])}

                                    <Typography
                                        component="label"
                                        sx={{
                                            fontFamily: "Gilroy Medium",
                                            fontWeight: 500,
                                            pt: 0,
                                            zIndex: 2,
                                        }}
                                    >
                                        {services.fullInputName}
                                    </Typography>

                                    <Controller
                                        name={services.name}
                                        id={services.name}
                                        control={control}
                                        defaultValue={null}
                                        render={({
                                            field: { onChange, value },
                                        }) => (
                                            <Autocomplete
                                                autoHighlight
                                                autoSelect
                                                fullWidth
                                                options={serviceOptions}
                                                isOptionEqualToValue={(
                                                    option,
                                                    value
                                                ) => option === value}
                                                value={value || null}
                                                onChange={(_, data) =>
                                                    onChange(data || null)
                                                }
                                                sx={{ width: "100%" }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder={
                                                            services.placeholder
                                                        }
                                                        sx={{
                                                            "& .MuiOutlinedInput-input":
                                                                {
                                                                    p: "2px !important",
                                                                },
                                                            "& .MuiOutlinedInput-root":
                                                                {
                                                                    height: "38px",
                                                                },
                                                        }}
                                                    />
                                                )}
                                            />
                                        )}
                                    />

                                    <Typography variant="body1" color="error">
                                        {errors["services"]?.message}
                                    </Typography>

                                    <InputComponent
                                        name={inquiry.name}
                                        fullInputName={inquiry.fullInputName}
                                        placeholder={inquiry.placeholder}
                                        formProps={{
                                            inputProps: {
                                                maxLength: 3000,
                                            },
                                        }}
                                        multiline
                                        required
                                        rows={5}
                                        cssStyle={`
                                            width: 100% !important;
                                            & .MuiOutlinedInput-root {
                                                height: auto;
                                            }
                                        `}
                                    />
                                    {watch("inquiry")
                                        ? `${watch("inquiry").length} / 3000`
                                        : ""}
                                </Grid>
                            </FormProvider>
                        </form>
                    </Grid>

                    <Grid item flexDirection="column">
                        <Typography
                            sx={{
                                fontFamily: "Gilroy Bold",
                            }}
                        >
                            <Link href="/privacy-policy/" underline="hover">
                                Privacy Policy
                            </Link>
                        </Typography>

                        <Typography>
                            Or, contact{" "}
                            <Link
                                href={`/about/customer-service/?storeid=${storeID}`}
                                underline="hover"
                                sx={{
                                    fontFamily: "Gilroy Bold",
                                }}
                            >
                                Customer Service
                            </Link>
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        container
                        flexDirection="column"
                        alignItems="center"
                        sx={{ pt: 1 }}
                    >
                        <LoadingButton
                            type="submit"
                            variant="outlined"
                            loading={loading}
                            disabled={!isValid || apiSuccess}
                            onClick={() => {
                                handleSubmit()
                            }}
                            sx={{
                                color: "#a11816",
                                border: "3px solid #a11816 !important",
                                p: 0,
                                "&:disabled": {
                                    color: "#fff",
                                    backgroundColor: buttonStatusColor,
                                    border: "grey !important",
                                },
                                "&:hover": {
                                    backgroundColor: "#a11816",
                                    color: "#fff",
                                },
                            }}
                        >
                            {" "}
                            {!loading && (
                                <Typography variant={"h5"}>Submit</Typography>
                            )}
                        </LoadingButton>

                        {apiSuccess && (
                            <Typography
                                align="center"
                                variant="body1"
                                sx={{ color: "success.main" }}
                            >
                                Thank you. Your message was sent successfully.
                            </Typography>
                        )}

                        {apiFail && (
                            <Typography
                                align="center"
                                variant="body1"
                                sx={{ color: "error.main" }}
                            >
                                We encountered an error sending your inquiry.
                                Please try again.
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}

CustomerInquiryForm.propTypes = propTypes

export default CustomerInquiryForm
