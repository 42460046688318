/** @jsxImportSource @emotion/react */
/** @format */

import { css } from "@emotion/react"
import { Grid, Typography, useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/styles"
import MD from "react-markdown"
import rehypeRaw from "rehype-raw"

import Link from "../Link"
import OptimizedImage from "../OptimizedImage"
import Slide from "../Slide"
import { Actions, Heading, Socials } from "./index"

export const Card = ({
    name,
    actionAlignment,
    backgroundColor,
    body,
    children,
    heading,
    hideImage,
    hideOnMobile,
    hideOnDesktop,
    icon,
    image,
    imageWidth,
    imageHeight,
    isDarkMode,
    learnMoreUrl,
    title,
    titleAlignment,
    titleColor,
    titleConfig,
    titleLink,
    socials,
    subheading,
    variant,
    verticalAlignment,
    verticalPadding,
    actionsCollection = {},
    ...props
}) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

    let {
        actions,
        subtitle = {},
        imageAlignment,
        imageAlignmentMobile,
        contentAlignment,
    } = props
    imageAlignment =
        imageAlignmentMobile && isMobile ? imageAlignmentMobile : imageAlignment
    actions = actions || actionsCollection.items || []
    actions = actions.filter((action) => !action?.fields?.hidden)
    subtitle = subtitle.fields || subtitle

    const hasBody = body || actions?.length > 0 || socials

    const cardBody = hasBody && (
        <Grid
            container
            direction={"column"}
            flexWrap={"nowrap"}
            style={
                actionAlignment === "inline"
                    ? { height: "auto" }
                    : { height: "100%" }
            }
        >
            {body && (
                <Grid
                    item
                    flexGrow={
                        ["tile", "outlined"].indexOf(variant) >= 0 ? 1 : 0
                    }
                    xs={12}
                    css={css`
                        width: 100%;
                    `}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: contentAlignment,
                            width: "100%",
                        }}
                    >
                        <Typography
                            {...{
                                level: "body1",
                            }}
                            component="div"
                        >
                            <MD components={{
                                a: ({ node, ...props }) => {
                                    return <Link {...props} underline="hover" />
                                }
                            }}
                                rehypePlugins={[rehypeRaw]}>{body}</MD>
                        </Typography>
                    </div>
                </Grid>
            )}

            {actions?.length > 0 && (
                <Actions {...{ actions, alignment: contentAlignment }} />
            )}

            {socials && <Socials {...socials} />}
        </Grid>
    )

    const media = image?.fields || image
    const { height, ...mediaProps } = media || {} //remove height universally

    let direction = (!media || !imageAlignment) && "column"
    direction =
        direction || (["start", "left"].indexOf(imageAlignment) >= 0 && "row")
    direction =
        direction ||
        (["end", "right"].indexOf(imageAlignment) >= 0 && "row-reverse")
    direction = direction || (imageAlignment === "stacked" && "column")
    direction = direction || "column"

    const mediaHeight = (imageHeight && imageHeight + "em") || "100%"
    let mediaWidth = direction === "column" ? 12 : 6
    mediaWidth = (imageWidth && imageWidth * 0.01 * 12) || mediaWidth
    mediaWidth = imageAlignment === "stacked" && isMobile ? 12 : mediaWidth

    const getMedia = (mediaProps) => {
        if (hideImage) return null

        const mediaType = mediaProps.file.contentType.split("/")[0]

        switch (mediaType) {
            case "image":
                return (
                    <OptimizedImage
                        {...{ ...mediaProps }}
                        containerStyle={{
                            height: mediaHeight,
                            display: "flex",
                        }}
                    />
                )
            case "video":
                return (
                    <video width="100%" controls>
                        <source
                            src={`https:${mediaProps.file.url}`}
                            type={mediaProps.contentType}
                        />
                        Your browser does not support the video tag.
                    </video>
                )
        }
    }

    const hasContent = title || heading || subtitle || subheading || cardBody || children
    const link = learnMoreUrl?.fields
    const card =
        variant === "tile" ? (
            <>
                <Slide
                    imgSrc={media?.file?.url || media?.url}
                    title1={title}
                    title2={subheading || subtitle?.text}
                    link={link?.url}
                    linkTitle={link?.text}
                    content={cardBody}
                    contentLinkText={null}
                    contentLink={null}
                    display={{
                        xs: hideOnMobile ? "none" : "block",
                        sm: hideOnDesktop ? "none" : "block",
                    }}
                    sm={12}
                    padded={false}
                />
            </>
        ) : (
            <Grid
                item
                xs={12}
                display={{
                    xs: hideOnMobile ? "none" : "flex",
                    sm: hideOnDesktop ? "none" : "flex",
                }}
                css={css`
                    background-color: ${backgroundColor};
                    padding-left: ${variant === "outlined" && "1em"};
                    padding-right: ${variant === "outlined" && "1em"};
                    padding-top: ${(["top", "both"].indexOf(verticalPadding) >=
                        0 &&
                        "2em") ||
                    (variant === "outlined" && "1em")};
                    padding-bottom: ${(["bottom", "both"].indexOf(
                        verticalPadding
                    ) >= 0 &&
                        "2em") ||
                    (variant === "outlined" && "1em")};
                `}
            >
                <Grid
                    container
                    direction={direction}
                    alignItems={verticalAlignment || "center"}
                    sx={{
                        flexWrap: "nowrap",
                    }}
                >
                    {media && imageAlignment !== "background" && !hideImage && (
                        <Grid
                            item
                            xs={12}
                            md={mediaWidth}
                            style={{
                                padding: hasContent ? "1em" : "none",
                            }}
                            sx={{
                                width: `${(mediaWidth * 100) / 12}%`,
                            }}
                        >
                            {getMedia(mediaProps)}
                        </Grid>
                    )}

                    {hasContent && (
                        <Grid
                            item
                            container
                            direction={"column"}
                            justifyContent={verticalAlignment}
                            xs={12}
                        >
                            <Grid
                                item
                                container
                                flexGrow={
                                    ["tile", "outlined"].indexOf(variant) >= 0
                                        ? 1
                                        : 0
                                }
                                direction={"column"}
                                alignItems={contentAlignment}
                            >
                                {(title || heading) && (
                                    <Grid item>
                                        <Heading
                                            {...{
                                                ...{
                                                    text: title || heading,
                                                    level: "h3",
                                                },
                                                icon,
                                                alignment: titleAlignment,
                                                color: titleColor,
                                                link: titleLink,
                                                ...(titleConfig || {}),
                                            }}
                                        />
                                    </Grid>
                                )}
                                {(subtitle || subheading) && (
                                    <Grid item>
                                        <Heading
                                            {...{
                                                ...(subtitle || {
                                                    text: subheading,
                                                }),
                                                level: subtitle?.level || "h4",
                                                color: titleColor,
                                                alignment: titleAlignment,
                                            }}
                                        />
                                    </Grid>
                                )}
                                {cardBody && (
                                    <Grid
                                        item
                                        flexGrow={
                                            ["tile", "outlined"].indexOf(
                                                variant
                                            ) >= 0
                                                ? 1
                                                : 0
                                        }
                                    >
                                        {cardBody}
                                    </Grid>
                                )}
                                {children}
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        )

    const backgroundImage =
        variant !== "tile" && imageAlignment === "background"
            ? `url(${media?.file?.url || media?.src}?fm=webp)`
            : null
    const background = backgroundImage
        ? backgroundImage // `linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), ${backgroundImage}`
        : null

    return (
        <Grid
            container
            item
            flexGrow={1}
            style={{ background }}
            css={css`
                border: ${variant === "outlined" ? "1px solid #ebebeb" : ""};
            `}
        >
            {card}
        </Grid>
    )
}
