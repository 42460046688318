/** @format */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { Grid, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { useRef } from "react"

import Link from "../Link"

const useStyles = makeStyles(() => ({
    HideFirstRowOnMobile: {
        "@media (max-width: 600px)": {
            display: "none",
        },
    },
}))

const Slide = ({
    imgSrc,
    title,
    title1,
    title2,
    title3,
    link,
    linkTitle,
    content,
    customTitle,
    video,
    sm = 4, //TODO deprecate
    isHideShadow = false,
    isHideFirstRow = false,
    height = "230px",
    icon,
    contentLinkText,
    contentLink,
    padded = true,
    // disableClick = false,
}) => {
    const classes = useStyles()
    const videoRef = useRef(null)

    const getTypographyTag = () => {
        return (
            <Typography
                component='span'
                title={linkTitle}
                css={css`
                    text-decoration: none;
                    color: #fff;
                    &:hover {
                        color: #dbdbdb;
                    }
                `}
            >
                {video ? (
                    <>
                        <video
                            muted
                            ref={videoRef}
                            css={css`
                                width: 348px;
                                height: 230px;
                                z-index: 13px;
                            `}
                        >
                            <source src={video} type="video/mp4" />
                        </video>
                    </>
                ) : (
                    <Grid
                        container
                        css={css`
                            height: ${height};
                            overflow: hidden;
                            position: relative;
                            padding: 20px 0 0 15px;
                            background-repeat: no-repeat;
                            background-size: cover;
                            background-color: #000;
                            display: block;
                            background-image: url(${imgSrc}?fm=webp);
                        `}
                    >
                        {!isHideShadow && (
                            <div
                                css={css`
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    z-index: 5;
                                    width: 100%;
                                    height: 100%;
                                    background-color: rgba(0, 0, 0, 0.54);
                                `}
                            ></div>
                        )}

                        {!isHideFirstRow && (
                            <span
                                className={classes.HideFirstRowOnMobile}
                                css={css`
                                    margin: 10px 0;
                                    display: block;
                                `}
                            >
                                &nbsp;
                            </span>
                        )}
                        {customTitle ? customTitle : undefined}

                        {title1 && (
                            <Typography
                                component={"h3"}
                                variant={"h3"}
                                css={css`
                                    position: relative;
                                    z-index: 10;
                                    margin: 0;
                                    font-size: 1.4rem;
                                    line-height: 26px;
                                    text-transform: uppercase;
                                    text-shadow: 4px 3px 8px #000;
                                    color: inherit;
                                    font-weight: 400;
                                    @media (min-width: 992px) and (max-width: 1199px) {
                                        font-size: 1.5rem;
                                        line-height: 1.8rem;
                                    }
                                    @media (min-width: 768px) and (max-width: 991px) {
                                        font-size: 1.2rem;
                                        line-height: 1.5rem;
                                    }
                                `}
                            >
                                {title1}
                            </Typography>
                        )}

                        {title2 && (
                            <Typography
                                className={`title-2-info`}
                                component={"h2"}
                                variant={"h2"}
                                css={css`
                                    position: relative;
                                    z-index: 10;
                                    margin: 0;
                                    font-size: 2.1rem;
                                    line-height: 34px;
                                    text-transform: uppercase;
                                    text-shadow: 4px 3px 8px #000;
                                    color: inherit;
                                    @media (max-width: 375px) {
                                        font-size: 1.4rem;
                                    }
                                    @media (max-width: 599px) and (min-width: 376px) {
                                        font-size: 2rem;
                                    }
                                    @media (min-width: 600px) and (max-width: 991px) {
                                        font-size: 1.4rem;
                                        line-height: 1.6rem;
                                    }
                                    @media (min-width: 992px) and (max-width: 1199px) {
                                        font-size: 1.8rem;
                                        line-height: 2rem;
                                    }
                                `}
                            >
                                {title2}
                            </Typography>
                        )}

                        {title3 && (
                            <Grid>
                                <Typography
                                    className={`title-3-info`}
                                    component={"h3"}
                                    variant={"h3"}
                                    css={css`
                                        @media (min-width: 768px) and (max-width: 991px) {
                                            font-size: 1.2rem;
                                        }
                                    `}
                                >
                                    {title3}
                                </Typography>
                            </Grid>
                        )}

                        {link && (
                            <Typography
                                component={"span"}
                                variant={"body"}
                                title={title}
                                aria-label={`Learn more about ${title}`}
                                css={css`
                                    position: absolute;
                                    font-weight: 800;
                                    bottom: 10px;
                                    left: 10px;
                                    z-index: 10;
                                    font-size: 0.9rem;
                                    text-transform: uppercase;
                                    color: #fff;
                                    text-decoration: none;
                                    &:hover {
                                        text-decoration: underline;
                                    }
                                `}
                            >{`Learn More`}</Typography>
                        )}
                    </Grid>
                )}
            </Typography>
        )
    }

    return (
        <Grid
            item
            container
            direction={"column"}
            sm={sm}
            xs={12}
            css={css`
                ${padded ? "padding: 0 15px 25px 15px;" : ""}
            `}
        >
            <Grid item>
                {link ? (
                    <Link href={link} noLinkStyle>
                        {getTypographyTag()}
                    </Link>
                ) : (
                    getTypographyTag()
                )}
            </Grid>

            {/* Text content below image tile */}
            <Grid item container direction={"column"} flexGrow={1}>
                <Grid item flexGrow={1}>
                    <Typography
                        component={"div"}
                        variant={"subtitle1"}
                        css={css`
                            height: 100%;
                            margin-top: 10px;
                            font-size: 0.9rem;
                            color: #595959 !important;
                        `}
                    >
                        {content}
                    </Typography>
                </Grid>

                {/* {contentLinkText && ( */}
                <Grid
                    item
                    css={css`
                        margin-top: 1.25rem;
                        margin-left: 5.5rem;
                    `}
                >
                    <Link
                        css={css`
                            color: #005275;
                        `}
                        href={contentLink || "#"}
                        noLinkStyle
                    >
                        {contentLinkText || ""}
                    </Link>
                </Grid>
                {/* )} */}
            </Grid>
        </Grid>
    )
}

export default Slide
