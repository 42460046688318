import { useRouter } from "next/router"
import { useContext, useCallback } from "react"
import { Context } from "../context"
import { dataLayer, GTM_EVENTS } from "../util/index"

const useCtaDataLayerEvent = (name, callback) => {
    const {
        state: { store },
    } = useContext(Context)
    const router = useRouter()

    const mapCtaToGtmEvent = {
        "book an appointment": "appointmentCtaClick",
        "find your location": "appointmentCtaClick",
        "get online estimate": "oetCtaClick",
    }
    const gtmEvent = mapCtaToGtmEvent[`${name.toLowerCase()}`]

    const handleDataLayerEvent = useCallback(
        (e) => {
            dataLayer({
                event: GTM_EVENTS[gtmEvent],
                gtm_storeid: store?.storeId || router?.query?.storeid || "",
                gtm_pagerefsource: window.document.referrer,
                gtm_dma: store?.dma ?? "",
            })

            callback && callBack(e)
        },
        [callback]
    )

    return handleDataLayerEvent
}

export default useCtaDataLayerEvent
