const { default: axios } = require("axios")
const { space, environment, isPreview, accessToken } = require("./config")

const getGraphQLClient = async (preview) => {
    const SPACE = space
    const ENVIRONMENT = environment
    const CONTENTFUL_URL = `https://graphql.contentful.com/content/v1/spaces/${SPACE}/environments/${ENVIRONMENT}`
    const CONTENTFUL_PREVIEW = preview || isPreview

    const headers = {
        Authorization: `Bearer ${accessToken(CONTENTFUL_PREVIEW)}`,
    }

    const client = {
        query: (data) => {
            const requestOptions = {
                url: CONTENTFUL_URL,
                method: "POST",
                headers,
                data,
            }

            const request = axios.request(requestOptions)

            return request
        },
    }

    return client
}

const getQuery = (
    { type: { type, queryFragment }, name },
    { preview, locale = "en-US" }
) => {
    const gql = {
        query: `query(
          $name: String!
          $preview: Boolean! = false
          $locale: String! = "en-US"
        ) {
          ${type}(
            where: { name: $name }
            preview: $preview
            locale: $locale
            limit: 1
          ) {
            ${queryFragment}
          }
        }`,
        variables: {
            locale,
            name,
            preview,
        },
    }

    return gql
}

const queryContent = async ({ id, type, name }, { preview, ...options }) => {
    if (!name) return

    const CONTENTFUL_PREVIEW = preview || isPreview
    const query = getQuery(
        { type, name },
        { preview: CONTENTFUL_PREVIEW, ...options }
    )

    try {
        const client = await getGraphQLClient(CONTENTFUL_PREVIEW)
        const clientQuery = await client.query(query)

        let {
            data: { data },
        } = clientQuery

        if (!data[type.type]) return

        data = data[type.type].items[0]

        const resp = id ? { [id]: data || null } : data

        return resp
    } catch (err) {
        console.error(`Query Error for ${name}`, err)
        throw new Error(err)
    }
}

const ContentModels = {}
ContentModels.Asset = `
  url
  img: url
  src: url
  title
  description
  alt: description
  width
  height
`
ContentModels.Heading = `
  name
  text
  level
  style
  alignment
  width
  color
  fontSizeMultiplier
  htmlStyles
  logo {
    ${ContentModels.Asset}
  }
`
ContentModels.Link = `
  name
  text
  title: text
  url
  link: url
  icon
  hidden
`
ContentModels.CtaButton = `
  name
  path
  page {
    name
    path
    slug
  }
  color
  variant
  text: children
  icon
  iconPlacement
  fullWidth
  disabled
`
ContentModels.Cta = `
  label
  type
  placeholder
  icon
  iconPlacement
  button {
    ${ContentModels.CtaButton}
  }
`
ContentModels.InputField = `
  name
  label
  placeholder
  type
  icon
  iconPlacement
  button {
    ${ContentModels.CtaButton}
  }
  spacing
  width
`
ContentModels.SocialMedia = `
  text: connectWithUs
  icons: socialMediaLinksCollection {
    items {
      ${ContentModels.Link}
    }
  }
`
ContentModels.Modal = `
  title
  heading {
    ${ContentModels.Heading}
  }
  subtitle {
    ${ContentModels.Heading}
  }
  cta {
    ${ContentModels.Cta}
  }
  body
`
ContentModels.Actions = `
  items {
    __typename
    ... on CtaButton {
      ${ContentModels.CtaButton}
    }
    ... on Link {
      ${ContentModels.Link}
    }
    ... on InputField {
      ${ContentModels.InputField}
    }
  }
`
ContentModels.Navigation = `
  name
  text
  subMenu: navigationItemsCollection(limit:10) {
    items {
      ... on NavItem {
        name
        text
        navLink {
          name
          path
          slug
        }
      }
    }
  }
`

const ContentType = {
    Footer: {
        type: "maacoFooterCollection",
        queryFragment: `items {
          maaco_logo: logo {
            ${ContentModels.Asset}
          }
          links: webLinksCollection {
            items {
              ${ContentModels.Link}
            }
          }
          copyright
          card_img: creditCardImage {
            ${ContentModels.Asset}
          }
          learnMore {
            ${ContentModels.Link}
          }
          socials {
            ... on SocialMedia {
              ${ContentModels.SocialMedia}
            }
          }
          footer_links: legalLinksCollection {
            items {
              ${ContentModels.Link}
            }
          }
        }`,
    },
    Header: {
        type: "siteWideHeaderCollection",
        queryFragment: `items {
          maacoLogo {
            ${ContentModels.Asset}
          }
          findYourMaaco
          navBarLinksCollection {
            items {
              ... on Link {
                ${ContentModels.Link}
              }
              ... on Navigation {
                ${ContentModels.Navigation}
              }
            }
          }
          contactUsCard {
            ... on Modal {
              ${ContentModels.Modal}
            }
          }
          callForInquiries
          actionsCollection {
            ${ContentModels.Actions}
          }
          localeSwitcherCollection {
            items {
              ... on Link {
                ${ContentModels.Link}
              }
            }
          }
        }`,
    },
    Card: {
        name: "card",
        type: "cardCollection",
        queryFragment: `items {
          __typename
          actionsCollection {
            ${ContentModels.Actions}
          }
          backgroundColor
          body
          contentAlignment
          hideOnMobile
          hideOnDesktop
          icon
          image {
            ${ContentModels.Asset}
          }
          imageAlignment
          isDarkMode
          learnMoreUrl {
            ${ContentModels.Link}
          }
          title
          titleAlignment
          titleColor
          socials {
            ${ContentModels.SocialMedia}
          }
          subheading
          subtitle {
            ${ContentModels.Heading}
          }
          variant
          verticalAlignment
        }`,
    },
}

const Content = {
    Footer: { id: "footer", type: ContentType.Footer, name: "Maaco Footer" },
    FooterMobile: {
        id: "footerMobile",
        type: ContentType.Footer,
        name: "Maaco Mobile Footer",
    },
    Header: {
        id: "header",
        type: ContentType.Header,
        name: "Maaco Site Wide Header",
    },
}

module.exports = { Content, ContentType, queryContent }
