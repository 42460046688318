import CustomerServiceForm from "../Forms/CustomerServiceForm"
import { FranchiseCareersForm } from "../Forms/FranchiseCareersForm"
import { RealEstateForm } from "../Forms/RealEstateForm"

const formMap = {
    "Customer Service - Page Section Form": CustomerServiceForm,
    "Real Estate Wanted - Page Section Form": RealEstateForm,
    "Careers at Maaco Form": FranchiseCareersForm,
}

export const Form = ({ name, ...props }) => {
    const Form = formMap[name]

    if (Form) return <Form />

    console.log("Form Not Found", { name, ...props })
    return null
}
