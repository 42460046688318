/** @format */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Grid,
    Snackbar,
} from "@mui/material"
import { useCallback, useContext, useEffect, useState } from "react"

import { Context, OetContext } from "../../context"
import { containerCSS, getVisitedURL } from "../../util"
import { getOetStepContent } from "../../util/contentful/api"
import {
    OET_OUTCOMES,
    OET_STEP_LIST,
    OET_STEPS,
} from "../../util/globalOETVariables"
import { handleCaughtError, handleError } from "../../util/handlers"
import { OET_API } from "../../util/oetApi"
import {
    getFeatureFlag,
    handleDataLayerSubmissionEvent,
    handleOetStepSubmission,
} from "../../util/oetApiHelpers"
import { FormWrapper } from "../Forms/FormWrapper/form-wrapper"
import Steppers from "./Components/Steppers"
import OETStep from "./Steps/Step"
import { oetOutcomeMapper } from "./utils/oetOutcome"

const steps = Object.values(OET_STEPS)

const OnlineEstimatorForm = ({ preview }) => {
    const { state, dispatch } = useContext(OetContext)
    const { state: maacoState, dispatch: maacoDispatch } = useContext(Context)
    const [apiSubmitting, setApiSubmitting] = useState(false)
    const [resetForm, setResetForm] = useState(false)

    const [estimateStepContent, setEstimateStepContent] = useState(null)
    const [loadingStepContent, setLoadingStepContent] = useState(false)

    const estimateStep = OET_STEP_LIST.findIndex(({ contentfulSteps }) =>
        contentfulSteps.includes(state?.estimateStep)
    )
    const step = estimateStep + 1
    const isFirstStep = step === 1
    const isLastStep = state?.estimateStep === OET_STEPS.estimate
    const navigationStep = steps.indexOf(state?.estimateStep)

    const hasShortCutPath = state.service.isOetComplete
    const showStepper = step !== 4 || (step === 4 && !hasShortCutPath)

    const handleCloseAlert = () => {
        dispatch({ type: "ERRORS", payload: "" })
    }

    const handleRestart = useCallback(() => {
        dispatch({
            type: "RESET_OET",
        })

        maacoDispatch({
            type: "CURRENT_STORE",
            payload: undefined,
        })
    }, [dispatch, maacoDispatch])

    const getEstimateStepContent = useCallback(
        async (step) => {
            let stepContent = []
            setLoadingStepContent(true)
            try {
                stepContent =
                    (await getOetStepContent({
                        resolveLinks: true,
                        preview,
                        step,
                    })) ?? []

                setEstimateStepContent(stepContent)
                setLoadingStepContent(false)
            } catch (err) {
                setLoadingStepContent(false)
                handleError(err, false)
            }
        },
        [preview]
    )

    const handleEstimateStep = useCallback(
        (step) => {
            dispatch({
                type: "ESTIMATE_STEP",
                payload: {
                    estimateStep: steps[step],
                    estimateSteps: OET_STEP_LIST.length,
                },
            })
        },
        [dispatch]
    )

    const handleOETFormSubmit = useCallback(
        async (formData) => {
            // PREVENTING FORM SUBMISSION FROM APPOINTMENT AT LAST STEP
            if (state?.estimateStep === "Estimate Summary") return

            const location = maacoState.currentStore
                ? maacoState.currentStore
                : state.location

            if (step === 1 && !state.featureFlag) {
                await getFeatureFlag(location, dispatch)
            }

            // DISPATCHING DATA TO STATE REGARDLESS OF SUBMISSION SUCCESS
            if (state?.estimateStep === OET_STEPS.selectLocation) {
                dispatch({
                    type: "STORE_LOCATION",
                    payload: { ...location },
                })
                // SEND DATA LAYER EVENT AND PASS TO NEXT STEP WITHOUT HITTING THE API
                handleDataLayerSubmissionEvent({
                    step,
                    state: { ...state, location },
                })
                setResetForm(true)
                handleEstimateStep(navigationStep + 1)
                setApiSubmitting(false)

                return
            } else if (state?.estimateStep === OET_STEPS.yourInfo) {
                dispatch({
                    type: "USER_INFO",
                    payload: {
                        ...state.userInfo,
                        ...formData,
                    },
                })
            } else if (state?.estimateStep === OET_STEPS.vehicleInfo) {
                dispatch({
                    type: "VEHICLE_INFO",
                    payload: {
                        ...state.vehicleInfo,
                        ...formData,
                    },
                })
            } else if (state?.estimateStep === OET_STEPS.vehicleServices) {
                if (formData.paintType) {
                    dispatch({
                        type: "ESTIMATE",
                        payload: Math.floor(
                            Number(
                                location?.oetWholeVehiclePricing[
                                    `${formData.paintType}Paint`
                                ]
                            )
                        ).toLocaleString(),
                    })
                }
            }

            if (apiSubmitting) return

            const leadId = state?.leadId ? { leadId: state.leadId } : null
            const storeId = location ? { storeId: location.storeId } : null

            const lastStepPayload =
                state?.estimateStep === OET_STEPS.vehicleServices
                    ? {
                          ...state.userInfo,
                          ...state.vehicleInfo,
                          ...state.service,
                          oetWholeVehiclePricing:
                              location.oetWholeVehiclePricing,
                      }
                    : null

            let setPayload = {
                ...leadId,
                ...storeId,
                ...lastStepPayload,
                visitedUrl: getVisitedURL(window.location),
                userIp: sessionStorage.getItem("userIp"),
            }

            if (formData) {
                setPayload = { ...formData, ...setPayload }
            }

            try {
                setApiSubmitting(true)

                const response = await handleOetStepSubmission(
                    state?.estimateStep,
                    {
                        ...setPayload,
                    }
                )

                if (!response?.oetResponse?.leadId) {
                    setApiSubmitting(false)
                    dispatch({
                        type: "ERRORS",
                        payload:
                            "Whoops, something went wrong while submitting the form. Please try again.",
                    })
                    return
                }

                if (state?.estimateStep === OET_STEPS.yourInfo) {
                    dispatch({
                        type: "LEAD_ID",
                        payload: response?.oetResponse?.leadId,
                    })
                }

                const outcome = oetOutcomeMapper(formData)
                const isFastPath =
                    outcome &&
                    (outcome === OET_OUTCOMES.fastPathAge ||
                        outcome === OET_OUTCOMES.fastPathEV ||
                        outcome === OET_OUTCOMES.fastPathOther)

                if (
                    state?.estimateStep === OET_STEPS.vehicleInfo &&
                    isFastPath
                ) {
                    dispatch({
                        type: "ESTIMATE_OUTCOME",
                        payload: outcome,
                    })

                    handleDataLayerSubmissionEvent({
                        step: OET_STEPS.estimate,
                        state: { ...state, location },
                    })
                }

                // HANDLE OET COMPLETE GTM EVENT
                if (state?.estimateStep === OET_STEPS.vehicleServices) {
                    // THIS WILL ONLY BE POSSIBLE FOR THIS STEP, AS
                    // THE COUPON OR APPOINTMENT APIS CAN FAIL
                    if (response.errors?.length) {
                        response.errors.forEach(({ type, error }) => {
                            dispatch({
                                type: "ERRORS",
                                payload: `${type} Error: ${error}`,
                            })
                        })
                    }

                    const {
                        vehicleService,
                        insuranceAction,
                        spotRepairAction,
                        undecidedAction,
                        ...data
                    } = formData

                    if (!response.appointmentSet) {
                        delete data.appointmentDate
                        delete data.appointmentTime
                        delete formData.appointmentDate
                        delete formData.appointmentDate
                    }

                    dispatch({
                        type: "ESTIMATE_OUTCOME",
                        payload: outcome,
                    })

                    dispatch({
                        type: "SERVICE_DETAIL",
                        payload: {
                            ...state.service,
                            type: vehicleService,
                            atRisk: spotRepairAction === OET_OUTCOMES.atRisk,
                            customerAction:
                                (spotRepairAction || undecidedAction) ===
                                OET_OUTCOMES.customerAction,
                            followUp:
                                insuranceAction ===
                                    OET_OUTCOMES.followUpInsurance ||
                                spotRepairAction ===
                                    OET_OUTCOMES.followUpSpot ||
                                undecidedAction ===
                                    OET_OUTCOMES.followUpUndecided,
                            setAppointment: Boolean(
                                data.appointmentTime &&
                                    data.appointmentDate &&
                                    response.appointmentSet
                            ),
                            ...data,
                        },
                    })

                    handleDataLayerSubmissionEvent({
                        step: 5, // Estimate Summary - the fifth & final step
                        state: { ...state, location },
                    })
                }

                // LOCATION IS NOT SET IN THE STATE WHEN
                // IT IS NEEDED AFTER STEP ONE SUBMISSION
                // HANDLE OET STEP COMPLETE EVENTS (NOT INCLUDING OET COMPLETE)
                handleDataLayerSubmissionEvent({
                    step,
                    state: { ...state, location },
                })

                const nextStepValue = isFastPath ? 2 : 1

                setResetForm(true)
                handleEstimateStep(navigationStep + nextStepValue)
                setApiSubmitting(false)
            } catch (error) {
                setApiSubmitting(false)
                console.log("error occurred in submitting form ", error)
                dispatch({
                    type: "ERRORS",
                    payload:
                        `Error submitting form. ${error.message}` ??
                        "Whoops, something went wrong while submitting the form. Please try again.",
                })
            }
        },
        [
            apiSubmitting,
            dispatch,
            handleEstimateStep,
            maacoState?.currentStore,
            navigationStep,
            state,
            step,
        ]
    )

    // FETCH STEP CONTENT FROM CONTENTFUL WHEN ESTIMATE STEP UP UPDATED
    useEffect(() => {
        if (state?.estimateStep === estimateStepContent?.name) return
        getEstimateStepContent(state?.estimateStep)
    }, [getEstimateStepContent, estimateStepContent?.name, state?.estimateStep])

    useEffect(() => {
        dispatch({
            type: "FORM_SUBMITTING",
            payload: apiSubmitting,
        })
    }, [apiSubmitting, dispatch])

    // RESET FORM TO NEW DEFAULT VALUES WHEN STEP UPDATES
    useEffect(() => {
        setResetForm(false)
        return () => setResetForm(true)
    }, [state?.estimateStep])

    // SEND ABANDON REQUEST WHEN USER NAVIGATES AWAY OR REFRESHES PAGE
    useEffect(() => {
        // run unmount logic to capture abandon
        return async () => {
            const { leadId, estimateStep, estimateSteps, location, userInfo } =
                state
            // Cannot successfully submit an abandon if users did not submit their information.
            // Consequence of missing user info: LMT & GLF will see undefined for user values.
            if (
                window.location.href.indexOf("/online-estimator") === -1 &&
                leadId &&
                userInfo?.firstName &&
                estimateStep !== OET_STEPS.estimate
            ) {
                const postData = {
                    leadId,
                    storeId: location?.storeId || null,
                    visitedUrl: getVisitedURL(window.location),
                    userIp: sessionStorage.getItem("userIp"),
                    step:
                        Object.values(OET_STEPS).findIndex(
                            (step) => step === estimateStep
                        ) + 1,
                }

                // TODO: Determine if we need to clear any local context values +
                OET_API.postOetAbandon(postData).catch((e) => {
                    handleCaughtError("error at Abandon oet process", e)
                })
            }
        }
    }, [state])

    useEffect(() => {
        return () => {
            if (!isLastStep) return

            handleRestart()
        }
    }, [isLastStep, handleRestart])

    if (!estimateStepContent) return null

    return (
        <div
            css={css`
                ${containerCSS}
                display: ${loadingStepContent ? "flex" : "block"};
                align-items: ${loadingStepContent ? "center" : "inherit"};
                justify-content: ${loadingStepContent ? "center" : "inherit"};
                padding: 0;
                margin-top: 40px;
                height: ${loadingStepContent ? "500px" : "inherit"};
                width: 100%;
                @media (min-width: 768px) {
                    padding-left: 0;
                    padding-right: 0;
                    width: 100%;
                }
                @media (min-width: 992px) {
                    padding-left: 0;
                    padding-right: 0;
                    width: 100%;
                }
                @media (min-width: 1200px) {
                    width: 1170px;
                }
            `}
        >
            {loadingStepContent ? (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    {showStepper && (
                        <Steppers
                            steps={OET_STEP_LIST}
                            step={step}
                            activeColor={"#D40E00"}
                            inactiveColor={"#CCCCCC"}
                        />
                    )}

                    <FormWrapper
                        formName={state?.estimateStep}
                        handleFormSubmit={handleOETFormSubmit}
                        resetValues={resetForm}
                    >
                        <OETStep {...estimateStepContent}>
                            <Grid
                                item
                                display="flex"
                                justifyContent="center"
                                xs={12}
                                sx={{ mt: 8 }}
                            >
                                {!(isFirstStep || isLastStep) && (
                                    <Button
                                        disabled={apiSubmitting}
                                        variant="outlined"
                                        color="secondary"
                                        onClick={() => {
                                            // NAVIGATE TO PREVIOUS STEP
                                            handleEstimateStep(
                                                navigationStep - 1
                                            )
                                        }}
                                    >
                                        Back
                                    </Button>
                                )}

                                {!(isFirstStep || isLastStep) && (
                                    <Button
                                        disabled={apiSubmitting}
                                        variant="contained"
                                        color="secondary"
                                        type="submit"
                                    >
                                        Continue
                                    </Button>
                                )}

                                {isLastStep && (
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={handleRestart}
                                    >
                                        Restart
                                    </Button>
                                )}
                            </Grid>
                        </OETStep>
                    </FormWrapper>
                </>
            )}
            {!!state?.error && state.error?.length > 0 && (
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={!!state.error}
                    onClose={handleCloseAlert}
                >
                    <Alert
                        onClose={handleCloseAlert}
                        severity="error"
                        variant="filled"
                        sx={{ width: "100%" }}
                    >
                        {state.error}
                    </Alert>
                </Snackbar>
            )}
        </div>
    )
}

export default OnlineEstimatorForm
