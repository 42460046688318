/** @format */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { NextLinkComposed } from "../../Link"
import { Button } from "@mui/material"
import useCtaDataLayerEvent from "../../../hooks/useCtaDataLayerEvent"

const CustomButtonRounded = ({
    label,
    path,
    color,
    variant,
    children,
    icon,
    iconPlacement,
    fullWidth = false,
    disabled,
    handleOnClick,
    sxProps,
    cssStyles,
}) => {
    const buttonText = label || children
    const handleDataLayerEvent = useCtaDataLayerEvent(buttonText, handleOnClick)

    const buttonIcon = icon && (
        <span
            className={icon}
            css={css`
                font-size: 1.5rem;
                ${iconPlacement === "left"
                    ? "margin-right: 6px"
                    : "margin-left: 6px"}
            `}
        ></span>
    )

    const getBackgroundColor = (color) =>
        color === "primary" ? "primary.light" : "secondary"

    const variantCustomStyles =
        variant === "outlined"
            ? {
                  borderWidth: "2px",
                  borderColor: getBackgroundColor(color),
                  backgroundColor: "white",
                  "&:hover": {
                      color: "white",
                      backgroundColor: getBackgroundColor(color),
                  },
              }
            : {
                  color: "white",
                  backgroundColor: getBackgroundColor(color),
                  "&:hover": { backGroundColor: "" },
              }

    return (
        <Button
            LinkComponent={!path ? undefined : NextLinkComposed}
            to={path}
            color={color}
            variant={variant === "filled" ? "contained" : variant}
            startIcon={iconPlacement === "left" && buttonIcon}
            endIcon={iconPlacement === "right" && buttonIcon}
            fullWidth={fullWidth}
            disabled={disabled}
            onClick={(e) => handleDataLayerEvent(e)}
            sx={[
                {
                    ...variantCustomStyles,
                    "&:hover > *": {
                        boxShadow: "none !important",
                    },
                },
                {
                    borderRadius: "4px",
                    whiteSpace: "nowrap",
                    ...sxProps,
                },
            ]}
            css={css`
                ${cssStyles}
            `}
        >
            {buttonText}
        </Button>
    )
}

export default CustomButtonRounded
