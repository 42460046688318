import { getEnvironmentVariable } from "./util/env"
import { CustomBackend } from "./util/i18n/config"
import { defaults } from "./util/i18n/utils"

const i18nextConfig = {
    serializeConfig: false,
    ...defaults({
        countryCode: getEnvironmentVariable({
            key: "COUNTRY_CODE",
        }),
    }),
    use: [CustomBackend],
}

export default i18nextConfig
