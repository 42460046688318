import { Box } from "@mui/material"
import axios from "axios"
import isEqual from "lodash.isequal"
import { useRouter } from "next/router"
import { publicIp } from "public-ip"
import { useContext, useEffect, useState } from "react"

import { Context } from "../../context"
import { pageview } from "../../util"
import { handleCaughtError } from "../../util/handlers"
import Footer from "../Footer"
import Loader from "../Loader"
import PreviewBanner from "../PreviewBanner"
import TopBar from "../Topbar"

const Layout = ({
    hideTopBar = false,
    hideFooter = false,
    hideLoader = false,
    hideSearchBar,
    pageTitle,
    context,
    preview = false,
    ...props
}) => {
    const { state, dispatch } = useContext(Context)
    const router = useRouter()

    const [isLoading, setIsLoading] = useState(false)

    // log SPA changes to pageviews using pageTitle
    useEffect(() => {
        const handleRouteChange = (url) => pageview(url, { title: pageTitle })

        router.events.on("routeChangeComplete", handleRouteChange)
        return () => {
            router.events.off("routeChangeComplete", handleRouteChange)
        }
    }, [router.events, pageTitle])

    useEffect(() => {
        const uid = function () {
            return (
                Date.now().toString(36) +
                Math.random().toString(36).substring(2)
            )
        }
        if (
            !sessionStorage.getItem("sessionId") ||
            sessionStorage.getItem("sessionId") === null ||
            sessionStorage.getItem("sessionId") === "null"
        ) {
            sessionStorage.setItem("sessionId", uid())
        }

        if (
            !sessionStorage.getItem("userIp") ||
            sessionStorage.getItem("userIp") === null ||
            sessionStorage.getItem("userIp") === "null"
        ) {
            ; (async () => {
                let ip = await publicIp()
                axios
                    // working ipAPI key(used in production): EIFJcBq4GslwfZ9YXOCtXqqg8LtmHgPxCxIIbcOBgCEOeN2Xof
                    // using old key (RMZVfeLvxIhNcUYaiBxCVnnJVN70WHIdWuELscz4xmilWV4bXL) in stage to reduce rate usage
                    .get(
                        `https://ipapi.co/${ip}/json/?key=EIFJcBq4GslwfZ9YXOCtXqqg8LtmHgPxCxIIbcOBgCEOeN2Xof`

                        // `https://ipapi.co/${ip}/json/?key=EIFJcBq4GslwfZ9YXOCtXqqg8LtmHgPxCxIIbcOBgCEOeN2Xof`
                    )
                    .then((res) => {
                        if (res) {
                            sessionStorage.setItem("userIp", ip)
                        }
                    })
                    .catch((error) => {
                        if (error) {
                            sessionStorage.setItem("userIp", null)
                            handleCaughtError("Error getting IP ", error)
                        }
                    })
            })()
        }
    }, [])

    // Check for initial UTM_OBJECT on page load, if it's not present, assign the query string
    // As the page is not Server Side Rendered, router.query is an empty object initially, so we must use the URL API
    useEffect(() => {
        if (!sessionStorage.getItem("UTM_OBJECT")) {
            let oGquery = Object.fromEntries(
                new URLSearchParams(location.search)
            )
            sessionStorage.setItem("UTM_OBJECT", JSON.stringify(oGquery))
            sessionStorage.setItem(
                "rawLeadGenUrlString",
                location.search.slice(1, location.search.length)
            )
        }
    }, [router])

    useEffect(() => {
        const newContent = { ...state.content, ...context }

        if (isEqual(state.content, newContent)) return

        dispatch({ type: "CONTENT", payload: newContent })
    }, [context, dispatch, state])

    useEffect(() => {
        router.events.on("routeChangeStart", () => {
            setIsLoading(true)
        })

        router.events.on("routeChangeComplete", () => {
            setIsLoading(false)
        })

        router.events.on("routeChangeError", () => {
            setIsLoading(false)
        })
    }, [router])

    return (
        <>
            {preview && <PreviewBanner />}
            {!hideLoader && <Loader loading={isLoading} />}
            {!hideTopBar && (
                <TopBar dispatch={dispatch} hideSearchBar={hideSearchBar} />
            )}
            <Box sx={{ width: "100%" }}>{props.children}</Box>
            {hideFooter ? null : <Footer />}
        </>
    )
}

export default Layout
