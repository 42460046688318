/** @format */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { CircularProgress, Grid, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useRouter } from "next/router"
import { useCallback, useContext, useEffect, useState } from "react"
import Geocode from "react-geocode"

import { Context } from "../../context"
import { API } from "../../util/api"
import { getEnvironmentVariable } from "../../util/env"
import { handleCaughtError } from "../../util/handlers"
import { States } from "./states"
import { Stores } from "./stores"

export const SearchResults = ({ ...props }) => {
    const { state, dispatch } = useContext(Context)
    const router = useRouter()
    const [searchResults, setSearchResults] = useState(null)

    const { content: { dmaName, msoName } = {} } = state

    const getStores = useCallback(
        async (storeArgs) => {
            await API.getStores({
                ...storeArgs,
                isServerCall: false,
            })
                .then((stores) => {
                    setSearchResults(stores || [])
                    if (!stores?.length) return

                    dispatch({
                        type: "CURRENT_STORE",
                        payload: stores[0],
                    })
                })
                .catch((e) => {
                    if (e.message && e.message.includes("ZERO_RESULTS")) {
                        setSearchResults([])
                    } else {
                        handleCaughtError("Error getting stores from API ", e)
                    }
                })
        },
        [dispatch]
    )

    useEffect(() => {
        dispatch({ type: "STORES", payload: searchResults })
    }, [searchResults, dispatch])

    useEffect(() => {
        if (!router.isReady || !router.query.key) return

        if (dmaName || msoName) {
            dispatch({
                type: "CONTENT",
                payload: {
                    dmaName: null,
                    msoName: null,
                },
            })
        }

        Geocode.fromAddress(router.query.key)
            .then(async (response) => {
                if (response.results.length) {
                    const { lat, lng } = response.results[0].geometry.location
                    const country = getEnvironmentVariable({
                        key: "COUNTRY_CODE",
                    })
                    await getStores({
                        lat,
                        lng,
                        country,
                    })
                }
            })
            .catch((e) => {
                if (e.message && e.message.includes("ZERO_RESULTS")) {
                    setSearchResults([])
                } else {
                    handleCaughtError("Error getting search results ", e)
                }
            })
    }, [
        getStores,
        router.isReady,
        router.query.key,
        dispatch,
        dmaName,
        msoName,
    ])

    if (router.query.key && !searchResults) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <CircularProgress />
            </Box>
        )
    }

    if (searchResults && !searchResults.length)
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Typography
                        variant="h6"
                        color="primary"
                        css={css(`
                                            color: #AF0E11; font-size: 1.2rem;font-weight: bold;margin-bottom:0;
                                        `)}
                    >
                        {searchResults &&
                            "Sorry, we couldn't find any locations in your area."}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <States />
                </Grid>
            </Grid>
        )

    return <Stores {...{ stores: searchResults }} />
}
