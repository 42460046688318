/** @jsxImportSource @emotion/react */
/** @format */

import { css } from "@emotion/react"
import { Grid, Typography } from "@mui/material"
import { useTranslation } from "next-i18next"
import { useContext } from "react"
import { useSWRConfig } from "swr"

import { Context } from "../../context"
import Link from "../Link"

export const Heading = ({
    alignment,
    fontSizeMultiplier,
    icon,
    logo,
    style,
    width,
    link: { fields: link } = {},
    level = "body1",
    iconAlignment = "left",
    textTransform = "uppercase",
    ...props
}) => {
    let { text } = props

    const { t } = useTranslation()
    const { state } = useContext(Context)
    const { fallback } = useSWRConfig()

    const { content = fallback?.context } = state

    if (!text) return null

    text = t(text, content)

    let { color } = { ...props }
    color = color || (level === "h1" && "#214E70")

    const iconEl = icon && (
        <Grid item>
            <i
                className={`fa-solid fa-${icon} fa-2x`}
                css={css`
                    color: ${color || "inherit"};
                    margin: 0 0.5em;
                `}
            />
        </Grid>
    )

    let direction = (!icon || !iconAlignment) && "row"
    direction = direction || (iconAlignment === "left" && "row")
    direction = direction || (iconAlignment === "right" && "row-reverse")
    direction = direction || (iconAlignment === "stacked" && "column")

    const heading =
        (link && (
            <Link
                href={link.url}
                target={link.opensInNewTab ? "_blank" : "_self"}
                noLinkStyle
            >
                {text}
            </Link>
        )) ||
        text

    return (
        <Grid
            container
            direction={direction}
            flexWrap={"nowrap"}
            css={css`
                text-align: ${alignment};
                justify-content: ${alignment};
                align-items: center;
            `}
        >
            {iconEl}
            <Grid
                item
                css={css`
                    width: ${width}%;
                `}
            >
                <Typography
                    component={level.indexOf("body") >= 0 ? "p" : level}
                    variant={style || level}
                    textTransform={
                        textTransform || level.indexOf("body") >= 0
                            ? "none"
                            : textTransform
                    }
                    css={css`
                        display: inline-block;
                        color: ${color || "inherit"};
                        ${fontSizeMultiplier
                            ? `font-size: ${fontSizeMultiplier}em`
                            : ""}
                    `}
                >
                    {heading}
                </Typography>
            </Grid>
        </Grid>
    )
}
