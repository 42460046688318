import { yupResolver } from "@hookform/resolvers/yup"
import { useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"

// Created as an extension to the react-hook-form FormProvider
export const BaseForm = ({
    children,
    defaultValues,
    formName,
    handleFormSubmit,
    resetValues = false,
    schema,
}) => {
    const methods = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues: defaultValues,
    })

    // RESET FORM TO NEW DEFAULT VALUES WHEN FORM NAME UPDATES
    useEffect(() => {
        if (formName) {
            methods.reset(defaultValues)
        }
    }, [defaultValues, formName])

    useEffect(() => {
        if (resetValues) {
            methods.reset(defaultValues)
        }
    }, [defaultValues, resetValues])

    const formSubmit = (formData) => {
        handleFormSubmit(formData)
    }

    const formError = (error) => {
        console.log("form error ", error)
    }

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(formSubmit, formError)}>
                {children}
            </form>
        </FormProvider>
    )
}
